import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader } from "mrb/components/layout";
import { MrbToggle } from "mrb/components/checkbox";
import { MrbButton } from "mrb/components/button";
import { NakiNigunArtistPreviewAlbumList } from "administration/naki-nigun/components";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { defaultTemplate } from "common/hoc";
import { ToggleActionsLoader, Image } from "common/components";

function NakiNigunArtistPreviewTemplate({ nakiNigunArtistPreviewViewStore, t }) {
    const {
        item,
        loaderStore,
        toggleIsActive,
        togglePremiumStatus,
        toggleVisibilityStatus,
        toggleMobileAppVisibilityStatus,
        toggleNakiTouchVisibilityStatus,
        toggleFeaturedStatus,
        coverImageUrl,
        deleteArtist,
    } = nakiNigunArtistPreviewViewStore;

    return (
        <MrbPreviewPage store={nakiNigunArtistPreviewViewStore} contentClassNameExtend="u-padd--none" t={t}>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="c-content__header--primary__img">
                                <div className="u-animation--fadeIn">
                                    <Image src={coverImageUrl} />
                                </div>
                            </div>
                            <div className="c-content__header--primary__info">
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.FIELDS.NAME")}
                                        </p>
                                        <p className="c-content__header--primary__title">{item.name}</p>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <div className="c-container--sidebar u-display--flex--ai--flex-start">
                <NakiNigunArtistPreviewAlbumList
                    nakiNigunArtistPreviewViewStore={nakiNigunArtistPreviewViewStore}
                    innerList
                    t={t}
                />

                <div className="c-card--primary u-mar--bottom--med">
                    <MrbContentLoader
                        loaderStore={loaderStore}
                        loaderRender={() => <ToggleActionsLoader lineheight={25} rows={5} sidebarLrg />}
                    >
                        {() => (
                            <div className="u-animation--fadeIn">
                                <div className="c-sidebar__item">
                                    <div className="u-type--base">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.STATUS")}
                                    </div>
                                    <MrbToggle
                                        id="naki-nigun-artist-active-status"
                                        value={item.isActive}
                                        onChange={(event) => toggleIsActive(event.target.checked)}
                                    />
                                </div>
                                <div className="c-sidebar__item">
                                    <div className="u-type--base">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.PREMIUM")}
                                    </div>
                                    <MrbToggle
                                        id="naki-nigun-artist-premium-status"
                                        value={item.isPremium}
                                        onChange={(event) => togglePremiumStatus(event.target.checked)}
                                    />
                                </div>

                                <div className="u-separator--primary"></div>

                                <div className="c-sidebar__item">
                                    <div className="u-type--base">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.VISIBLE_IN_APP")}
                                    </div>
                                    <MrbToggle
                                        id="naki-nigun-artist-mobile-app-visibility-status"
                                        value={item.isVisibleInApp}
                                        onChange={(event) => toggleMobileAppVisibilityStatus(event.target.checked)}
                                    />
                                </div>

                                <div className="u-separator--primary"></div>

                                <div className="c-sidebar__item">
                                    <div className="u-type--base">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.VISIBLE_ON_PORTAL")}
                                    </div>
                                    <MrbToggle
                                        id="naki-nigun-artist-visibility-status"
                                        value={item.isVisible}
                                        onChange={(event) => toggleVisibilityStatus(event.target.checked)}
                                    />
                                </div>
                                <div className="c-sidebar__item">
                                    <div className="u-type--base">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.FEATURED_ON_PORTAL")}
                                    </div>
                                    <MrbToggle
                                        id="naki-nigun-artist-featured-status"
                                        value={item.isFeatured}
                                        onChange={(event) => toggleFeaturedStatus(event.target.checked)}
                                    />
                                </div>

                                <div className="u-separator--primary"></div>

                                <div className="c-sidebar__item">
                                    <div className="u-type--base">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.VISIBLE_IN_EMBEDDED_APP")}
                                    </div>
                                    <MrbToggle
                                        id="naki-nigun-artist-embedded-app-visibility-status"
                                        value={item.isVisibleInEmbeddedApp}
                                        onChange={(event) => toggleNakiTouchVisibilityStatus(event.target.checked)}
                                    />
                                </div>

                                <div className="u-separator--primary"></div>

                                <div className="c-sidebar__item">
                                    <div className="u-type--base">
                                        {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.DELETE_LABEL")}
                                    </div>
                                    <MrbButton
                                        t={t}
                                        className="c-btn c-btn--primary c-btn--sml"
                                        label="ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.BUTTONS.DELETE"
                                        onClick={deleteArtist}
                                    />
                                </div>
                            </div>
                        )}
                    </MrbContentLoader>
                </div>
            </div>
        </MrbPreviewPage>
    );
}

NakiNigunArtistPreviewTemplate.propTypes = {
    nakiNigunArtistPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(NakiNigunArtistPreviewTemplate);
