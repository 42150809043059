import React from "react";
import { PodcastEpisodeTemplate } from "themes/administration/podcast/components";

class PodcastEpisode extends React.Component {
    render() {
        return <PodcastEpisodeTemplate {...this.props} />;
    }
}

export default PodcastEpisode;
