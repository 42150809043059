import { moduleProviderFactory } from "mrb/core";
import { AdminPlaylistList, AdminPlaylistPreview } from "administration/playlist/pages";
import { AdminPlaylistListRouteStore, AdminPlaylistPreviewRouteStore } from "administration/playlist/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "Playlist",
        routes: [
            {
                name: "master.administration.playlist",
                pattern: "/playlist",
                children: [
                    {
                        name: "master.administration.playlist.list",
                        pattern: "",
                        component: AdminPlaylistList,
                        store: AdminPlaylistListRouteStore,
                        data: {
                            title: "ADMINISTRATION.PLAYLIST.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.administration.playlist.preview",
                        pattern: "/preview/:id",
                        component: AdminPlaylistPreview,
                        store: AdminPlaylistPreviewRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.PLAYLIST",
                order: 7,
                icon: "playlists",
                route: "master.administration.playlist.list",
            },
        ],
    });
})();
