import React from "react";
import { NakiMobileEmbeddedFeaturedSectionTemplate } from "themes/administration/naki-mobile/pages";
import { NakiMobileEmbeddedFeaturedSectionViewStore } from "administration/naki-mobile/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiMobileEmbeddedFeaturedSectionViewStore(rootStore, componentProps),
    "nakiMobileEmbeddedFeaturedSectionViewStore"
)
class NakiMobileEmbeddedFeaturedSection extends React.Component {
    render() {
        return <NakiMobileEmbeddedFeaturedSectionTemplate {...this.props} />;
    }
}

export default NakiMobileEmbeddedFeaturedSection;
