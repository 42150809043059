import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbEditPage } from "mrb/components";
import { MrbContentLoader } from "mrb/components/loader";
import { defaultTemplate } from "common/hoc";

function AdminProducerEditTemplate({ adminProducerEditViewStore, t }) {
    const {
        form,
        loaderStore,
    } = adminProducerEditViewStore;

    return (
        <MrbEditPage store={adminProducerEditViewStore} t={t}>
            <div className="c-container--content--sidebar u-padd--none">
                <div className="c-card--primary">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <div>Loader</div>}>
                        {() => (
                            <div className="u-animation--fadeIn">
                                <div className="row">
                                    <div className="col col-sml-12 col-lrg-6">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("name")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>                                    
                                </div>                                
                            </div>
                        )}
                    </MrbContentLoader>
                </div>                
            </div>
        </MrbEditPage>
    );
}

AdminProducerEditTemplate.propTypes = {
    adminProducerEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(AdminProducerEditTemplate);