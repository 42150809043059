import React from "react";
import { NakiNigunArtistPreviewAlbumTemplate } from "themes/administration/naki-nigun/components";
import { NakiNigunArtistPreviewAlbumViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunArtistPreviewAlbumViewStore(rootStore, componentProps),
    "nakiNigunArtistPreviewAlbumViewStore"
)
class NakiNigunArtistPreviewAlbum extends React.Component {
    render() {
        return <NakiNigunArtistPreviewAlbumTemplate {...this.props} />;
    }
}

export default NakiNigunArtistPreviewAlbum;
