import React from "react";
import { UserEditTemplate } from "themes/administration/user/pages";
import { UserEditViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new UserEditViewStore(rootStore, componentProps),
    "userEditViewStore"
)
class UserEdit extends React.Component {
    render() {
        return <UserEditTemplate {...this.props} />;
    }
}

export default UserEdit;
