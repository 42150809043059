import React from "react";
import { PropTypes } from "prop-types";
import { AnalyticsLayout } from "administration/analytics/layouts";
import { TrendingListItem, TopTrendingListViewMoreLoader } from "administration/analytics/components";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";
import { defaultTemplate } from "common/hoc";
import { StatsWidgetEmptyState } from "common/components/empty-state";

function TrendingPodcastListTemplate({ trendingPodcastListViewStore, t }) {
    const { analyticsTimeRangeViewStore, gridStore } = trendingPodcastListViewStore;

    return (
        <AnalyticsLayout analyticsTimeRangeViewStore={analyticsTimeRangeViewStore} t={t}>
            <div className="c-container--base">
                <h1 className="u-type--base u-mar--bottom--sml">Trending podcasts chart</h1>
                <div className="c-card--primary">
                    <div className="c-analytics__header">
                        <span className="c-analytics__header__item c-analytics__item--position">Position</span>
                        <span className="c-analytics__header__item c-analytics__item--name">Podcast</span>
                        <span className="c-analytics__header__item c-analytics__item--prev-position">
                            Previous Position
                        </span>
                        <span className="c-analytics__header__item c-analytics__item--score">Playbacks</span>
                    </div>
                    <MrbInfiniteScrollGrid
                        loaderRenderer={() => <TopTrendingListViewMoreLoader noButton />}
                        loadMoreLoader={<TopTrendingListViewMoreLoader loadMore row={3} noButton />}
                        emptyStateRenderer={() => (
                            <StatsWidgetEmptyState classNameExtend="u-padd--top--lrg u-padd--bottom--lrg" />
                        )}
                        classNameOverride=""
                        store={gridStore}
                        cellRenderer={(props) => <TrendingListItem {...props} />}
                    />
                </div>
            </div>
        </AnalyticsLayout>
    );
}

TrendingPodcastListTemplate.propTypes = {
    trendingPodcastListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(TrendingPodcastListTemplate);
