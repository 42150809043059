import React from "react";
import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { MrbFieldSelectStore } from "mrb/components/select";
import { NakiNigunAlbumEditForm } from "administration/naki-nigun/forms";
import { NakiNigunAlbumCoverImageUploadViewStore } from "administration/naki-nigun/stores";
import { find, findIndex, isNil, map } from "lodash";

class NakiNigunAlbumEditViewStore extends MrbBaseEditViewStore {
    @observable overrideRadioUsersSettingsIsVisible = false;
    @observable overrideAppUsersSettingsIsVisible = false;
    @observable isCollapsed = false;
    panelRef = React.createRef();

    @computed get album() {
        return find(this.nakiNigunArtistEditViewStore.albums, (album) => album.id === this.albumId);
    }

    @computed get isNewlyAdded() {
        return isNil(this.album.dateCreated);
    }

    @computed get albumIndex() {
        return findIndex(this.nakiNigunArtistEditViewStore.albums, (album) => album.id === this.albumId) + 1;
    }

    @computed get episodeSortTypeOptions() {
        return this.nakiNigunArtistEditViewStore.episodeSortTypeOptions;
    }

    @computed get disabledForShufflingWeight() {
        return this.nakiNigunArtistEditViewStore.disabledForShufflingWeight;
    }

    @computed get isPremiumDisabled() {
        return this.nakiNigunArtistEditViewStore.form.$("isPremium").value;
    }

    @computed get isActiveStatusDisabled() {
        return !this.nakiNigunArtistEditViewStore.form.$("isActive").value;
    }

    constructor(rootStore, { nakiNigunArtistEditViewStore, albumId }) {
        super(rootStore, {
            name: "naki-nigun-albums",
            FormClass: NakiNigunAlbumEditForm,
            redirectOnCreateSuccess: false,
        });
        makeObservable(this);
        this.albumId = albumId;
        this.nakiNigunArtistEditViewStore = nakiNigunArtistEditViewStore;
        this.routeStore = this.nakiNigunArtistEditViewStore.routeStore;

        this.coverImageUploadViewStore = new NakiNigunAlbumCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.routeStore,
            width: 200,
            height: 200,
        });

        this.genreSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                isMulti: true,
                initFetch: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findGenres(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createGenre({ name });
                    },
                },
            },
            this.form.$("genreIds")
        );

        this.producerSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                initFetch: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findProducers(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createProducer({ name });
                    },
                },
            },
            this.form.$("producerId")
        );

        if (!this.isNewlyAdded) {
            this.reaction(
                () => this.form.$("isTurnedOnByDefault").value,
                (isTurnedOnByDefault) => {
                    if (isTurnedOnByDefault !== this.album.isTurnedOnByDefault) {
                        runInAction(() => {
                            this.overrideRadioUsersSettingsIsVisible = true;
                        });
                    } else {
                        runInAction(() => {
                            this.overrideRadioUsersSettingsIsVisible = false;
                            this.form.$("overrideRadioUsersSettings").value = false;
                        });
                    }
                }
            );
            this.reaction(
                () => this.form.$("isTurnedOnByDefaultInApp").value,
                (isTurnedOnByDefault) => {
                    if (isTurnedOnByDefault !== this.album.isTurnedOnByDefaultInApp) {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = true;
                        });
                    } else {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = false;
                            this.form.$("overrideAppUsersSettings").value = false;
                        });
                    }
                }
            );
        }

        this.setForm();

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                if (isCollapsed) {
                    this.form.$("title").focus();
                    if (this.panelRef) {
                        // We need to execute scroll function asynchronously in order to take effect.
                        setTimeout(() => {
                            this.panelRef.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        });
                    }
                }
            }
        );

        this.reaction(
            () => this.form.changed,
            (isChanged) => {
                if (isChanged) {
                    this.nakiNigunArtistEditViewStore.markAlbumAsChanged(this.albumId);
                }
            }
        );

        this.reaction(
            () => this.coverImageUploadViewStore.hasChanged,
            (isChanged) => {
                if (isChanged) {
                    this.nakiNigunArtistEditViewStore.markAlbumAsChanged(this.albumId);
                }
            }
        );

        this.reaction(
            () => this.nakiNigunArtistEditViewStore.form.$("isPremium").value,
            (isArtistPremium) => {
                if (isArtistPremium) {
                    this.form.$("isPremium").onChange(isArtistPremium);
                }
            }
        );

        this.reaction(
            () => this.form.$("isPremium").value,
            (isPremium) => {
                if (isPremium) {
                    this.form.$("producerId").addIsRequiredRule();
                } else {
                    this.form.$("producerId").removeIsRequiredRule();
                }
            }
        );

        this.reaction(
            () => this.nakiNigunArtistEditViewStore.form.$("isActive").value,
            (isActive) => {
                this.form.$("isActive").onChange(isActive);
            }
        );

        if (this.form.$("isPremium").value === true) {
            this.form.$("producerId").addIsRequiredRule();
        }
    }

    @action.bound
    setForm() {
        this.form.set({
            ...this.album,
            genreIds: this.album.genres ? map(this.album.genres, (genre) => genre.id) : null,
            producerId: this.album.producerId,
        });
        this.genreSelectStore.setSelectedItem(this.album.genres || null);
        this.producerSelectStore.setSelectedItem(this.album.producer);
        if (this.album.coverImageId) {
            if (this.isNewlyAdded) {
                this.coverImageUploadViewStore.setUploadedFileId(this.album.coverImageId);
            } else {
                this.coverImageUploadViewStore.setImageUrl(
                    this.routeStore.getNakiNigunAlbumCoverImageUrl(this.album.coverImageId)
                );
            }
        } else {
            this.coverImageUploadViewStore.setUploadedFileId(null);
        }
    }

    resetForm() {
        this.form.clear();
        this.setForm();
        this.nakiNigunArtistEditViewStore.markAlbumAsUpdated(this.album.id);
    }

    @action.bound
    onInit() {
        const albumId = this.rootStore.routerStore.routerState.queryParams.albumId;
        if (albumId && this.albumId === albumId) {
            this.isCollapsed = true;
        }
        return Promise.resolve();
    }

    create(data) {
        if (this.coverImageUploadViewStore.hasChanged) {
            data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        } else {
            data.coverImageId = this.album.coverImageId;
        }
        const selectedGenres = this.genreSelectStore.selectedOption;
        if (selectedGenres) {
            data.genres = map(selectedGenres, (genreOption) => genreOption.item);
        }
        this.nakiNigunArtistEditViewStore.updateAlbum(this.albumId, data);
        this.form.$changed = false;
        this.form.each((field) => (field.$changed = false));
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update resource.", err);
    }

    setPanelRef = (ref) => {
        this.panelRef = ref;
    };

    @action.bound
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    deleteAlbum = (event) => {
        // Prevent collapsing the album panel when a user clicks on the delete button.
        event.stopPropagation();
        event.preventDefault();
        this.nakiNigunArtistEditViewStore.deleteAlbum(this.albumId);
    };
}

export default NakiNigunAlbumEditViewStore;
