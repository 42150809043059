import { action } from "mobx";
import { BaseShuffleEditViewStore } from "administration/shuffle/stores";
import { MrbSelectStore } from "mrb/components/select";
import { UUID } from "common/utils";
import { findIndex, head, map } from "lodash";

class StationShuffleEditViewStore extends BaseShuffleEditViewStore {
    constructor(rootStore, componentProps) {
        super(rootStore, componentProps);

        this.shuffleItemSelectStore = new MrbSelectStore({
            isSearchable: true,
            textKey: "title",
            valueKey: "id",
            initFetch: false,
            actions: {
                find: (searchTerm, pageNumber, pageSize) => {
                    const selectedIds = map(
                        this.items,
                        (item) => item.station.id
                    );
                    return this.routeStore.findShufflableStation({
                        search: searchTerm,
                        orderBy: "title",
                        orderDirection: "asc",
                        fields: "id,title,isPremium",
                        pageNumber: pageNumber,
                        pageSize: pageSize,
                        excludeIds: selectedIds.length > 0 ? selectedIds : null,
                    });
                },
            },
        });
    }

    get(id) {
        return this.routeStore.getStationShuffle(id);
    }

    create(data) {
        return this.routeStore.createStationShuffle(data);
    }

    update(data) {
        return this.routeStore.updateStationShuffle(data);
    }

    @action.bound
    addStationShuffleItem() {
        const station = this.shuffleItemSelectStore.selectedItem;
        const obj = {
            id: UUID.create(),
            stationId: station.id,
            station: station,
            shuffleWeightId: head(this.shuffleWeights).id,
        };
        if (this.itemExists(obj.stationId)) {
            return;
        }
        this.addShuffleItem(obj);
        this.shuffleItemSelectStore.clearOptions();
        this.shuffleItemSelectStore.filter();
    }

    itemExists(itemId) {
        return (
            findIndex(this.items, (item) => item.stationId === itemId) !== -1
        );
    }
}

export default StationShuffleEditViewStore;
