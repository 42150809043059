import React from "react";
import { AdminPlaylistTemplate } from "themes/administration/playlist/components";

class AdminPlaylist extends React.Component {
    render() {
        return <AdminPlaylistTemplate {...this.props} />;
    }
}

export default AdminPlaylist;
