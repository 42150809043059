import React from "react";
import { PodcastGroupPreviewSubPodcastAnalyticsStatsTemplate } from "themes/administration/podcast/components";

class PodcastGroupPreviewSubPodcastAnalyticsStats extends React.Component {
    render() {
        return <PodcastGroupPreviewSubPodcastAnalyticsStatsTemplate {...this.props} />;
    }
}

export default PodcastGroupPreviewSubPodcastAnalyticsStats;
