import React from "react";
import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { PodcastVolumeEditForm } from "administration/podcast/forms";
import { PodcastCoverImageUploadViewStore } from "administration/podcast/stores";
import { find, findIndex, isNil } from "lodash";

class PodcastEditPodcastVolumeViewStore extends MrbBaseEditViewStore {
    @observable overrideUsersSettingsIsVisible = false;
    @observable isCollapsed = false;
    panelRef = React.createRef();

    @computed get podcastVolume() {
        return find(this.podcastEditViewStore.podcastVolumes, (podcastVolume) => podcastVolume.id === this.podcastId);
    }

    @computed get podcastVolumeIndex() {
        return (
            findIndex(
                this.podcastEditViewStore.podcastVolumes,
                (podcastVolume) => podcastVolume.id === this.podcastId
            ) + 1
        );
    }

    @computed get isNewlyAdded() {
        return isNil(this.podcastVolume.dateCreated);
    }

    constructor(rootStore, { podcastEditViewStore, podcastId }) {
        super(rootStore, {
            name: "podcast-podcast-volume",
            FormClass: PodcastVolumeEditForm,
            redirectOnCreateSuccess: false,
        });
        makeObservable(this);
        this.podcastId = podcastId;
        this.podcastEditViewStore = podcastEditViewStore;
        this.routeStore = this.podcastEditViewStore.routeStore;

        this.coverImageUploadViewStore = new PodcastCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.routeStore,
            width: 200,
            height: 200,
        });

        this.setForm();

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                if (isCollapsed && this.panelRef) {
                    // We need to execute scroll function asynchronously in order to take effect.
                    setTimeout(() => {
                        this.panelRef.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }, 500);
                }
            }
        );

        this.reaction(
            () => this.form.changed,
            (isChanged) => {
                if (isChanged) {
                    this.podcastEditViewStore.markPodcastVolumeAsChanged(this.podcastId);
                }
            }
        );

        this.reaction(
            () => this.coverImageUploadViewStore.hasChanged,
            (isChanged) => {
                if (isChanged) {
                    this.podcastEditViewStore.markPodcastVolumeAsChanged(this.podcastId);
                }
            }
        );
    }

    @action.bound
    setForm() {
        this.form.set({
            ...this.podcastVolume,
        });
        if (this.podcastVolume.podcastFileEntryId) {
            if (this.isNewlyAdded) {
                this.coverImageUploadViewStore.setUploadedFileId(this.podcastVolume.podcastFileEntryId);
            } else {
                this.coverImageUploadViewStore.setImageUrl(
                    this.routeStore.getPodcastCoverImageUrl(this.podcastVolume.podcastFileEntryId)
                );
            }
        } else {
            this.coverImageUploadViewStore.setUploadedFileId(null);
        }
    }

    resetForm() {
        this.form.clear();
        this.setForm();
        this.podcastEditViewStore.markPodcastVolumeAsUpdated(this.podcastVolume.id);
    }

    @action.bound
    onInit() {
        const podcastVolumeId = this.rootStore.routerStore.routerState.queryParams.podcastVolumeId;
        if (podcastVolumeId && this.podcastVolume.id === podcastVolumeId) {
            this.isCollapsed = true;
        }
        return Promise.resolve();
    }

    create(data) {
        if (this.coverImageUploadViewStore.hasChanged) {
            data.podcastFileEntryId = this.coverImageUploadViewStore.uploadedFileId;
        } else {
            data.podcastFileEntryId = this.podcastVolume.podcastFileEntryId;
        }
        this.podcastEditViewStore.updatePodcastVolume(this.podcastId, data);
        this.form.$changed = false;
        this.form.each((field) => (field.$changed = false));
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update resource.", err);
    }

    setPanelRef = (ref) => {
        this.panelRef = ref;
    };

    @action.bound
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    deletePodcastVolume = (event) => {
        // Prevent collapsing the podcast volume panel when a user clicks on the delete button.
        event.stopPropagation();
        event.preventDefault();
        this.podcastEditViewStore.deletePodcastVolume(this.podcastId);
    };
}

export default PodcastEditPodcastVolumeViewStore;
