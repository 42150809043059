import { MrbBaseViewStore } from "mrb/core";
import { PodcastEpisodeEditTitleForm } from "administration/podcast/forms";
import { localizationService } from "common/localization";
import { makeObservable, observable, action } from "mobx";

class PodcastEpisodeEditTitleViewStore extends MrbBaseViewStore {
    @observable isFormChanged = false;

    constructor(rootStore, { podcastEpisodeListViewStore, id, title }) {
        super(rootStore);
        makeObservable(this);
        this.podcastEpisodeListViewStore = podcastEpisodeListViewStore;
        this.routeStore = podcastEpisodeListViewStore.routeStore;
        this.id = id;
        this.title = title;

        this.form = new PodcastEpisodeEditTitleForm({
            onSuccess: (form) => {
                const item = form.values();
                return this.updateResource(item);
            },
            onInit: (form) => {
                form.set({ title: title });
                form.$("title").focus();
            },
        });

        this.registerRouteExitBlocking();
        this.reaction(
            () => this.form.changed,
            (isChanged) => {
                if (isChanged) {
                    this.isFormChanged = isChanged;
                }
            }
        );
    }

    @action.bound
    async updateResource(data) {
        this.form.setFieldsDisabled(true);
        try {
            await this.update(this.id, data);
            this.form.setFieldsDisabled(false);
            this.onUpdateSuccess(data.title);
        } catch (err) {
            this.form.setFieldsDisabled(false);
            this.onUpdateError(err);
        }
    }

    async update(id, resource) {
        this.isFormChanged = false;
        await this.routeStore.editPodcastEpisodeTitle(id, resource);
    }

    onUpdateError(err) {
        this.rootStore.notificationStore.error(
            localizationService.t("ADMINISTRATION.PODCAST.EPISODES.ERROR.UPDATE_PODCAST_EPISODE_TITLE_GENERIC")
        );
    }

    @action.bound
    onUpdateSuccess(newPodcastEpisodeTitle) {
        this.podcastEpisodeListViewStore.updatePodcastEpisodeTitle(this.id, newPodcastEpisodeTitle);
        this.podcastEpisodeListViewStore.editPodcastEpisodeTitleModal.close();
    }

    registerRouteExitBlocking() {
        this.reaction(
            () => this.isFormChanged,
            (isChanged) => {
                this.routeStore.setIsRouteExitBlocked(isChanged);
            }
        );
    }

    destroy() {
        super.destroy();
        this.form.destroy();
    }
}

export default PodcastEpisodeEditTitleViewStore;
