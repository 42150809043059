import React from "react";
import { PropTypes } from "prop-types";
import { MrbListPage } from "mrb/components/layout";
import { MrbTable } from "mrb/components/table";
import { MrbQuerySearchInput } from "mrb/components/input";
import { TableNoDataOverlay } from "common/components";
import { defaultTemplate } from "common/hoc";
import { MrbSelect } from "mrb/components/select";

function UserListTemplate({ userListViewStore, t }) {
    const { gridStore, queryUtility, roleSelectStore } = userListViewStore;
    return (
        <MrbListPage store={userListViewStore} t={t}>
            <div className="u-display--flex u-mar--bottom--sml c-container--base">
                <div className="row row--align-end">
                    <div className="col col-sml-6 col-lrg-6">
                        <MrbQuerySearchInput queryUtility={queryUtility} />
                    </div>
                    <div className="col col-sml-6 col-lrg-6">
                        <MrbSelect t={t} store={roleSelectStore} classNameExtend="c-dropdown--dark c-dropdown--sml" />
                    </div>
                </div>
            </div>
            <div className="c-content__main">
                <MrbTable
                    t={t}
                    tableStore={gridStore}
                    className="ag-theme-alpine"
                    emptyStateRender={TableNoDataOverlay}
                />
            </div>
        </MrbListPage>
    );
}

UserListTemplate.propTypes = {
    userListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(UserListTemplate);
