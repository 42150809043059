import { MrbBasePreviewViewStore } from "mrb/core";

class OrderPreviewViewStore extends MrbBasePreviewViewStore {
    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            navigateBack: "master.administration.order.list",
        });
        this.routeStore = routeStore;
    }

    get(id) {
        return this.routeStore.getOrder(id);
    }
}

export default OrderPreviewViewStore;
