import React from "react";
import { OrderTableLoaderTemplate } from "themes/administration/order/components";

class OrderTableLoader extends React.Component {
    render() {
        return <OrderTableLoaderTemplate {...this.props} />;
    }
}

export default OrderTableLoader;
