import { action, computed, observable, makeObservable, toJS } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { ShuffleEditForm } from "administration/shuffle/forms";
import { map, isNil, sortBy, snakeCase, findIndex, filter } from "lodash";

class BaseShuffleEditViewStore extends MrbBaseEditViewStore {
    @observable items = [];

    @observable.ref shuffleWeights = [];

    shuffleItemSelectStore = null;

    @computed get shuffleWeightOptions() {
        return map(this.shuffleWeights, (item) => {
            return {
                value: item.id,
                label: `ADMINISTRATION.SHUFFLE.EDIT.OPTIONS.SHUFFLE_WEIGHT.${snakeCase(
                    item.name
                ).toUpperCase()}_OPTION_LABEL`,
            };
        });
    }

    @computed get isAddDisabled() {
        return isNil(this.shuffleItemSelectStore.selectedItem);
    }

    @computed get shuffleWeightIds() {
        return map(this.shuffleWeights, (item) => item.id);
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            name: "shuffle",
            FormClass: ShuffleEditForm,
            autoFocusField: "name",
            navigateBack: "master.administration.shuffle.list",
        });
        makeObservable(this);
        this.routeStore = routeStore;

        this.reaction(
            () => this.form.submitted && this.items.length === 0,
            (invalidateShuffleItems) => {
                if (invalidateShuffleItems) {
                    this.form.invalidate("ADMINISTRATION.SHUFFLE.EDIT.VALIDATION.SHUFFLE_ITEMS_EMPTY");
                } else {
                    this.form.validator.error = null;
                }
            }
        );
    }

    async onInit() {
        await this.loadLookups();
        await super.onInit();
        await this.shuffleItemSelectStore.filter();
    }

    async loadLookups() {
        const shuffleWeights = await this.routeStore.getShuffleWeights();
        this.setShuffleWeights(shuffleWeights);
    }

    setItem(item) {
        this.items = item.shuffleItems;
        item.shuffleItems = null;
        super.setItem(item);
    }

    @action.bound
    setShuffleWeights(items) {
        this.shuffleWeights = sortBy(items, ["weight"]);
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to create resource.", err);
    }

    onUpdateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update resource.", err);
    }

    createResource(data) {
        if (this.items.length === 0) {
            this.form.invalidate("ADMINISTRATION.SHUFFLE.EDIT.VALIDATION.SHUFFLE_ITEMS_EMPTY");
            return;
        }
        data.shuffleItems = toJS(this.items);
        return super.createResource(data);
    }

    updateResource(data) {
        if (this.items.length === 0) {
            this.form.invalidate("ADMINISTRATION.SHUFFLE.EDIT.VALIDATION.SHUFFLE_ITEMS_EMPTY");
            return;
        }
        data.shuffleItems = toJS(this.items);
        return super.updateResource(data);
    }

    addShuffleItem(item) {
        this.items.push(item);
        this.shuffleItemSelectStore.clear();
    }

    @action.bound
    setItemShuffleWeight(shuffleItemId, shuffleWeightId) {
        const index = findIndex(this.items, (item) => item.id === shuffleItemId);
        if (index >= 0) {
            this.items[index].shuffleWeightId = shuffleWeightId;
        }
    }

    @action.bound
    deleteShuffleItem(shuffleItemId) {
        this.items = filter(this.items, (item) => item.id !== shuffleItemId);
    }

    itemExists(itemId) {
        throw new Error("Method not implemented.");
    }
}

export default BaseShuffleEditViewStore;
