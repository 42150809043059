import React from "react";
import { PodcastEpisodeContentExplorerTemplate } from "themes/administration/content-explorer";
import { PodcastEpisodeContentExplorerViewStore } from "administration/content-explorer/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView(
    (rootStore, componentProps) => new PodcastEpisodeContentExplorerViewStore(rootStore, componentProps),
    "podcastEpisodeContentExplorerViewStore"
)
@observer
class PodcastEpisodeContentExplorer extends React.Component {
    render() {
        return <PodcastEpisodeContentExplorerTemplate {...this.props} />;
    }
}

export default PodcastEpisodeContentExplorer;
