import React from "react";
import { observer } from "mobx-react";
import { TrendingPodcastListTemplate } from "themes/administration/analytics/pages";
import { TrendingPodcastListViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new TrendingPodcastListViewStore(rootStore, componentProps),
    "trendingPodcastListViewStore"
)
@observer
class TrendingPodcastList extends React.Component {
    render() {
        return <TrendingPodcastListTemplate {...this.props} />;
    }
}

export default TrendingPodcastList;
