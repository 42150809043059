import React from "react";
import { PropTypes } from "prop-types";
// import { MrbSimpleSelectPanelContainer, MrbSimpleSelectValueContainer } from "./components";
import { observer } from "mobx-react";
import { MrbButton } from "mrb/components/button";
import { map } from "lodash";
import { CustomDropdown } from "common/components";

function PlaylistTagsTemplate({ store, t = (i) => i }) {
    return (
        <div>
            <CustomDropdown
                contentClassNameExtend="u-width--300--max"
                onOpen={store.onOpen}
                onClose={store.onClose}
                trigger={<Selector store={store} t={t} />}
            >
                <PanelContainer store={store} t={t} />
            </CustomDropdown>
        </div>
    );
}

const Selector = observer(function Selector({ store, t = (i) => i }) {
    const { selectedOption } = store;
    if (selectedOption) {
        if (selectedOption.length === 1) {
            return (
                <div className="c-tag c-tag--sml c-tag--rounded c-tag--inactive--outline u-cursor--pointer with-actions">
                    <p className="u-type--sml u-type--ellipsis u-type--break--all">{selectedOption[0].label}</p>
                    <i className="u-icon u-icon--base u-icon--tag u-mar--left--micro"></i>
                </div>
            );
        } else if (selectedOption.length > 1) {
            return (
                <div className="c-tag c-tag--sml c-tag--rounded c-tag--inactive--outline u-cursor--pointer with-actions">
                    {selectedOption.length}
                    <i className="u-icon u-icon--base u-icon--tag u-mar--left--micro"></i>
                </div>
            );
        }
    }

    return (
        <div>
            <i className="u-icon u-icon--med u-icon--tag"></i>
        </div>
    );
});

PlaylistTagsTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const PanelContainer = observer(function PanelContainer({ store, optionRender, t }) {
    return <SelectPanel store={store} t={t} optionRender={optionRender} />;
});

const SelectPanel = observer(function SelectPanel({ store, t }) {
    const {
        options,
        searchTerm,
        setSearchTerm,
        createOption,
        setFocusedIndex,
        onChange,
        selectedOption,
        showCreateOption,
    } = store;

    return (
        <div>
            <input
                className="c-input c-input--search c-input--base "
                placeholder="Search"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
            />
            {options && options.length > 0 ? (
                <div className="u-display--flex u-display--flex--wrap u-gap--tny u-mar--top--sml u-height--350--max u-overflow--auto">
                    {map(options, (option, index) => {
                        const isActive = selectedOption.find((opt) => opt.value === option.value) !== undefined;

                        return (
                            <div
                                key={option.value}
                                onMouseOver={() => setFocusedIndex(index)}
                                onMouseDown={() => onChange(option)}
                                className={`c-tag c-tag--sml c-tag--rounded c-tag--${
                                    isActive ? "primary" : "inactive--outline"
                                } u-cursor--pointer with-actions`}
                            >
                                {t(option.label)}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="u-type--bold u-type--center u-type--break--all u-type--ellipsis u-mar--top--sml">
                    <p>No Results</p>
                </div>
            )}
            {showCreateOption && (
                <div>
                    <div className="u-separator--primary"></div>

                    <div className="u-display--flex">
                        <MrbButton
                            classNameOverride="c-btn c-btn--base c-btn--full c-btn--outline"
                            onClick={() => createOption(searchTerm)}
                            content={
                                <div className="u-type--bold u-type--break--all u-type--ellipsis">
                                    Create "{searchTerm}" playlist
                                </div>
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default observer(PlaylistTagsTemplate);
