import { action, computed, observable, makeObservable, toJS, override } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { NakiNigunArtistEditForm } from "administration/naki-nigun/forms";
import { NakiNigunArtistCoverImageUploadViewStore } from "administration/naki-nigun/stores";
import { applicationErrorCodes } from "common/constants";
import { UUID } from "common/utils";
import { map, findIndex, filter, merge, some, omit, orderBy } from "lodash";

export const ARTIST_FORM_STATUS = {
    CHANGED: "CHANGED",
    UPDATED: "UPDATED",
};

class NakiNigunArtistEditViewStore extends MrbBaseEditViewStore {
    @observable albums = [];
    @observable albumsChanged = false;

    @computed get anyUnsavedAlbum() {
        return some(this.albums, (album) => album.FORM_STATUS === ARTIST_FORM_STATUS.CHANGED);
    }

    @observable.ref episodeSortTypes = [];

    @computed get episodeSortTypeOptions() {
        return map(this.episodeSortTypes, (item) => {
            return {
                value: item.id,
                label: `ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.ALBUM.FIELDS.${item.name.toUpperCase()}_OPTION_LABEL`,
            };
        });
    }

    @computed get isFormChanged() {
        return (
            this.form.changed ||
            some(
                this.albums,
                (album) =>
                    album.FORM_STATUS === ARTIST_FORM_STATUS.CHANGED || album.FORM_STATUS === ARTIST_FORM_STATUS.UPDATED
            ) ||
            this.albumsChanged ||
            this.coverImageUploadViewStore.hasChanged
        );
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            name: "naki-nigun-artist",
            FormClass: NakiNigunArtistEditForm,
            autoFocusField: "name",
            navigateBack: "master.administration.naki-nigun.artist.list",
            successCreateNotification: null,
            successUpdateNotification: null,
            blockRouteExit: true,
            redirectOnCreateSuccess: ({ id }) =>
                this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.preview", { id: id }),
            redirectOnUpdateSuccess: () =>
                this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.preview", { id: this.id }),
        });
        this.routeStore = routeStore;
        makeObservable(this);

        this.coverImageUploadViewStore = new NakiNigunArtistCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.routeStore,
        });

        this.reaction(
            () => this.form.submitted && this.albums.length === 0,
            (invalidateAlbums) => {
                if (invalidateAlbums) {
                    this.form.invalidate("ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.VALIDATION.ALBUMS_EMPTY");
                } else {
                    this.form.validator.error = null;
                }
            }
        );

        this.reaction(
            () =>
                this.form.submitted &&
                some(this.albums, (album) => this.form.$("isPremium").value && album.producerId === null),
            (invalidateAlbums) => {
                if (invalidateAlbums) {
                    this.form.invalidate(
                        "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.VALIDATION.PREMIUM_ALBUM_MISSING_PRODUCER"
                    );
                } else {
                    this.form.validator.error = null;
                }
            }
        );

        this.reaction(
            () => this.form.$("isPremium").value,
            (isPremium) => {
                if (!isPremium) {
                    this.form.$("premiumInfo").set(null);
                }
            }
        );

        this.registerRouteExitBlocking();
    }

    async onInit() {
        await this.loadLookups();
        await this.initializeResource();
        if (this.isEdit && this.item.coverImageId) {
            this.coverImageUploadViewStore.setImageUrl(
                this.routeStore.getNakiNigunArtistCoverImageUrl(this.item.coverImageId)
            );
        }
    }

    @action.bound
    setEpisodeSortTypes(items) {
        this.episodeSortTypes = items;
    }

    async loadLookups() {
        const episodeSortTypes = await this.routeStore.getEpisodeSortTypes();
        this.setEpisodeSortTypes(orderBy(episodeSortTypes, (sortType) => sortType.sortOrder));
    }

    createResource(data) {
        this._onAfterSave(data);
        data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        return super.createResource(data);
    }

    updateResource(data) {
        this._onAfterSave(data);
        if (this.coverImageUploadViewStore.hasChanged) {
            data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        } else {
            data.coverImageId = this.item.coverImageId;
        }
        return super.updateResource(data);
    }

    _onAfterSave(data) {
        data.albums = map(this.albums, (album, index) => {
            return {
                ...omit(album, "FORM_STATUS"),
                genreIds: album.genres ? map(album.genres, (genre) => genre.id) : null,
                sortOrder: index + 1,
            };
        });
        if (!data.albums || data.albums.length === 0) {
            this.form.invalidate("ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.VALIDATION.ALBUMS_EMPTY");
            return;
        }
        if (some(data.albums, (album) => data.isPremium && album.producerId === null)) {
            this.form.invalidate("ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.VALIDATION.PREMIUM_ALBUM_MISSING_PRODUCER");
            return;
        }
    }

    @override
    setItem(item) {
        this.albums = item.albums;
        item.albums = null;
        super.setItem(item);
    }

    get(id) {
        return this.routeStore.getNakiNigunArtist(id);
    }

    create(data) {
        return this.routeStore.createNakiNigunArtist(data);
    }

    resetForm() {
        this.form.set({
            isActive: true,
            isVisible: true,
            isVisibleInApp: true,
        });
    }

    async update(data) {
        await this.routeStore.updateNakiNigunArtist(data);
    }

    onCreateSuccess(result) {
        this.routeStore.setIsRouteExitBlocked(false);
        super.onCreateSuccess(result);
    }

    onUpdateSuccess(result) {
        this.routeStore.setIsRouteExitBlocked(false);
        super.onUpdateSuccess(result);
    }

    onCreateError(err) {
        if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
            this.routeStore.setIsRouteExitBlocked(false);
            const artist = err.data.response;
            this.rootStore.notificationStore.error(
                "Artist created, but unexpected error occurred during RSS sync.",
                err
            );
            this.redirectOnCreateSuccess(artist);
        } else {
            this.rootStore.notificationStore.error("An unexpected error occurred while trying to create artist.", err);
        }
    }

    onUpdateError(err) {
        if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
            this.routeStore.setIsRouteExitBlocked(false);
            this.rootStore.notificationStore.error(
                "Artist updated, but an unexpected error occurred during RSS sync.",
                err
            );
            this.redirectOnUpdateSuccess();
        } else {
            this.rootStore.notificationStore.error("An unexpected error occurred while trying to update artist.", err);
        }
    }

    @action.bound
    addAlbum(item) {
        item.id = UUID.create();
        item.FORM_STATUS = ARTIST_FORM_STATUS.UPDATED;
        this.albums.push(item);
        this.albumsChanged = true;
    }

    registerRouteExitBlocking() {
        this.reaction(
            () => this.isFormChanged,
            (isChanged) => {
                this.routeStore.setIsRouteExitBlocked(isChanged);
            }
        );
    }

    @action.bound
    updateAlbum(id, item) {
        const index = findIndex(this.albums, (album) => album.id === id);
        item.FORM_STATUS = ARTIST_FORM_STATUS.UPDATED;
        if (item.genres !== undefined) {
            this.albums[index].genres = item.genres;
        }
        this.albums[index] = merge(this.albums[index], item);
        this.albumsChanged = true;
    }

    @action.bound
    reorderAlbums(result) {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const newAlbums = Array.from(toJS(this.albums));
        const album = newAlbums.splice(source.index, 1)[0];
        album.FORM_STATUS = ARTIST_FORM_STATUS.UPDATED;
        newAlbums.splice(destination.index, 0, album);

        this.albums = newAlbums;
        this.albumsChanged = true;
    }

    @action.bound
    markAlbumAsChanged(albumId) {
        const index = findIndex(this.albums, (album) => album.id === albumId);
        if (index > -1) {
            this.albums[index].FORM_STATUS = ARTIST_FORM_STATUS.CHANGED;
        }
        this.albumsChanged = true;
    }

    @action.bound
    markAlbumAsUpdated(albumId) {
        const index = findIndex(this.albums, (album) => album.id === albumId);
        if (index > -1) {
            this.albums[index].FORM_STATUS = ARTIST_FORM_STATUS.UPDATED;
        }
        this.albumsChanged = true;
    }

    @action.bound
    deleteAlbum(albumId) {
        this.albums = filter(this.albums, (album) => album.id !== albumId);
    }
}

export default NakiNigunArtistEditViewStore;
