import React from "react";
import { StationPromoPreviewTemplate } from "themes/administration/station/components";
import { StationPromoPreviewViewStore } from "administration/station/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new StationPromoPreviewViewStore(rootStore, componentProps),
    "stationPromoPreviewViewStore"
)
class StationPromoPreview extends React.Component {
    render() {
        return <StationPromoPreviewTemplate {...this.props} />;
    }
}

export default StationPromoPreview;
