import { MrbBaseRouteStore } from "mrb/core";
import { AppHomeSectionService, MediaVaultStreamService, FeaturedSectionFileStreamService } from "common/services";
import moment from "moment";

class BaseFeaturedSectionRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(AppHomeSectionService);
        this.mediaVaultStreamService = new MediaVaultStreamService(
            this.rootStore.apiClient,
            this.rootStore.application.baasic
        );
        this.featuredSectionFileStreamService = this.rootStore.createApplicationService(
            FeaturedSectionFileStreamService
        );
    }

    async getFeaturedGrid(systemType) {
        const response = await this.service.getFeaturedGrid({
            embed: "album,artist,station,podcast",
            systemType: systemType,
        });
        return response.data;
    }

    async updateFeaturedGrid(systemType, resources) {
        const response = await this.service.updateFeaturedGrid(systemType, resources);
        return response.data;
    }

    async getContent(filter) {
        const response = await this.service.getContent(filter);
        return response.data;
    }

    getMediaVaultFileUrl(id) {
        return this.mediaVaultStreamService.mediaVaultModule.streams.routeDefinition.get(id);
    }

    async uploadImageFile(file) {
        const now = moment();
        const [name, extension] = file.name.split(".");
        const path = `${name}_${now.year()}-${now.month()}-${now.date()}-${now.hour()}-${now.minute()}-${now.second()}.${extension}`;
        const response = await this.mediaVaultStreamService.create(
            {
                path: path,
                fileName: file.name,
            },
            file
        );
        return response.data;
    }

    getFeaturedSectionCoverImageUrl(fileEntryId) {
        return this.featuredSectionFileStreamService.routeService.get(fileEntryId);
    }
}

export default BaseFeaturedSectionRouteStore;
