import { MrbBaseRouteStore } from "mrb/core";
import {
    PodcastService,
    EpisodeSortTypeCacheService,
    MediaVaultStreamService,
    PodcastFileStreamService,
    GenreLookupService,
    LocationService,
    LanguageService,
    ProducerService,
} from "common/services";
import moment from "moment";

class BasePodcastEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(PodcastService);
        this.episodeSortTypeService = new EpisodeSortTypeCacheService(this.rootStore);
        this.mediaVaultStreamService = new MediaVaultStreamService(
            this.rootStore.apiClient,
            this.rootStore.application.baasic
        );
        this.podcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
        this.genreLookupService = this.rootStore.createApplicationService(GenreLookupService);
        this.locationService = this.rootStore.createApplicationService(LocationService);
        this.languageService = this.rootStore.createApplicationService(LanguageService);
        this.producerService = this.rootStore.createApplicationService(ProducerService);
    }

    getEpisodeSortTypes() {
        return this.episodeSortTypeService.getItems();
    }

    getMediaVaultFileUrl(id) {
        return this.mediaVaultStreamService.mediaVaultModule.streams.routeDefinition.get(id);
    }

    async uploadImageFile(file) {
        const now = moment();
        const [name, extension] = file.name.split(".");
        const path = `${name}_${now.year()}-${now.month()}-${now.date()}-${now.hour()}-${now.minute()}-${now.second()}.${extension}`;
        const response = await this.mediaVaultStreamService.create(
            {
                path: path,
                fileName: file.name,
            },
            file
        );
        return response.data;
    }

    getPodcastCoverImageUrl(coverImageId) {
        return this.podcastFileStreamService.routeService.get(coverImageId);
    }

    async findGenres(searchPhrase) {
        const response = await this.genreLookupService.find(searchPhrase);
        return response.data;
    }

    async findProducers(searchPhrase) {
        const response = await this.producerService.find(searchPhrase);
        return response.data;
    }

    async createProducer(resource) {
        const response = await this.producerService.create(resource);
        return response.data;
    }

    async createGenre(resource) {
        const response = await this.genreLookupService.create(resource);
        return response.data;
    }

    async findLocations(searchPhrase) {
        const response = await this.locationService.find(searchPhrase);
        return response.data;
    }

    async createLocation(resource) {
        const response = await this.locationService.create(resource);
        return response.data;
    }

    async createLanguage(resource) {
        const response = await this.languageService.create(resource);
        return response.data;
    }

    async findLanguages(searchPhrase) {
        const response = await this.languageService.find(searchPhrase);
        return response.data;
    }
}

export default BasePodcastEditRouteStore;
