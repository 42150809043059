import { action, computed, makeObservable, observable, toJS } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { UUID } from "common/utils";
import { findIndex, filter, forEach } from "lodash";
import { MrbModalParams } from "mrb/common/models";
import { MrbSelectStore } from "mrb/components/select";
import { find, isNil } from "lodash";

class NakiMobileHomeSectionListViewStore extends MrbBaseViewStore {
    sectionsLimit = 20;

    @observable sections = [];
    @observable isSubmitting = false;

    @computed get sectionsLimitReached() {
        return this.sections.length >= this.sectionsLimit;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;
        makeObservable(this);
        this.addGenreHomeSectionModalParams = new MrbModalParams();

        this.genreSelectStore = new MrbSelectStore({
            isSearchable: true,
            virtualized: false,
            initFetch: false,
            isOptionDisabled: (option) => {
                let genreSectionAlreadyExists = find(this.sections, (section) => section.genreId === option.item.id);
                return !isNil(genreSectionAlreadyExists);
            },
            actions: {
                find: (searchTerm) => {
                    return this.routeStore.findGenres({
                        search: searchTerm,
                    });
                },
            },
        });
    }

    onInit() {
        return this.fetchResources();
    }

    async fetchResources() {
        try {
            const items = await this.get();
            this.setSections(items);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to load home screen sections. Please contact the support team."
            );
        }
    }

    get() {
        return this.routeStore.getAll();
    }

    @action.bound
    async onClickSave() {
        try {
            this.setIsSubmitting(true);
            forEach(this.sections, (section, index) => {
                section.sortOrder = index;
                if (section.items && section.items.length > 0) {
                    forEach(section.items, (item, itemIndex) => {
                        item.sortOrder = itemIndex;
                    });
                }
            });
            await this.routeStore.update(this.sections);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to update home screen sections. Please contact the support team."
            );
        } finally {
            this.setIsSubmitting(false);
        }
    }

    @action.bound
    addSection() {
        const section = {
            id: UUID.create(),
            title: "New Section",
            isSystem: false,
            isActive: true,
            items: [],
            sortOrder: this.sections.length,
        };
        this.sections.push(section);
    }

    @action.bound
    addGenreSection() {
        const genreItem = this.genreSelectStore.selectedItem;
        const section = {
            id: UUID.create(),
            title: genreItem.name,
            genreId: genreItem.id,
            isSystem: false,
            isActive: true,
            items: [],
            sortOrder: this.sections.length,
        };

        this.sections.push(section);
        this.genreSelectStore.clear();
        this.addGenreHomeSectionModalParams.close();
    }

    @action.bound
    openAddGenreHomeSectionModal() {
        this.addGenreHomeSectionModalParams.open();
    }

    @action.bound
    setSections(items) {
        this.sections = items;
    }

    @action.bound
    setIsSubmitting(value) {
        this.isSubmitting = value;
    }

    @action.bound
    reorderSections(result) {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const newSections = Array.from(toJS(this.sections));
        const section = newSections.splice(source.index, 1)[0];
        newSections.splice(destination.index, 0, section);

        this.setSections(newSections);
    }

    toggleIsActive(id, value) {
        const index = findIndex(this.sections, (section) => section.id === id);
        if (index >= 0) {
            if (this.sections[index]) {
                this.sections[index].isActive = value;
            }
        }
    }

    @action.bound
    deleteSection(sectionId) {
        this.sections = filter(this.sections, (section) => section.id !== sectionId);
    }
}

export default NakiMobileHomeSectionListViewStore;
