import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage } from "mrb/components/layout";
import {
    AdminProducerAccountSummaryPreview,
    AdminProducerAccountAssignedProducersPreview,
} from "administration/producer/components";
import { ProducerAccountPreviewBalance, ProducerAccountPreviewRevenueTransaction } from "common/components/producer";
import { TableNoDataOverlay } from "common/components";
import { MrbModal } from "mrb/components/modal";
import { MrbButton } from "mrb/components/button";
import {
    AdminProducerAccountBalanceEdit,
    AdminProducerAccountStripeAccountEdit,
} from "administration/producer/components";
import { defaultTemplate } from "common/hoc";

function AdminProducerAccountPreviewTemplate({ adminProducerAccountPreviewViewStore, t }) {
    const {
        revenueGridStore,
        balanceGridStore,
        addToAccountBalanceModal,
        updateStripeAccountModal,
        openAddToAccountBalanceModal,
        openUpdateStripeAccountModal,
    } = adminProducerAccountPreviewViewStore;
    return (
        <MrbPreviewPage store={adminProducerAccountPreviewViewStore} t={t}>
            <div className="c-container--sidebar u-padd--none">
                <div>
                    <ProducerAccountPreviewRevenueTransaction
                        title="Revenue Transactions"
                        gridStore={revenueGridStore}
                        emptyStateRenderer={TableNoDataOverlay}
                        t={t}
                    />

                    <div className="u-mar--bottom--sml">
                        <div>
                            <ProducerAccountPreviewBalance
                                title="Account Balance"
                                gridStore={balanceGridStore}
                                emptyStateRenderer={TableNoDataOverlay}
                                t={t}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col col-sml-12 col-lrg-6 col-xxlrg-12">
                            <AdminProducerAccountSummaryPreview
                                adminProducerAccountPreviewViewStore={adminProducerAccountPreviewViewStore}
                                t={t}
                            />

                            <AdminProducerAccountAssignedProducersPreview
                                adminProducerAccountPreviewViewStore={adminProducerAccountPreviewViewStore}
                                t={t}
                            />
                            <div>
                                <h1 className="u-type--base u-mar--bottom--sml">Actions</h1>
                                <div className="c-card--primary c-card--med">
                                    <MrbButton
                                        label="Change Balance"
                                        onClick={openAddToAccountBalanceModal}
                                        classNameExtend="c-btn--full u-mar--bottom--sml"
                                    />
                                    <MrbButton
                                        label="Change Stripe Account"
                                        onClick={openUpdateStripeAccountModal}
                                        classNameExtend="c-btn--full"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddToAccountBalanceModal modalParams={addToAccountBalanceModal} t={t} />
            <UpdateStripeAccountModal modalParams={updateStripeAccountModal} t={t} />
        </MrbPreviewPage>
    );
}

AdminProducerAccountPreviewTemplate.propTypes = {
    adminProducerAccountPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function AddToAccountBalanceModal({ modalParams, t }) {
    return (
        <MrbModal modalParams={modalParams} className="modal__content modal__content--secondary">
            {(props) => (
                <div>
                    <div className="u-mar--bottom--sml">
                        <h2>Change Account Balance</h2>
                    </div>
                    <AdminProducerAccountBalanceEdit
                        t={t}
                        producerUserAccount={props.modalData.producerUserAccount}
                        onSubmit={props.modalParams.addToAccountBalance}
                    />
                </div>
            )}
        </MrbModal>
    );
}

function UpdateStripeAccountModal({ modalParams, t }) {
    return (
        <MrbModal modalParams={modalParams} className="modal__content modal__content--secondary">
            {(props) => (
                <div>
                    <div className="u-mar--bottom--sml">
                        <h2>Change Stripe Account</h2>
                    </div>
                    <div>
                        <AdminProducerAccountStripeAccountEdit
                            t={t}
                            producerUserAccount={props.modalData.producerUserAccount}
                            onSubmit={props.modalParams.updateStripeAccount}
                            routeStore={props.modalData.routeStore}
                        />
                    </div>
                </div>
            )}
        </MrbModal>
    );
}

export default defaultTemplate(AdminProducerAccountPreviewTemplate);
