import React from "react";
import { StationsPreviewDescriptionLoaderTemplate } from "themes/administration/station/components";

class StationsPreviewDescriptionLoader extends React.Component {
    render() {
        return <StationsPreviewDescriptionLoaderTemplate {...this.props} />;
    }
}

export default StationsPreviewDescriptionLoader;
