import React from 'react'
import ContentLoader from "react-content-loader";

const NakiNigunArtistEditNameLoaderTemplate = ({
    ...props
}) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;

    if (screenwidth > 1199) {
        loaderWidth = screenwidth - 587;
    } else if (screenwidth > 991) {
        loaderWidth = screenwidth - 250;
    } else {
        loaderWidth = screenwidth;
    }

    return (
        <ContentLoader
            speed={2}
            width={loaderWidth}
            height="100%"
            viewBox={`0 0 ${loaderWidth} ${screenwidth > 544 ? 160 : 240}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <rect x="0" y={5} rx="6" ry="6" width="80" height="8" />
            <rect x="0" y={24} rx="6" ry="6" width="100%" height="42" />

            <ToggleLoader screenwidth={screenwidth} />

        </ContentLoader >
    )
}

export default NakiNigunArtistEditNameLoaderTemplate

const ToggleLoader = ({ screenwidth }) => {

    return (
        screenwidth > 544 ?
            <React.Fragment>
                <rect x="0" y={110} rx="6" ry="6" width="50" height="8" />
                <rect x="0" y={134} rx="12" ry="12" width="50" height="24" />

                <rect x="80" y={110} rx="6" ry="6" width="50" height="8" />
                <rect x="80" y={134} rx="12" ry="12" width="50" height="24" />

                <rect x="170" y={110} rx="6" ry="6" width="80" height="8" />
                <rect x="170" y={134} rx="12" ry="12" width="50" height="24" />

                <rect x="280" y={110} rx="6" ry="6" width="50" height="8" />
                <rect x="280" y={134} rx="12" ry="12" width="50" height="24" />
            </React.Fragment>
            :
            <React.Fragment>
                <rect x="0" y={110} rx="6" ry="6" width="50" height="8" />
                <rect x="0" y={134} rx="12" ry="12" width="50" height="24" />

                <rect x="50%" y={110} rx="6" ry="6" width="50" height="8" />
                <rect x="50%" y={134} rx="12" ry="12" width="50" height="24" />

                <rect x="0" y={180} rx="6" ry="6" width="80" height="8" />
                <rect x="0" y={204} rx="12" ry="12" width="50" height="24" />

                <rect x="50%" y={180} rx="6" ry="6" width="50" height="8" />
                <rect x="50%" y={204} rx="12" ry="12" width="50" height="24" />
            </React.Fragment>
    )
}