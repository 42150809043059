import { moduleProviderFactory } from "mrb/core";
import {
    UserList,
    UserEdit,
    UserContentExpiration,
    UserDeviceList,
    UserDeviceSettings,
} from "administration/user/pages";
import {
    UserEditRouteStore,
    UserListRouteStore,
    UserDeviceListRouteStore,
    UserContentExpirationRouteStore,
    UserDeviceSettingsRouteStore,
} from "administration/user/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "User",
        routes: [
            {
                name: "master.administration.user",
                pattern: "/user",
                children: [
                    {
                        name: "master.administration.user.list",
                        pattern: "",
                        component: UserList,
                        store: UserListRouteStore,
                        data: {
                            title: "ADMINISTRATION.USER.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.administration.user.edit",
                        pattern: "/:id/edit",
                        component: UserEdit,
                        store: UserEditRouteStore,
                    },
                    {
                        name: "master.administration.user.content-expiration",
                        pattern: "/:id/content-expiration",
                        component: UserContentExpiration,
                        store: UserContentExpirationRouteStore,
                    },
                    {
                        name: "master.administration.user.device-settings",
                        pattern: "/:id/device-settings",
                        component: UserDeviceSettings,
                        store: UserDeviceSettingsRouteStore,
                    },
                    {
                        name: "master.administration.user.device-list",
                        pattern: "/:id/device-list",
                        component: UserDeviceList,
                        store: UserDeviceListRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.USER",
                order: 13,
                icon: "users",
                route: "master.administration.user.list",
            },
        ],
    });
})();
