import React from "react";
import ContentLoader from "react-content-loader";

const AddPodcastsToTheGroupLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;
    const loaderWidth = screenwidth > 990 ? screenwidth - 260 : screenwidth;
    
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${loaderWidth} 600`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            {screenwidth > 460 ? <AddPodcastOnLarge /> : <AddPodcastOnSmall />}
        </ContentLoader>
    );
};

const AddPodcastOnLarge = () => {
    return (
        <React.Fragment>
            <rect x="0" y="17" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="37" rx="6" ry="6" width="75%" height="40" />

            <rect x="0" y="97" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="117" rx="6" ry="6" width="75%" height="40" />

            <rect x="0" y="177" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="197" rx="6" ry="6" width="75%" height="40" />

            <rect x="0" y="257" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="277" rx="6" ry="6" width="75%" height="40" />

            <rect x="77%" y="17" rx="6" ry="6" width="23%" height="230" />
            <rect x="77%" y="277" rx="6" ry="6" width="23%" height="40" />

            <rect x="0" y="337" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="357" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="417" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="437" rx="6" ry="6" width="100%" height="80" />

            <rect x="0" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="0" y="567" rx="6" ry="6" width="6%" height="20" />

            <rect x="8%" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="8%" y="567" rx="6" ry="6" width="6%" height="20" />

            <rect x="16%" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="16%" y="567" rx="6" ry="6" width="15%" height="20" />

            <rect x="32%" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="32%" y="567" rx="6" ry="6" width="6%" height="20" />

            <rect x="40%" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="40%" y="567" rx="6" ry="6" width="20%" height="20" />
        </React.Fragment>
    );
};

const AddPodcastOnSmall = () => {
    return (
        <React.Fragment>
            <rect x="0" y="17" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="37" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="97" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="117" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="177" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="197" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="257" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="277" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="337" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="357" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="417" rx="6" ry="6" width="10%" height="10" />
            <rect x="0" y="437" rx="6" ry="6" width="100%" height="80" />

            <rect x="0" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="0" y="567" rx="6" ry="6" width="15%" height="20" />

            <rect x="17%" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="17%" y="567" rx="6" ry="6" width="15%" height="20" />

            <rect x="34%" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="34%" y="567" rx="6" ry="6" width="25%" height="20" />

            <rect x="61%" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="61%" y="567" rx="6" ry="6" width="15%" height="20" />

            <rect x="78%" y="547" rx="6" ry="6" width="4%" height="10" />
            <rect x="78%" y="567" rx="6" ry="6" width="21%" height="20" />
        </React.Fragment>
    );
};

export default AddPodcastsToTheGroupLoaderTemplate;
