import React from "react";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { defaultTemplate } from "common/hoc";
import { MrbRadioButtons } from "mrb/components/radio";
import {
    PodcastListenersList,
    StationListenersList,
    NakiNigunListenersList,
} from "administration/analytics/components";

function ContentStatsTemplate({ contentStatsViewStore, t }) {
    const { contentTypeOptions, setSelectedContentType, selectedContentType } = contentStatsViewStore;
    return (
        <MrbPage>
            <MrbPageHeader>Content Stats</MrbPageHeader>
            <div className="u-animation--fadeIn">
                <MrbRadioButtons
                    id="content-stats-type-control"
                    name="content-stats-type-control"
                    inputClassNameExtend="c-segmented--secondary"
                    options={contentTypeOptions}
                    onChange={setSelectedContentType}
                    value={selectedContentType}
                    t={t}
                />
            </div>
            <Content selectedContentType={selectedContentType} />
        </MrbPage>
    );
}

function Content({ selectedContentType }) {
    if (selectedContentType === 0) {
        return <PodcastListenersList />;
    } else if (selectedContentType === 1) {
        return <StationListenersList />;
    } else if (selectedContentType === 2) {
        return <NakiNigunListenersList />;
    }

    throw new Error("Invalid content type.");
}

export default defaultTemplate(ContentStatsTemplate);
