import { BaseCoverImageUploadViewStore } from "common/stores";
import { forEach } from "lodash";

class NakiMobileFeaturedSectionCoverImageUploadViewStore extends BaseCoverImageUploadViewStore {
    errorsMap = {
        "file-invalid-type": () => {
            return "File type must be .jpg, .png or .gif";
        },
        "file-too-large": () => {
            return "File is larger than 20MB";
        },
    };

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            onFileRejected: (uploadResults) => {
                console.log(uploadResults);
                if (uploadResults && uploadResults.length > 0) {
                    forEach(uploadResults[0].errors, (error) => {
                        rootStore.notificationStore.error(this.errorsMap[error.code]);
                    });
                }
            },
        });
        this.routeStore = routeStore;
    }

    uploadImageToMediaVault(file) {
        return this.routeStore.uploadImageFile(file);
    }

    getMediaVaultFileUrl(id) {
        return this.routeStore.getMediaVaultFileUrl(id);
    }
}

export default NakiMobileFeaturedSectionCoverImageUploadViewStore;
