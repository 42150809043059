import React from "react";
import { PropTypes } from "prop-types";
import { MrbContentLoader } from "mrb/components/loader";
import { MrbButton } from "mrb/components/button";
import {
    DeviceMenuEditPageLayout,
    DeviceMenuList,
    DeviceMenuSearchResult,
    DeviceMenuLatestItems,
    DeviceListLoader,
} from "administration/device-menu/components";
import { defaultTemplate } from "common/hoc";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import { SearchDeviceLoader } from "application/device-settings/components";
function DeviceMenuEditTemplate({ deviceMenuEditViewStore, t }) {
    const { loaderStore, addSeparator, addSearch, deviceMenuSearchViewStore } =
        deviceMenuEditViewStore;

    return (
        <DeviceMenuEditPageLayout store={deviceMenuEditViewStore} t={t}>
            <div>
                <div className="c-device-menu__card u-mar--bottom--sml">
                    <MrbContentLoader
                        loaderStore={loaderStore}
                        loaderRender={() => <SearchDeviceLoader />}
                    >
                        {() => (
                            <div className="u-animation--fadeIn">
                                <div className="c-device-menu__card--search">
                                    <DeviceSearchFilter
                                        store={deviceMenuEditViewStore}
                                        t={t}
                                    />
                                    <div className="c-device-menu__actions">
                                        <MrbButton
                                            className="c-btn c-btn--base c-btn--ghost"
                                            label="Add Separator"
                                            onClick={addSeparator}
                                            disabled={
                                                deviceMenuEditViewStore.submitting
                                            }
                                        />{" "}
                                        <MrbButton
                                            className="c-btn c-btn--base c-btn--ghost"
                                            label="Add Search"
                                            onClick={addSearch}
                                            disabled={
                                                deviceMenuEditViewStore.submitting
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </MrbContentLoader>
                </div>
                <div className="c-device-menu">
                    <div className="c-device-menu__card c-device-menu__card--primary">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <DeviceListLoader />}
                        >
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <DeviceMenuList
                                        store={deviceMenuEditViewStore}
                                        t={t}
                                    />
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>

                    <div className="c-device-menu__card c-device-menu__card--results">
                        <DeviceMenuSearchResult
                            loaderStore={loaderStore}
                            store={deviceMenuSearchViewStore}
                            t={t}
                        />
                    </div>

                    <div className="c-device-menu__card c-device-menu__card--latest-items">
                        <DeviceMenuLatestItems
                            store={deviceMenuEditViewStore}
                            t={t}
                        />
                    </div>
                </div>
            </div>
        </DeviceMenuEditPageLayout>
    );
}

DeviceMenuEditTemplate.propTypes = {
    deviceMenuEditViewStore: PropTypes.object.isRequired,
};

const DeviceSearchFilter = observer(function DeviceSearchFilter({ store, t }) {
    const {
        submitting,
        deviceMenuSearchViewStore: { deviceSearchFilter },
    } = store;

    const onChange = (event) => {
        deviceSearchFilter.setSearch(event.target.value);
    };

    const onInlineReset = () => {
        deviceSearchFilter.setSearch("");
    };

    const isSearchEmpty = isEmpty(deviceSearchFilter.search);

    return (
        <React.Fragment>
            <div className="u-position--rel u-mar--right--sml">
                <input
                    className={`c-input c-input--search c-input--search--sml c-input--base ${
                        !isSearchEmpty ? "clear" : ""
                    }`}
                    type="text"
                    placeholder={t("Search")}
                    value={deviceSearchFilter.search || ""}
                    onChange={onChange}
                    disabled={submitting}
                />
                {!isSearchEmpty && (
                    <span className="c-btn--clear" onClick={onInlineReset}>
                        <i className="u-icon u-icon--base u-icon--close--primary"></i>
                    </span>
                )}
            </div>
        </React.Fragment>
    );
});

export default defaultTemplate(DeviceMenuEditTemplate);
