import React from "react";
import { AdminProducerAccountsTemplate } from "themes/administration/producer/pages";
import { AdminProducerAccountsViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminProducerAccountsViewStore(rootStore, componentProps),
    "adminProducerAccountsViewStore"
)
class AdminProducerAccounts extends React.Component {
    render() {
        return <AdminProducerAccountsTemplate {...this.props} />;
    }
}

export default AdminProducerAccounts;
