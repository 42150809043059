import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditFormLayout } from "mrb/components/layout";
import { MrbFieldInput, MrbFieldTextArea, MrbFieldNumericInput } from "mrb/components/input";
import { MrbFieldSelect } from "mrb/components/select";
import { MrbFieldToggle } from "mrb/components/checkbox";
import { MrbFieldRadioButtons } from "mrb/components/radio";
import { PodcastCoverImageUpload } from "administration/podcast/components";
import { FieldRichTextEditor, ProducerFieldSelect } from "common/components";
import { defaultTemplate } from "common/hoc";

function AddPodcastGroupItemTemplate({ addPodcastGroupItemViewStore, t }) {
    const {
        form,
        episodeSortTypeOptions,
        resetForm,
        coverImageUploadViewStore,
        genreSelectStore,
        languageSelectStore,
        locationSelectStore,
        producerSelectStore,
        isPremiumDisabled,
    } = addPodcastGroupItemViewStore;
    return (
        <MrbEditFormLayout
            form={form}
            t={t}
            onClickCancel={resetForm}
            submitLabel="ADMINISTRATION.PODCAST.EDIT.ADD_PODCAST_TO_GROUP.BUTTONS.ADD"
            cancelLabel="ADMINISTRATION.PODCAST.EDIT.ADD_PODCAST_TO_GROUP.BUTTONS.RESET"
        >
            <div className="u-animation--fadeIn">
                <div className="row">
                    <div className="col col-sml-12 col-xxlrg-8 u-mar--top--sml u-mar--bottom--sml">
                        <div className="row">
                            <div className="col col-sml-12">
                                <MrbFieldInput t={t} field={form.$("title")} classNameExtend="c-input--outline" />

                                <MrbFieldInput
                                    t={t}
                                    field={form.$("podcastFeed.feedUrl")}
                                    classNameExtend="c-input--outline"
                                />

                                <MrbFieldInput
                                    t={t}
                                    field={form.$("descriptionLink")}
                                    classNameExtend="c-input--outline"
                                />
                            </div>

                            <div className="col col-sml-12 col-med-6">
                                <MrbFieldSelect t={t} store={locationSelectStore} classNameExtend="c-dropdown--dark" />
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <MrbFieldSelect t={t} store={languageSelectStore} classNameExtend="c-dropdown--dark" />
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <MrbFieldSelect t={t} store={genreSelectStore} classNameExtend="c-dropdown--dark" />
                            </div>
                            <div className="col col-sml-12 col-med-6">
                                <ProducerFieldSelect
                                    t={t}
                                    store={producerSelectStore}
                                    classNameExtend="c-dropdown--dark"
                                />
                            </div>

                            <div className="col col-sml-12 col-xxlrg-12">
                                <MrbFieldInput t={t} field={form.$("keywords")} classNameExtend="c-input--outline" />

                                <MrbFieldTextArea
                                    t={t}
                                    field={form.$("description")}
                                    classNameExtend="c-input--outline"
                                    labelClassNameExtend="u-mar--top--tny"
                                />

                                <FieldRichTextEditor field={form.$("alternativeDescription")} t={t} />
                            </div>
                            <div className="col col-sml-6">
                                <div className="u-display--flex u-gap--med">
                                    <MrbFieldRadioButtons
                                        field={form.$("podcastFeed.episodeSortTypeId")}
                                        t={t}
                                        options={episodeSortTypeOptions}
                                    />

                                    <MrbFieldNumericInput
                                        precision={0}
                                        t={t}
                                        field={form.$("podcastFeed.episodeLimit")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col col-sml-12 col-xxlrg-4 u-mar--top--sml u-mar--bottom--sml">
                        <div className="c-toggle__card-list">
                            <MrbFieldToggle classNameExtend="c-toggle__item--inline" t={t} field={form.$("isActive")} />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isPremium")}
                                disabled={isPremiumDisabled}
                            />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isTurnedOnByDefault")}
                            />

                            <div className="u-separator--primary u-mar--none"></div>
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isVisibleInApp")}
                            />

                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isTurnedOnByDefaultInApp")}
                            />
                            <div className="u-separator--primary u-mar--none"></div>
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isVisibleInEmbeddedApp")}
                            />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isTurnedOnByDefaultInEmbeddedApp")}
                            />
                            <div className="u-separator--primary u-mar--none"></div>
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("podcastFeed.recentlyUpdatedVisibility")}
                            />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("podcastFeed.hlsEnabled")}
                            />
                            <div className="u-separator--primary u-mar--none"></div>
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("excludedFromRevenue")}
                            />
                        </div>
                        <div className="u-separator--primary u-separator--primary--base"></div>
                        <PodcastCoverImageUpload
                            classNameExtendUpload="c-upload--tny"
                            coverImageUploadViewStore={coverImageUploadViewStore}
                            isFullWidthFooterButtons
                        />
                    </div>
                </div>

                <div className="u-separator--primary"></div>
            </div>
        </MrbEditFormLayout>
    );
}

AddPodcastGroupItemTemplate.propTypes = {
    addPodcastGroupItemViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(AddPodcastGroupItemTemplate);
