import React from "react";
import { StationPreviewTemplate } from "themes/administration/station/pages";
import { StationPreviewViewStore } from "administration/station/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new StationPreviewViewStore(rootStore, componentProps),
    "stationPreviewViewStore"
)
class StationPreview extends React.Component {
    render() {
        return <StationPreviewTemplate {...this.props} />;
    }
}

export default StationPreview;
