import React from "react";
import { PodcastEpisodeEditTemplate } from "themes/administration/podcast/pages";
import { PodcastEpisodeEditViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new PodcastEpisodeEditViewStore(rootStore, componentProps),
    "podcastEpisodeEditViewStore"
)
class PodcastEpisodeEdit extends React.Component {
    render() {
        return <PodcastEpisodeEditTemplate {...this.props} />;
    }
}

export default PodcastEpisodeEdit;
