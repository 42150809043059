import React from "react";
import { PropTypes } from "prop-types";
import { PodcastPreviewPodcastVolume, PodcastGroupLineLoader } from "administration/podcast/components";
import { MrbContentLoader } from "mrb/components/loader";
import { map } from "lodash";
import { observer } from "mobx-react";
function PodcastPreviewPodcastVolumeListTemplate({ podcastPreviewViewStore, t }) {
    const { item, loaderStore } = podcastPreviewViewStore;

    return (
        <div>
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <PodcastGroupLineLoader />}>
                {() => (
                    <div className="u-animation--fadeIn">
                        {map(item.podcastVolumes, (podcastVolume, key) => (
                            <PodcastPreviewPodcastVolume
                                key={key}
                                podcastPreviewViewStore={podcastPreviewViewStore}
                                podcastVolume={podcastVolume}
                                t={t}
                            />
                        ))}
                    </div>
                )}
            </MrbContentLoader>
        </div>
    );
}

PodcastPreviewPodcastVolumeListTemplate.propTypes = {
    podcastPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(PodcastPreviewPodcastVolumeListTemplate);
