import React from "react";
import { observer } from "mobx-react";
import { UserDeviceSettingsTemplate } from "themes/administration/user/pages";
import { UserDeviceSettingsViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new UserDeviceSettingsViewStore(rootStore, componentProps),
    "userDeviceSettingsViewStore"
)
@observer
class UserDeviceSettings extends React.Component {
    render() {
        return <UserDeviceSettingsTemplate {...this.props} />;
    }
}

export default UserDeviceSettings;
