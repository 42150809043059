import React from "react";
import { PropTypes } from "prop-types";
import { StatsWidgetLoader } from "administration/analytics/components";
import { TrendingIndicator } from "administration/analytics/components";
import { MrbContentLoader } from "mrb/components/loader";
import { numberFormatter } from "common/utils";
import { observer } from "mobx-react";

function PodcastGroupPreviewSubPodcastAnalyticsStatsTemplate({ store, t }) {
    const { item, currentPeriodLabel, previousPeriodLabel, loaderStore } = store;
    return (
        <MrbContentLoader
            loaderStore={loaderStore}
            loaderRender={() => <StatsWidgetLoader statsPreview className={"col col-sml-12"} row={2} />}
        >
            {() => (
                <React.Fragment>
                    <div className="col col-sml-12 col-lrg-6 u-mar--bottom--sml">
                        <p className="u-type--sml u-type--color--opaque">
                            Total # of playbacks in {currentPeriodLabel}
                        </p>
                        <p>{numberFormatter.format(item.currentCycleScore)}</p>
                    </div>
                    {item.currentCyclePosition && item.previousCyclePosition && (
                        <div className="col col-sml-12 col-lrg-6 u-mar--bottom--sml">
                            <div className="u-display--flex u-display--flex--ai--center">
                                <TrendingIndicator
                                    classNameExtend="c-analytics__stats--med"
                                    indicatorClassNameExtend="u-icon--analytics--lrg"
                                    currentCycleNumber={item.currentCyclePosition}
                                    previousCycleNumber={item.previousCyclePosition}
                                />
                            </div>
                        </div>
                    )}
                    <div className="col col-sml-12 col-lrg-6 u-mar--bottom--sml">
                        <p className="u-type--sml u-type--color--opaque">Chart position</p>
                        <p>{item.currentCyclePosition ? item.currentCyclePosition : "Unlisted"}</p>
                    </div>
                    <div className="col col-sml-12 col-lrg-6">
                        <p className="u-type--sml u-type--color--opaque">{previousPeriodLabel} chart position</p>
                        <p>{item.previousCyclePosition ? item.previousCyclePosition : "Unlisted"}</p>
                    </div>
                </React.Fragment>
            )}
        </MrbContentLoader>
    );
}

PodcastGroupPreviewSubPodcastAnalyticsStatsTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(PodcastGroupPreviewSubPodcastAnalyticsStatsTemplate);
