import { LocalizedForm } from "common/localization";

export default class NakiNigunAlbumTrackEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "title",
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.ALBUM.FIELDS.TITLE_LABEL",
                    placeholder: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.ALBUM.FIELDS.TITLE_PLACEHOLDER",
                    rules: "required|string",
                },
            ],
        };
    }
}
