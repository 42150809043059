import React from "react";
import { PropTypes } from "prop-types";
import {
    MrbFieldInput,
    MrbEditPage,
    MrbFieldTextArea,
    MrbFieldToggle,
    MrbFieldSelect,
    MrbFieldRadioButtons,
    MrbFieldNumericInput,
} from "mrb/components";
import { MrbContentLoader } from "mrb/components/loader";
import {
    AddPodcastsToTheGroupLoader,
    AddPodcastVolumeItem,
    PodcastCoverImageUpload,
} from "administration/podcast/components";
import { MrbFileUploadValidation } from "mrb/components/media";
import {
    AddStationsOrPodcastsLoader,
    CoverImageUploadLoader,
    FieldRichTextEditor,
    ProducerFieldSelect,
} from "common/components";
import { defaultTemplate } from "common/hoc";
import { PodcastEditPodcastVolumeListTemplate } from "../components";

function PodcastEditTemplate({ podcastEditViewStore, t }) {
    const {
        form,
        loaderStore,
        episodeSortTypeOptions,
        coverImageUploadViewStore,
        genreSelectStore,
        languageSelectStore,
        locationSelectStore,
        overrideRadioUsersSettingsIsVisible,
        overrideAppUsersSettingsIsVisible,
        producerSelectStore,
        podcastContentTypeSelectStore,
        isTorahAnytimeTypeSelected,
    } = podcastEditViewStore;
    const { fileUploadStore } = coverImageUploadViewStore;

    return (
        <MrbEditPage store={podcastEditViewStore} t={t}>
            <div className="row">
                <div className="col col-sml-12 col-xxlrg-7 col-huge-8 u-mar--top--sml u-mar--bottom--sml">
                    <div className="c-card--primary">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <AddStationsOrPodcastsLoader podcast />}
                        >
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <div className="row">
                                        <div className="col col-sml-12 col-lrg-7 col-xxlrg-12 col-huge-7">
                                            <MrbFieldSelect
                                                t={t}
                                                store={podcastContentTypeSelectStore}
                                                classNameExtend="c-dropdown--dark"
                                            />
                                        </div>
                                    </div>
                                    <div className="u-separator--primary"></div>

                                    <div className="row">
                                        <div className="col col-sml-12 col-lrg-7 col-xxlrg-12 col-huge-7">
                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("title")}
                                                classNameExtend="c-input--outline"
                                            />
                                            <MrbFieldInput
                                                t={t}
                                                disabled={isTorahAnytimeTypeSelected}
                                                field={form.$("podcastFeed.feedUrl")}
                                                classNameExtend="c-input--outline"
                                            />
                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("descriptionLink")}
                                                classNameExtend="c-input--outline"
                                            />
                                            <MrbFieldSelect
                                                t={t}
                                                store={locationSelectStore}
                                                classNameExtend="c-dropdown--dark"
                                            />
                                            <MrbFieldSelect
                                                t={t}
                                                store={languageSelectStore}
                                                classNameExtend="c-dropdown--dark"
                                            />
                                            <MrbFieldSelect
                                                t={t}
                                                store={genreSelectStore}
                                                classNameExtend="c-dropdown--dark"
                                            />
                                            <ProducerFieldSelect
                                                t={t}
                                                store={producerSelectStore}
                                                classNameExtend="c-dropdown--dark"
                                            />

                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("keywords")}
                                                classNameExtend="c-input--outline"
                                            />
                                            <MrbFieldTextArea
                                                t={t}
                                                field={form.$("description")}
                                                classNameExtend="c-input--outline"
                                            />
                                            <FieldRichTextEditor field={form.$("alternativeDescription")} t={t} />
                                            {form.$("isPremium").value ? (
                                                <FieldRichTextEditor field={form.$("premiumInfo")} t={t} />
                                            ) : null}

                                            <div className="u-display--flex">
                                                <div className="u-mar--right--lrg">
                                                    <MrbFieldNumericInput
                                                        precision={0}
                                                        t={t}
                                                        disabled={isTorahAnytimeTypeSelected}
                                                        field={form.$("podcastFeed.episodeLimit")}
                                                    />
                                                </div>
                                                <MrbFieldRadioButtons
                                                    field={form.$("podcastFeed.episodeSortTypeId")}
                                                    t={t}
                                                    disabled={isTorahAnytimeTypeSelected}
                                                    options={episodeSortTypeOptions}
                                                />
                                            </div>
                                        </div>

                                        <div className="col col-sml-12 col-lrg-5 col-xxlrg-12 col-huge-5">
                                            <div className="c-toggle__card-list">
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isActive")}
                                                />
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    disabled={isTorahAnytimeTypeSelected}
                                                    field={form.$("isPremium")}
                                                />
                                                <MrbFieldToggle
                                                    t={t}
                                                    classNameExtend="c-toggle__item--inline"
                                                    field={form.$("isTurnedOnByDefault")}
                                                />
                                                <div className="u-separator--primary u-mar--none"></div>
                                                <MrbFieldToggle
                                                    t={t}
                                                    classNameExtend="c-toggle__item--inline"
                                                    field={form.$("isTurnedOnByDefaultInApp")}
                                                />
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isVisibleInApp")}
                                                />
                                                <div className="u-separator--primary u-mar--none"></div>
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isVisible")}
                                                />
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isFeatured")}
                                                />
                                                {overrideRadioUsersSettingsIsVisible ? (
                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("overrideRadioUsersSettings")}
                                                    />
                                                ) : null}
                                                <div className="u-separator--primary u-mar--none"></div>
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isVisibleInEmbeddedApp")}
                                                />
                                                <MrbFieldToggle
                                                    t={t}
                                                    classNameExtend="c-toggle__item--inline"
                                                    field={form.$("isTurnedOnByDefaultInEmbeddedApp")}
                                                />
                                                {overrideAppUsersSettingsIsVisible ? (
                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("overrideAppUsersSettings")}
                                                    />
                                                ) : null}

                                                <div className="u-separator--primary u-mar--none"></div>
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("podcastFeed.recentlyUpdatedVisibility")}
                                                />

                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    disabled={isTorahAnytimeTypeSelected}
                                                    field={form.$("podcastFeed.hlsEnabled")}
                                                />

                                                <div className="u-separator--primary u-mar--none"></div>
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("excludedFromRevenue")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>
                </div>

                <div className="col col-sml-12 col-xxlrg-4 u-mar--top--sml u-mar--bottom--sml">
                    <div className="c-card--primary">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <CoverImageUploadLoader />}>
                            {() => (
                                <PodcastCoverImageUpload
                                    classNameExtendUpload="c-upload--tny"
                                    loaderStore={loaderStore}
                                    coverImageUploadViewStore={coverImageUploadViewStore}
                                    isFullWidthFooterButtons
                                    showInfo
                                />
                            )}
                        </MrbContentLoader>
                    </div>
                    <MrbFileUploadValidation store={fileUploadStore} />
                </div>

                <div className="col col-sml-12 col-xxlrg-8 u-mar--top--sml u-mar--top--med">
                    <div>
                        <h1 className="u-type--base u-mar--bottom--sml">
                            {t("ADMINISTRATION.PODCAST.EDIT.ADD_PODCASTVOLUME.TITLE")}
                        </h1>

                        <div className="c-card--primary">
                            <MrbContentLoader
                                loaderStore={loaderStore}
                                loaderRender={() => <AddPodcastsToTheGroupLoader />}
                            >
                                {() => <AddPodcastVolumeItem podcastEditViewStore={podcastEditViewStore} />}
                            </MrbContentLoader>
                        </div>
                    </div>

                    <div className="u-mar--top--sml">
                        <PodcastEditPodcastVolumeListTemplate podcastEditViewStore={podcastEditViewStore} t={t} />
                    </div>
                </div>
            </div>
        </MrbEditPage>
    );
}

PodcastEditTemplate.propTypes = {
    podcastEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(PodcastEditTemplate);
