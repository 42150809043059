import React from "react";
import { PropTypes } from "prop-types";
import { MrbSelect } from "mrb/components/select";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";
import { isNil } from "lodash";

function EditFeaturedSectionContentTemplate({ editFeaturedSectionContentViewStore, cancel, t }) {
    const { contentSelectStore, onSelectItem } = editFeaturedSectionContentViewStore;

    return (
        <div>
            <div>
                <MrbSelect
                    store={contentSelectStore}
                    formatOptionLabel={({ item }) => <OptionLabel item={item} />}
                    classNameExtend="c-dropdown--dark c-dropdown--base"
                />
            </div>

            <div className="u-separator--primary u-separator--primary--base"></div>

            <div className="modal__footer__btn-group">
                <MrbButton t={t} label="Add" onClick={onSelectItem} disabled={isNil(contentSelectStore.selectedItem)} />
                <MrbButton classNameOverride="c-btn c-btn--base c-btn--ghost" t={t} label="Cancel" onClick={cancel} />
            </div>
        </div>
    );
}

EditFeaturedSectionContentTemplate.propTypes = {
    editFeaturedSectionContentViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function OptionLabel({ item }) {
    let icon = null;
    if (!isNil(item.stationId)) {
        icon = "stations";
    } else if (!isNil(item.podcastId)) {
        icon = "podcasts";
    } else if (!isNil(item.artistId)) {
        icon = "nigun";
    } else if (!isNil(item.albumId)) {
        icon = "nigun";
    }
    return (
        <p className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-cursor--pointer">
            <span className="u-display--flex u-display--flex--ai--center u-type--color--negative">
                <i className={`u-icon u-icon--med u-icon--${icon} u-mar--right--tny`} />
                {item.title}
            </span>
            {item.isPremium && <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny" />}
        </p>
    );
}

export default observer(EditFeaturedSectionContentTemplate);
