import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { PodcastCoverImageUploadViewStore } from "administration/podcast/stores";
import { map, orderBy } from "lodash";

class BasePodcastEditViewStore extends MrbBaseEditViewStore {
    @observable.ref episodeSortTypes = [];

    @computed get episodeSortTypeOptions() {
        return map(this.episodeSortTypes, (item) => {
            return {
                value: item.id,
                label: `ADMINISTRATION.PODCAST.EDIT.FIELDS.${item.name.toUpperCase()}_OPTION_LABEL`,
            };
        });
    }

    constructor(rootStore, routeStore, config) {
        super(rootStore, config);
        makeObservable(this);
        this.routeStore = routeStore;

        this.coverImageUploadViewStore = new PodcastCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.routeStore,
        });
    }

    async onInit() {
        await this.loadLookups();
        await this.initializeResource();
        if (this.isEdit && this.item.coverImageId) {
            this.coverImageUploadViewStore.setImageUrl(this.routeStore.getPodcastCoverImageUrl(this.item.coverImageId));
        }
    }

    @action.bound
    setEpisodeSortTypes(items) {
        this.episodeSortTypes = items;
    }

    async loadLookups() {
        const episodeSortTypes = await this.routeStore.getEpisodeSortTypes();
        this.setEpisodeSortTypes(orderBy(episodeSortTypes, (sortType) => sortType.sortOrder));
    }
}

export default BasePodcastEditViewStore;
