import React from "react";
import { PropTypes } from "prop-types";
import { PodcastGroupEditSubPodcast } from "administration/podcast/components";
import { DragDropList } from "common/components";
import { observer } from "mobx-react";

function PodcastGroupEditSubPodcastListTemplate({
    podcastGroupEditViewStore,
    t,
}) {
    const { subPodcasts, reorderPodcasts } = podcastGroupEditViewStore;

    return (
        <React.Fragment>
            {subPodcasts && subPodcasts.length > 0 && (
                <DragDropList
                    droppableId="sub-podcasts"
                    items={subPodcasts}
                    onDragEnd={reorderPodcasts}
                    itemRender={(item) => (
                        <PodcastGroupEditSubPodcast
                            podcastId={item.id}
                            podcastGroupEditViewStore={
                                podcastGroupEditViewStore
                            }
                            t={t}
                        />
                    )}
                ></DragDropList>
            )}
        </React.Fragment>
    );
}

PodcastGroupEditSubPodcastListTemplate.propTypes = {
    podcastGroupEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(PodcastGroupEditSubPodcastListTemplate);
