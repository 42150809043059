import React from "react";
import { observer } from "mobx-react";

function DashboardTemplate() {
    return (
        <React.Fragment>
            <div className="c-empty-state c-empty-state--dashboard">
                <i className="u-icon u-icon--welcome" />
                <h3 className="c-empty-state__title">Hi, welcome to NakiRadio!</h3>
                <p className="c-empty-state__desc">
                    Use this application for stations, podcasts, devices, and users administration.
                </p>
            </div>
        </React.Fragment>
    );
}
export default observer(DashboardTemplate);
