import React from "react";
import { observer } from "mobx-react";
import { AdminProducerPreviewTemplate } from "themes/administration/producer/pages";
import { AdminProducerPreviewViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminProducerPreviewViewStore(rootStore, componentProps),
    "adminProducerPreviewViewStore"
)
@observer
class AdminProducerPreview extends React.Component {
    render() {
        return <AdminProducerPreviewTemplate {...this.props} />;
    }
}

export default AdminProducerPreview;
