import React from "react";
import { observer } from "mobx-react";
import { StationActivityTemplate } from "themes/administration/analytics/pages";
import { StationActivityViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new StationActivityViewStore(rootStore, componentProps),
    "stationActivityViewStore"
)
@observer
class StationActivity extends React.Component {
    render() {
        return <StationActivityTemplate {...this.props} />;
    }
}

export default StationActivity;
