import { moduleProviderFactory } from "mrb/core";
import {
    AnalyticsDashboard,
    PremiumUsersActivity,
    RegisteredUsersActivity,
    TrendingStationList,
    TrendingPodcastList,
    ConnectedDevicesActivity,
    RegisteredUserDevicesActivity,
    PodcastActivity,
    StationActivity,
    DeviceActivity,
    UserDeviceActivity,
    ContentStats,
} from "administration/analytics/pages";
import {
    AnalyticsDashboardRouteStore,
    PremiumUsersActivityRouteStore,
    RegisteredUsersActivityRouteStore,
    TrendingStationListRouteStore,
    TrendingPodcastListRouteStore,
    ConnectedDevicesActivityRouteStore,
    RegisteredUserDevicesActivityRouteStore,
    PodcastActivityRouteStore,
    StationActivityRouteStore,
    DeviceActivityRouteStore,
    UserDeviceActivityRouteStore,
    ContentStatsRouteStore,
} from "administration/analytics/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "analytics",
        routes: [
            {
                name: "master.administration.analytics",
                pattern: "/analytics",
                children: [
                    {
                        name: "master.administration.analytics.dashboard",
                        pattern: "/dashboard",
                        component: AnalyticsDashboard,
                        store: AnalyticsDashboardRouteStore,
                    },
                    {
                        name: "master.administration.analytics.registered-users",
                        pattern: "/registered-users",
                        component: RegisteredUsersActivity,
                        store: RegisteredUsersActivityRouteStore,
                    },
                    {
                        name: "master.administration.analytics.connected-devices",
                        pattern: "/connected-devices",
                        component: ConnectedDevicesActivity,
                        store: ConnectedDevicesActivityRouteStore,
                    },
                    {
                        name: "master.administration.analytics.registered-devices",
                        pattern: "/registered-devices",
                        component: RegisteredUserDevicesActivity,
                        store: RegisteredUserDevicesActivityRouteStore,
                    },
                    {
                        name: "master.administration.analytics.premium-users",
                        pattern: "/premium-users",
                        component: PremiumUsersActivity,
                        store: PremiumUsersActivityRouteStore,
                    },
                    {
                        name: "master.administration.analytics.station",
                        pattern: "/station",
                        component: TrendingStationList,
                        store: TrendingStationListRouteStore,
                    },
                    {
                        name: "master.administration.analytics.station-activity",
                        pattern: "/station/:id",
                        component: StationActivity,
                        store: StationActivityRouteStore,
                    },
                    {
                        name: "master.administration.analytics.podcast",
                        pattern: "/podcast",
                        component: TrendingPodcastList,
                        store: TrendingPodcastListRouteStore,
                    },
                    {
                        name: "master.administration.analytics.podcast-activity",
                        pattern: "/podcast/:id",
                        component: PodcastActivity,
                        store: PodcastActivityRouteStore,
                    },
                    {
                        name: "master.administration.analytics.device-activity",
                        pattern: "/device",
                        component: DeviceActivity,
                        store: DeviceActivityRouteStore,
                    },
                    {
                        name: "master.administration.analytics.user-device-activity",
                        pattern: "/user-activity/:userId",
                        component: UserDeviceActivity,
                        store: UserDeviceActivityRouteStore,
                    },
                    {
                        name: "master.administration.analytics.content-stats",
                        pattern: "/content-stats",
                        component: ContentStats,
                        store: ContentStatsRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.ANALYTICS",
                order: 11,
                icon: "analytics",
                subMenu: [
                    {
                        title: "ADMINISTRATION.MENU.ANALYTICS_DASHBOARD",
                        order: 1,
                        route: "master.administration.analytics.dashboard",
                    },
                    {
                        title: "ADMINISTRATION.MENU.ANALYTICS_CONTENT_STATS",
                        order: 2,
                        route: "master.administration.analytics.content-stats",
                    },
                ],
            },
        ],
    });
})();
