import { MrbBaseRouteStore } from "mrb/core";
import { ShuffleService } from "common/services";

class ShuffleListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(ShuffleService);
    }

    async findShuffles(params) {
        const response = await this.service.find(params);
        return response.data;
    }

    async deleteShuffle(resource) {
        const response = await this.service.delete(resource);
        return response.data;
    }
}

export default ShuffleListRouteStore;
