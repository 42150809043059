import { filter } from "lodash";
import { action, observable, makeObservable } from "mobx";
import { MrbBasePreviewViewStore } from "mrb/core";
import { localizationService } from "common/localization";

class AdminPlaylistPreviewViewStore extends MrbBasePreviewViewStore {
    @observable items = [];

    constructor(rootStore, { routeStore }) {
        const playlistId = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: playlistId,
        });
        makeObservable(this);
        this.routeStore = routeStore;
    }

    async get(id) {
        const playlist = await this.routeStore.getAdminPlaylist(id);
        this.setItems(playlist.items);

        return playlist;
    }

    @action.bound
    setItems(items) {
        this.items = items;
    }

    @action.bound
    async removeAlbumTrackFromPlaylist(playlistId, albumTrackId) {
        try {
            await this.routeStore.deleteAlbumTrackFromAdminPlaylist(playlistId, albumTrackId);
            const newItems = filter(this.items, (item) => item.albumTrackId !== albumTrackId);
            this.setItems(newItems);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.PLAYLIST.ERROR.REMOVE_PLAYLIST_ITEM"),
                err
            );
        }
    }
}

export default AdminPlaylistPreviewViewStore;
