import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { EditFeaturedSectionContent } from "administration/naki-mobile/components";
import { observer } from "mobx-react";

function EditFeaturedSectionContentModalTemplate({ modalParams, loaderStore, t }) {
    return (
        <MrbModal title={"Add new item"} modalParams={modalParams} t={t} className="modal__content modal__content--lrg">
            <p className="u-type--sml u-type--left u-mar--bottom--tny">Select from list</p>
            <EditFeaturedSectionContent
                routeStore={modalParams.params.routeStore}
                mobileAppSystemType={modalParams.params.mobileAppSystemType}
                item={modalParams.data.item}
                onUpdate={modalParams.close}
                loaderStore={loaderStore}
                cancel={modalParams.close}
            />
        </MrbModal>
    );
}

EditFeaturedSectionContentModalTemplate.propTypes = {
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(EditFeaturedSectionContentModalTemplate);
