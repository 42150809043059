import React from "react";
import DownloadItem from "./DownloadItem";
import { map } from "lodash";
import { mergeCss } from "mrb/common/utils";

const DownloadList = ({
    items,
    onCancel,
    onRetry,
    startingIndex,
    noContentMessage,
    isCurrentlyDownloading,
    downloadStatus,
    t,
}) => {
    const hasDownloadData = items && (items.length > 0 || isCurrentlyDownloading);

    return (
        <div
            className={mergeCss(
                "u-display--flex u-display--flex--column u-gap--base",
                !hasDownloadData && "u-display--flex--jc--center u-height--100"
            )}
        >
            {hasDownloadData ? (
                map(items, (item, index) => (
                    <DownloadItem
                        key={item.index}
                        item={item}
                        index={index + (startingIndex || 0)}
                        onCancel={onCancel}
                        onRetry={onRetry}
                        downloadStatus={downloadStatus}
                        t={t}
                    />
                ))
            ) : (
                <div className="c-empty-state--no-results u-gap--med">
                    <i className="u-icon u-icon--empty-download"></i>
                    <p className="u-type--base u-type--wgt--semibold">{noContentMessage}</p>
                </div>
            )}
        </div>
    );
};

export default DownloadList;
