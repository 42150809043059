import { LocalizedForm } from "common/localization";

export default class NakiNigunArtistEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "name",
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.NAME_LABEL",
                    placeholder: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.NAME_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "isActive",
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_ACTIVE_LABEL",
                    placeholder: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_ACTIVE_PLACEHOLDER",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "isFeatured",
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_FEATURED_LABEL",
                    placeholder: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_FEATURED_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isPremium",
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_PREMIUM_LABEL",
                    placeholder: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_PREMIUM_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isVisible",
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_VISIBLE_LABEL",
                    placeholder: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_VISIBLE_PLACEHOLDER",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "isVisibleInApp",
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_VISIBLE_IN_APP_LABEL",
                    placeholder: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_VISIBLE_IN_APP_PLACEHOLDER",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "isVisibleInEmbeddedApp",
                    label: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_VISIBLE_IN_EMBEDDED_APP_LABEL",
                    placeholder: "ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.FIELDS.IS_VISIBLE_IN_EMBEDDED_APP_PLACEHOLDER",
                    type: "checkbox",
                    value: true,
                },
            ],
        };
    }
}
