import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { ProducerPaymentReportSummary } from "administration/producer/components";
import { ProducerReportLayout, ProducerReport } from "common/components/producer";
import { defaultTemplate } from "common/hoc";

function ProducerPaymentReportPreviewTemplate({ producerPaymentReportPreviewViewStore, t }) {
    return (
        <ProducerReportLayout
            producerReportViewStore={producerPaymentReportPreviewViewStore}
            backButtonRenderer={(props) => <BackButton {...props} />}
            t={t}
        >
            <div className="c-container--sidebar u-padd--none">
                <ProducerReport producerReportViewStore={producerPaymentReportPreviewViewStore} t={t} />

                <div className="c-container--sml order-2">
                    <ProducerPaymentReportSummary
                        producerPaymentReportPreviewViewStore={producerPaymentReportPreviewViewStore}
                        t={t}
                    />
                </div>
            </div>
        </ProducerReportLayout>
    );
}

ProducerPaymentReportPreviewTemplate.propTypes = {
    producerPaymentReportPreviewViewStore: PropTypes.object.isRequired,
    summaryNameFieldRenderer: PropTypes.any,
    t: PropTypes.func,
};

function BackButton({ summaryData }) {
    return (
        <MrbRouterLink routeName="master.administration.producer.preview" params={{ id: summaryData.producerId }}>
            <span className="display--ib u-mar--right--tny align--v--middle">
                <i className="u-icon u-icon--med u-icon--back" />
            </span>
            {summaryData.producerName}
        </MrbRouterLink>
    );
}

export default defaultTemplate(ProducerPaymentReportPreviewTemplate);
