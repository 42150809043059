import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbFieldToggle, MrbButton, MrbEditPage } from "mrb/components";
import { MrbPageContentSidebar } from "mrb/components/layout";
import { MrbContentLoader, MrbLineContentLoader } from "mrb/components/loader";
import { defaultTemplate } from "common/hoc";
import { MrbModal } from "mrb/components/modal";
import { AdminUserSubscriptionSettings, UserPasswordChange, UserEditLoader } from "administration/user/components";
import { observer } from "mobx-react";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { map } from "lodash";

function UserEditTemplate({ userEditViewStore, t }) {
    const { id, form, loaderStore, passwordResetModal, isAdminItself, routeStore, producers, guestEmail, artistInfo } =
        userEditViewStore;

    return (
        <React.Fragment>
            <MrbEditPage store={userEditViewStore} t={t} contentClassNameExtend="c-container--sidebar">
                <MrbContentLoader
                    loaderStore={loaderStore}
                    loaderRender={() => (
                        <div className="c-card--primary">
                            <UserEditLoader checkBox userProfile />
                        </div>
                    )}
                >
                    {() => (
                        <div className="c-card--primary u-mar--bottom--med">
                            <div className="u-animation--fadeIn">
                                <div className="row row--align-center">
                                    <div className="col col-sml-12 col-huge-6">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("firstName")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>

                                    <div className="col col-sml-12 col-huge-6">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("lastName")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>

                                    <div className="col col-sml-12 col-huge-6">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("email")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>

                                    <div className="col col-sml-6 col-med-3 col-lrg-6 col-xxlrg-3">
                                        <MrbFieldToggle
                                            t={t}
                                            field={form.$("isAdministrator")}
                                            disabled={isAdminItself}
                                        />
                                    </div>
                                    {guestEmail && (
                                        <div className="col col-sml-12 col-huge-6 ">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.USER.EDIT.FIELDS.GUEST_EMAIL_LABEL")}
                                            </p>
                                            <p className="u-type--base">
                                                <p>{guestEmail}</p>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </MrbContentLoader>

                <div className="u-mar--bottom--med">
                    <div className="c-analytics__title">User Subscription</div>
                    <AdminUserSubscriptionSettings userId={id} routeStore={routeStore} t={t} />
                </div>
                {artistInfo != null && artistInfo.artistRegisterMessage != null && (
                    <div className="u-mar--bottom--med">
                        <div className="c-analytics__title"> {t("ADMINISTRATION.USER.EDIT.ARTIST_INFO.TITLE")}</div>
                        <div className="c-card--primary">
                            <div>
                                <p className="u-type--sml u-type--color--opaque">
                                    {t("ADMINISTRATION.USER.EDIT.ARTIST_INFO.PRODUCER_TYPE_LABEL")}
                                </p>
                                <p className="u-mar--bottom--sml">{artistInfo.producerType.name}</p>
                                <p className="u-type--sml u-type--color--opaque">
                                    {t("ADMINISTRATION.USER.EDIT.ARTIST_INFO.PHONE_NUMBER_LABEL")}
                                </p>
                                <p className="u-mar--bottom--sml">{artistInfo.phoneNumber}</p>
                            </div>

                            <p className="u-type--sml u-type--color--opaque">
                                {t("ADMINISTRATION.USER.EDIT.ARTIST_INFO.REGISTRATION_MESSAGE_LABEL")}
                            </p>
                            <p>{artistInfo.artistRegisterMessage}</p>
                        </div>
                    </div>
                )}

                <MrbPageContentSidebar>
                    {!form.$("isAdministrator").value && (
                        <div className="c-card--primary u-mar--bottom--sml">
                            <MrbContentLoader
                                loaderStore={loaderStore}
                                loaderRender={() => (
                                    <MrbLineContentLoader
                                        rows={3}
                                        lineheight={38}
                                        setY={50}
                                        smallWidth={true}
                                        setHeight={140}
                                    />
                                )}
                            >
                                {() => (
                                    <React.Fragment>
                                        <MrbRouterLink
                                            className="c-btn c-btn--base c-btn--ghost c-btn--full u-mar--bottom--sml"
                                            routeName="master.administration.user.device-list"
                                            params={{ id: id }}
                                        >
                                            {t("ADMINISTRATION.USER.EDIT.BUTTONS.USER_DEVICES")}
                                        </MrbRouterLink>
                                        <MrbRouterLink
                                            className="c-btn c-btn--base c-btn--ghost c-btn--full u-mar--bottom--sml"
                                            routeName="master.administration.user.device-settings"
                                            params={{ id: id }}
                                        >
                                            {t("ADMINISTRATION.USER.EDIT.BUTTONS.DEVICE_SETTINGS")}
                                        </MrbRouterLink>
                                        <MrbRouterLink
                                            className="c-btn c-btn--base c-btn--ghost c-btn--full u-mar--bottom--sml"
                                            routeName="master.administration.user.content-expiration"
                                            params={{ id: id }}
                                        >
                                            {t("ADMINISTRATION.USER.EDIT.BUTTONS.CONTENT_EXPIRATION")}
                                        </MrbRouterLink>
                                        <MrbRouterLink
                                            className="c-btn c-btn--base c-btn--ghost c-btn--full"
                                            routeName="master.administration.analytics.user-device-activity"
                                            params={{ userId: id }}
                                        >
                                            {t("ADMINISTRATION.USER.EDIT.BUTTONS.USER_ACTIVITY")}
                                        </MrbRouterLink>
                                    </React.Fragment>
                                )}
                            </MrbContentLoader>
                        </div>
                    )}

                    <div className="c-card--primary">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => (
                                <MrbLineContentLoader
                                    rows={4}
                                    lineheight={38}
                                    setY={55}
                                    smallWidth={true}
                                    setHeight={150}
                                />
                            )}
                        >
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <Actions
                                        userEditViewStore={userEditViewStore}
                                        t={t}
                                        isAdminItself={isAdminItself}
                                    />
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>

                    {producers.length > 0 && (
                        <div className="c-card--primary u-mar--top--sml">
                            {producers.length === 1 ? (
                                <p className="u-type--sml">Producer</p>
                            ) : (
                                <p className="u-type--sml">Producers</p>
                            )}

                            <div className="u-height--350--max u-overflow--auto">
                                {map(producers, (producer) => (
                                    <div>
                                        <MrbRouterLink
                                            routeName="master.administration.producer.preview"
                                            params={{ id: producer.producerId }}
                                            key={producer.producerId}
                                            className="u-display--if u-display--flex--ai--center"
                                        >
                                            <React.Fragment>
                                                <span className="u-type--base">{producer.producerName}</span>
                                                <i
                                                    className="u-mar--left--tny c-nav__icon u-icon--med c-nav__icon--producers"
                                                    title="Registered producer"
                                                ></i>
                                            </React.Fragment>
                                        </MrbRouterLink>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </MrbPageContentSidebar>
            </MrbEditPage>

            <MrbModal modalParams={passwordResetModal}>
                <UserPasswordChange modalParams={passwordResetModal} />
            </MrbModal>
        </React.Fragment>
    );
}

UserEditTemplate.propTypes = {
    userEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const Actions = observer(function Actions({ userEditViewStore, t, isAdminItself }) {
    const { item, approveUser, disapproveUser, unlockUser, lockUser, changePassword } = userEditViewStore;

    return (
        <React.Fragment>
            {item && (
                <React.Fragment>
                    <div className="row">
                        <div className="col col-sml-12 col-med-6 col-lrg-12">
                            {item.isApproved && !isAdminItself ? (
                                <MrbButton
                                    disabled={isAdminItself}
                                    onClick={disapproveUser}
                                    label="ADMINISTRATION.USER.EDIT.BUTTONS.DISAPPROVE"
                                    t={t}
                                    classNameExtend="c-btn--full u-mar--bottom--sml"
                                />
                            ) : (
                                <MrbButton
                                    disabled={isAdminItself}
                                    onClick={approveUser}
                                    label="ADMINISTRATION.USER.EDIT.BUTTONS.APPROVE"
                                    t={t}
                                    classNameExtend="c-btn--full u-mar--bottom--sml"
                                />
                            )}
                        </div>
                        <div className="col col-sml-12 col-med-6 col-lrg-12">
                            {item.isLockedOut ? (
                                <MrbButton
                                    disabled={isAdminItself}
                                    onClick={unlockUser}
                                    label="ADMINISTRATION.USER.EDIT.BUTTONS.UNLOCK"
                                    t={t}
                                    classNameExtend="c-btn--full u-mar--bottom--sml"
                                />
                            ) : (
                                <MrbButton
                                    disabled={isAdminItself}
                                    onClick={lockUser}
                                    label="ADMINISTRATION.USER.EDIT.BUTTONS.LOCK"
                                    t={t}
                                    classNameExtend="c-btn--full u-mar--bottom--sml"
                                />
                            )}
                        </div>
                        <div className="col col-sml-12 col-med-6 col-lrg-12">
                            <MrbButton
                                disabled={!item.isApproved}
                                onClick={changePassword}
                                label="ADMINISTRATION.USER.EDIT.BUTTONS.CHANGE_PASSWORD"
                                t={t}
                                classNameExtend="c-btn--full"
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

export default defaultTemplate(UserEditTemplate);
