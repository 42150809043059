import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { observer } from "mobx-react";
import { NakiNigunAlbumTrackEditTitle } from "administration/naki-nigun/components";

const NakiNigunAlbumTrackEditModalTemplate = observer(function NakiNigunAlbumTrackEditModalTemplate({
    nakiNigunAlbumTrackListViewStore,
    modalParams,
    t,
}) {
    return (
        <MrbModal modalParams={modalParams} t={t} className="modal__content modal__content--lrg">
            {() => (
                <NakiNigunAlbumTrackEditTitle
                    nakiNigunAlbumTrackListViewStore={nakiNigunAlbumTrackListViewStore}
                    id={modalParams.data.id}
                    title={modalParams.data.title}
                    closeModal={modalParams.close}
                    t={t}
                />
            )}
        </MrbModal>
    );
});

NakiNigunAlbumTrackEditModalTemplate.propTypes = {
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default NakiNigunAlbumTrackEditModalTemplate;
