import React from "react";
import { PropTypes } from "prop-types";
import { PortalDetailedActivity } from "administration/analytics/components";
import { defaultTemplate } from "common/hoc";

function ConnectedDevicesActivityTemplate({ connectedDevicesActivityViewStore, t }) {
    return (
        <PortalDetailedActivity
            store={connectedDevicesActivityViewStore}
            activityLabel="Connected devices over period"
            totalLabel="Total # of connected devices"
            t={t}
        />
    );
}

ConnectedDevicesActivityTemplate.propTypes = {
    connectedDevicesActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(ConnectedDevicesActivityTemplate);
