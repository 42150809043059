import React from "react";
import { ShuffleEditPodcastItemTemplate } from "themes/administration/shuffle/components";

class ShuffleEditPodcastItem extends React.Component {
    render() {
        return <ShuffleEditPodcastItemTemplate {...this.props} />;
    }
}

export default ShuffleEditPodcastItem;
