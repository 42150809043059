import { makeObservable } from "mobx";
import { nakiRadioSystemTypes } from "common/constants";
import BaseFeaturedSectionViewStore from "./BaseFeaturedSectionViewStore";
import { MrbModalParams } from "mrb/common/models";

class NakiMobileEmbeddedFeaturedSectionViewStore extends BaseFeaturedSectionViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, { routeStore });
        makeObservable(this);
        this.routeStore = routeStore;
        this.mobileAppSystemType = nakiRadioSystemTypes.mobile_embedded;

        this.editItemModalParams = new MrbModalParams(
            {},
            {
                routeStore: this.routeStore,
                mobileAppSystemType: this.mobileAppSystemType,
            }
        );
    }
}

export default NakiMobileEmbeddedFeaturedSectionViewStore;
