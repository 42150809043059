import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbEditContent, MrbPageHeader } from "mrb/components/layout";
import { PodcastGroupEditPageHeader } from "administration/podcast/components";
import { observer } from "mobx-react";

function PodcastGroupEditPageLayoutTemplate({
    store,
    children,
    t,
    submitLabel,
    cancelLabel,
    ...pageProps
}) {
    const {
        form,
        onClickCancel,
        title,
        layoutActions,
        navigationOptions,
        navigateBack,
    } = store;

    return (
        <MrbPage t={t} {...pageProps}>
            <MrbPageHeader>
                <PodcastGroupEditPageHeader
                    form={form}
                    onClickCancel={onClickCancel}
                    title={title}
                    layoutActions={layoutActions}
                    navigateBack={navigateBack}
                    navigationOptions={navigationOptions}
                    submitLabel={submitLabel}
                    cancelLabel={cancelLabel}
                    store={store}
                    t={t}
                />
            </MrbPageHeader>
            <MrbEditContent>{children}</MrbEditContent>
        </MrbPage>
    );
}

PodcastGroupEditPageLayoutTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    children: PropTypes.any,
    t: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
};

export default observer(PodcastGroupEditPageLayoutTemplate);
