import React from "react";
import { PropTypes } from "prop-types";
import { Image, HTMLRender } from "common/components";
import { isNil } from "lodash";

function StationPromoPreviewTemplate({ stationPromoPreviewViewStore, t }) {
    const { item, coverImageUrl } = stationPromoPreviewViewStore;
    if (isNil(item)) {
        return null;
    }

    return (
        <div className="c-stations__preview">
            <div className="c-stations__preview__img">
                <Image src={coverImageUrl} className="c-upload__preview" />
            </div>
            <div className="c-stations__preview__desc">
                <p className="u-type--sml u-type--color--opaque">
                    {t("ADMINISTRATION.STATION.PREVIEW.FIELDS.PROMO.DESCRIPTION")}
                </p>
                <HTMLRender className="c-collapse-panel__body__description">{item.description}</HTMLRender>
            </div>
        </div>
    );
}

StationPromoPreviewTemplate.propTypes = {
    stationPromoPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default StationPromoPreviewTemplate;
