import React from "react";
import { NakiNigunArtistEditTemplate } from "themes/administration/naki-nigun/pages";
import { NakiNigunArtistEditViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunArtistEditViewStore(rootStore, componentProps),
    "nakiNigunArtistEditViewStore"
)
class NakiNigunArtistEdit extends React.Component {
    render() {
        return <NakiNigunArtistEditTemplate {...this.props} />;
    }
}

export default NakiNigunArtistEdit;
