import React from "react";
import { PropTypes } from "prop-types";
import { PortalDetailedActivity } from "administration/analytics/components";
import { defaultTemplate } from "common/hoc";

function RegisteredUsersActivityTemplate({ registeredUsersActivityViewStore, t }) {
    return (
        <PortalDetailedActivity
            store={registeredUsersActivityViewStore}
            activityLabel="Registered Portal users over period"
            totalLabel="Total # of registered Portal users"
            t={t}
        />
    );
}

RegisteredUsersActivityTemplate.propTypes = {
    registeredUsersActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(RegisteredUsersActivityTemplate);
