import { action, observable, makeObservable, toJS } from "mobx";
import { MrbBasePreviewViewStore } from "mrb/core";
import { httpStatusCodes } from "mrb/core/constants";
import { DeviceMenuSearchViewStore, DeviceMenuLatestItemsViewStore } from "administration/device-menu/stores";
import { deviceMenuTypes } from "common/constants";
import { UUID } from "common/utils";
import { find, filter, map, omit } from "lodash";

class DeviceMenuEditViewStore extends MrbBasePreviewViewStore {
    @observable.ref deviceMenuTypes = [];
    @observable.ref deviceMenu = [];
    @observable submitting = false;

    virtualizedListRef = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            layoutActions: {
                save: {
                    label: "Save",
                    onClick: () => this.save(),
                },
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
        const route = this.rootStore.routerStore.getCurrentRoute();
        if (route && route.data && route.data.title) {
            this.title = route.data.title;
        }

        this.deviceMenuSearchViewStore = new DeviceMenuSearchViewStore(this.rootStore, this);
        this.deviceMenuLatestItemsViewStore = new DeviceMenuLatestItemsViewStore(this.rootStore, this);
    }

    async onInit() {
        await this.loadLookups();
        await Promise.all([
            this.initializeResource(),
            this.deviceMenuSearchViewStore.onInit(),
            this.deviceMenuLatestItemsViewStore.onInit(),
        ]);
    }

    scrollToItem = (index) => {
        this.virtualizedListRef.scrollToItem(index, "smart");
    };

    async loadLookups() {
        const items = await this.routeStore.getDeviceMenuTypes();
        this.setDeviceMenuTypes(items);
    }

    @action.bound
    setDeviceMenuTypes(items) {
        this.deviceMenuTypes = items;
    }

    @action.bound
    setSubmitting(isSubmitting) {
        this.submitting = isSubmitting;
    }

    onClickCancel() {
        this.rootStore.routerStore.goBack();
    }

    setItem(item) {
        this.deviceMenu = map(item, (menuItem) => {
            return {
                ...menuItem,
                deviceMenuType: find(this.deviceMenuTypes, (x) => x.id === menuItem.deviceMenuTypeId),
            };
        });
    }

    @action.bound
    async save() {
        try {
            this.setSubmitting(true);
            const data = map(this.deviceMenu, (item, index) => {
                return {
                    ...omit(item, ["title", "isPremium", "id"]),
                    sortOrder: index + 1,
                };
            });
            await this.routeStore.updateDeviceMenu(data);
            this.routeStore.setIsRouteExitBlocked(false);
        } catch (err) {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to update the device menu. Please contact the support team."
            );
        } finally {
            this.setSubmitting(false);
        }
    }

    @action.bound
    addSeparator() {
        const separatorDeviceMenuType = find(this.deviceMenuTypes, (type) => type.abrv === deviceMenuTypes.separator);
        const separatorMenuItem = {
            id: UUID.create(),
            deviceMenuTypeId: separatorDeviceMenuType.id,
            deviceMenuType: separatorDeviceMenuType,
        };
        this.deviceMenu = [separatorMenuItem, ...this.deviceMenu];
        this.routeStore.setIsRouteExitBlocked(true);
    }

    @action.bound
    addSearch() {
        const searchDeviceMenuType = find(this.deviceMenuTypes, (type) => type.abrv === deviceMenuTypes.search);
        const searchMenuItem = {
            id: UUID.create(),
            deviceMenuTypeId: searchDeviceMenuType.id,
            deviceMenuType: searchDeviceMenuType,
        };
        this.deviceMenu = [searchMenuItem, ...this.deviceMenu];
        this.routeStore.setIsRouteExitBlocked(true);
    }

    @action.bound
    reorderMenu(result) {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        this.moveDeviceMenuItem(source.index, destination.index);
    }

    @action.bound
    moveToTop(index) {
        this.moveDeviceMenuItem(index, 0);
        this.scrollToItem(0);
    }

    @action.bound
    moveDeviceMenuItem(sourceIndex, destinationIndex) {
        const newDeviceMenu = Array.from(toJS(this.deviceMenu));
        const item = newDeviceMenu.splice(sourceIndex, 1)[0];
        newDeviceMenu.splice(destinationIndex, 0, item);

        this.deviceMenu = newDeviceMenu;
        this.routeStore.setIsRouteExitBlocked(true);
    }

    @action.bound
    removeMenuItem(itemId) {
        this.deviceMenu = filter(this.deviceMenu, (item) => item.id !== itemId);
        this.routeStore.setIsRouteExitBlocked(true);
    }

    async initializeResource() {
        try {
            await this.getResource(this.id);
            return true;
        } catch (err) {
            if (err.statusCode === httpStatusCodes.NotFound) {
                await this.rootStore.routerStore.goToNotFound();
                return false;
            } else {
                throw err;
            }
        }
    }

    get(id) {
        return this.routeStore.getDeviceMenu();
    }

    setVirtualizedListRef = (ref) => {
        this.virtualizedListRef = ref;
    };

    onDestroy() {
        this.deviceMenuSearchViewStore.onDestroy();
        this.deviceMenuLatestItemsViewStore.onDestroy();
    }
}

export default DeviceMenuEditViewStore;
