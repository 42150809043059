import { MrbBaseListViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { MrbQuerySelectStore } from "mrb/components/select";
import { makeObservable, action, observable, computed } from "mobx";
import { MrbFilterParameters } from "mrb/core/filter";
import { convertSecondsToHMS } from "common/utils";
import { localizationService } from "common/localization";
import { map, indexOf, includes, forEach, filter } from "lodash";
import { DownloadRequest } from "common/models";
import { AlbumTrackPlaylistCell } from "administration/content-explorer/components";

class AblumTrackContentExplorerFilter extends MrbFilterParameters {
    @observable audioTrackAvailable;

    constructor() {
        super();
        makeObservable(this);
        this.reset();
    }

    reset() {
        super.reset();
        this.audioTrackAvailable = null;
    }
}

class AblumTrackContentExplorerViewStore extends MrbBaseListViewStore {
    @observable selectedItems = [];
    selectedRowIds = [];

    @computed
    get selectedItemIds() {
        return map(this.selectedItems, (item) => item.id);
    }

    audioFileAvailabilityOptions = [
        {
            label: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.FILTER_OPTIONS.ALL",
            value: null,
        },
        {
            label: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.FILTER_OPTIONS.AVAILABLE",
            value: true,
        },
        {
            label: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.FILTER_OPTIONS.NOT_AVAILABLE",
            value: false,
        },
    ];

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            queryConfig: {
                filter: new AblumTrackContentExplorerFilter(),
                orderBy: "title",
                orderDirection: "asc",
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;

        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                columns: [
                    {
                        key: "title",
                        title: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.COLUMNS.TITLE",
                        sortable: true,
                        headerCheckboxSelection: true,
                        checkboxSelection: true,
                        minWidth: 400,
                        flex: 1,
                    },
                    {
                        key: "album.title",
                        title: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.COLUMNS.ALBUM_TITLE",
                        minWidth: 200,
                    },
                    {
                        key: "album.artist.name",
                        title: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.COLUMNS.ARTIST_NAME",
                        minWidth: 200,
                    },
                    {
                        key: "duration",
                        title: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.COLUMNS.DURATION",
                        format: (value) => convertSecondsToHMS(value),
                        minWidth: 140,
                        maxWidth: 140,
                        resizable: false,
                    },
                    {
                        key: "audioFileSize",
                        title: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.COLUMNS.AUDIO_FILE_SIZE",
                        format: (value) => (value === 0 ? "-" : `${(value / Math.round(1024 * 1024)).toFixed(2)} MB`),
                        minWidth: 140,
                        maxWidth: 140,
                        resizable: false,
                    },
                    {
                        key: "audioFileBitrate",
                        title: "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.COLUMNS.AUDIO_FILE_BITRATE",
                        format: (value) => (value === 0 ? "-" : `${value} Kbit/s`),
                        minWidth: 140,
                        maxWidth: 140,
                        resizable: false,
                    },
                    {
                        key: "playlist",
                        sortable: false,
                        title: "Playlist",
                        cell: (props) => (
                            <AlbumTrackPlaylistCell
                                {...props}
                                addPlaylist={(trackId, newPlaylist) => {
                                    map(this.gridStore.data, (d) => {
                                        if (d.id === trackId) {
                                            d.playlists = [...d.playlists, newPlaylist];
                                        }
                                        return d;
                                    });
                                }}
                                removePlaylist={(trackId, removedPlaylist) => {
                                    map(this.gridStore.data, (d) => {
                                        if (d.id === trackId) {
                                            d.playlists = filter(
                                                d.playlists,
                                                (playlist) => playlist.id !== removedPlaylist.id
                                            );
                                        }
                                    });
                                }}
                            />
                        ),
                    },
                ],
                selection: {
                    rowSelection: "multiple",
                    isRowSelectable: (data) => {
                        return data.audioStreamUrl != null && data.audioStreamUrl !== "";
                    },
                    onRowSelected: (node) => {
                        if (node.isSelected() && !includes(this.selectedItemIds, node.data.id)) {
                            this.selectedItems.push(node.data);
                        } else if (!node.isSelected() && includes(this.selectedItemIds, node.data.id)) {
                            const index = indexOf(this.selectedItemIds, node.data.id);
                            if (index !== -1) {
                                this.selectedItems.splice(index, 1);
                            }
                        }
                    },
                },
                onDataChange: () => {
                    const allRows = this.gridStore.gridApi.getModel().rowsToDisplay;
                    const rowsToSelect = allRows.filter((row) => this.selectedItemIds.includes(row.data.id));
                    if (rowsToSelect.length > 0) {
                        forEach(rowsToSelect, (row) => {
                            this.gridStore.gridApi.selectNode(row, true);
                        });
                    }
                },
            })
        );

        this.albumSelectStore = new MrbQuerySelectStore(
            {
                placeholder: "Select albums",
                isSearchable: true,
                virtualized: false,
                textKey: "title",
                valueKey: "id",
                initFetch: true,
                isMulti: true,
                actions: {
                    find: (searchTerm, pageNumber, pageSize) => {
                        const params = {
                            search: searchTerm,
                            pageNumber: pageNumber,
                            pageSize: pageSize,
                            sort: "title|asc",
                        };
                        return this.routeStore.findAlbums(params);
                    },
                    mapItem: (item) => {
                        return {
                            value: item.id,
                            label: item.title,
                            item: item,
                        };
                    },
                },
            },
            this.queryUtility,
            "albumIds"
        );

        this.artistSelectStore = new MrbQuerySelectStore(
            {
                placeholder: "Select artists",
                isSearchable: true,
                virtualized: false,
                textKey: "name",
                valueKey: "id",
                initFetch: true,
                isMulti: true,
                actions: {
                    find: (searchTerm, pageNumber, pageSize) => {
                        const params = {
                            search: searchTerm,
                            pageNumber: pageNumber,
                            pageSize: pageSize,
                            sort: "name|asc",
                        };
                        return this.routeStore.findArtists(params);
                    },
                    mapItem: (item) => {
                        return {
                            value: item.id,
                            label: item.name,
                            item: item,
                        };
                    },
                },
            },
            this.queryUtility,
            "artistIds"
        );
    }

    find(params) {
        params.embed = "album,album.artist,playlists";
        return this.routeStore.findAudioTrackAlbumSongs(params);
    }

    onFetchError() {
        this.rootStore.notificationStore.error(
            localizationService.t("ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.ERROR.GENERIC_MESSAGE")
        );
    }

    @action.bound
    changeAudioTrackAvailabilityFilter(value) {
        this.queryUtility.filter.set("audioTrackAvailable", value);
        this.queryUtility.fetch();
    }

    @action.bound
    downloadSelectedItems() {
        if (this.selectedItems == null || this.selectedItems.length < 1) {
            return;
        }
        const validSelectedItems = filter(this.selectedItems, (item) => item.audioStreamUrl);

        if (validSelectedItems.length > 0) {
            const downloadRequests = map(
                validSelectedItems,
                (item) =>
                    new DownloadRequest(
                        item.id,
                        item.title,
                        item.artworkUrl,
                        this.routeStore.createAlbumTrackAudioFileUrl(item.id),
                        item.duration,
                        item.audioFileSize
                    )
            );

            this.routeStore.rootStore.downloadManagerStore.addItemsToQueue(downloadRequests);
        }

        this.gridStore.gridApi.deselectAll();
    }
}

export default AblumTrackContentExplorerViewStore;
