import React from "react";
import { OrderListTemplate } from "themes/administration/order/pages";
import { OrderListViewStore } from "administration/order/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView((rootStore, componentProps) => new OrderListViewStore(rootStore, componentProps), "orderListViewStore")
class OrderList extends React.Component {
    render() {
        return <OrderListTemplate {...this.props} />;
    }
}

export default OrderList;
