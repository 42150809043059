import { MrbBaseRouteStore } from "mrb/core";
import { AppHomeSectionService, GenreLookupService } from "common/services";

class NakiMobileHomeSectionListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(AppHomeSectionService);
        this.genreLookupService = this.rootStore.createApplicationService(GenreLookupService);
    }

    async getAll() {
        const response = await this.service.getAll({
            embed: "items,items.album,items.artist,items.station,items.podcast",
        });
        return response.data;
    }

    async update(resources) {
        const response = await this.service.update(resources);
        return response.data;
    }

    async getContent(filter) {
        const response = await this.service.getContent(filter);
        return response.data;
    }

    async findGenres(searchPhrase) {
        const response = await this.genreLookupService.find(searchPhrase);
        return response.data;
    }
}

export default NakiMobileHomeSectionListRouteStore;
