import React from "react";
import { AdminProducerEditTemplate } from "themes/administration/producer/pages";
import { AdminProducerEditViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminProducerEditViewStore(rootStore, componentProps),
    "adminProducerEditViewStore"
)
class AdminProducerEdit extends React.Component {
    render() {
        return <AdminProducerEditTemplate {...this.props} />;
    }
}

export default AdminProducerEdit;