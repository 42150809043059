import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { MrbContentLoaderStore } from "mrb/components/loader";
import { MrbFileUploadViewStore } from "mrb/components/media";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";
import { head } from "lodash";

class StationCoverImageUploadViewStore extends MrbBaseViewStore {
    @computed get file() {
        return head(this.fileUploadStore.files);
    }

    @observable uploadedFileId = null;

    @observable.ref imageUrl = null;

    @observable hasChanged = false;

    constructor(rootStore, routeStore) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.loaderStore = new MrbContentLoaderStore({
            suspendRenderOnInit: false,
        });
        this.fileUploadStore = new MrbFileUploadViewStore(this.rootStore, {
            acceptedFiles: "image/jpeg, image/png, image/gif",
            maxSize: 2000000,
            showValidation: false,
            errorsMap: {
                "file-invalid-type": () => {
                    return "File type must be .jpg, .png or .gif";
                },
                "file-too-large": () => {
                    return "File is larger than 2MB";
                },
            },
        });

        this.reaction(
            () => this.file,
            (file) => {
                if (file) {
                    this.uploadFile(file);
                } else {
                    this.setUploadedFileId(null);
                }
            }
        );

        this.reaction(
            () => this.uploadedFileId,
            (id) => {
                if (id) {
                    this.setImageUrl(this.routeStore.getMediaVaultFileUrl(id));
                } else {
                    this.setImageUrl(null);
                }
            }
        );

        this.uploadImage = this.uploadImage.bind(this);
        this.remove = this.remove.bind(this);
    }

    @action.bound
    async uploadFile(file) {
        try {
            this.loaderStore.suspend();
            const mediaVaultFile = await this.routeStore.uploadImageFile(file);
            runInAction(() => {
                this.setUploadedFileId(mediaVaultFile.id);
                this.loaderStore.resume();
            });
        } catch (err) {
            this.rootStore.notificationStore.error(
                "Unexpected error occurred while trying to upload image. Please contact the support team.",
                err
            );
            this.loaderStore.resume();
        }
    }

    @action.bound
    setUploadedFileId(fileId) {
        this.uploadedFileId = fileId;
        this.hasChanged = true;
    }

    @action.bound
    setImageUrl(url) {
        if (url) {
            this.imageUrl = this.createAuthenticatedImageUrl(url);
        } else {
            this.imageUrl = null;
        }
    }

    uploadImage = (event) => {
        this.fileUploadStore.raiseBrowse(event);
    };

    remove = () => {
        this.setImageUrl(null);
        this.setUploadedFileId(null);
        this.fileUploadStore.clear();
    };

    createAuthenticatedImageUrl = (baseUrl) => {
        return createBaasicAuthenticatedMediaVaultUrl(this.rootStore, baseUrl, {
            width: 400,
            height: 400,
        });
    };
}

export default StationCoverImageUploadViewStore;
