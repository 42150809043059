import { BasePodcastPreviewRouteStore } from "administration/podcast/stores";

class PodcastPreviewRouteStore extends BasePodcastPreviewRouteStore {
    constructor(rootStore) {
        super(rootStore);
    }

    async getPodcast(id) {
        const response = await this.service.get(id, {
            embed: "podcastFeed,podcastFeed.episodeSortType,genres,location,language,podcastVolumes,producer",
        });
        return response.data;
    }
}

export default PodcastPreviewRouteStore;
