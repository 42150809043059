import React, { useState, useCallback } from "react";
import ContentLoader from "react-content-loader";

const SectionListLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width="100%"
                height={760}
                viewBox={`0 0 ${loaderWidth} 760`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <rect x="0" y="0" rx="6" ry="6" width="100%" height="360" />
                <rect x="0" y="370" rx="6" ry="6" width="100%" height="90" />
                <rect x="0" y="470" rx="6" ry="6" width="100%" height="90" />
                <rect x="0" y="570" rx="6" ry="6" width="100%" height="90" />
                <rect x="0" y="670" rx="6" ry="6" width="100%" height="90" />
            </ContentLoader>
        </div>
    );
};

export default SectionListLoaderTemplate;
