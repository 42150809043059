import React from "react";
import { ProducerPaymentReportPreviewTemplate } from "themes/administration/producer/pages";
import { ProducerPaymentReportPreviewViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView(
    (rootStore, componentProps) => new ProducerPaymentReportPreviewViewStore(rootStore, componentProps),
    "producerPaymentReportPreviewViewStore"
)
@observer
class ProducerPaymentReportPreview extends React.Component {
    render() {
        return <ProducerPaymentReportPreviewTemplate {...this.props} />;
    }
}

export default ProducerPaymentReportPreview;
