import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { mergeCss } from "mrb/common/utils";
import { MrbToggle } from "baasic-react-bootstrap/src";
import { defaultTemplate } from "common/hoc";

function AdminPlaylistTemplate({ item, index, provided, isDragging, style, store, t }) {
    const { togglePlaylistIsActive, goToPlaylistPreview } = store;

    const className = mergeCss(
        "c-device-menu__item",
        isDragging ? "c-device-menu__item--dragged" : null,
        !item.isPremium ? "fullHeight" : null
    );

    return (
        <div style={style}>
            <div
                className={className}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                data-is-dragging={isDragging}
                data-testid={item.id}
                data-index={index}
                index={index}
            >
                <React.Fragment>
                    <div className="c-device-menu__item__info">
                        <span className="u-type--wgt--bold u-mar--right--tny">{index + 1}.</span>

                        <div className="c-device-menu__item__title">{item.title}</div>
                    </div>
                    <div className="u-display--flex u-display--flex--wrap">
                        <MrbButton
                            onClick={() => goToPlaylistPreview(item.id)}
                            className="c-btn c-btn--base c-btn--ghost u-mar--right--sml"
                            label={t("ADMINISTRATION.PLAYLIST.BUTTONS.PREVIEW")}
                            t={t}
                        />

                        <MrbToggle
                            id={`playlist-status-${item.id}`}
                            value={item.isActive}
                            disabled={item.isToggleDisabled}
                            onChange={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                togglePlaylistIsActive(item);
                            }}
                            classNameOverride="c-btn c-btn--status--med"
                        />
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
}

AdminPlaylistTemplate.propTypes = {
    item: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
};

export default defaultTemplate(AdminPlaylistTemplate);
