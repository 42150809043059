import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { MrbEditFormLayout } from "mrb/components/layout";
import { MrbFieldInput } from "mrb/components/input";
import { MrbButton } from "mrb/components/button";
import { PodcastCoverImageUpload } from "administration/podcast/components";
import { PODCAST_FORM_STATUS } from "administration/podcast/stores";
import { observer } from "mobx-react";

function PodcastVolumeEditTemplate({ podcastEditPodcastVolumeViewStore, t }) {
    const {
        form,
        podcastVolumeIndex,
        podcastVolume,
        isCollapsed,
        toggleCollapse,
        setPanelRef,
        coverImageUploadViewStore,
        resetForm,
        deletePodcastVolume,
    } = podcastEditPodcastVolumeViewStore;
    return (
        <React.Fragment>
            <MrbCollapsiblePanel
                innerRef={setPanelRef}
                title={podcastVolume.title}
                t={t}
                isCollapsed={isCollapsed}
                onChangeCollapse={toggleCollapse}
                headerRender={(props) => (
                    <PodcastVolumePanelHeader
                        {...props}
                        podcastVolume={podcastVolume}
                        podcastVolumeIndex={podcastVolumeIndex}
                        deletePodcastVolume={deletePodcastVolume}
                    />
                )}
            >
                {() => (
                    <MrbEditFormLayout
                        form={form}
                        t={t}
                        onClickCancel={resetForm}
                        cancelLabel="Cancel"
                        submitLabel="Update"
                    >
                        <div className="c-upload__wrapper c-upload__wrapper--sml">
                            <div className="c-upload c-upload--sml">
                                <PodcastCoverImageUpload
                                    classNameExtendUpload="c-upload__upload--sml"
                                    coverImageUploadViewStore={coverImageUploadViewStore}
                                    showInfo={false}
                                />
                            </div>

                            <MrbFieldInput t={t} field={form.$("title")} classNameExtend="c-input--outline" />
                        </div>

                        <div className="u-separator--primary"></div>
                    </MrbEditFormLayout>
                )}
            </MrbCollapsiblePanel>
        </React.Fragment >
    );
}

PodcastVolumeEditTemplate.propTypes = {
    podcastEditPodcastVolumeViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const PodcastVolumePanelHeader = observer(function PodcastVolumePanelHeader({
    title,
    t,
    isCollapsed,
    podcastVolumeIndex,
    podcastVolume,
    deletePodcastVolume,
}) {
    return (
        <React.Fragment>
            <div className="u-display--flex u-display--flex--ai--center">
                <span className="u-type--wgt--bold u-mar--right--tny">{podcastVolumeIndex}.</span>
                {t(title)}
            </div>
            <div className="u-display--flex u-display--flex--ai--center">
                {podcastVolume.FORM_STATUS === PODCAST_FORM_STATUS.CHANGED && (
                    <div className="c-validation--info c-validation--info--secondary">
                        <p className="c-validation__label">
                            {t("ADMINISTRATION.PODCAST.EDIT.VALIDATION.PODCAST_VOLUME_CHANGED")}
                        </p>
                    </div>
                )}
                <div className="u-display--flex u-display--flex--ai--center">
                    <MrbButton
                        t={t}
                        label="ADMINISTRATION.PODCAST.EDIT.PODCASTVOLUMES.BUTTONS.DELETE"
                        onClick={deletePodcastVolume}
                        classNameOverride="c-btn c-btn--sml c-btn--ghost u-mar--right--tny"
                    />
                    <i
                        className={`u-icon u-icon--med u-icon--arrow-down ${isCollapsed ? "u-rotate--180" : ""
                            } u-cursor--pointer u-mar--top--micro--alt`}
                    ></i>
                </div>
            </div>
        </React.Fragment>
    );
});

export default observer(PodcastVolumeEditTemplate);
