import React from "react";
import { PodcastEpisodeListTemplate } from "themes/administration/podcast/pages";
import { PodcastEpisodeListViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new PodcastEpisodeListViewStore(rootStore, componentProps),
    "podcastEpisodeListViewStore"
)
class PodcastEpisodeList extends React.Component {
    render() {
        return <PodcastEpisodeListTemplate {...this.props} />;
    }
}

export default PodcastEpisodeList;
