import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbEditPage } from "mrb/components";
import { MrbContentLoader } from "mrb/components/loader";
import { MrbButton } from "mrb/components/button";
import { CoverImageUpload, UploadAlbumTrackLoader } from "common/components";
import { MrbFileUpload, MrbFileUploadValidation } from "mrb/components/media";
import { CoverImageUploadLoader } from "common/components";
import { defaultTemplate } from "common/hoc";
import { isNil } from "lodash";
import { observer } from "mobx-react";

function NakiNigunAlbumTrackEditTemplate({ nakiNigunAlbumTrackEditViewStore, t }) {
    const { form, loaderStore, fileUploadStore, file, coverImageUploadViewStore } = nakiNigunAlbumTrackEditViewStore;

    return (
        <MrbEditPage store={nakiNigunAlbumTrackEditViewStore} t={t}>
            <div className="c-container--base">
                <div className="c-card--primary">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <UploadAlbumTrackLoader />}>
                        {() => (
                            <div className="u-animation--fadeIn">
                                <div>
                                    <MrbFieldInput t={t} field={form.$("title")} classNameExtend="c-input--outline" />
                                </div>

                                <div className="row">
                                    <div className="col col-sml-12 col-lrg-6">
                                        <div className="u-display--flex u-display--flex--column">
                                            <MrbFileUpload store={fileUploadStore}>
                                                <div className="u-animation--fadeIn">
                                                    <div className="c-upload__upload c-upload__upload--lrg u-padd--none">
                                                        {isNil(file) ? (
                                                            <FileUploadNote />
                                                        ) : (
                                                            <UploadedFileInfo file={file} />
                                                        )}
                                                    </div>
                                                </div>
                                            </MrbFileUpload>
                                            <UploadFooter store={nakiNigunAlbumTrackEditViewStore} />
                                        </div>
                                    </div>

                                    <div className="col col-sml-12 col-lrg-6">
                                        <div className="u-display--flex u-display--flex--column">
                                            <MrbContentLoader
                                                loaderStore={loaderStore}
                                                loaderRender={() => <CoverImageUploadLoader />}
                                            >
                                                {() => (
                                                    <CoverImageUpload
                                                        classNameExtendUpload="c-upload__upload--lrg u-padd--none"
                                                        loaderStore={loaderStore}
                                                        coverImageUploadViewStore={coverImageUploadViewStore}
                                                        isFullWidthFooterButtons
                                                        showInfo
                                                    />
                                                )}
                                            </MrbContentLoader>
                                            <MrbFileUploadValidation store={fileUploadStore} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </MrbContentLoader>
                </div>
            </div>
        </MrbEditPage>
    );
}

function FileUploadNote() {
    return (
        <React.Fragment>
            <i className="u-icon u-icon--huge u-icon--upload-audio u-mar--bottom--sml"></i>
            <p className="u-type--sml u-type--center">
                Click here to upload or <br /> drag and drop file
            </p>
            <ul className="u-type--sml u-type--center u-mar--top--sml">
                <li className="u-type--sml u-type--color--opaque">• Upload mp3 file</li>
                <li className="u-type--sml u-type--color--opaque">• Max. file size is 200MB</li>
            </ul>
        </React.Fragment>
    );
}

function UploadedFileInfo({ file }) {
    return (
        <React.Fragment>
            <i className="u-icon u-icon--huge u-icon--upload-audio u-mar--bottom--sml"></i>
            <p className="u-type--sml u-type--color--opaque">Name:</p>
            <p className="u-type--sml u-type--clamp u-type--break u-type--center u-width--300--max u-mar--bottom--tny">
                {file.name}
            </p>

            <p className="u-type--sml u-type--color--opaque">Size:</p>
            <p className="u-type--sml">{`${(file.size / Math.round(1024 * 1024)).toFixed(2)} MB`}</p>
        </React.Fragment>
    );
}

const UploadFooter = observer(function UploadFooter({ store }) {
    const { attachAudioFile, file, removeAudioFile, loaderStore } = store;

    return (
        <React.Fragment>
            {file ? (
                <div className="c-upload__footer">
                    <MrbButton
                        label="Replace"
                        disabled={loaderStore.loading}
                        onClick={attachAudioFile}
                        aria-busy={loaderStore.loading}
                        classNameExtend="c-btn--full"
                    />
                    <MrbButton
                        classNameExtend="c-btn--ghost u-mar--left--tny c-btn--full"
                        label="Remove"
                        onClick={removeAudioFile}
                        disabled={loaderStore.loading}
                    />
                </div>
            ) : (
                <MrbButton
                    label="Upload audio file"
                    onClick={attachAudioFile}
                    className="c-btn c-btn--primary c-btn--base c-btn--full"
                />
            )}
        </React.Fragment>
    );
});

NakiNigunAlbumTrackEditTemplate.propTypes = {
    nakiNigunAlbumTrackEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(NakiNigunAlbumTrackEditTemplate);
