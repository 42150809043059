import { BaseCoverImageUploadViewStore } from "common/stores";

class NakiNigunArtistCoverImageUploadViewStore extends BaseCoverImageUploadViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;
    }

    uploadImageToMediaVault(file) {
        return this.routeStore.uploadImageFile(file);
    }

    getMediaVaultFileUrl(id) {
        return this.routeStore.getMediaVaultFileUrl(id);
    }
}

export default NakiNigunArtistCoverImageUploadViewStore;
