import React from "react";
import { observer } from "mobx-react";
import { PodcastGroupPreviewTemplate } from "themes/administration/podcast/pages";
import { PodcastGroupPreviewViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastGroupPreviewViewStore(rootStore, componentProps),
    "podcastGroupPreviewViewStore"
)
@observer
class PodcastGroupPreview extends React.Component {
    render() {
        return <PodcastGroupPreviewTemplate {...this.props} />;
    }
}

export default PodcastGroupPreview;
