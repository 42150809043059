import { action, computed, observable, makeObservable } from "mobx";
import { MrbBasePreviewViewStore } from "mrb/core";
import { MrbModalParams } from "mrb/common/models";
import { localizationService } from "common/localization";
import { forEach, find, some, isNil, filter, includes } from "lodash";
import { DownloadRequest } from "common/models";

class PodcastEpisodeListFilter {
    @observable search = "";
    @observable hlsAvailable = null;
    @observable mp3Available = null;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setSearch(search) {
        this.search = search;
    }

    @action.bound
    setHlsAvailable(value) {
        this.hlsAvailable = value;
    }

    @action.bound
    setMp3Available(value) {
        this.mp3Available = value;
    }
}

class PodcastEpisodeListViewStore extends MrbBasePreviewViewStore {
    @observable episodes = [];
    @observable.ref filter = new PodcastEpisodeListFilter();

    @computed get filteredEpisodes() {
        if (this.filter.search === "" && this.filter.hlsAvailable === null && this.filter.mp3Available === null) {
            return this.episodes;
        }
        return filter(this.episodes, (episode) => {
            if (
                this.filter.hlsAvailable !== null &&
                !(
                    (this.filter.hlsAvailable && episode.hlsStreamUrl) ||
                    (!this.filter.hlsAvailable && !episode.hlsStreamUrl)
                )
            ) {
                return false;
            }
            if (
                this.filter.mp3Available !== null &&
                !(
                    (this.filter.mp3Available && episode.audioStreamUrl) ||
                    (!this.filter.mp3Available && !episode.audioStreamUrl)
                )
            ) {
                return false;
            }
            if (
                this.filter.search !== "" &&
                this.filter.search != null &&
                !includes(episode.title.toLowerCase(), this.filter.search.toLowerCase())
            ) {
                return false;
            }

            return true;
        });
    }

    @computed get isSyncing() {
        if (this.episodes.length === 0) {
            return false;
        }
        return some(this.episodes, (episode) => !isNil(episode.syncTask));
    }

    @computed get coverImageUrl() {
        if (this.item && this.item.coverImageId) {
            return this.rootStore.coverImageFactory.createPodcastCoverImageUrl(this.item.coverImageId, 150, 150);
        }

        return null;
    }

    constructor(rootStore, { routeStore }) {
        const podcastId = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: podcastId,
            layoutActions: {
                add: {
                    onClick: () => this.openGeneratePopupForPodcast(),
                    label: "ADMINISTRATION.PODCAST.EPISODES.HEADER.BUTTONS.ADD_EPISODE",
                    className: "c-btn c-btn--base c-btn--primary",
                },
                generate: {
                    onClick: () => this.openGeneratePopupForPodcast(),
                    label: "ADMINISTRATION.PODCAST.EPISODES.HEADER.BUTTONS.GENERATE",
                    className: "c-btn c-btn--base c-btn--primary",
                },
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;

        this.generateAudioFilesModal = new MrbModalParams();
        this.editPodcastEpisodeTitleModal = new MrbModalParams();

        this.openGeneratePopupForEpisode = this.openGeneratePopupForEpisode.bind(this);
        this.openGeneratePopupForPodcast = this.openGeneratePopupForPodcast.bind(this);
        this.openEditPodcastEpisodeTitleModal = this.openEditPodcastEpisodeTitleModal.bind(this);
        this.goToUploadEpisode = this.goToUploadEpisode.bind(this);

        this.reaction(
            () => this.rootStore.contentEncoderSyncStore.podcastEpisodeTasks,
            async (episodeTasks) => {
                const isUpdated = await this._assignSyncTask(episodeTasks, this.episodes);
                if (isUpdated) {
                    this.getEpisodes(this.id);
                }
            }
        );
    }

    async onInit() {
        await Promise.all([this.getEpisodes(this.id), this.initializeResource()]);
    }

    get(id) {
        return this.routeStore.getPodcast(id);
    }

    @action.bound
    async getEpisodes(podcastId) {
        try {
            const episodes = await this.routeStore.getEpisodes(podcastId);
            this._assignSyncTask(this.rootStore.contentEncoderSyncStore.podcastEpisodeTasks, episodes);
            this.setEpisodes(episodes);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.PODCAST.EPISODES.ERROR.FETCH_EPISODES_GENERIC"),
                err
            );
        }
    }

    @action.bound
    async processHlsEncodingForPodcastEpisode(podcastEpisodeId, processHls, processMp3) {
        const data = {
            ids: [podcastEpisodeId],
            processHls: processHls,
            overwriteHls: processHls,
            processMp3: processMp3,
            overwriteMp3: processMp3,
        };
        try {
            await this.routeStore.hlsEncodePodcastEpisodes(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.PODCAST.EPISODES.ERROR.ENCODE_EPISODE_GENERIC"),
                err
            );
        }
        this.rootStore.contentEncoderSyncStore.resetSync();
        this.generateAudioFilesModal.close();
    }

    @action.bound
    async processHlsEncodingForPodcast(processHls, processMp3) {
        const data = {
            ids: [this.id],
            processHls: processHls,
            overwriteHls: processHls,
            processMp3: processMp3,
            overwriteMp3: processMp3,
        };
        try {
            await this.routeStore.hlsEncodePodcasts(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.PODCAST.EPISODES.ERROR.ENCODE_PODCAST_GENERIC"),
                err
            );
        }
        this.rootStore.contentEncoderSyncStore.resetSync();
        this.generateAudioFilesModal.close();
    }

    @action.bound
    async editPodcastEpisodeTitle(podcastEpisodeId, title) {
        const data = { title: title };
        try {
            await this.routeStore.editPodcastEpisodeTitle(podcastEpisodeId, data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.PODCAST.EPISODES.ERROR.ENCODE_SONG_GENERIC"),
                err
            );
        }
        this.editPodcastEpisodeTitleModal.close();
    }

    openEditPodcastEpisodeTitleModal(podcastEpisode) {
        this.editPodcastEpisodeTitleModal.open({
            id: podcastEpisode.id,
            title: podcastEpisode.title,
            editPodcastEpisodeTitle: this.editPodcastEpisodeTitle,
        });
    }

    openGeneratePopupForEpisode(podcastEpisode) {
        this.generateAudioFilesModal.open({
            id: podcastEpisode.id,
            artworkUrl: podcastEpisode.artworkUrl,
            title: podcastEpisode.title,
            duration: podcastEpisode.duration,
            hlsStreamUrl: podcastEpisode.hlsStreamUrl,
            audioFileBitrate: podcastEpisode.audioFileBitrate,
            audioStreamUrl: podcastEpisode.audioStreamUrl,
            audioFileSize: podcastEpisode.audioFileSize,
            processHlsEncodingForSingleEntity: this.processHlsEncodingForPodcastEpisode,
        });
    }

    @action.bound
    async syncNewEpisodes() {
        try {
            await this.routeStore.syncPodcastsBackground({ ids: [this.id] });
            this.rootStore.notificationStore.info(localizationService.t("ADMINISTRATION.PODCAST.EPISODES.SYNC.START"));
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.PODCAST.EPISODES.ERROR.SYNC_NEW_EPISODES_GENERIC"),
                err
            );
        }
    }

    openGeneratePopupForPodcast() {
        this.generateAudioFilesModal.open({
            artworkUrl: this.coverImageUrl,
            title: this.item.title,
            subtitle: this.item.parentPodcast?.title,
            processHlsEncodingForAllEntities: this.processHlsEncodingForPodcast,
        });
    }

    _assignSyncTask(syncTasks, episodes) {
        let isUpdated = false;
        forEach(episodes, (episode) => {
            const newSyncTask = find(syncTasks, (syncTask) => episode.id === syncTask.podcastEpisodeId);
            if (!isNil(episode.syncTask) && isNil(newSyncTask)) {
                isUpdated = true;
            }
            episode.syncTask = newSyncTask;
        });
        return isUpdated;
    }

    @action.bound
    setEpisodes(episodes) {
        this.episodes = episodes;
    }

    updatePodcastEpisodeTitle(episodeId, newTitle) {
        const episode = find(this.episodes, (episode) => episode.id === episodeId);
        if (episode) {
            episode.title = newTitle;
        }
    }

    downloadPodcastEpisode = (item) => {
        if (item && item.audioStreamUrl) {
            var downloadRequest = new DownloadRequest(
                item.id,
                item.title,
                item.artworkUrl,
                this.routeStore.createPodcastEpisodeAudioFileUrl(item.id),
                item.duration,
                item.audioFileSize
            );
            this.routeStore.rootStore.downloadManagerStore.addItemToQueue(downloadRequest);
        } else {
            return null;
        }
    };

    @action.bound
    async deletePodcastEpisode(item) {
        if (item && item.isManualUpload) {
            this.rootStore.confirmDialogStore.showConfirm({
                message: "Do you want to delete the episode?",
                onConfirm: async () => {
                    try {
                        await this.routeStore.deleteEpisode(item.id);
                        const newEpisodes = filter(this.episodes, (episode) => episode.id !== item.id);
                        this.setEpisodes(newEpisodes);
                    } catch (err) {
                        this.rootStore.notificationStore.error(
                            localizationService.t("ADMINISTRATION.PODCAST.EPISODES.ERROR.DELETE_FAILED"),
                            err
                        );
                    }
                },
                yesLabel: "Delete",
                noLabel: "Cancel",
                description: "You won't be able to revert this action.",
            });
        }
    }

    goToUploadEpisode() {
        this.rootStore.routerStore.goTo("master.administration.podcast.episodes.create", { id: this.id });
    }
}

export default PodcastEpisodeListViewStore;
