import { MrbBaseViewStore } from "mrb/core";

class DashboardViewStore extends MrbBaseViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;
    }
}

export default DashboardViewStore;
