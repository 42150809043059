import React from "react";
import { observer } from "mobx-react";
import { PodcastShuffleEditTemplate } from "themes/administration/shuffle/pages";
import { PodcastShuffleEditViewStore } from "administration/shuffle/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastShuffleEditViewStore(rootStore, componentProps),
    "podcastShuffleEditViewStore"
)
@observer
class PodcastShuffleEdit extends React.Component {
    render() {
        return <PodcastShuffleEditTemplate {...this.props} />;
    }
}

export default PodcastShuffleEdit;
