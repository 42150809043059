import React from "react";
import { PodcastPreviewPodcastVolumeTemplate } from "themes/administration/podcast/components";
import { PodcastPreviewPodcastVolumeViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastPreviewPodcastVolumeViewStore(rootStore, componentProps),
    "podcastPreviewPodcastVolumeViewStore"
)
class PodcastPreviewPodcastVolume extends React.Component {
    render() {
        return <PodcastPreviewPodcastVolumeTemplate {...this.props} />;
    }
}

export default PodcastPreviewPodcastVolume;
