import { computed, makeObservable } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { MrbFieldSelectStore } from "mrb/components/select";
import { NakiNigunAlbumEditForm } from "administration/naki-nigun/forms";
import { NakiNigunAlbumCoverImageUploadViewStore } from "administration/naki-nigun/stores";
import { map } from "lodash";

class NakiNigunAddAlbumViewStore extends MrbBaseEditViewStore {
    @computed get episodeSortTypeOptions() {
        return this.nakiNigunArtistEditViewStore.episodeSortTypeOptions;
    }

    @computed get isPremiumDisabled() {
        return this.nakiNigunArtistEditViewStore.form.$("isPremium").value;
    }

    @computed get isActiveStatusDisabled() {
        return !this.nakiNigunArtistEditViewStore.form.$("isActive").value;
    }

    constructor(rootStore, { nakiNigunArtistEditViewStore }) {
        super(rootStore, {
            name: "naki-nigun-album",
            FormClass: NakiNigunAlbumEditForm,
            redirectOnCreateSuccess: false,
        });
        this.nakiNigunArtistEditViewStore = nakiNigunArtistEditViewStore;
        this.routeStore = this.nakiNigunArtistEditViewStore.routeStore;
        makeObservable(this);

        this.coverImageUploadViewStore = new NakiNigunAlbumCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.nakiNigunArtistEditViewStore.routeStore,
        });

        this.reaction(
            () => this.nakiNigunArtistEditViewStore.form.$("isPremium").value,
            (isPremium) => {
                this.form.$("isPremium").set(isPremium);
            }
        );

        this.reaction(
            () => this.form.$("isPremium").value,
            (isPremium) => {
                if (isPremium) {
                    this.form.$("producerId").addIsRequiredRule();
                } else {
                    this.form.$("producerId").removeIsRequiredRule();
                }
            }
        );

        this.reaction(
            () => this.nakiNigunArtistEditViewStore.form.$("isActive").value,
            (isActive) => {
                this.form.$("isActive").set(isActive);
            }
        );

        this.genreSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                isMulti: true,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findGenres(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createGenre({ name });
                    },
                },
            },
            this.form.$("genreIds")
        );

        this.producerSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findProducers(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createProducer({ name });
                    },
                },
            },
            this.form.$("producerId")
        );
    }

    create(data) {
        data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        const selectedGenres = this.genreSelectStore.selectedOption;
        if (selectedGenres) {
            data.genres = map(selectedGenres, (genreOption) => genreOption.item);
        }
        const selectedProducerOption = this.producerSelectStore.selectedOption;
        if (selectedProducerOption) {
            data.producer = selectedProducerOption.item;
        }
        this.nakiNigunArtistEditViewStore.addAlbum(data);
        return Promise.resolve();
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to add album.", err);
    }

    resetForm() {
        this.form.clear();
        this.coverImageUploadViewStore.remove();
        const isArtistPremium = this.nakiNigunArtistEditViewStore.form.$("isPremium").value;
        this.form.set({
            isActive: true,
            albumFeed: {
                contentSortTypeId: this.episodeSortTypeOptions[0].value,
                recentlyUpdatedVisibility: true,
            },
            isPremium: isArtistPremium,
        });
        this.genreSelectStore.setSelectedItem(null);
        this.producerSelectStore.setSelectedItem(null);
    }

    onCreateSuccess() {
        this.resetForm();
    }
}

export default NakiNigunAddAlbumViewStore;
