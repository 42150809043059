function canExpandLeft(column) {
    return column > 0;
}

function canExpandRight(column, columnSpan, columnLimit) {
    return column + 1 + (columnSpan - 1) < columnLimit;
}

function canExpandBottom(row, rowSpan, rowLimit) {
    return row + 1 + (rowSpan - 1) < rowLimit;
}

function canExpandTop(row) {
    return row > 0;
}

function canCollapseLeft(columnSpan) {
    return columnSpan > 1;
}

function canCollapseRight(columnSpan) {
    return columnSpan > 1;
}

function canCollapseTop(rowSpan) {
    return rowSpan > 1;
}

function canCollapseBottom(rowSpan) {
    return rowSpan > 1;
}

export {
    canExpandLeft,
    canExpandRight,
    canExpandBottom,
    canExpandTop,
    canCollapseTop,
    canCollapseLeft,
    canCollapseRight,
    canCollapseBottom,
};
