import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { MrbSelect } from "mrb/components/select";
import { MrbToggle } from "mrb/components/checkbox";
import { PremiumBadge, Image } from "common/components";
import { observer } from "mobx-react";
import { useRootStore } from "mrb/core/contexts";
import { map, isNil } from "lodash";

function NakiMobileHomeSectionTemplate({ nakiMobileHomeSectionViewStore, t }) {
    return <EditPanelContainer nakiMobileHomeSectionViewStore={nakiMobileHomeSectionViewStore} t={t} />;
}

const EditPanelContainer = observer(function EditPanelContainer({ nakiMobileHomeSectionViewStore, t }) {
    return <EditPanel nakiMobileHomeSectionViewStore={nakiMobileHomeSectionViewStore} t={t} />;
});

const EditPanel = observer(function EditPanel({ nakiMobileHomeSectionViewStore, t }) {
    const {
        item,
        isSystemSection,
        setIsActive,
        onTitleChange,
        contentSelectStore,
        onSelectItem,
        isAddContentButtonDisabled,
        deleteSection,
        selectedItemExists,
        goToGenreContent,
    } = nakiMobileHomeSectionViewStore;
    return (
        <div className="c-device-menu__item c-device-menu__item--secondary u-mar--bottom--tny">
            <div className="u-width--100">
                <div className="c-device__header">
                    <TitleInput item={item} onChange={onTitleChange} />
                    <MrbToggle
                        label="Visible in the App"
                        labelClassNameExtend=" u-mar--top--tny"
                        id={`section-${item.id}`}
                        value={item.isActive}
                        onChange={(event) => setIsActive(event.target.checked)}
                    />

                    <div className="u-mar--left--auto u-display--flex u-gap--sml">
                        {item.genreId && (
                            <MrbButton
                                label={t("ADMINISTRATION.GENRE.GENRE_CONTENT_LIST_BUTTON_LABEL")}
                                className="c-btn c-btn--base c-btn--primary"
                                onClick={goToGenreContent}
                            />
                        )}
                        {!isSystemSection && (
                            <MrbButton
                                label="Delete"
                                className="c-btn c-btn--base c-btn--ghost"
                                onClick={deleteSection}
                            />
                        )}
                    </div>
                </div>

                {!isSystemSection && !item.genreId && (
                    <div>
                        <div className="u-separator--primary"></div>
                        <div className="c-device__header">
                            <MrbSelect
                                store={contentSelectStore}
                                disabled={!item.isActive}
                                formatOptionLabel={({ item }) => <OptionLabel item={item} />}
                                classNameExtend="c-dropdown--dark c-dropdown--base u-width--400--px"
                            />
                            <MrbButton
                                t={t}
                                label="Add"
                                classNameOverride="c-btn c-btn--med c-btn--primary"
                                onClick={onSelectItem}
                                disabled={isAddContentButtonDisabled}
                            />
                            {selectedItemExists && (
                                <div className="c-device-menu__note c-device-menu__note--secondary u-mar--left--sml">
                                    {contentSelectStore.selectedItem.title} already selected.
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <ContentPanel nakiMobileHomeSectionViewStore={nakiMobileHomeSectionViewStore} t={t} />
            </div>
        </div>
    );
});

const ContentPanel = observer(function ContentPanel({ nakiMobileHomeSectionViewStore, t }) {
    const { item, deleteItem } = nakiMobileHomeSectionViewStore;

    if (item.items.length === 0) return null;

    return <div className="c-device__list">{map(item.items, (item) => renderItem(item, deleteItem))}</div>;
});

function renderItem(item, onDelete) {
    if (!isNil(item.station)) {
        return <Station station={item.station} onDelete={() => onDelete(item.id)} />;
    } else if (!isNil(item.podcast)) {
        return <Podcast podcast={item.podcast} onDelete={() => onDelete(item.id)} />;
    } else if (!isNil(item.artist)) {
        return <NakiNigunArtist artist={item.artist} onDelete={() => onDelete(item.id)} />;
    } else if (!isNil(item.album)) {
        return <NakiNigunAlbum album={item.album} onDelete={() => onDelete(item.id)} />;
    }

    return null;
}

function Station({ station, onDelete }) {
    const rootStore = useRootStore();
    let coverImageUrl;
    if (station.coverImageId) {
        coverImageUrl = rootStore.coverImageFactory.createStationCoverImageUrl(station.coverImageId, 200, 200);
    }
    return (
        <div className="c-device__list__item c-device__list__item--station">
            <Image src={coverImageUrl} />
            <PremiumBadge premiumSize="secondary" isPremium={station.isPremium} />
            <p className="c-device__item__title">{station.title}</p>
            <MrbButton
                className="c-btn c-btn--base c-btn--ghost c-device__btn"
                icon="u-icon u-icon--sml u-icon--close--primary"
                onClick={onDelete}
            />
        </div>
    );
}

function Podcast({ podcast, onDelete }) {
    const rootStore = useRootStore();
    let coverImageUrl;
    if (podcast.coverImageId) {
        coverImageUrl = rootStore.coverImageFactory.createPodcastCoverImageUrl(podcast.coverImageId, 200, 200);
    }
    return (
        <div className="c-device__list__item">
            <Image src={coverImageUrl} />
            <PremiumBadge premiumSize="secondary" isPremium={podcast.isPremium} />
            <p className="c-device__item__title">{podcast.title}</p>
            <MrbButton
                className="c-btn c-btn--base c-btn--ghost c-device__btn"
                icon="u-icon u-icon--sml u-icon--close--primary"
                onClick={onDelete}
            />
        </div>
    );
}

function NakiNigunArtist({ artist, onDelete }) {
    const rootStore = useRootStore();
    let coverImageUrl;
    if (artist.coverImageId) {
        coverImageUrl = rootStore.coverImageFactory.createNakiNigunArtistCoverImageUrl(artist.coverImageId, 200, 200);
    }
    return (
        <div className="c-device__list__item">
            <Image src={coverImageUrl} />
            <PremiumBadge premiumSize="secondary" isPremium={artist.isPremium} />
            <p className="c-device__item__title">{artist.name}</p>
            <MrbButton
                className="c-btn c-btn--base c-btn--ghost c-device__btn"
                icon="u-icon u-icon--sml u-icon--close--primary"
                onClick={onDelete}
            />
        </div>
    );
}

function NakiNigunAlbum({ album, onDelete }) {
    const rootStore = useRootStore();
    let coverImageUrl;
    if (album.coverImageId) {
        coverImageUrl = rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(album.coverImageId, 200, 200);
    }
    return (
        <div className="c-device__list__item">
            <Image src={coverImageUrl} />
            <PremiumBadge premiumSize="secondary" isPremium={album.isPremium} />
            <p className="c-device__item__title">{album.title}</p>
            <MrbButton
                className="c-btn c-btn--base c-btn--ghost c-device__btn"
                icon="u-icon u-icon--sml u-icon--close--primary"
                onClick={onDelete}
            />
        </div>
    );
}

const TitleInput = observer(function TitleInput({ item, onChange }) {
    return (
        <div className="u-width--100 u-width--300--max">
            <label className="u-type--sml">Section Title</label>
            <input
                className="c-input c-input--text c-input--med c-input--outline u-mar--top--tny"
                value={item.title}
                disabled={!item.isActive || item.genreId}
                onChange={onChange}
            />
        </div>
    );
});

function OptionLabel({ item }) {
    let icon = null;
    if (!isNil(item.stationId)) {
        icon = "stations";
    } else if (!isNil(item.podcastId)) {
        icon = "podcasts";
    } else if (!isNil(item.artistId)) {
        icon = "nigun";
    } else if (!isNil(item.albumId)) {
        icon = "nigun";
    }
    return (
        <span>
            <i className={`u-icon u-icon--med u-icon--${icon} u-mar--right--tny`} />
            {item.title}
            {item.isPremium && <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny" />}
        </span>
    );
}

NakiMobileHomeSectionTemplate.propTypes = {
    nakiMobileHomeSectionViewStore: PropTypes.object.isRequired,
};

export default observer(NakiMobileHomeSectionTemplate);
