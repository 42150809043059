import { moduleProviderFactory } from "mrb/core";
import { Dashboard } from "administration/dashboard/pages";
import { DashboardRouteStore } from "administration/dashboard/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "dashboard",
        routes: [
            {
                name: "master.administration.dashboard",
                pattern: "/dashboard",
                component: Dashboard,
                store: DashboardRouteStore,
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.DASHBOARD",
                order: 1,
                icon: "dashboard",
                route: "master.administration.dashboard",
            },
        ],
    });
})();
