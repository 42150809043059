import { makeObservable } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { PodcastVolumeEditForm } from "administration/podcast/forms";
import { PodcastCoverImageUploadViewStore } from "administration/podcast/stores";

class AddPodcastVolumeItemViewStore extends MrbBaseEditViewStore {

    constructor(rootStore, { podcastEditViewStore }) {
        super(rootStore, {
            name: "podcast-volume-item",
            FormClass: PodcastVolumeEditForm,
            redirectOnCreateSuccess: false,
        });
        this.podcastEditViewStore = podcastEditViewStore;
        this.routeStore = this.podcastEditViewStore.routeStore;
        makeObservable(this);

        this.coverImageUploadViewStore = new PodcastCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.podcastEditViewStore.routeStore,
        });
    }

    create(data) {
        data.podcastFileEntryId = this.coverImageUploadViewStore.uploadedFileId;
        this.podcastEditViewStore.addPodcastVolume(data);
        return Promise.resolve();
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update resource.", err);
    }

    resetForm() {
        this.form.clear();
        this.coverImageUploadViewStore.remove();
        this.form.set({
            isActive: true
        });
    }

    onCreateSuccess() {
        this.resetForm();
    }
}

export default AddPodcastVolumeItemViewStore;
