import React from "react";
import { PlaylistTagsTemplate } from "themes/administration/content-explorer";

class PlaylistTags extends React.Component {
    render() {
        return <PlaylistTagsTemplate {...this.props} />;
    }
}

export default PlaylistTags;
