import React from "react";
import { UserListTemplate } from "themes/administration/user/pages";
import { UserListViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new UserListViewStore(rootStore, componentProps),
    "userListViewStore"
)
class UserList extends React.Component {
    render() {
        return <UserListTemplate {...this.props} />;
    }
}

export default UserList;
