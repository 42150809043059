import React from "react";
import PropTypes from "prop-types";
import { renderActions } from "mrb/components/layout/utils";
import { MrbBreadcrumbs } from "mrb/components/breadcrumbs";
import { MrbButton } from "mrb/components/button";
import { isFunction } from "lodash";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { MrbEditFormValidation } from "mrb/components/layout/content";
import { observer } from "mobx-react";

function NakiNigunArtistEditPageHeaderTemplate({
    form,
    onClickCancel,
    title,
    layoutActions,
    navigationOptions,
    navigateBack,
    t = (i) => i,
    submitLabel,
    cancelLabel,
    store,
}) {
    return (
        <React.Fragment>
            <div className="c-page__header--top">
                {navigateBack ? (
                    <MrbRouterLink routeName={navigateBack.routeName}>
                        <span className="display--ib u-mar--right--tny align--v--middle">
                            <i className="u-icon u-icon--med u-icon--back" />
                        </span>
                        {t(navigateBack.title)}
                    </MrbRouterLink>
                ) : (
                    <div>{t(title)}</div>
                )}
                <div className="c-header__actions">
                    <UnsavedAlbumValidation store={store} t={t} />
                    <MrbEditFormValidation form={form} t={t} />

                    <EditPageActions
                        form={form}
                        onClickCancel={onClickCancel}
                        t={t}
                        submitLabel={submitLabel}
                        cancelLabel={cancelLabel}
                    />
                    {renderActions(layoutActions)}
                </div>
            </div>
            {navigationOptions && (
                <div className="c-page__header--bottom">
                    <MrbBreadcrumbs options={navigationOptions} t={t} />
                </div>
            )}
        </React.Fragment>
    );
}

NakiNigunArtistEditPageHeaderTemplate.propTypes = {
    form: PropTypes.object.isRequired,
    onClickCancel: PropTypes.func,
    title: PropTypes.string,
    layoutActions: PropTypes.object,
    navigationOptions: PropTypes.object,
    navigateBack: PropTypes.object,
    t: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    store: PropTypes.object.isRequired,
};

const EditPageActions = observer(function EditPageActions({
    form,
    onClickCancel,
    submitLabel = "Save",
    cancelLabel = "Cancel",
    t,
}) {
    return (
        <React.Fragment>
            {onClickCancel && isFunction(onClickCancel) && (
                <MrbButton
                    t={t}
                    className="c-btn c-btn--base c-btn--ghost"
                    label={cancelLabel}
                    onClick={onClickCancel}
                />
            )}{" "}
            <MrbButton
                t={t}
                type="submit"
                className="c-btn c-btn--base c-btn--primary"
                onClick={form.onSubmit}
                aria-label={submitLabel}
                aria-busy={form.submitting || form.validating}
                label={submitLabel}
                disabled={form.submitting || form.validating}
            />
        </React.Fragment>
    );
});

EditPageActions.propTypes = {
    form: PropTypes.object.isRequired,
    onClickCancel: PropTypes.func,
    submitLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    t: PropTypes.func,
};

const UnsavedAlbumValidation = observer(function UnsavedAlbumValidation({ store, t }) {
    return (
        store.anyUnsavedAlbum && (
            <div className="c-validation--info">
                <p className="c-validation__label">
                    {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.VALIDATION.ALBUM_CHANGED")}
                </p>
            </div>
        )
    );
});

UnsavedAlbumValidation.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(NakiNigunArtistEditPageHeaderTemplate);
