import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader, MrbPageHeader } from "mrb/components/layout";
import { defaultTemplate } from "common/hoc";
import { Image } from "common/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { isEmpty } from "lodash";
import { EmptyState } from "common/components/empty-state";
import { FixedSizeList } from "react-window";
import { AdminPlaylistItem } from "administration/playlist/components";
import { NakiNigunArtistAlbumLoader } from "administration/naki-nigun/components";

function AdminPlaylistPreviewTemplate({ adminPlaylistPreviewViewStore, t }) {
    const { item, items, coverImageUrl, loaderStore, removeAlbumTrackFromPlaylist } = adminPlaylistPreviewViewStore;

    return (
        <MrbPreviewPage store={adminPlaylistPreviewViewStore} contentClassNameExtend="u-padd--none" t={t}>
            <MrbPageHeader>
                <div className="c-page__header--top">
                    <React.Fragment>
                        <MrbRouterLink routeName="master.administration.playlist.list">
                            <span className="display--ib u-mar--right--tny align--v--middle">
                                <i className="u-icon u-icon--med u-icon--back" />
                            </span>
                            {t("ADMINISTRATION.PLAYLIST.TITLE")}
                        </MrbRouterLink>
                    </React.Fragment>
                </div>
            </MrbPageHeader>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="c-content__header--primary__img">
                                <Image src={coverImageUrl} />
                            </div>
                            <div className="c-content__header--primary__info">
                                <div className="u-mar--bottom--sml">
                                    <div className="u-display--flex u-display--flex--ai--center u-gap--base">
                                        <p className="c-content__header--primary__title">{item.title}</p>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <div className="c-container c-container--base">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <NakiNigunArtistAlbumLoader />}>
                    {() => (
                        <React.Fragment>
                            {isEmpty(items) ? (
                                <div className="c-card c-card--primary c-card--med">
                                    <EmptyState />
                                </div>
                            ) : (
                                <FixedSizeList
                                    height={!isEmpty(items) ? 500 : 660}
                                    width={"100%"}
                                    itemCount={items.length}
                                    itemSize={102}
                                    style={{ overflowX: "hidden" }}
                                    itemData={{
                                        items: items,
                                    }}
                                >
                                    {({ index, style }) => (
                                        <div style={style}>
                                            <AdminPlaylistItem
                                                removeAlbumTrackFromPlaylist={removeAlbumTrackFromPlaylist}
                                                item={items[index]}
                                                wrapperClassName="u-mar--right--sml"
                                                t={t}
                                            />
                                        </div>
                                    )}
                                </FixedSizeList>
                            )}
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </MrbPreviewPage>
    );
}

AdminPlaylistPreviewTemplate.propTypes = {
    adminPlaylistPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(AdminPlaylistPreviewTemplate);
