import { MrbBaseViewStore } from "mrb/core";
import { NakiNigunAlbumTrackEditTitleForm } from "administration/naki-nigun/forms";
import { localizationService } from "common/localization";
import { makeObservable, observable, action } from "mobx";

class NakiNigunAlbumTrackEditTitleViewStore extends MrbBaseViewStore {
    @observable isFormChanged = false;

    constructor(rootStore, { nakiNigunAlbumTrackListViewStore, id, title }) {
        super(rootStore);
        makeObservable(this);
        this.nakiNigunAlbumTrackListViewStore = nakiNigunAlbumTrackListViewStore;
        this.routeStore = nakiNigunAlbumTrackListViewStore.routeStore;
        this.id = id;
        this.title = title;

        this.form = new NakiNigunAlbumTrackEditTitleForm({
            onSuccess: (form) => {
                const item = form.values();
                return this.updateResource(item);
            },
            onInit: (form) => {
                form.set({ title: title });
                form.$("title").focus();
            },
        });

        this.registerRouteExitBlocking();
        this.reaction(
            () => this.form.changed,
            (isChanged) => {
                if (isChanged) {
                    this.isFormChanged = isChanged;
                }
            }
        );
    }

    @action.bound
    async updateResource(data) {
        this.form.setFieldsDisabled(true);
        try {
            await this.update(this.id, data);
            this.form.setFieldsDisabled(false);
            this.onUpdateSuccess(data.title);
        } catch (err) {
            this.form.setFieldsDisabled(false);
            this.onUpdateError(err);
        }
    }

    async update(id, resource) {
        this.isFormChanged = false;
        await this.routeStore.editAlbumTrackTitle(id, resource);
    }

    onUpdateError(err) {
        this.rootStore.notificationStore.error(
            localizationService.t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.SONG_LIST.ERROR.GENERIC_MESSAGE")
        );
    }

    @action.bound
    onUpdateSuccess(newAlbumTrackTitle) {
        this.nakiNigunAlbumTrackListViewStore.updateAlbumTrackTitle(this.id, newAlbumTrackTitle);
        this.nakiNigunAlbumTrackListViewStore.editAlbumTrackModal.close();
    }

    registerRouteExitBlocking() {
        this.reaction(
            () => this.isFormChanged,
            (isChanged) => {
                this.routeStore.setIsRouteExitBlocked(isChanged);
            }
        );
    }

    destroy() {
        super.destroy();
        this.form.destroy();
    }
}

export default NakiNigunAlbumTrackEditTitleViewStore;
