import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { MrbEditFormLayout } from "mrb/components/layout";
import { MrbFieldSelect } from "mrb/components/select";
import { MrbFieldInput, MrbFieldNumericInput } from "mrb/components/input";
import { MrbFieldToggle } from "mrb/components/checkbox";
import { MrbButton } from "mrb/components/button";
import { MrbFieldRadioButtons } from "mrb/components/radio";
import { ARTIST_FORM_STATUS } from "administration/naki-nigun/stores";
import { CoverImageUpload, ProducerFieldSelect } from "common/components";
import { observer } from "mobx-react";

function NakiNigunAlbumEditTemplate({ nakiNigunAlbumEditViewStore, t }) {
    const {
        form,
        albumIndex,
        album,
        episodeSortTypeOptions,
        isCollapsed,
        toggleCollapse,
        setPanelRef,
        coverImageUploadViewStore,
        resetForm,
        deleteAlbum,
        genreSelectStore,
        producerSelectStore,
        overrideRadioUsersSettingsIsVisible,
        overrideAppUsersSettingsIsVisible,
        isPremiumDisabled,
        isActiveStatusDisabled,
    } = nakiNigunAlbumEditViewStore;
    return (
        <React.Fragment>
            <MrbCollapsiblePanel
                innerRef={setPanelRef}
                title={album.title}
                t={t}
                isCollapsed={isCollapsed}
                onChangeCollapse={toggleCollapse}
                headerRender={(props) => (
                    <AlbumPanelHeader {...props} album={album} albumIndex={albumIndex} deleteAlbum={deleteAlbum} />
                )}
            >
                {() => (
                    <MrbEditFormLayout
                        form={form}
                        t={t}
                        onClickCancel={resetForm}
                        cancelLabel="Cancel"
                        submitLabel="Update"
                    >
                        <div className="row">
                            <div className="col col-sml-12 col-xxlrg-8 u-mar--bottom--sml">
                                <div className="row">
                                    <div className="col col-sml-12">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("title")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>

                                    <div className="col col-sml-12">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("albumFeed.feedUrl")}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>

                                    <div className="col col-sml-12 col-med-6 col-xxlrg-2">
                                        <MrbFieldNumericInput
                                            classNameOverride={"c-input c-input--med c-input--outline"}
                                            precision={0}
                                            t={t}
                                            field={form.$("year")}
                                        />
                                    </div>

                                    <div className="col col-sml-12 col-med-6 col-xxlrg-10">
                                        <MrbFieldSelect
                                            t={t}
                                            store={genreSelectStore}
                                            classNameExtend="c-dropdown--dark"
                                        />
                                    </div>
                                    <div className="col col-sml-12">
                                        <ProducerFieldSelect
                                            t={t}
                                            store={producerSelectStore}
                                            classNameExtend="c-dropdown--dark"
                                        />
                                    </div>

                                    <div className="col col-sml-12">
                                        <MrbFieldInput
                                            t={t}
                                            field={form.$("keywords")}
                                            classNameExtend="c-input--outline"
                                        />
                                        <div className="">
                                            <MrbFieldRadioButtons
                                                field={form.$("albumFeed.contentSortTypeId")}
                                                t={t}
                                                options={episodeSortTypeOptions}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col col-sml-12 col-xxlrg-4">
                                <div className="c-toggle__card-list">
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item--inline"
                                        t={t}
                                        field={form.$("isActive")}
                                        disabled={isActiveStatusDisabled}
                                    />
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item--inline"
                                        t={t}
                                        field={form.$("isPremium")}
                                        disabled={isPremiumDisabled}
                                    />
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item--inline"
                                        t={t}
                                        field={form.$("isTurnedOnByDefault")}
                                    />
                                    <div className="u-separator--primary u-mar--none"></div>

                                    {overrideRadioUsersSettingsIsVisible ? (
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item--inline"
                                            t={t}
                                            field={form.$("overrideRadioUsersSettings")}
                                        />
                                    ) : null}
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item--inline"
                                        t={t}
                                        field={form.$("isTurnedOnByDefaultInApp")}
                                    />
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item--inline"
                                        t={t}
                                        field={form.$("isTurnedOnByDefaultInEmbeddedApp")}
                                    />
                                    {overrideAppUsersSettingsIsVisible ? (
                                        <MrbFieldToggle
                                            classNameExtend="c-toggle__item--inline"
                                            t={t}
                                            field={form.$("overrideAppUsersSettings")}
                                        />
                                    ) : null}
                                    <div className="u-separator--primary u-mar--none"></div>

                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item--inline"
                                        t={t}
                                        field={form.$("isFeatured")}
                                    />
                                    <MrbFieldToggle
                                        classNameExtend="c-toggle__item--inline"
                                        t={t}
                                        field={form.$("albumFeed.hlsEnabled")}
                                        disabled={isActiveStatusDisabled}
                                    />
                                </div>

                                <div className="u-separator--primary"></div>

                                <CoverImageUpload
                                    classNameExtendUpload="c-upload--tny"
                                    coverImageUploadViewStore={coverImageUploadViewStore}
                                    isFullWidthFooterButtons
                                    showInfo={false}
                                />
                            </div>
                        </div>

                        <div className="u-separator--primary"></div>
                    </MrbEditFormLayout>
                )}
            </MrbCollapsiblePanel>
        </React.Fragment>
    );
}

NakiNigunAlbumEditTemplate.propTypes = {
    nakiNigunAlbumEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const AlbumPanelHeader = observer(function AlbumPanelHeader({ title, t, isCollapsed, albumIndex, album, deleteAlbum }) {
    return (
        <React.Fragment>
            <div className="u-display--flex u-display--flex--ai--center">
                <i className="u-icon u-icon--med u-icon--drag u-mar--right--tny"></i>
                <span className="u-type--wgt--bold u-mar--right--tny">{albumIndex}.</span>
                {t(title)}
                {album.isPremium && (
                    <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                )}
            </div>
            <div className="u-display--flex u-display--flex--ai--center">
                {album.FORM_STATUS === ARTIST_FORM_STATUS.CHANGED && (
                    <div className="c-validation--info c-validation--info--secondary">
                        <p className="c-validation__label">
                            {t("ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.VALIDATION.ALBUM_CHANGED")}
                        </p>
                    </div>
                )}
                <div className="u-display--flex u-display--flex--ai--center">
                    <MrbButton
                        t={t}
                        label="ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.ALBUM.BUTTONS.DELETE"
                        onClick={deleteAlbum}
                        classNameOverride="c-btn c-btn--sml c-btn--ghost u-mar--right--tny"
                    />
                    <i
                        className={`u-icon u-icon--med u-icon--arrow-down ${
                            isCollapsed ? "u-rotate--180" : ""
                        } u-cursor--pointer u-mar--top--micro--alt`}
                    ></i>
                </div>
            </div>
        </React.Fragment>
    );
});

export default observer(NakiNigunAlbumEditTemplate);
