import { MrbBaseRouteStore } from "mrb/core";
import { ShuffleService, ShuffleWeightCacheService } from "common/services";

class BaseShuffleEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(ShuffleService);

        this.shuffleWeightService = new ShuffleWeightCacheService(
            this.rootStore
        );
    }

    getShuffleWeights() {
        return this.shuffleWeightService.getItems();
    }
}

export default BaseShuffleEditRouteStore;
