import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { Image } from "common/components";
import { convertSecondsToHMS } from "common/utils";
import { isEmpty } from "lodash";
import { mergeCss } from "mrb/common/utils";
import { useRootStore } from "mrb/core";
import { MrbButton } from "mrb/components/button";

function AdminPlaylistItemTemplate({ item, wrapperClassName, removeAlbumTrackFromPlaylist, t }) {
    const rootStore = useRootStore();
    const artworkUrl = item.albumTrack.artworkFileEntryId
        ? rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(item.albumTrack.artworkFileEntryId, 50, 50)
        : null;

    return (
        <div className={mergeCss("c-card c-card--primary", wrapperClassName)}>
            <div className="u-display--flex u-display--flex--ai--center u-gap--base">
                <Image
                    className="c-home__preview__icon"
                    src={isEmpty(item.albumTrack.artworkUrl) ? artworkUrl : item.albumTrack.artworkUrl}
                    width="50"
                    height="50"
                />
                <div className="u-width--100">
                    <div className="c-home__preview__title u-mar--bottom--small u-width--100">
                        <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-width--100 u-gap--med">
                            <div className="u-display--flex u-display--flex--ai--center u-gap--sml">
                                <p className="u-type--ellipsis">{item.albumTrack.title}</p>
                            </div>
                            <MrbButton
                                label={t("ADMINISTRATION.PLAYLIST.BUTTONS.DELETE")}
                                classNameOverride="c-btn c-btn--sml c-btn--ghost u-mar--right--tny"
                                onClick={() => removeAlbumTrackFromPlaylist(item.playlistId, item.albumTrackId)}
                            />
                        </div>
                    </div>
                    <p className="u-type--sml u-type--color--opaque">
                        <span dir="ltr">{item.albumTrack.album.artist.name}</span>
                        {" • "}
                        <span dir="ltr">{item.albumTrack.album.title}</span>
                        {" • "}
                        {convertSecondsToHMS(item.albumTrack.duration)}
                    </p>
                </div>
            </div>
        </div>
    );
}

AdminPlaylistItemTemplate.propTypes = {
    item: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(AdminPlaylistItemTemplate);
