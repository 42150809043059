import React from "react";
import { NakiNigunAlbumTrackEditTemplate } from "themes/administration/naki-nigun/pages";
import { NakiNigunAlbumTrackEditViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunAlbumTrackEditViewStore(rootStore, componentProps),
    "nakiNigunAlbumTrackEditViewStore"
)
class NakiNigunAlbumTrackEdit extends React.Component {
    render() {
        return <NakiNigunAlbumTrackEditTemplate {...this.props} />;
    }
}

export default NakiNigunAlbumTrackEdit;
