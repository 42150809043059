import React from "react";
import ContentLoader from "react-content-loader";

const ShuffleCreateLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height={180}
            viewBox={`0 0 ${
                screenwidth > 768 ? 768 : screenwidth
            } 180`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <rect x="0" y="17" rx="6" ry="6" width="100%" height="40" />

            <rect x="0" y="77" rx="6" ry="6" width="88%" height="40" />
            <rect x="90%" y="77" rx="6" ry="6" width="10%" height="40" />

            <rect x="0" y="137" rx="6" ry="6" width="100%" height="40" />
        </ContentLoader>
    );
};

export default ShuffleCreateLoaderTemplate;
