import React from "react";
import { PropTypes } from "prop-types";
import { StatsWidget } from "administration/analytics/components";

function StationAnalyticsStatsWidgetTemplate({ stationAnalyticsStatsWidgetViewStore, t }) {
    return <StatsWidget store={stationAnalyticsStatsWidgetViewStore} t={t} />;
}

StationAnalyticsStatsWidgetTemplate.propTypes = {
    stationAnalyticsStatsWidgetViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default StationAnalyticsStatsWidgetTemplate;
