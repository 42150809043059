import { isNil } from "lodash";

function isGridItemDefined(item) {
    return (
        !isNil(item.stationId) ||
        !isNil(item.podcastId) ||
        !isNil(item.nakiNigunAlbumId) ||
        !isNil(item.nakiNigunArtistId)
    );
}

export default isGridItemDefined;
