import { action, observable, makeObservable } from "mobx";
import { MrbBasePreviewViewStore } from "mrb/core";
import { shuffleType } from "common/constants";
import { map, find, isNil } from "lodash";

class ShufflePreviewViewStore extends MrbBasePreviewViewStore {
    @observable.ref shuffleWeights = [];

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            layoutActions: {
                edit: {
                    onClick: () => {
                        if (this.item.shuffleType.abrv === shuffleType.station) {
                            this.rootStore.routerStore.goTo("master.administration.shuffle.edit-station", {
                                id: this.item.id,
                            });
                        } else if (this.item.shuffleType.abrv === shuffleType.podcast) {
                            this.rootStore.routerStore.goTo("master.administration.shuffle.edit-podcast", {
                                id: this.item.id,
                            });
                        } else {
                            this.rootStore.routerStore.goTo("master.administration.shuffle.edit-naki-nigun", {
                                id: this.item.id,
                            });
                        }
                    },
                    label: "Edit",
                    className: "c-btn c-btn--base c-btn--primary",
                },
            },
            navigateBack: "master.administration.shuffle.list",
        });
        makeObservable(this);
        this.routeStore = routeStore;
    }

    async onInit() {
        await this.loadLookups();
        return super.onInit();
    }

    setItem(item) {
        if (item.shuffleType.abrv === shuffleType.station) {
            this.setStationShuffle(item);
        } else if (item.shuffleType.abrv === shuffleType.podcast) {
            this.setPodcastShuffle(item);
        } else {
            this.setNakiNigunShuffle(item);
        }
    }

    @action.bound
    setStationShuffle({ shuffleItems, ...rest }) {
        this.item = {
            ...rest,
            shuffleItems: map(shuffleItems, (shuffleItem) => {
                return {
                    stationId: shuffleItem.station.id,
                    isActive: shuffleItem.station.isActive,
                    name: shuffleItem.station.title,
                    isPremium: shuffleItem.station.isPremium,
                    shuffleWeight: find(
                        this.shuffleWeights,
                        (shuffleWeight) => shuffleWeight.id === shuffleItem.shuffleWeightId
                    ),
                };
            }),
        };
    }

    @action.bound
    setPodcastShuffle({ shuffleItems, ...rest }) {
        this.item = {
            ...rest,
            shuffleItems: map(shuffleItems, (shuffleItem) => {
                return {
                    podcastId: shuffleItem.podcast.id,
                    parentPodcastId: shuffleItem.podcast.parentPodcastId,
                    isActive: shuffleItem.podcast.isActive,
                    name: shuffleItem.podcast.title,
                    isPremium: shuffleItem.podcast.isPremium,
                    shuffleWeight: find(
                        this.shuffleWeights,
                        (shuffleWeight) => shuffleWeight.id === shuffleItem.shuffleWeightId
                    ),
                };
            }),
        };
    }

    @action.bound
    setNakiNigunShuffle({ shuffleItems, ...rest }) {
        this.item = {
            ...rest,
            shuffleItems: map(shuffleItems, (shuffleItem) => {
                return {
                    nakiNigunAlbumId: shuffleItem.nakiNigunAlbum.id,
                    nakiNigunArtistId: shuffleItem.nakiNigunAlbum.artistId,
                    isActive: shuffleItem.nakiNigunAlbum.isActive,
                    name: `${shuffleItem.nakiNigunAlbum.artist.name} - ${shuffleItem.nakiNigunAlbum.title}`,
                    isPremium: shuffleItem.nakiNigunAlbum.isPremium,
                    shuffleWeight: find(
                        this.shuffleWeights,
                        (shuffleWeight) => shuffleWeight.id === shuffleItem.shuffleWeightId
                    ),
                };
            }),
        };
    }

    async get(id) {
        return this.routeStore.getShuffle(id);
    }

    async loadLookups() {
        const shuffleWeights = await this.routeStore.getShuffleWeights();
        this.setShuffleWeights(shuffleWeights);
    }

    @action.bound
    setShuffleWeights(items) {
        this.shuffleWeights = items;
    }

    goTo = (item) => {
        if (item.stationId) {
            this.rootStore.routerStore.goTo("master.administration.station.preview", { id: item.stationId });
        } else if (item.podcastId) {
            if (!isNil(item.parentPodcastId)) {
                this.rootStore.routerStore.goTo(
                    "master.administration.podcast.preview-group",
                    { id: item.parentPodcastId },
                    { subPodcastId: item.podcastId }
                );
            } else {
                this.rootStore.routerStore.goTo("master.administration.podcast.preview", { id: item.podcastId });
            }
        } else if (item.nakiNigunAlbumId) {
            this.rootStore.routerStore.goTo(
                "master.administration.naki-nigun.artist.preview",
                { id: item.nakiNigunArtistId },
                { albumId: item.nakiNigunAlbumId }
            );
        }
    };
}

export default ShufflePreviewViewStore;
