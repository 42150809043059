import { MrbBaseRouteStore } from "mrb/core";
import { PodcastService, PodcastFileStreamService, MediaVaultStreamService } from "common/services";
import moment from "moment";

class PodcastEpisodeEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PodcastService);
        this.mediaVaultStreamService = new MediaVaultStreamService(
            this.rootStore.apiClient,
            this.rootStore.application.baasic
        );
        this.podcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
    }

    async uploadEpisode(podcastId, formData) {
        const response = await this.service.uploadEpisode(podcastId, formData);
        return response.statusCode;
    }

    getMediaVaultFileUrl(id) {
        return this.mediaVaultStreamService.mediaVaultModule.streams.routeDefinition.get(id);
    }

    async uploadImageFile(file) {
        const now = moment();
        const [name, extension] = file.name.split(".");
        const path = `${name}_${now.year()}-${now.month()}-${now.date()}-${now.hour()}-${now.minute()}-${now.second()}.${extension}`;
        const response = await this.mediaVaultStreamService.create(
            {
                path: path,
                fileName: file.name,
            },
            file
        );
        return response.data;
    }

    getPodcastCoverImageUrl(coverImageId) {
        return this.podcastFileStreamService.routeService.get(coverImageId);
    }

    async getPodcast(podcastId) {
        const response = await this.service.get(podcastId);
        return response.data;
    }
}

export default PodcastEpisodeEditRouteStore;
