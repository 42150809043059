import React from "react";
import { PropTypes } from "prop-types";
import { StatsWidget } from "administration/analytics/components";

function PodcastAnalyticsStatsWidgetTemplate({ podcastAnalyticsStatsWidgetViewStore, t }) {
    return (
        <StatsWidget store={podcastAnalyticsStatsWidgetViewStore} t={t} />
    )
}

PodcastAnalyticsStatsWidgetTemplate.propTypes = {
    podcastAnalyticsStatsWidgetViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}

export default PodcastAnalyticsStatsWidgetTemplate;