import { BaseStatsWidgetViewStore } from "administration/analytics/stores";
import { timeRangeType } from "administration/analytics/constants";

class PodcastAnalyticsStatsWidgetViewStore extends BaseStatsWidgetViewStore {
    podcastId = null;

    constructor(rootStore, { routeStore, podcastId }) {
        super(rootStore, {
            routeName: "master.administration.analytics.podcast-activity",
            routeParams: { id: podcastId },
        });
        this.routeStore = routeStore;
        this.podcastId = podcastId;

        this.setTimeRange(timeRangeType.thisMonth);
    }

    fetch(filter) {
        const params = {
            from: filter.from,
            to: filter.to,
            previousCycleFrom: filter.previousCycleFrom,
            previousCycleTo: filter.previousCycleTo,
            timeZone: filter.timeZone,
        };
        return this.routeStore.getPodcastAnalyticsStats(this.podcastId, params);
    }
}

export default PodcastAnalyticsStatsWidgetViewStore;
