import { MrbBaseRouteStore } from "mrb/core";
import { PodcastService } from "common/services";

class PodcastListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PodcastService);
    }

    async findPodcasts(params) {
        const response = await this.service.find(params);
        return response.data;
    }

    async deletePodcast(resource) {
        const response = await this.service.delete(resource);
        return response.data;
    }
}

export default PodcastListRouteStore;
