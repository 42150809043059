import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditPage, MrbButton } from "mrb/components";
import { AddNewAccountLoader, AdminProducerAccountEdit } from "administration/producer/components";
import { MrbContentLoader } from "mrb/components/loader";
import { defaultTemplate } from "common/hoc";

function AdminProducerAccountsTemplate({ adminProducerAccountsViewStore, t }) {
    const { loaderStore, form, addNewAccount } = adminProducerAccountsViewStore;
    return (
        <MrbEditPage store={adminProducerAccountsViewStore} t={t}>
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <AddNewAccountLoader />}>
                {() => (
                    <React.Fragment>
                        {form.fields.size > 0 && <AddNewAccount onClickAddNew={addNewAccount} t={t} />}
                        {form.fields.size > 0 &&
                            form.map((field, key) => (
                                <AdminProducerAccountEdit
                                    form={field}
                                    key={key}
                                    adminProducerAccountsViewStore={adminProducerAccountsViewStore}
                                    routeStore={adminProducerAccountsViewStore.routeStore}
                                    t={t}
                                />
                            ))}

                        {form.fields.size === 0 && <NoDataEmptyState onClickAddNew={addNewAccount} t={t} />}
                    </React.Fragment>
                )}
            </MrbContentLoader>
        </MrbEditPage>
    );
}

AdminProducerAccountsTemplate.propTypes = {
    adminProducerAccountsViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function NoDataEmptyState({ onClickAddNew, t }) {
    return (
        <div className="c-card c-card--med c-card--primary">
            <div className="c-empty-state--no-results c-empty-state--center">
                <div className="u-mar--bottom--med">
                    <h3>{t("Add new account")}</h3>
                    <p>{t("In order to add a new account, please click the button below.")}</p>
                </div>
                <MrbButton label="Add" onClick={onClickAddNew} t={t} />
            </div>
        </div>
    );
}

const AddNewAccount = ({ onClickAddNew, t }) => {
    return (
        <div>
            <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between">
                <div className="">
                    <h3>{t("Add new account")}</h3>
                </div>
                <div>
                    <MrbButton label="Add" onClick={onClickAddNew} t={t} />
                </div>
            </div>

            <div className="u-separator--primary u-separator--primary--base"></div>
        </div>
    );
};

export default defaultTemplate(AdminProducerAccountsTemplate);
