import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader } from "mrb/components/layout";
import { MrbToggle } from "mrb/components/checkbox";
import { MrbButton } from "mrb/components/button";
import { PodcastAnalyticsStatsWidget } from "administration/podcast/components";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { podcastContentType } from "common/constants";
import { ProducerLabel } from "common/components/producer";
import { defaultTemplate } from "common/hoc";
import {
    PreviewContentLoader,
    HTMLRender,
    Genres,
    DescriptionLoader,
    ToggleActionsLoader,
    Image,
} from "common/components";
import { PodcastPreviewPodcastVolumeList } from "administration/podcast/components";
import { isNil } from "lodash";

function PodcastPreviewTemplate({ podcastPreviewViewStore, t }) {
    const {
        item,
        loaderStore,
        toggleIsActive,
        togglePremiumStatus,
        toggleOnByDefaultStatus,
        toggleOnByDefaultInAppStatus,
        toggleOnByDefaultInNakiTouchStatus,
        toggleVisibilityStatus,
        toggleFeaturedStatus,
        toggleRecentlyUpdatedVisibilityStatus,
        toggleAppVisibilityStatus,
        toggleExcludedFromRevenueStatus,
        toggleNakiTouchVisibilityStatus,
        coverImageUrl,
        deletePodcast,
        id,
        routeStore,
        isTorahAnytimePodcast,
        toggleHlsEnabledStatus,
    } = podcastPreviewViewStore;

    return (
        <MrbPreviewPage store={podcastPreviewViewStore} contentClassNameExtend="u-padd--none" t={t}>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="c-content__header--primary__img">
                                <Image src={coverImageUrl} />
                            </div>
                            <div className="c-content__header--primary__info">
                                <div className="u-mar--bottom--sml">
                                    <p className="u-type--sml u-type--color--opaque">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.TITLE")}
                                    </p>
                                    <div className="u-display--flex u-display--flex--ai--center u-gap--base">
                                        <p className="c-content__header--primary__title">{item.title}</p>
                                    </div>
                                </div>

                                <div className="c-content__header--primary__descriprtion">
                                    <p className="u-type--sml u-type--color--opaque">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.DESCRIPTION_LINK")}
                                    </p>
                                    {item.descriptionLink && (
                                        <a href={item.descriptionLink} target="_blank" rel="noreferrer">
                                            {item.descriptionLink}
                                        </a>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <div className="c-container--sidebar">
                <div>
                    <div className="c-card--primary c-card--med u-mar--bottom--med">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <DescriptionLoader />}>
                            {() => (
                                <div className="row">
                                    <div className="col col-sml-12">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.PORTAL_DESCRIPTION")}
                                            </p>
                                            <HTMLRender className="c-collapse-panel__body__description">
                                                {item.alternativeDescription || "-"}
                                            </HTMLRender>
                                        </div>
                                    </div>

                                    <div className="col col-sml-12">
                                        <div className="u-type--base">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.PREMIUM_INFO")}
                                            </p>
                                            <HTMLRender className="c-collapse-panel__body__description">
                                                {item.premiumInfo || "-"}
                                            </HTMLRender>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>

                    <div className="c-card--primary c-card--med u-mar--bottom--med">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <PreviewContentLoader row={4} />}
                        >
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <div className="row">
                                        <div className="col col-sml-6">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.CONTENT_TYPE")}
                                                </p>
                                                <p className="u-type--base">
                                                    <PodcastContentType contentType={item.podcastContentType} t={t} />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-sml-12 col-lrg-6">
                                            {item.podcastFeed && (
                                                <div className="u-type--base u-type--color--primary u-mar--bottom--sml">
                                                    <p className="u-type--sml u-type--color--opaque">
                                                        {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.FEED_URL")}
                                                    </p>
                                                    <a
                                                        href={item.podcastFeed.feedUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="u-type--link"
                                                    >
                                                        {item.podcastFeed.feedUrl}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col col-sml-6">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.LOCATION")}
                                                </p>
                                                <p className="u-type--base">{item.location && item.location.name}</p>
                                            </div>
                                        </div>
                                        <div className="col col-sml-6">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.LANGUAGE")}
                                                </p>
                                                <p className="u-type--base">{item.language && item.language.name}</p>
                                            </div>
                                        </div>
                                        <div className="col col-sml-6 u-mar--bottom--sml">
                                            <div className="u-type--base">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.GENRES")}
                                                </p>
                                                <Genres genres={item.genres} />
                                            </div>
                                        </div>
                                        <div className="col col-sml-6 u-mar--bottom--sml">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.EPISODE_SORT_TYPE")}
                                                </p>
                                                <p className="u-type--base">
                                                    {item.podcastFeed &&
                                                        item.podcastFeed.episodeSortType &&
                                                        item.podcastFeed.episodeSortType.name}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-sml-12 col-lrg-6">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.EPISODE_LIMIT")}
                                                </p>
                                                <p className="u-type--base">
                                                    {item.podcastFeed && item.podcastFeed.episodeLimit
                                                        ? item.podcastFeed.episodeLimit
                                                        : "-"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-sml-6">
                                            <div className="u-type--base u-mar--bottom--sml">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.PRODUCER")}
                                                </p>
                                                <p className="u-type--base">
                                                    {item.producer ? (
                                                        <ProducerLabel
                                                            name={item.producer.name}
                                                            isUser={!isNil(item.producer.user)}
                                                            user={item.producer.user}
                                                        />
                                                    ) : (
                                                        "-"
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-sml-12 col-lrg-6">
                                            <div className="u-type--base">
                                                <p className="u-type--sml u-type--color--opaque">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.DESCRIPTION")}
                                                </p>
                                                <p className="u-type--base">{item.description || "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>

                    <PodcastPreviewPodcastVolumeList podcastPreviewViewStore={podcastPreviewViewStore} t={t} />
                </div>

                <div>
                    <div className="row">
                        <div className="col col-sml-12 col-med-6 col-xxlrg-12">
                            <div className="c-card--primary u-mar--bottom--med">
                                <MrbContentLoader
                                    loaderStore={loaderStore}
                                    loaderRender={() => (
                                        <ToggleActionsLoader lineheight={25} smallWidth={true} rows={6} />
                                    )}
                                >
                                    {() => (
                                        <div className="u-animation--fadeIn">
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.STATUS")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-active-status"
                                                    value={item.isActive}
                                                    onChange={(event) => toggleIsActive(event.target.checked)}
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.PREMIUM")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-premium-status"
                                                    value={item.isPremium}
                                                    disabled={isTorahAnytimePodcast}
                                                    onChange={(event) => togglePremiumStatus(event.target.checked)}
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.MAKE_DEFAULT")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-on-by-default-status"
                                                    value={item.isTurnedOnByDefault}
                                                    onChange={(event) => toggleOnByDefaultStatus(event.target.checked)}
                                                />
                                            </div>

                                            <div className="u-separator--primary"></div>

                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.VISIBLE_IN_APP")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-app-visibility-status"
                                                    value={item.isVisibleInApp}
                                                    onChange={(event) =>
                                                        toggleAppVisibilityStatus(event.target.checked)
                                                    }
                                                />
                                            </div>

                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.MAKE_DEFAULT_IN_APP")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-on-by-default-in-app-status"
                                                    value={item.isTurnedOnByDefaultInApp}
                                                    onChange={(event) =>
                                                        toggleOnByDefaultInAppStatus(event.target.checked)
                                                    }
                                                />
                                            </div>

                                            <div className="u-separator--primary"></div>

                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.VISIBLE_ON_PORTAL")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-visibility-status"
                                                    value={item.isVisible}
                                                    onChange={(event) => toggleVisibilityStatus(event.target.checked)}
                                                />
                                            </div>

                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.FEATURED_ON_PORTAL")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-featured-status"
                                                    value={item.isFeatured}
                                                    onChange={(event) => toggleFeaturedStatus(event.target.checked)}
                                                />
                                            </div>

                                            <div className="u-separator--primary"></div>

                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t(
                                                        "ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.VISIBLE_IN_EMBEDDED_APP"
                                                    )}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-embedded-app-visibility-status"
                                                    value={item.isVisibleInEmbeddedApp}
                                                    onChange={(event) =>
                                                        toggleNakiTouchVisibilityStatus(event.target.checked)
                                                    }
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t(
                                                        "ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.MAKE_DEFAULT_IN_EMBEDDED_APP"
                                                    )}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-on-by-default-in-embedded-app-status"
                                                    value={item.isTurnedOnByDefaultInEmbeddedApp}
                                                    onChange={(event) =>
                                                        toggleOnByDefaultInNakiTouchStatus(event.target.checked)
                                                    }
                                                />
                                            </div>

                                            <div className="u-separator--primary"></div>

                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t(
                                                        "ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.RECENTLY_UPDATED_VISIBILITY"
                                                    )}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-recently-updated-visibility-status"
                                                    value={item.podcastFeed.recentlyUpdatedVisibility}
                                                    onChange={(event) =>
                                                        toggleRecentlyUpdatedVisibilityStatus(event.target.checked)
                                                    }
                                                />
                                            </div>
                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.HLS_ENABLED")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-hls-enabled-status"
                                                    value={item.podcastFeed.hlsEnabled}
                                                    onChange={(event) => toggleHlsEnabledStatus(event.target.checked)}
                                                />
                                            </div>

                                            <div className="u-separator--primary"></div>

                                            <div className="c-sidebar__item">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.EXCLUDED_FROM_REVENUE")}
                                                </div>
                                                <MrbToggle
                                                    id="podcast-excluded-from-revenue-status"
                                                    value={item.excludedFromRevenue}
                                                    onChange={(event) =>
                                                        toggleExcludedFromRevenueStatus(event.target.checked)
                                                    }
                                                />
                                            </div>

                                            <div className="u-separator--primary"></div>

                                            <div className="u-display--flex u-display--flex--jc--space-between u-display--flex--ai--center">
                                                <div className="u-type--base">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.DELETE_LABEL")}
                                                </div>
                                                <MrbButton
                                                    t={t}
                                                    className="c-btn c-btn--primary c-btn--sml"
                                                    label="ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.DELETE"
                                                    onClick={deletePodcast}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </MrbContentLoader>
                            </div>
                        </div>

                        <div className="col col-sml-12 col-med-6 col-xxlrg-12">
                            <div className="c-card--primary">
                                <PodcastAnalyticsStatsWidget podcastId={id} routeStore={routeStore} t={t} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MrbPreviewPage>
    );
}

PodcastPreviewTemplate.propTypes = {
    podcastPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function PodcastContentType({ contentType, t }) {
    return contentType === podcastContentType.torahAnytime
        ? t("ADMINISTRATION.PODCAST.CONTENT_TYPE.TORAH_ANYTIME")
        : t("ADMINISTRATION.PODCAST.CONTENT_TYPE.DEFAULT");
}

export default defaultTemplate(PodcastPreviewTemplate);
