import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditPage, MrbFieldInput } from "mrb/components";
import { MrbSelect } from "mrb/components/select";
import { MrbButton } from "mrb/components/button";
import { MrbContentLoader } from "mrb/components/loader";
import { ShuffleEditItems, ShuffleEditNakiNigunAlbumItem } from "administration/shuffle/components";
import { defaultTemplate } from "common/hoc";
import { ShuffleCreateLoader } from "administration/shuffle/components";

function NakiNigunShuffleEditTemplate({ nakiNigunShuffleEditViewStore, t }) {
    const { form, loaderStore, shuffleItemSelectStore, addNakiNigunAlbumShuffleItem, isAddDisabled } =
        nakiNigunShuffleEditViewStore;

    return (
        <MrbEditPage store={nakiNigunShuffleEditViewStore} t={t}>
            <div className={`c-container--base ${loaderStore.loading ? "c-card--primary" : ""}`}>
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <ShuffleCreateLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="c-card--primary c-card--med  u-mar--bottom--sml">
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <MrbFieldInput
                                            field={form.$("name")}
                                            t={t}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>

                                    <div className="c-shuffle__search">
                                        <div className="c-shuffle__search__input">
                                            <MrbSelect
                                                store={shuffleItemSelectStore}
                                                formatOptionLabel={({ item }) => <OptionLabel item={item} />}
                                                classNameExtend="c-dropdown--dark c-dropdown--base"
                                            />
                                        </div>
                                        <MrbButton
                                            t={t}
                                            label="Add"
                                            classNameOverride="c-btn c-btn--base c-btn--primary"
                                            onClick={addNakiNigunAlbumShuffleItem}
                                            disabled={isAddDisabled}
                                        />
                                    </div>
                                </div>
                            </div>

                            <ShuffleEditItems
                                shuffleEditViewStore={nakiNigunShuffleEditViewStore}
                                t={t}
                                itemRender={(props) => <ShuffleEditNakiNigunAlbumItem {...props} />}
                            />
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </MrbEditPage>
    );
}

NakiNigunShuffleEditTemplate.propTypes = {
    nakiNigunShuffleEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function OptionLabel({ item }) {
    return (
        <span>
            <span className="u-type--color--opaque">{item.artist.name}</span>
            <span className="u-mar--left--tny u-mar--right--tny">-</span>
            {item.title}
        </span>
    );
}

export default defaultTemplate(NakiNigunShuffleEditTemplate);
