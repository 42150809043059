import { MrbBaseRouteStore } from "mrb/core";
import { PodcastFileStreamService, PodcastService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class PodcastEpisodeContentExplorerRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(PodcastService);
        this.podcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
    }

    async findAudioTrackEpisodes(filter) {
        const response = await this.service.findAudioTrackEpisodes(filter);
        return response.data;
    }

    async getPodcasts(filter) {
        const response = await this.service.getPodcasts(filter);
        return response.data;
    }

    createPodcastEpisodeAudioFileUrl(podcastEpisodeId) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.podcastFileStreamService.routeService.getPodcastEpisodeAudioFile(podcastEpisodeId)
        );
    }
}

export default PodcastEpisodeContentExplorerRouteStore;
