import React from "react";
import { StationListTemplate } from "themes/administration/station/pages";
import { StationListViewStore } from "administration/station/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new StationListViewStore(rootStore, componentProps),
    "stationListViewStore"
)
class StationList extends React.Component {
    render() {
        return <StationListTemplate {...this.props} />;
    }
}

export default StationList;
