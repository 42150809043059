import React from "react";
import { AdminPlaylistPreviewTemplate } from "themes/administration/playlist/pages";
import { AdminPlaylistPreviewViewStore } from "administration/playlist/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminPlaylistPreviewViewStore(rootStore, componentProps),
    "adminPlaylistPreviewViewStore"
)
class AdminPlaylistPreview extends React.Component {
    render() {
        return <AdminPlaylistPreviewTemplate {...this.props} />;
    }
}

export default AdminPlaylistPreview;
