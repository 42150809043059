import React from "react";
import { StationAnalyticsStatsWidgetTemplate } from "themes/administration/station/components";
import { StationAnalyticsStatsWidgetViewStore } from "administration/station/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new StationAnalyticsStatsWidgetViewStore(rootStore, componentProps),
    "stationAnalyticsStatsWidgetViewStore"
)
class StationAnalyticsStatsWidget extends React.Component {
    render() {
        return <StationAnalyticsStatsWidgetTemplate {...this.props} />;
    }
}

export default StationAnalyticsStatsWidget;