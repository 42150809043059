import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { observer } from "mobx-react";
import { PodcastEpisodeEditTitle } from "administration/podcast/components";

const PodcastEpisodeEditTitleModalTemplate = observer(function PodcastEpisodeEditTitleModalTemplate({
    podcastEpisodeListViewStore,
    modalParams,
    t,
}) {
    return (
        <MrbModal modalParams={modalParams} t={t} className="modal__content modal__content--lrg">
            {() => (
                <PodcastEpisodeEditTitle
                    podcastEpisodeListViewStore={podcastEpisodeListViewStore}
                    id={modalParams.data.id}
                    title={modalParams.data.title}
                    closeModal={modalParams.close}
                    t={t}
                />
            )}
        </MrbModal>
    );
});

PodcastEpisodeEditTitleModalTemplate.propTypes = {
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default PodcastEpisodeEditTitleModalTemplate;
