import { MrbBaseRouteStore } from "mrb/core";
import { GenreService, GenreLookupService } from "common/services";

class GenreContentListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
        this.service = this.rootStore.createApplicationService(GenreService);
        this.genreLookupService = this.rootStore.createApplicationService(GenreLookupService);
    }

    async getGenreContent(id, filter) {
        const response = await this.service.getGenreContent(id, filter);
        return response.data;
    }

    async getGenre(id) {
        const response = await this.genreLookupService.get(id);
        return response.data;
    }
}

export default GenreContentListRouteStore;
