import React from "react";
import ContentLoader from "react-content-loader";

const PodcastGroupLineLoaderTemplate = (props) => {
    return (
        <React.Fragment>
            <PodcastPreviewLineLoaderTemplate {...props} />
            <PodcastPreviewLineLoaderTemplate {...props} />
            <PodcastPreviewLineLoaderTemplate {...props} />
            <PodcastPreviewLineLoaderTemplate {...props} />
            <PodcastPreviewLineLoaderTemplate {...props} />
        </React.Fragment>
    );
};

const PodcastPreviewLineLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;
    let loaderWidth;
    let setX;
    
    if (screenwidth > 1200) {
        loaderWidth = screenwidth - 690;
    } else if (screenwidth > 988) {
        loaderWidth = screenwidth - 200;
    } else {
        loaderWidth = screenwidth - 64;
    }

    if (screenwidth > 1440) {
        setX = "98%";
    } else if (screenwidth > 1024) {
        setX = "96%";
    } else if (screenwidth > 425) {
        setX = "95%";
    } else {
        setX = "91%";
    }

    return (
        <React.Fragment>
            <div className="c-card--primary u-mar--bottom--sml">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${loaderWidth} 16`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...props}
                >
                    <rect x="0" y="0" rx="6" ry="6" width="120" height="14" />
                    <rect x="135" y="0" rx="6" ry="6" width="14" height="14" />
                    <rect x={setX} y="0" rx="6" ry="6" width="28" height="14" />
                </ContentLoader>
            </div>
        </React.Fragment>
    );
};

export default PodcastGroupLineLoaderTemplate;
