import React from "react";
import { NakiNigunAlbumEditTemplate } from "themes/administration/naki-nigun/components";
import { NakiNigunAlbumEditViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunAlbumEditViewStore(rootStore, componentProps),
    "nakiNigunAlbumEditViewStore"
)
class NakiNigunAlbumEdit extends React.Component {
    render() {
        return <NakiNigunAlbumEditTemplate {...this.props} />;
    }
}

export default NakiNigunAlbumEdit;
