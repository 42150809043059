import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { MrbRadioButtons } from "mrb/components/radio";
import { observer } from "mobx-react";

function ShuffleEditStationItemTemplate({ item, store, t }) {
    const { deleteShuffleItem } = store;
    return (
        <div className="c-shuffle__item">
            <div className="c-shuffle__item__header">
                <div className="u-display--flex u-display--flex--ai--center">
                    {item.station.title}{" "}
                    {item.station.isPremium && (
                        <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                    )}
                </div>
                <MrbButton
                    t={t}
                    label="Delete"
                    classNameOverride="c-btn c-btn--sml c-btn--ghost"
                    onClick={() => deleteShuffleItem(item.id)}
                />
            </div>

            <div className="u-separator--primary u-separator--primary--sml"></div>

            <div className="c-shuffle__action">
                <WeightPicker key={item.id} item={item} store={store} t={t} />
            </div>
        </div>
    );
}

ShuffleEditStationItemTemplate.propTypes = {
    item: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const WeightPicker = observer(function WeightOverride({ item, store, t }) {
    const { shuffleWeightOptions, setItemShuffleWeight } = store;
    return (
        <React.Fragment>
            <div className="c-shuffle__action__item">
                <MrbRadioButtons
                    // inputClassNameExtend="c-segmented--sml"
                    id={`${item.id}-weights`}
                    name={`${item.id}-weights`}
                    options={shuffleWeightOptions}
                    t={t}
                    value={item.shuffleWeightId}
                    onChange={(value) => setItemShuffleWeight(item.id, value)}
                />
            </div>
        </React.Fragment>
    );
});

WeightPicker.propTypes = {
    item: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(ShuffleEditStationItemTemplate);
