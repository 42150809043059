import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { Image } from "common/components";
import { observer } from "mobx-react";

function PodcastPreviewPodcastVolumeTemplate({ podcastPreviewPodcastVolumeViewStore, t }) {
    const { podcastVolume, onClickEdit, coverImageUrl } = podcastPreviewPodcastVolumeViewStore;

    return (
        <div className="c-collapse-panel">
            <div className="c-collapse-panel__header">
                <div className="u-display--flex u-display--flex--ai--center u-gap--base">
                    <Image className="c-home__preview__icon" src={coverImageUrl} />
                    <p className="c-home__preview__title u-mar--bottom--none">{podcastVolume.title}</p>
                </div>

                <MrbButton
                    classNameOverride={"c-btn c-btn--base c-btn--ghost"}
                    onClick={onClickEdit}
                    label="ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.PODCAST_VOLUME_EDIT"
                    t={t}
                />
            </div>
        </div>
    );
}

PodcastPreviewPodcastVolumeTemplate.propTypes = {
    podcastVolume: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(PodcastPreviewPodcastVolumeTemplate);
