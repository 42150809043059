import React, { useState, useCallback } from "react";
import ContentLoader from "react-content-loader";

const StationPromoEditLoaderTemplate = (props) => {
    const screenWidth = window.innerWidth;
    const loaderHeight = screenWidth > 766 ? 250 : 450;
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {screenWidth > 766 ? (
                    <React.Fragment>
                        <rect x="70" y={0} rx="6" ry="6" width="168" height="200" />
                        <rect x="70" y={220} rx="6" ry="6" width="168" height="35" />

                        <rect x="345" y={12} rx="6" ry="6" width="168" height="14" />
                        <rect x="345" y={36} rx="6" ry="6" width="268" height="32" />
                        {/* prettier-ignore */}
                        <rect x="345" y={76} rx="6" ry="6" width={loaderWidth ? loaderWidth - 345 : "40%"} height="100" />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <rect x="37%" y={0} rx="6" ry="6" width="168" height="200" />
                        <rect x="37%" y={220} rx="6" ry="6" width="168" height="35" />

                        <rect x="0" y={270 + 12} rx="6" ry="6" width="168" height="14" />
                        <rect x="0" y={270 + 36} rx="6" ry="6" width="268" height="32" />
                        <rect x="0" y={270 + 76} rx="6" ry="6" width={"100%"} height="100" />
                    </React.Fragment>
                )}
            </ContentLoader>
        </div>
    );
};

export default StationPromoEditLoaderTemplate;
