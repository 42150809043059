import { MrbBaseRouteStore } from "mrb/core";
import { DeviceService, DeviceMenuTypeCacheService } from "common/services";

class DeviceMenuEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(DeviceService);

        this.deviceMenuTypeService = new DeviceMenuTypeCacheService(
            this.rootStore
        );
    }

    getDeviceMenuTypes() {
        return this.deviceMenuTypeService.getItems();
    }

    async getDeviceMenu() {
        const response = await this.service.getMenu();
        return response.data;
    }

    async getDeviceMenuLatestItems() {
        const response = await this.service.getMenuLatestItems();
        return response.data;
    }

    async updateDeviceMenu(resource) {
        const response = await this.service.updateMenu(resource);
        return response.data;
    }
}

export default DeviceMenuEditRouteStore;
