import React from "react";
import { GenreContentListTemplate } from "themes/administration/genre/pages";
import { GenreContentListViewStore } from "administration/genre/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new GenreContentListViewStore(rootStore, componentProps),
    "genreContentListViewStore"
)
class GenreContentList extends React.Component {
    render() {
        return <GenreContentListTemplate {...this.props} />;
    }
}

export default GenreContentList;
