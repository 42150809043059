import React from "react";
import { PodcastGroupEditSubPodcastTemplate } from "themes/administration/podcast/components";
import { PodcastGroupEditSubPodcastViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastGroupEditSubPodcastViewStore(rootStore, componentProps),
    "podcastGroupEditSubPodcastViewStore"
)
class PodcastGroupEditSubPodcast extends React.Component {
    render() {
        return <PodcastGroupEditSubPodcastTemplate {...this.props} />;
    }
}

export default PodcastGroupEditSubPodcast;
