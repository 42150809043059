import React from "react";
import { PropTypes } from "prop-types";
import { NakiNigunArtistPreviewAlbum } from "administration/naki-nigun/components";
import { PodcastGroupLineLoader } from "administration/podcast/components";
import { MrbContentLoader } from "mrb/components/loader";
import { map } from "lodash";
import { observer } from "mobx-react";

function NakiNigunArtistPreviewAlbumListTemplate({ nakiNigunArtistPreviewViewStore, t, innerList }) {
    const { item, loaderStore } = nakiNigunArtistPreviewViewStore;

    return (
        <div className={innerList ? "" : "c-container--content--main u-padd--top--none"}>
            <div>
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <PodcastGroupLineLoader />}>
                    {() => (
                        <div className="u-animation--fadeIn">
                            {map(item.albums, (album, key) => (
                                <NakiNigunArtistPreviewAlbum
                                    key={key}
                                    nakiNigunArtistPreviewViewStore={nakiNigunArtistPreviewViewStore}
                                    album={album}
                                    t={t}
                                />
                            ))}
                        </div>
                    )}
                </MrbContentLoader>
            </div>
        </div>
    );
}

NakiNigunArtistPreviewAlbumListTemplate.propTypes = {
    nakiNigunArtistPreviewViewStore: PropTypes.object.isRequired,
    innerList: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

export default observer(NakiNigunArtistPreviewAlbumListTemplate);
