import React from "react";
import { StationCoverImageUploadTemplate } from "themes/administration/station/components";

class StationCoverImageUpload extends React.Component {
    render() {
        return <StationCoverImageUploadTemplate {...this.props} />;
    }
}

export default StationCoverImageUpload;
