import React from "react";
import { observer } from "mobx-react";
import { DeviceActivityTemplate } from "themes/administration/analytics/pages";
import { DeviceActivityViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new DeviceActivityViewStore(rootStore, componentProps),
    "deviceActivityViewStore"
)
@observer
class DeviceActivity extends React.Component {
    render() {
        return <DeviceActivityTemplate {...this.props} />;
    }
}

export default DeviceActivity;
