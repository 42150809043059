import React from "react";
import { PropTypes } from "prop-types";
import { MrbModal } from "mrb/components/modal";
import { observer } from "mobx-react";
import { MrbSelect } from "mrb/components/select";
import { MrbButton } from "mrb/components/button";
import { isNil } from "lodash";

function AddGenreHomeSectionModalTemplate({ nakiMobileHomeSectionListViewStore, modalParams, t }) {
    return (
        <MrbModal
            title={"Add new genre home section"}
            modalParams={modalParams}
            t={t}
            className="modal__content modal__content--lrg"
        >
            <p className="u-type--sml u-type--left u-mar--bottom--tny">Select from list</p>
            <div>
                <div>
                    <MrbSelect
                        store={nakiMobileHomeSectionListViewStore.genreSelectStore}
                        classNameExtend="c-dropdown--dark c-dropdown--base"
                    />
                </div>

                <div className="u-separator--primary u-separator--primary--base"></div>

                <div className="modal__footer__btn-group">
                    <MrbButton
                        t={t}
                        label="Add"
                        disabled={isNil(nakiMobileHomeSectionListViewStore.genreSelectStore.value)}
                        onClick={() => {
                            nakiMobileHomeSectionListViewStore.addGenreSection();
                        }}
                    />
                    <MrbButton
                        classNameOverride="c-btn c-btn--base c-btn--ghost"
                        t={t}
                        label="Cancel"
                        onClick={modalParams.close}
                    />
                </div>
            </div>
        </MrbModal>
    );
}

AddGenreHomeSectionModalTemplate.propTypes = {
    modalParams: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(AddGenreHomeSectionModalTemplate);
