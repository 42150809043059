import { MrbBaseListViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { shuffleType } from "common/constants";
class ShuffleListViewStore extends MrbBaseListViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            queryConfig: {
                embed: "shuffleType",
                orderBy: "name",
                orderDirection: "asc",
            },
            layoutActions: {
                createPodcastShuffle: {
                    onClick: () => this.rootStore.routerStore.goTo("master.administration.shuffle.create-podcast"),
                    authorization: null,
                    label: "ADMINISTRATION.SHUFFLE.LIST.BUTTONS.ADD_PODCAST_SHUFFLE",
                },
                createStationShuffle: {
                    onClick: () => this.rootStore.routerStore.goTo("master.administration.shuffle.create-station"),
                    authorization: null,
                    label: "ADMINISTRATION.SHUFFLE.LIST.BUTTONS.ADD_STATION_SHUFFLE",
                },
                createNakiNigunShuffle: {
                    onClick: () => this.rootStore.routerStore.goTo("master.administration.shuffle.create-naki-nigun"),
                    authorization: null,
                    label: "ADMINISTRATION.SHUFFLE.LIST.BUTTONS.ADD_NAKI_NIGUN_SHUFFLE",
                },
            },
        });
        this.routeStore = routeStore;

        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                onRowClick: (node) =>
                    this.rootStore.routerStore.goTo("master.administration.shuffle.preview", { id: node.data.id }),
                columns: [
                    {
                        key: "name",
                        title: "Name",
                        sortable: true,
                    },
                    {
                        key: "shuffleType.name",
                        title: "Type",
                    },
                ],
                actions: {
                    edit: {
                        onClick: (item) => {
                            if (item.shuffleType.abrv === shuffleType.station) {
                                this.rootStore.routerStore.goTo("master.administration.shuffle.edit-station", {
                                    id: item.id,
                                });
                            } else if (item.shuffleType.abrv === shuffleType.podcast) {
                                this.rootStore.routerStore.goTo("master.administration.shuffle.edit-podcast", {
                                    id: item.id,
                                });
                            } else {
                                this.rootStore.routerStore.goTo("master.administration.shuffle.edit-naki-nigun", {
                                    id: item.id,
                                });
                            }
                        },
                        label: "Edit",
                        authorization: null,
                        buttonProps: {
                            className: "c-btn c-btn--sml c-btn--ghost u-mar--right--tny",
                        },
                    },
                    delete: {
                        onClick: (item) =>
                            this.rootStore.confirmDialogStore.showConfirm({
                                message: "Do you want to delete shuffle?",
                                onConfirm: async () => {
                                    try {
                                        await this.routeStore.deleteShuffle(item);
                                        this.queryUtility.fetch();
                                    } catch (err) {
                                        this.rootStore.notificationStore.error(
                                            "An unexpected error occurred. Unable to delete shuffle.",
                                            err
                                        );
                                    }
                                },
                                yesLabel: "Delete",
                                noLabel: "Cancel",
                                description: "You won't be able to revert this action.",
                            }),
                        label: "ADMINISTRATION.SHUFFLE.LIST.ACTIONS.DELETE",
                        buttonProps: {
                            className: "c-btn c-btn--sml c-btn--ghost",
                        },
                    },
                },
            })
        );
    }

    find(params) {
        return this.routeStore.findShuffles(params);
    }
}

export default ShuffleListViewStore;
