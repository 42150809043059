import React from "react";
import { PodcastGroupEditSubPodcastListTemplate } from "themes/administration/podcast/components";

class PodcastGroupEditSubPodcastList extends React.Component {
    render() {
        return <PodcastGroupEditSubPodcastListTemplate {...this.props} />;
    }
}

export default PodcastGroupEditSubPodcastList;
