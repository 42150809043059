import { computed, makeObservable } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { MrbFieldSelectStore } from "mrb/components/select";
import { PodcastGroupItemEditForm } from "administration/podcast/forms";
import { PodcastCoverImageUploadViewStore } from "administration/podcast/stores";
import { map } from "lodash";

class AddPodcastGroupItemViewStore extends MrbBaseEditViewStore {
    @computed get episodeSortTypeOptions() {
        return this.podcastGroupEditViewStore.episodeSortTypeOptions;
    }

    @computed get isPremiumDisabled() {
        return this.podcastGroupEditViewStore.form.$("isPremium").value;
    }

    constructor(rootStore, { podcastGroupEditViewStore }) {
        super(rootStore, {
            name: "podcast-group-item",
            FormClass: PodcastGroupItemEditForm,
            redirectOnCreateSuccess: false,
        });
        this.podcastGroupEditViewStore = podcastGroupEditViewStore;
        this.routeStore = this.podcastGroupEditViewStore.routeStore;
        makeObservable(this);

        this.coverImageUploadViewStore = new PodcastCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.podcastGroupEditViewStore.routeStore,
        });

        this.reaction(
            () => this.podcastGroupEditViewStore.form.$("isPremium").value,
            (isPremium) => {
                this.form.$("isPremium").set(isPremium);
            }
        );

        this.reaction(
            () => this.form.$("isPremium").value,
            (isPremium) => {
                if (isPremium) {
                    this.form.$("producerId").addIsRequiredRule();
                } else {
                    this.form.$("producerId").removeIsRequiredRule();
                }
            }
        );

        this.reaction(
            () => this.podcastGroupEditViewStore.form.$("isVisibleInApp").value,
            (isVisibleInApp) => {
                this.form.$("isVisibleInApp").onChange(isVisibleInApp);
            }
        );

        this.reaction(
            () => this.podcastGroupEditViewStore.form.$("isVisibleInEmbeddedApp").value,
            (isVisibleInEmbeddedApp) => {
                this.form.$("isVisibleInEmbeddedApp").onChange(isVisibleInEmbeddedApp);
            }
        );

        this.genreSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                isMulti: true,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findGenres(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createGenre({ name });
                    },
                },
            },
            this.form.$("genreIds")
        );

        this.locationSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findLocations(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createLocation({ name });
                    },
                },
            },
            this.form.$("locationId")
        );

        this.languageSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findLanguages(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createLanguage({ name });
                    },
                },
            },
            this.form.$("languageId")
        );

        this.producerSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findProducers(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createProducer({ name });
                    },
                },
            },
            this.form.$("producerId")
        );
    }

    create(data) {
        data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        const selectedLocationOption = this.locationSelectStore.selectedOption;
        if (selectedLocationOption) {
            data.location = selectedLocationOption.item;
        }
        const selectedLanguageOption = this.languageSelectStore.selectedOption;
        if (selectedLanguageOption) {
            data.language = selectedLanguageOption.item;
        }
        const selectedGenres = this.genreSelectStore.selectedOption;
        if (selectedGenres) {
            data.genres = map(selectedGenres, (genreOption) => genreOption.item);
        }
        const selectedProducerOption = this.producerSelectStore.selectedOption;
        if (selectedProducerOption) {
            data.producer = selectedProducerOption.item;
        }
        this.podcastGroupEditViewStore.addSubPodcast(data);
        return Promise.resolve();
    }

    onCreateError(err) {
        this.rootStore.notificationStore.error("An unexpected error occurred while trying to update resource.", err);
    }

    resetForm() {
        this.form.clear();
        this.coverImageUploadViewStore.remove();
        const isPodcastGroupPremium = this.podcastGroupEditViewStore.form.$("isPremium").value;
        const isPodcastGroupVisibleInApp = this.podcastGroupEditViewStore.form.$("isVisibleInApp").value;
        const isPodcastGroupVisibleInNakiTouch = this.podcastGroupEditViewStore.form.$("isVisibleInEmbeddedApp").value;
        this.form.set({
            isActive: true,
            isVisibleInApp: isPodcastGroupVisibleInApp,
            isVisibleInEmbeddedApp: isPodcastGroupVisibleInNakiTouch,
            podcastFeed: {
                episodeSortTypeId: this.episodeSortTypeOptions[0].value,
                recentlyUpdatedVisibility: true,
            },
            isPremium: isPodcastGroupPremium,
        });
        this.genreSelectStore.setSelectedItem(null);
        this.locationSelectStore.setSelectedItem(null);
        this.languageSelectStore.setSelectedItem(null);
        this.producerSelectStore.setSelectedItem(null);
    }

    onCreateSuccess() {
        this.resetForm();
    }
}

export default AddPodcastGroupItemViewStore;
