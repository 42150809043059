import { action, computed, observable, makeObservable, toJS } from "mobx";
import { PodcastGroupEditForm } from "administration/podcast/forms";
import { BasePodcastEditViewStore } from "administration/podcast/stores";
import { applicationErrorCodes } from "common/constants";
import { UUID } from "common/utils";
import { map, findIndex, filter, merge, some, omit, isEmpty } from "lodash";

export const PODCAST_FORM_STATUS = {
    CHANGED: "CHANGED",
    UPDATED: "UPDATED",
};

class PodcastGroupEditViewStore extends BasePodcastEditViewStore {
    @observable subPodcasts = [];
    @observable subPodcastsChanged = false;

    @computed get anyUnsavedSubPodcast() {
        return some(this.subPodcasts, (subPodcast) => subPodcast.FORM_STATUS === PODCAST_FORM_STATUS.CHANGED);
    }

    @computed get isFormChanged() {
        return (
            this.form.changed ||
            some(
                this.subPodcasts,
                (subPodcast) =>
                    subPodcast.FORM_STATUS === PODCAST_FORM_STATUS.CHANGED ||
                    subPodcast.FORM_STATUS === PODCAST_FORM_STATUS.UPDATED
            ) ||
            this.subPodcastsChanged ||
            this.coverImageUploadViewStore.hasChanged
        );
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, routeStore, {
            id: id,
            name: "podcast",
            FormClass: PodcastGroupEditForm,
            autoFocusField: "title",
            navigateBack: "master.administration.podcast.list",
            blockRouteExit: true,
            redirectOnCreateSuccess: ({ id }) =>
                this.rootStore.routerStore.goTo("master.administration.podcast.preview-group", { id: id }),
            redirectOnUpdateSuccess: () =>
                this.rootStore.routerStore.goTo("master.administration.podcast.preview-group", { id: this.id }),
        });
        makeObservable(this);

        this.reaction(
            () => this.form.submitted && this.subPodcasts.length === 0,
            (invalidateSubPodcasts) => {
                if (invalidateSubPodcasts) {
                    this.form.invalidate("ADMINISTRATION.PODCAST.EDIT.VALIDATION.SUB_PODCASTS_EMPTY");
                } else {
                    this.form.validator.error = null;
                }
            }
        );

        this.reaction(
            () =>
                this.form.submitted &&
                some(
                    this.subPodcasts,
                    (subPodcast) => this.form.$("isPremium").value && subPodcast.producerId === null
                ),
            (invalidateSubPodcasts) => {
                if (invalidateSubPodcasts) {
                    this.form.invalidate("ADMINISTRATION.PODCAST.EDIT.VALIDATION.PREMIUM_PODCAST_MISSING_PRODUCER");
                } else {
                    this.form.validator.error = null;
                }
            }
        );

        this.reaction(
            () => this.form.$("isPremium").value,
            (isPremium) => {
                if (!isPremium) {
                    this.form.$("premiumInfo").set(null);
                }
            }
        );

        this.registerRouteExitBlocking();
    }

    createResource(data) {
        this._onAfterSave(data);
        data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        return super.createResource(data);
    }

    updateResource(data) {
        this._onAfterSave(data);
        if (this.coverImageUploadViewStore.hasChanged) {
            data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        } else {
            data.coverImageId = this.item.coverImageId;
        }
        return super.updateResource(data);
    }

    _onAfterSave(data) {
        data.subPodcasts = map(this.subPodcasts, (subPodcast, index) => {
            return {
                ...omit(subPodcast, "FORM_STATUS"),
                genreIds: subPodcast.genres ? map(subPodcast.genres, (genre) => genre.id) : null,
                sortOrder: index + 1,
            };
        });
        if (!data.subPodcasts || data.subPodcasts.length === 0) {
            this.form.invalidate("ADMINISTRATION.PODCAST.EDIT.VALIDATION.SUB_PODCASTS_EMPTY");
            return;
        }
        if (some(data.subPodcasts, (subPodcast) => data.isPremium && subPodcast.producerId === null)) {
            this.form.invalidate("ADMINISTRATION.PODCAST.EDIT.VALIDATION.PREMIUM_PODCAST_MISSING_PRODUCER");
            return;
        }
        if (!data.isPremium && !isEmpty(data.premiumInfo)) {
            data.premiumInfo = null;
        }
    }

    setItem(item) {
        this.subPodcasts = item.subPodcasts;
        item.subPodcasts = null;
        super.setItem(item);
    }

    get(id) {
        return this.routeStore.getPodcastGroup(id);
    }

    create(data) {
        return this.routeStore.createPodcastGroup(data);
    }

    resetForm() {
        this.form.set({
            isActive: true,
            isVisible: true,
        });
    }

    update(data) {
        return this.routeStore.updatePodcastGroup(data);
    }

    onCreateSuccess(result) {
        this.routeStore.setIsRouteExitBlocked(false);
        super.onCreateSuccess(result);
    }

    onUpdateSuccess(result) {
        this.routeStore.setIsRouteExitBlocked(false);
        super.onUpdateSuccess(result);
    }

    onCreateError(err) {
        if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
            this.routeStore.setIsRouteExitBlocked(false);
            const podcast = err.data.response;
            this.rootStore.notificationStore.error(
                "Podcast group created, but unexpected error occurred during RSS sync.",
                err
            );
            this.redirectOnCreateSuccess(podcast);
        } else {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to create podcast group.",
                err
            );
        }
    }

    onUpdateError(err) {
        if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
            this.routeStore.setIsRouteExitBlocked(false);
            this.rootStore.notificationStore.error(
                "Podcast group updated, but an unexpected error occurred during RSS sync.",
                err
            );
            this.redirectOnUpdateSuccess();
        } else {
            this.rootStore.notificationStore.error(
                "An unexpected error occurred while trying to updated podcast group.",
                err
            );
        }
    }

    @action.bound
    addSubPodcast(item) {
        item.id = UUID.create();
        item.FORM_STATUS = PODCAST_FORM_STATUS.UPDATED;
        this.subPodcasts.push(item);
        this.subPodcastsChanged = true;
    }

    @action.bound
    updateSubPodcast(id, item) {
        const index = findIndex(this.subPodcasts, (subPodcast) => subPodcast.id === id);
        item.FORM_STATUS = PODCAST_FORM_STATUS.UPDATED;
        if (item.genres !== undefined) {
            this.subPodcasts[index].genres = item.genres;
        }
        this.subPodcasts[index] = merge(this.subPodcasts[index], item);
        this.subPodcastsChanged = true;
    }

    @action.bound
    reorderPodcasts(result) {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const newPodcasts = Array.from(toJS(this.subPodcasts));
        const podcast = newPodcasts.splice(source.index, 1)[0];
        podcast.FORM_STATUS = PODCAST_FORM_STATUS.UPDATED;
        newPodcasts.splice(destination.index, 0, podcast);

        this.subPodcasts = newPodcasts;
        this.subPodcastsChanged = true;
    }

    @action.bound
    markSubPodcastAsChanged(podcastId) {
        const index = findIndex(this.subPodcasts, (subPodcast) => subPodcast.id === podcastId);
        if (index > -1) {
            this.subPodcasts[index].FORM_STATUS = PODCAST_FORM_STATUS.CHANGED;
        }
        this.subPodcastsChanged = true;
    }

    @action.bound
    markSubPodcastAsUpdated(podcastId) {
        const index = findIndex(this.subPodcasts, (subPodcast) => subPodcast.id === podcastId);
        if (index > -1) {
            this.subPodcasts[index].FORM_STATUS = PODCAST_FORM_STATUS.UPDATED;
        }
        this.subPodcastsChanged = true;
    }

    registerRouteExitBlocking() {
        this.reaction(
            () => this.isFormChanged,
            (isChanged) => {
                this.routeStore.setIsRouteExitBlocked(isChanged);
            }
        );
    }

    @action.bound
    deleteSubPodcast(podcastId) {
        this.subPodcasts = filter(this.subPodcasts, (subPodcast) => subPodcast.id !== podcastId);
    }
}

export default PodcastGroupEditViewStore;
