import { MrbBaseListViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { mrbDefaultFormats } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";

class OrderListViewStore extends MrbBaseListViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            queryConfig: {
                orderBy: "dateCreated",
                orderDirection: "desc",
                embed: "orderStatus",
            },
        });
        this.routeStore = routeStore;

        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                onRowClick: (node) =>
                    this.rootStore.routerStore.goTo("master.administration.order.preview", { id: node.data.id }),
                columns: [
                    {
                        key: "orderNumber",
                        title: "ADMINISTRATION.ORDER.LIST.COLUMNS.ORDER_NUMBER",
                    },
                    {
                        key: "billingAddress",
                        title: "ADMINISTRATION.ORDER.LIST.COLUMNS.CUSTOMER",
                        format: (billingAddress) => `${billingAddress.firstName} ${billingAddress.lastName}`,
                    },
                    {
                        key: "orderStatus",
                        title: "ADMINISTRATION.ORDER.LIST.COLUMNS.ORDER_STATUS",
                        sortable: false,
                        format: (status) => status.name,
                    },
                    {
                        key: "totalAmount",
                        title: "ADMINISTRATION.ORDER.LIST.COLUMNS.TOTAL",
                        format: {
                            type: mrbDefaultFormats.currency,
                            currency: "USD",
                        },
                    },
                    {
                        key: "dateCreated",
                        title: "ADMINISTRATION.ORDER.LIST.COLUMNS.DATE_OF_ORDER",
                        sortable: true,
                        format: {
                            type: mrbDefaultFormats.date,
                            format: applicationDefaults.formats.dateTimeSeconds,
                        },
                    },
                ],
            })
        );
    }

    find(params) {
        return this.routeStore.findOrders(params);
    }

    onFetchError() {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team."
        );
    }
}

export default OrderListViewStore;
