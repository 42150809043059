import React from "react";
import { observer } from "mobx-react";
import { AdminPlaylistListTemplate } from "themes/administration/playlist/pages";
import { AdminPlaylistListViewStore } from "administration/playlist/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminPlaylistListViewStore(rootStore, componentProps),
    "adminPlaylistListViewStore"
)
@observer
class AdminPlaylistList extends React.Component {
    render() {
        return <AdminPlaylistListTemplate {...this.props} />;
    }
}

export default AdminPlaylistList;
