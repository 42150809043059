import React from "react";
import { ContentExplorerTemplate } from "themes/administration/content-explorer";
import { ContentExplorerViewStore } from "administration/content-explorer/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView(
    (rootStore, componentProps) => new ContentExplorerViewStore(rootStore, componentProps),
    "contentExplorerViewStore"
)
@observer
class ContentExplorer extends React.Component {
    render() {
        return <ContentExplorerTemplate {...this.props} />;
    }
}

export default ContentExplorer;
