import React from "react";
import { PodcastEditPodcastVolumeTemplate } from "themes/administration/podcast/components";
import { PodcastEditPodcastVolumeViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastEditPodcastVolumeViewStore(rootStore, componentProps),
    "podcastEditPodcastVolumeViewStore"
)
class PodcastEditPodcastVolume extends React.Component {
    render() {
        return <PodcastEditPodcastVolumeTemplate {...this.props} />;
    }
}

export default PodcastEditPodcastVolume;
