import React from "react";
import { PodcastGroupPreviewSubPodcastListTemplate } from "themes/administration/podcast/components";

class PodcastGroupPreviewSubPodcastList extends React.Component {
    render() {
        return <PodcastGroupPreviewSubPodcastListTemplate {...this.props} />;
    }
}

export default PodcastGroupPreviewSubPodcastList;
