import React from "react";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import PropTypes from "prop-types";
import { defaultTemplate } from "common/hoc";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { mergeCss } from "mrb/common/utils";

function ContentExplorerTemplate({ contentExplorerViewStore, renderChildRoute, t, ...props }) {
    const { selectedTab, setSelectedTab } = contentExplorerViewStore;

    function getHeaderTitle() {
        if (selectedTab.value === 1) {
            return t("ADMINISTRATION.CONTENT_EXPLORER.PODCAST_EPISODE_LIST.TITLE");
        } else {
            return t("ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.TITLE");
        }
    }

    return (
        <React.Fragment>
            <MrbPage>
                <MrbPageHeader>
                    <div>{getHeaderTitle()}</div>
                </MrbPageHeader>

                <div className="c-tabs">
                    <React.Fragment>
                        <MrbRouterLink
                            onClick={() => setSelectedTab(1)}
                            className={mergeCss(
                                "c-tabs__item",
                                selectedTab.title === "Podcast episodes" && "c-tabs__item--active"
                            )}
                            routeName="master.administration.content-explorer.list.podcast-episodes"
                        >
                            {t("ADMINISTRATION.CONTENT_EXPLORER.PODCAST_EPISODE_LIST.TITLE")}
                        </MrbRouterLink>
                        <MrbRouterLink
                            onClick={() => setSelectedTab(2)}
                            className={mergeCss(
                                "c-tabs__item",
                                selectedTab.title === "Album tracks" && "c-tabs__item--active"
                            )}
                            routeName="master.administration.content-explorer.list.album-tracks"
                        >
                            {t("ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.TITLE")}
                        </MrbRouterLink>
                    </React.Fragment>
                </div>

                {renderChildRoute(props)}
            </MrbPage>
        </React.Fragment>
    );
}

ContentExplorerTemplate.propTypes = {
    contentExplorerViewStore: PropTypes.object.isRequired,
    renderChildRoute: PropTypes.func,
    t: PropTypes.func,
};

export default defaultTemplate(ContentExplorerTemplate);
