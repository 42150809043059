import React from "react";
import { NakiMobileFeaturedSectionTemplate } from "themes/administration/naki-mobile/pages";
import { NakiMobileFeaturedSectionViewStore } from "administration/naki-mobile/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiMobileFeaturedSectionViewStore(rootStore, componentProps),
    "nakiMobileFeaturedSectionViewStore"
)
class NakiMobileFeaturedSection extends React.Component {
    render() {
        return <NakiMobileFeaturedSectionTemplate {...this.props} />;
    }
}

export default NakiMobileFeaturedSection;
