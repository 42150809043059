import { BaseShuffleEditRouteStore } from "administration/shuffle/stores";
import { PodcastService } from "common/services";

class PodcastShuffleEditRouteStore extends BaseShuffleEditRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.podcastService = this.rootStore.createApplicationService(PodcastService);
    }

    async getPodcastShuffle(id) {
        const response = await this.service.get(id, {
            embed: "shuffleItems,shuffleItems.podcast,shuffleItems.podcast.podcastFeed",
        });
        return response.data;
    }

    async createPodcastShuffle(resource) {
        const response = await this.service.createPodcast(resource);
        return response.data;
    }

    async updatePodcastShuffle(resource) {
        const response = await this.service.update(resource);
        return response.data;
    }

    async findShufflablePodcast(params) {
        const response = await this.podcastService.findShufflablePodcast(params);
        return response.data;
    }
}

export default PodcastShuffleEditRouteStore;
