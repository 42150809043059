import { action, observable, makeObservable } from "mobx";
import { PodcastAnalyticsStatsWidgetViewStore } from "administration/podcast/stores";

class SubPodcastAnalyticsStatsWidgetViewStore extends PodcastAnalyticsStatsWidgetViewStore {
    @observable isInitialized = false;

    constructor(rootStore, options) {
        super(rootStore, options);
        makeObservable(this);
    }

    async onInit() {
        if (!this.isInitialized) {
            await this.fetchResource(this.filter);
            this.setIsInitialized(true);
        }
    }

    @action.bound
    setIsInitialized(value) {
        this.isInitialized = value;
    }
}

export default SubPodcastAnalyticsStatsWidgetViewStore;
