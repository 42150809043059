import React from "react";
import { observer } from "mobx-react";
import { DeviceMenuEditTemplate } from "themes/administration/device-menu/pages";
import { DeviceMenuEditViewStore } from "administration/device-menu/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new DeviceMenuEditViewStore(rootStore, componentProps),
    "deviceMenuEditViewStore"
)
@observer
class DeviceMenuEdit extends React.Component {
    render() {
        return <DeviceMenuEditTemplate {...this.props} />;
    }
}

export default DeviceMenuEdit;
