import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput } from "mrb/components/input";
import { MrbEditFormValidation } from "mrb/components/layout/content";
import { MrbButton } from "mrb/components/button";
import { isFunction } from "lodash";
import { observer } from "mobx-react";

function PodcastEpisodeEditTitle({ podcastEpisodeEditTitleViewStore, closeModal, t }) {
    const { form } = podcastEpisodeEditTitleViewStore;
    return (
        <React.Fragment>
            <MrbFieldInput classNameExtend="c-input--outline" t={t} field={form.$("title")} />
            <div className="u-mar--top--med u-display--flex u-display--flex--jc--flex-end u-gap--sml">
                {closeModal && isFunction(closeModal) && (
                    <MrbButton
                        t={t}
                        className="c-btn c-btn--base c-btn--ghost"
                        label={t("ADMINISTRATION.PODCAST.EPISODES.BUTTONS.CANCEL")}
                        onClick={closeModal}
                    />
                )}{" "}
                <MrbButton
                    t={t}
                    type="submit"
                    className="c-btn c-btn--base c-btn--primary"
                    onClick={form.onSubmit}
                    aria-busy={form.submitting || form.validating}
                    label={t("ADMINISTRATION.PODCAST.EPISODES.BUTTONS.SAVE")}
                    disabled={form.submitting || form.validating}
                />
            </div>
            <MrbEditFormValidation form={form} t={t} />
        </React.Fragment>
    );
}

PodcastEpisodeEditTitle.propTypes = {
    podcastEpisodeEditTitleViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(PodcastEpisodeEditTitle);
