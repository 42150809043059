import React from "react";
import { PropTypes } from "prop-types";
import { PortalDetailedActivity } from "administration/analytics/components";
import { defaultTemplate } from "common/hoc";

function DeviceActivityTemplate({ deviceActivityViewStore, t }) {
    return (
        <PortalDetailedActivity
            store={deviceActivityViewStore}
            activityLabel="Active devices over period"
            totalLabel="Total # of active devices"
            t={t}
        />
    );
}

DeviceActivityTemplate.propTypes = {
    deviceActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(DeviceActivityTemplate);
