import React from "react";
import { PodcastEditPodcastVolumeListTemplate } from "themes/administration/podcast/components";

class PodcastEditPodcastVolumeList extends React.Component {
    render() {
        return <PodcastEditPodcastVolumeListTemplate {...this.props} />;
    }
}

export default PodcastEditPodcastVolumeList;
