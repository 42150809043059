import React from "react";
import { PropTypes } from "prop-types";
import { PortalDetailedActivity } from "administration/analytics/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { mergeCss } from "mrb/common/utils";
import { defaultTemplate } from "common/hoc";

function StationActivityTemplate({ stationActivityViewStore, t }) {
    return (
        <PortalDetailedActivity
            store={stationActivityViewStore}
            activityLabel="Station playbacks over period"
            totalLabel="Total # of playbacks"
            nameFieldRenderer={(props) => <StationNameField {...props} />}
            t={t}
        />
    );
}

StationActivityTemplate.propTypes = {
    stationActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function StationNameField({ summaryData }) {
    return (
        <React.Fragment>
            <p className="u-type--sml u-type--color--opaque">Station</p>
            <p className="u-display--flex u-display--flex--ai--center">
                <MrbRouterLink
                    routeName="master.administration.station.preview"
                    params={{ id: summaryData.station.id }}
                    className="c-analytics__list__title c-analytics__list__title--lrg">
                    {summaryData.station.title}
                </MrbRouterLink>
                <i
                    className={mergeCss(
                        "u-icon u-icon--base u-mar--left--tny",
                        summaryData.station && summaryData.station.isPremium ? "u-icon--premium" : null
                    )}
                />
            </p>
        </React.Fragment>
    );
}

export default defaultTemplate(StationActivityTemplate);
