import React from "react";
import { EditFeaturedSectionContentTemplate } from "themes/administration/naki-mobile/components";
import { EditFeaturedSectionContentViewStore } from "administration/naki-mobile/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new EditFeaturedSectionContentViewStore(rootStore, componentProps),
    "editFeaturedSectionContentViewStore"
)
class EditFeaturedSectionContent extends React.Component {
    render() {
        return <EditFeaturedSectionContentTemplate {...this.props} />;
    }
}

export default EditFeaturedSectionContent;
