import React from "react";
import { PropTypes } from "prop-types";
import { MrbTable } from "mrb/components/table";
import { MrbPageContentHeader } from "mrb/components/layout";
import { MrbSelect } from "mrb/components/select";
import { TableNoDataOverlay } from "common/components";
import {
    UserAccountSummary,
    UserDeviceActivitySummary,
    UserContentListSummary,
} from "administration/analytics/components";
import { AnalyticsLayout } from "administration/analytics/layouts";
import { defaultTemplate } from "common/hoc";

function UserDeviceActivityTemplate({ userDeviceActivityViewStore, t }) {
    const { analyticsTimeRangeViewStore, deviceSelectStore, gridStore } = userDeviceActivityViewStore;
    return (
        <AnalyticsLayout analyticsTimeRangeViewStore={analyticsTimeRangeViewStore} t={t}>
            <MrbPageContentHeader classNameOverride="u-mar--bottom--sml">
                <div className="c-container--sidebar u-padd--none">
                    <div className="row">
                        <div className="col col-sml-12 col-lrg-6 u-mar--bottom--med">
                            <UserAccountSummary userDeviceActivityViewStore={userDeviceActivityViewStore} t={t} />
                        </div>
                        <div className="col col-sml-12 col-lrg-6 u-mar--bottom--med">
                            <UserDeviceActivitySummary
                                userDeviceActivityViewStore={userDeviceActivityViewStore}
                                t={t}
                            />
                        </div>
                    </div>

                    <div className="u-mar--bottom--med">
                        <UserContentListSummary userDeviceActivityViewStore={userDeviceActivityViewStore} t={t} />
                    </div>
                </div>
                <div className="row">
                    <div className="col col-sml-12 col-lrg-4">
                        <MrbSelect store={deviceSelectStore} classNameExtend="c-dropdown--dark c-dropdown--base" />
                    </div>
                </div>
            </MrbPageContentHeader>
            <MrbTable t={t} tableStore={gridStore} className="ag-theme-alpine" emptyStateRender={TableNoDataOverlay} />
        </AnalyticsLayout>
    );
}

UserDeviceActivityTemplate.propTypes = {
    userDeviceActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(UserDeviceActivityTemplate);
