import React from "react";
import { NakiNigunAlbumTrackEditTitleTemplate } from "themes/administration/naki-nigun/components";
import { NakiNigunAlbumTrackEditTitleViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunAlbumTrackEditTitleViewStore(rootStore, componentProps),
    "nakiNigunAlbumTrackEditTitleViewStore"
)
class NakiNigunAlbumTrackEditTitle extends React.Component {
    render() {
        return <NakiNigunAlbumTrackEditTitleTemplate {...this.props} />;
    }
}

export default NakiNigunAlbumTrackEditTitle;
