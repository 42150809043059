import React from "react";
import { NakiNigunAlbumTrackListTemplate } from "themes/administration/naki-nigun/pages";
import { NakiNigunAlbumTrackListViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunAlbumTrackListViewStore(rootStore, componentProps),
    "nakiNigunAlbumTrackListViewStore"
)
class NakiNigunAlbumTrackList extends React.Component {
    render() {
        return <NakiNigunAlbumTrackListTemplate {...this.props} />;
    }
}

export default NakiNigunAlbumTrackList;
