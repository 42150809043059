import React from "react";
import { NakiNigunArtistEditPageHeaderTemplate } from "themes/administration/naki-nigun/components";

class NakiNigunArtistEditPageHeader extends React.Component {
    render() {
        return <NakiNigunArtistEditPageHeaderTemplate {...this.props} />;
    }
}

export default NakiNigunArtistEditPageHeader;
