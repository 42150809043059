import { moduleProviderFactory } from "mrb/core";
import { DeviceMenuEdit } from "administration/device-menu/pages";
import { DeviceMenuEditRouteStore } from "administration/device-menu/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "DeviceMenu",
        routes: [
            {
                name: "master.administration.device-menu",
                pattern: "/device-menu",
                children: [
                    {
                        name: "master.administration.device-menu.edit",
                        pattern: "",
                        component: DeviceMenuEdit,
                        store: DeviceMenuEditRouteStore,
                        data: {
                            title: "Device Menu",
                        },
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.DEVICE",
                order: 8,
                icon: "devices",
                subMenu: [
                    {
                        title: "ADMINISTRATION.MENU.DEVICE_MENU",
                        order: 1,
                        route: "master.administration.device-menu.edit",
                    },
                ],
            },
        ],
    });
})();
