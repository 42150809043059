import React from "react";
import { PropTypes } from "prop-types";
import { CoverImageUpload, FieldRichTextEditor } from "common/components";
import { observer } from "mobx-react";

function StationPromoEditTemplate({ stationPromoEditViewStore, t }) {
    const { form, promoImageUploadViewStore } = stationPromoEditViewStore;
    return (
        <div className="row">
            <div className="col col-sml-12 col-lrg-4 col-xxlrg-5 col-huge-3">
                <div className="c-upload c-upload--sml c-upload--secondary">
                    <CoverImageUpload
                        classNameExtendUpload="c-upload__upload--sml"
                        coverImageUploadViewStore={promoImageUploadViewStore}
                        showInfo={false}
                    />
                </div>
            </div>

            <div className="col col-sml-12 col-lrg-8 col-xxlrg-7 col-huge-9">
                <FieldRichTextEditor field={form.$("stationPromo.description")} t={t} />
            </div>
        </div>
    );
}

StationPromoEditTemplate.propTypes = {
    stationPromoEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default observer(StationPromoEditTemplate);
