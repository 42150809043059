import React from "react";
import { observer } from "mobx-react";
import { ContentStatsTemplate } from "themes/administration/analytics/pages";
import { ContentStatsViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new ContentStatsViewStore(rootStore, componentProps),
    "contentStatsViewStore"
)
@observer
class ContentStats extends React.Component {
    render() {
        return <ContentStatsTemplate {...this.props} />;
    }
}

export default ContentStats;
