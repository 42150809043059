import React from "react";
import { PodcastCoverImageUploadTemplate } from "themes/administration/podcast/components";

class PodcastCoverImageUpload extends React.Component {
    render() {
        return <PodcastCoverImageUploadTemplate {...this.props} />;
    }
}

export default PodcastCoverImageUpload;
