import { action, makeObservable, observable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";
import { find } from "lodash";

class FeaturedSectionViewStore extends MrbBaseViewStore {
    @observable selectedTab = { value: 1, title: "mobile" };

    tabs = [
        { value: 1, title: "mobile" },
        { value: 2, title: "mobile_embedded" },
    ];

    constructor(rootStore, { routeStore }) {
        super(rootStore);
        this.routeStore = routeStore;
        makeObservable(this);
    }

    async onInit() {
        var currentRoute = this.rootStore.routerStore.getCurrentRoute();
        if (currentRoute.name === "master.administration.naki-mobile.featured-section.mobile") {
            this.setSelectedTab(1);
        } else if (currentRoute.name === "master.administration.naki-mobile.featured-section.mobile-embedded") {
            this.setSelectedTab(2);
        }
    }

    onViewUpdate(prevProps, newProps) {
        var currentRoute = this.rootStore.routerStore.getCurrentRoute();
        if (
            this.selectedTab.value === 2 &&
            currentRoute.name === "master.administration.naki-mobile.featured-section.mobile"
        ) {
            this.setSelectedTab(1);
        }
    }
    @action.bound
    setSelectedTab(value) {
        this.selectedTab = find(this.tabs, { value: value });
    }
}

export default FeaturedSectionViewStore;
