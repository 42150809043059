import { moduleProviderFactory } from "mrb/core";
import {
    PodcastEdit,
    PodcastList,
    PodcastGroupPreview,
    PodcastPreview,
    PodcastGroupEdit,
    PodcastEpisodeList,
    PodcastEpisodeEdit,
} from "administration/podcast/pages";
import {
    PodcastEditRouteStore,
    PodcastListRouteStore,
    PodcastGroupPreviewRouteStore,
    PodcastPreviewRouteStore,
    PodcastGroupEditRouteStore,
    PodcastEpisodeListRouteStore,
    PodcastEpisodeEditRouteStore,
} from "administration/podcast/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "Podcast",
        routes: [
            {
                name: "master.administration.podcast",
                pattern: "/podcast",
                children: [
                    {
                        name: "master.administration.podcast.create",
                        pattern: "/create",
                        component: PodcastEdit,
                        store: PodcastEditRouteStore,
                    },
                    {
                        name: "master.administration.podcast.create-group",
                        pattern: "/create-group",
                        component: PodcastGroupEdit,
                        store: PodcastGroupEditRouteStore,
                    },
                    {
                        name: "master.administration.podcast.edit-group",
                        pattern: "/:id/edit-group",
                        component: PodcastGroupEdit,
                        store: PodcastGroupEditRouteStore,
                    },
                    {
                        name: "master.administration.podcast.edit",
                        pattern: "/:id/edit",
                        component: PodcastEdit,
                        store: PodcastEditRouteStore,
                    },
                    {
                        name: "master.administration.podcast.list",
                        pattern: "",
                        component: PodcastList,
                        store: PodcastListRouteStore,
                        data: {
                            title: "ADMINISTRATION.PODCAST.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.administration.podcast.preview-group",
                        pattern: "/:id/group",
                        component: PodcastGroupPreview,
                        store: PodcastGroupPreviewRouteStore,
                    },
                    {
                        name: "master.administration.podcast.preview",
                        pattern: "/:id",
                        component: PodcastPreview,
                        store: PodcastPreviewRouteStore,
                    },
                    {
                        name: "master.administration.podcast.episodes",
                        pattern: "/:id/episodes",
                        component: PodcastEpisodeList,
                        store: PodcastEpisodeListRouteStore,
                    },
                    {
                        name: "master.administration.podcast.episodes.create",
                        pattern: "/:id/episodes/create",
                        component: PodcastEpisodeEdit,
                        store: PodcastEpisodeEditRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.PODCAST",
                order: 3,
                icon: "podcasts",
                route: "master.administration.podcast.list",
            },
        ],
    });
})();
