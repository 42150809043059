import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { mergeCss } from "mrb/common/utils";
import { Image } from "common/components";

function GenreContentListItemTemplate({ item }) {
    return (
        <div className="u-animation--fadeIn c-analytics__list__item c-analytics__list__item--secondary">
            <div className="c-analytics__list__img">
                <Image src={item.coverImageUrl} />
            </div>
            <div className="c-analytics__list__information">
                <div className="u-display--flex--1">
                    <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between">
                        <div className="u-display--flex u-display--flex--ai--center">
                            {item.routeParams ? (
                                <MrbRouterLink
                                    className={"c-analytics__list__title c-analytics__list__title--sml"}
                                    routeName={item.routeParams.route}
                                    params={item.routeParams.params}
                                    queryParams={item.routeParams.queryParams}
                                    title={item.title}
                                >
                                    {item.title}
                                </MrbRouterLink>
                            ) : (
                                <span className="u-type--base">{item.title || "Unknown"}</span>
                            )}
                            {item.isPremium ? (
                                <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny" />
                            ) : null}
                        </div>
                        <p
                            className={mergeCss(
                                "c-shuffle__status",
                                `c-shuffle__status--${item.isActive ? "active" : "inactive"}`
                            )}
                        >
                            <i
                                className={`u-icon u-icon--med u-icon--circle--${
                                    item.isActive ? "active" : "inactive"
                                } u-mar--right--micro`}
                            ></i>
                            {item.isActive ? "Active" : "Inactive"}
                        </p>
                    </div>
                    <div className="u-type--sml u-type--color--opaque">{item.type}</div>
                </div>
            </div>
        </div>
    );
}

GenreContentListItemTemplate.propTypes = {
    item: PropTypes.object.isRequired,
};

export default GenreContentListItemTemplate;
