import React from "react";
import PropTypes from "prop-types";
import { Accordion, MainSidebar } from "common/components";
import { MrbProgressBarLoader } from "mrb/components/loader";
import { MrbConfirmDialog } from "mrb/components/modal";
import { mergeCss } from "mrb/common/utils";
import { defaultTemplate } from "common/hoc";
import { MrbRadioButtons } from "baasic-react-bootstrap/src";
import { observer } from "mobx-react";
import DownloadItem from "./components/DownloadItem";
import DownloadList from "./components/DownloadList";

function AdministrationLayoutTemplate({ renderChildRoute, routeStore, t }) {
    const { rootStore } = routeStore;
    const { menuStore, downloadManagerStore } = rootStore;

    return (
        <React.Fragment>
            <div className={mergeCss("l__admin", menuStore.isOpen ? "is-open" : null)}>
                <MrbProgressBarLoader loaderStore={rootStore.mainLoaderStore} />
                <MainSidebar />
                <div className="l__admin--main">{renderChildRoute()}</div>
                <MrbConfirmDialog store={rootStore.confirmDialogStore} t={t} />
                {downloadManagerStore.items.length > 0 && (
                    <DownloadManager downloadManagerStore={downloadManagerStore} t={t} />
                )}
            </div>
        </React.Fragment>
    );
}

AdministrationLayoutTemplate.propTypes = {
    renderChildRoute: PropTypes.func.isRequired,
    routeStore: PropTypes.object.isRequired,
};

const DownloadManager = observer(function DownloadManager({ downloadManagerStore, t }) {
    const {
        currentlyDownloadingItem,
        items,
        queuedItems,
        canceledItems,
        failedItems,
        finishedItems,
        selectedViewValue,
        setSelectedViewValue,
        downloadStatus,
        closeWindow,
    } = downloadManagerStore;

    const downloadManagerStyle = {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        minWidth: "400px",
        zIndex: 9,
    };

    function getNumberOfRemainingItems() {
        var remaining = 0;
        if (queuedItems != null && queuedItems.length > 0) {
            remaining += queuedItems.length;
        }
        if (currentlyDownloadingItem != null) {
            remaining += 1;
        }
        return remaining;
    }

    function getDisplayedItems() {
        switch (selectedViewValue) {
            case 1:
                return items;
            case 2:
                return queuedItems;
            case 3:
                return finishedItems;
            case 4:
                return canceledItems;
            case 5:
                return failedItems;
            default:
                return items;
        }
    }

    function getNoContentMessage() {
        switch (selectedViewValue) {
            case 1:
                return t("ADMINISTRATION.DOWNLOAD_MANAGER.EMPTY_STATE.ALL_ITEMS");
            case 2:
                return t("ADMINISTRATION.DOWNLOAD_MANAGER.EMPTY_STATE.IN_PROGRESS");
            case 3:
                return t("ADMINISTRATION.DOWNLOAD_MANAGER.EMPTY_STATE.FINISHED");
            case 4:
                return t("ADMINISTRATION.DOWNLOAD_MANAGER.EMPTY_STATE.CANCELED");
            case 5:
                return t("ADMINISTRATION.DOWNLOAD_MANAGER.EMPTY_STATE.FAILED");
            default:
                return t("ADMINISTRATION.DOWNLOAD_MANAGER.EMPTY_STATE.ALL_ITEMS");
        }
    }

    const itemsToDownload = getDisplayedItems();
    const noContentMessage = getNoContentMessage();

    const handleCancel = (index) => {
        downloadManagerStore.cancelDownload(index);
    };

    const handleRetry = (index) => {
        downloadManagerStore.retryDownload(index);
    };

    return (
        <Accordion
            className="c-accordion--dark"
            title={`${getNumberOfRemainingItems()} ${t("ADMINISTRATION.DOWNLOAD_MANAGER.ACCORDION_TITLE")}`}
            customStyle={downloadManagerStyle}
            handleRemoveCard={closeWindow}
            additionalContent={
                <div className="u-mar--bottom--med">
                    <MrbRadioButtons
                        id={`downloadView`}
                        name={`downloadView`}
                        options={[
                            {
                                value: 1,
                                label: `${t("ADMINISTRATION.DOWNLOAD_MANAGER.TABS.ALL_ITEMS")} (${items.length})`,
                            },
                            {
                                value: 2,
                                label: `${t("ADMINISTRATION.DOWNLOAD_MANAGER.TABS.IN_PROGRESS")} (${
                                    queuedItems.length + (currentlyDownloadingItem != null ? 1 : 0)
                                })`,
                            },
                            {
                                value: 3,
                                label: `${t("ADMINISTRATION.DOWNLOAD_MANAGER.TABS.FINISHED")} (${
                                    finishedItems.length
                                })`,
                            },
                            {
                                value: 4,
                                label: `${t("ADMINISTRATION.DOWNLOAD_MANAGER.TABS.CANCELED")} (${
                                    canceledItems.length
                                })`,
                            },
                            {
                                value: 5,
                                label: `${t("ADMINISTRATION.DOWNLOAD_MANAGER.TABS.FAILED")} (${failedItems.length})`,
                            },
                        ]}
                        t={t}
                        inputClassNameExtend="c-segmented--secondary "
                        value={selectedViewValue}
                        onChange={(value) => setSelectedViewValue(value)}
                    />
                </div>
            }
            showClose
        >
            {selectedViewValue === 2 ? (
                <React.Fragment>
                    {currentlyDownloadingItem && (
                        <DownloadItem
                            item={currentlyDownloadingItem}
                            index={0}
                            onCancel={handleCancel}
                            onRetry={handleRetry}
                            downloadStatus={downloadStatus}
                            t={t}
                        />
                    )}

                    <DownloadList
                        items={itemsToDownload}
                        isCurrentlyDownloading={currentlyDownloadingItem != null}
                        startingIndex={1}
                        onCancel={handleCancel}
                        onRetry={handleRetry}
                        noContentMessage={noContentMessage}
                        downloadStatus={downloadStatus}
                        t={t}
                    />
                </React.Fragment>
            ) : (
                <DownloadList
                    items={itemsToDownload}
                    onCancel={handleCancel}
                    onRetry={handleRetry}
                    noContentMessage={noContentMessage}
                    downloadStatus={downloadStatus}
                    t={t}
                />
            )}
        </Accordion>
    );
});

export default defaultTemplate(AdministrationLayoutTemplate);
