import React from "react";
import { observer } from "mobx-react";
import { AddPodcastGroupItemTemplate } from "themes/administration/podcast/components";
import { AddPodcastGroupItemViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new AddPodcastGroupItemViewStore(rootStore, componentProps),
    "addPodcastGroupItemViewStore"
)
@observer
class AddPodcastGroupItem extends React.Component {
    render() {
        return <AddPodcastGroupItemTemplate {...this.props} />;
    }
}

export default AddPodcastGroupItem;
