import React from "react";
import { NakiNigunAlbumTrackTemplate } from "themes/administration/naki-nigun/components";

class NakiNigunAlbumTrack extends React.Component {
    render() {
        return <NakiNigunAlbumTrackTemplate {...this.props} />;
    }
}

export default NakiNigunAlbumTrack;
