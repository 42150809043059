import React from "react";
import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";

class PodcastPreviewPodcastVolumeViewStore extends MrbBaseViewStore {
    @observable isCollapsed = false;
    @observable podcastVolume = null;
    panelRef = React.createRef();

    @computed get coverImageUrl() {
        if (this.podcastVolume && this.podcastVolume.podcastFileEntryId) {
            return this.routeStore.createPodcastCoverImageUrl(this.podcastVolume.podcastFileEntryId, 150, 150);
        }

        return null;
    }

    constructor(rootStore, { podcastPreviewViewStore, podcastVolume }) {
        super(rootStore);
        makeObservable(this);
        this.podcastPreviewViewStore = podcastPreviewViewStore;
        this.routeStore = podcastPreviewViewStore.routeStore;
        this.podcastVolume = podcastVolume;

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                console.log(isCollapsed, this.panelRef);
                if (isCollapsed && this.panelRef) {
                    // We need to execute scroll function asynchronously in order to take effect.
                    setTimeout(() => {
                        this.panelRef.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    });
                }
            }
        );

    }

    @action.bound
    onInit() {
        const podcastVolumeId = this.rootStore.routerStore.routerState.queryParams.podcastVolumeId;
        if (podcastVolumeId && this.podcastVolume.id === podcastVolumeId) {
            this.isCollapsed = true;
        }
        return Promise.resolve();
    }

    @action.bound
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    onClickEdit = () => {
        this.rootStore.routerStore.goTo(
            "master.administration.podcast.edit",
            { id: this.podcastVolume.podcastId },
            { podcastVolumeId: this.podcastVolume.id }
        );
    };

    setPanelRef = (ref) => {
        this.panelRef = ref;
    };
}

export default PodcastPreviewPodcastVolumeViewStore;
