import { LocalizedForm } from "common/localization";

export default class PodcastGroupEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "title",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.TITLE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.TITLE_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "alternativeDescription",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PORTAL_DESCRIPTION_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PORTAL_DESCRIPTION_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "premiumInfo",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PREMIUM_INFO_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PREMIUM_INFO_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "descriptionLink",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.DESCRIPTION_LINK_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.DESCRIPTION_LINK_PLACEHOLDER",
                    rules: "string|url",
                },
                {
                    name: "isActive",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_ACTIVE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_ACTIVE_PLACEHOLDER",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "isFeatured",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_FEATURED_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_FEATURED_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isPremium",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_PREMIUM_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_PREMIUM_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isVisible",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_PLACEHOLDER",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "isVisibleInApp",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_IN_APP",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_IN_APP",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "isVisibleInEmbeddedApp",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_IN_EMBEDDED_APP",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_IN_EMBEDDED_APP",
                    type: "checkbox",
                    value: true,
                },
            ],
        };
    }
}
