import React from "react";
import { ShuffleEditStationItemTemplate } from "themes/administration/shuffle/components";

class ShuffleEditStationItem extends React.Component {
    render() {
        return <ShuffleEditStationItemTemplate {...this.props} />;
    }
}

export default ShuffleEditStationItem;
