import React from "react";
import { AlbumTrackContentExplorerTemplate } from "themes/administration/content-explorer";
import { AlbumTrackContentExplorerViewStore } from "administration/content-explorer/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView(
    (rootStore, componentProps) => new AlbumTrackContentExplorerViewStore(rootStore, componentProps),
    "albumTrackContentExplorerViewStore"
)
@observer
class AlbumTrackContentExplorer extends React.Component {
    render() {
        return <AlbumTrackContentExplorerTemplate {...this.props} />;
    }
}

export default AlbumTrackContentExplorer;
