import React from "react";
import { PropTypes } from "prop-types";
import { PodcastGroupPreviewSubPodcast, PodcastGroupLineLoader } from "administration/podcast/components";
import { MrbContentLoader } from "mrb/components/loader";
import { map } from "lodash";
import { observer } from "mobx-react";
function PodcastGroupPreviewSubPodcastListTemplate({ podcastGroupPreviewViewStore, t }) {
    const { item, loaderStore } = podcastGroupPreviewViewStore;

    return (
        <div className="c-container--content--main u-padd--top--none">
            <div>
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <PodcastGroupLineLoader />}>
                    {() => (
                        <div className="u-animation--fadeIn">
                            {map(item.subPodcasts, (subPodcast, key) => (
                                <PodcastGroupPreviewSubPodcast
                                    key={key}
                                    podcastGroupPreviewViewStore={podcastGroupPreviewViewStore}
                                    podcast={subPodcast}
                                    t={t}
                                />
                            ))}
                        </div>
                    )}
                </MrbContentLoader>
            </div>
        </div>
    );
}

PodcastGroupPreviewSubPodcastListTemplate.propTypes = {
    podcastGroupPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(PodcastGroupPreviewSubPodcastListTemplate);
