import React from "react";
import { PodcastAnalyticsStatsWidgetTemplate } from "themes/administration/podcast/components";
import { PodcastAnalyticsStatsWidgetViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastAnalyticsStatsWidgetViewStore(rootStore, componentProps),
    "podcastAnalyticsStatsWidgetViewStore"
)
class PodcastAnalyticsStatsWidget extends React.Component {
    render() {
        return <PodcastAnalyticsStatsWidgetTemplate {...this.props} />;
    }
}

export default PodcastAnalyticsStatsWidget;