import { MrbBaseRouteStore } from "mrb/core";
import { OrderService } from "common/services";

class OrderListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(OrderService);
    }

    async findOrders(params) {
        const response = await this.service.find(params);
        return response.data;
    }
}

export default OrderListRouteStore;
