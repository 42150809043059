import React from "react";
import { StationPromoEditLoaderTemplate } from "themes/administration/station/components";

class StationPromoEditLoader extends React.Component {
    render() {
        return <StationPromoEditLoaderTemplate {...this.props} />;
    }
}

export default StationPromoEditLoader;
