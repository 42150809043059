import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditPage, MrbFieldInput } from "mrb/components";
import { MrbSelect } from "mrb/components/select";
import { MrbButton } from "mrb/components/button";
import { MrbContentLoader } from "mrb/components/loader";
import {
    ShuffleEditItems,
    ShuffleEditStationItem,
} from "administration/shuffle/components";
import { defaultTemplate } from "common/hoc";
import { ShuffleCreateLoader } from "administration/shuffle/components";

function StationShuffleEditTemplate({ stationShuffleEditViewStore, t }) {
    const {
        form,
        loaderStore,
        shuffleItemSelectStore,
        addStationShuffleItem,
        isAddDisabled,
    } = stationShuffleEditViewStore;

    return (
        <MrbEditPage store={stationShuffleEditViewStore} t={t}>
            <div
                className={`c-container--base ${
                    loaderStore.loading ? "c-card--primary" : ""
                }`}
            >
                <MrbContentLoader
                    loaderStore={loaderStore}
                    loaderRender={() => <ShuffleCreateLoader />}
                >
                    {() => (
                        <React.Fragment>
                            <div className="c-card--primary c-card--med u-mar--bottom--sml">
                                <div className="u-animation--fadeIn">
                                    <div className="u-mar--bottom--sml">
                                        <MrbFieldInput
                                            field={form.$("name")}
                                            t={t}
                                            classNameExtend="c-input--outline"
                                        />
                                    </div>

                                    <div className="c-shuffle__search">
                                        <div className="c-shuffle__search__input">
                                            <MrbSelect
                                                store={shuffleItemSelectStore}
                                                classNameExtend="c-dropdown--dark c-dropdown--base"
                                            />
                                        </div>
                                        <MrbButton
                                            t={t}
                                            label="Add"
                                            classNameOverride="c-btn c-btn--base c-btn--primary"
                                            onClick={addStationShuffleItem}
                                            disabled={isAddDisabled}
                                        />
                                    </div>
                                </div>
                            </div>

                            <ShuffleEditItems
                                shuffleEditViewStore={
                                    stationShuffleEditViewStore
                                }
                                t={t}
                                itemRender={(props) => (
                                    <ShuffleEditStationItem {...props} />
                                )}
                            />
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </MrbEditPage>
    );
}

StationShuffleEditTemplate.propTypes = {
    stationShuffleEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(StationShuffleEditTemplate);
