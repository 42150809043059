import { action, computed, makeObservable, observable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { map, filter, startsWith, findIndex, isNil, take } from "lodash";

class DeviceSearchFilter {
    @observable search = "";

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setSearch(search) {
        this.search = search;
    }
}

class SearchResult {
    @observable.ref items = [];
    @observable position = 0;
    totalResults = 0;

    @computed get index() {
        const item = this.items[this.position];
        if (item) {
            return item.index;
        }
        return -1;
    }

    searchTerm = "";

    constructor(searchTerm, items, totalResults, focusedItemId = null) {
        makeObservable(this);
        this.setItems(items);
        this.searchTerm = searchTerm;
        this.totalResults = totalResults;
        this.setFocusedItem(focusedItemId);
    }

    @action.bound
    setItems(items) {
        this.items = items;
    }

    @action.bound
    nextItem() {
        if (this.position === this.items.length - 1) {
            this.setPosition(0);
        } else {
            this.setPosition(this.position + 1);
        }
    }

    @action.bound
    previousItem() {
        if (this.position === 0) {
            this.setPosition(this.items.length - 1);
        } else {
            this.setPosition(this.position - 1);
        }
    }

    @action.bound
    setPosition(position) {
        this.position = position;
    }

    setFocusedItem(itemId) {
        if (itemId) {
            const index = findIndex(this.items, (item) => item.id === itemId);
            if (index !== -1) {
                this.setPosition(index);
            }
        }
    }
}

class DeviceMenuSearchViewStore extends MrbBaseViewStore {
    @observable searchTerm = "";
    @observable focusedItemId = null;

    deviceSearchFilter = new DeviceSearchFilter();

    searchContainerRef = null;

    @computed get searchResult() {
        if (this.searchTerm) {
            const results = filter(
                this.deviceMenuViewStore.deviceMenu,
                (item) =>
                    item.title &&
                    startsWith(item.title.toLowerCase(), this.searchTerm)
            );
            return new SearchResult(
                this.searchTerm,
                map(take(results, 10), (item) => {
                    return {
                        ...item,
                        index: findIndex(
                            this.deviceMenuViewStore.deviceMenu,
                            (menuItem) => menuItem.id === item.id
                        ),
                    };
                }),
                results.length,
                this.focusedItemId
            );
        }
        return null;
    }

    constructor(rootStore, deviceMenuViewStore) {
        super(rootStore);
        makeObservable(this);
        this.deviceMenuViewStore = deviceMenuViewStore;

        this.reaction(
            () => this.searchResult && this.searchResult.index,
            (index) => this.deviceMenuViewStore.scrollToItem(index)
        );

        this.reaction(
            () => this.searchResult && this.searchResult.position,
            (index) => {
                this.scrollSearchResultIntoView(index);
                this.setFocusedItemId(index);
            }
        );

        this.reaction(
            () => this.deviceSearchFilter.search.trim().toLowerCase(),
            (searchTerm) => {
                this.setSearchTerm(searchTerm);
            },
            {
                delay: 200,
            }
        );
    }

    onInit() {
        document.addEventListener("keydown", this.toggleSearchPosition);
        return Promise.resolve();
    }

    @action.bound
    setSearchTerm(searchTerm) {
        this.searchTerm = searchTerm;
    }

    setSearchContainerRef = (ref) => {
        this.searchContainerRef = ref;
    };

    @action.bound
    setFocusedItemId(index) {
        if (!isNil(index) && this.searchResult) {
            const item = this.searchResult.items[index];
            if (item) {
                this.focusedItemId = item.id;
            }
        } else {
            this.focusedItemId = null;
        }
    }

    scrollSearchResultIntoView = (index) => {
        if (this.searchContainerRef) {
            const children = this.searchContainerRef.children;
            const element = children[index];
            if (element) {
                element.scrollIntoView({
                    block: "center",
                });
            }
        }
    };

    toggleSearchPosition = (event) => {
        if (this.searchResult) {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                event.stopPropagation();
                this.searchResult.nextItem();
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                event.stopPropagation();
                this.searchResult.previousItem();
            }
        }
    };

    scrollToItem = (index) => {
        this.deviceMenuViewStore.scrollToItem(index);
    };

    onDestroy() {
        document.removeEventListener("keydown", this.toggleSearchPosition);
    }
}

export default DeviceMenuSearchViewStore;
