import React from "react";
import { OrderInfoLoaderTemplate } from "themes/administration/order/components";

class OrderInfoLoader extends React.Component {
    render() {
        return <OrderInfoLoaderTemplate {...this.props} />;
    }
}

export default OrderInfoLoader;
