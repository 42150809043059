import React from "react";
import { Progressbar } from "common/components";
import { MrbButton } from "mrb/components/button";

export const DownloadItem = ({ item, index, onCancel, onRetry, downloadStatus, t }) => {
    return (
        <div className="u-display--flex u-gap--base">
            <p className="u-type--sml">{index + 1}.</p>
            <Progressbar
                displayValue={item.title && item.title.trim() ? item.title : "No title"}
                value={item.downloadProgress}
                total={100}
                status={item.status}
            />

            {item.status !== downloadStatus.finished && <div className="u-separator--vertical"></div>}

            {(item.status === downloadStatus.new || item.status === downloadStatus.downloading) && (
                <MrbButton
                    onClick={() => onCancel(item.index)}
                    classNameOverride="c-btn c-btn--icon"
                    onlyIcon
                    icon="u-icon u-icon--med u-icon--delete"
                    title={t("ADMINISTRATION.DOWNLOAD_MANAGER.BUTTONS.CANCEL")}
                />
            )}
            {(item.status === downloadStatus.canceled || item.status === downloadStatus.failed) && (
                <MrbButton
                    onClick={() => onRetry(item.index)}
                    classNameOverride="c-btn c-btn--icon"
                    onlyIcon
                    icon="u-icon u-icon--med u-icon--retry"
                    title={t("ADMINISTRATION.DOWNLOAD_MANAGER.BUTTONS.RETRY")}
                />
            )}
        </div>
    );
};

export default DownloadItem;
