import { BaseShuffleEditRouteStore } from "administration/shuffle/stores";
import { StationService } from "common/services";

class StationShuffleEditRouteStore extends BaseShuffleEditRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.stationService = this.rootStore.createApplicationService(
            StationService
        );
    }

    async getStationShuffle(id) {
        const response = await this.service.get(id, {
            embed: "shuffleItems,shuffleItems.station",
        });
        return response.data;
    }

    async createStationShuffle(resource) {
        const response = await this.service.createStation(resource);
        return response.data;
    }

    async updateStationShuffle(resource) {
        const response = await this.service.update(resource);
        return response.data;
    }

    async findShufflableStation(params) {
        const response = await this.stationService.findShufflableStation(
            params
        );
        return response.data;
    }
}

export default StationShuffleEditRouteStore;
