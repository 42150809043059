import React from "react";
import { observer } from "mobx-react";
import { AdminProducerListTemplate } from "themes/administration/producer/pages";
import { AdminProducerListViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminProducerListViewStore(rootStore, componentProps),
    "adminProducerListViewStore"
)
@observer
class AdminProducerList extends React.Component {
    render() {
        return <AdminProducerListTemplate {...this.props} />;
    }
}

export default AdminProducerList;
