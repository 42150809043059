import React from "react";
import { PodcastEpisodeEditTitleModalTemplate } from "themes/administration/podcast/components";

class PodcastEpisodeEditTitleModal extends React.Component {
    render() {
        return <PodcastEpisodeEditTitleModalTemplate {...this.props} />;
    }
}

export default PodcastEpisodeEditTitleModal;
