import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbFieldTextArea, MrbFieldToggle, MrbEditPage, MrbFieldSelect } from "mrb/components";
import { StationCoverImageUpload, StationPromoEdit, StationPromoEditLoader } from "administration/station/components";
import { MrbFileUploadValidation } from "mrb/components/media";
import { MrbContentLoader } from "mrb/components/loader";
import { CoverImageUploadLoader, AddStationsOrPodcastsLoader, FieldRichTextEditor } from "common/components";
import { defaultTemplate } from "common/hoc";

function StationEditTemplate({ stationEditViewStore, t }) {
    const {
        form,
        coverImageUploadViewStore,
        loaderStore,
        locationSelectStore,
        languageSelectStore,
        genreSelectStore,
        stationPromoEditViewStore,
        overrideRadioUsersSettingsIsVisible,
        overrideAppUsersSettingsIsVisible,
    } = stationEditViewStore;
    const { fileUploadStore } = coverImageUploadViewStore;

    return (
        <MrbEditPage store={stationEditViewStore} t={t}>
            <div className="row">
                <div className="col col-sml-12 col-xxlrg-7 col-huge-8 u-mar--top--sml u-mar--bottom--sml">
                    <div className="c-card--primary">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <AddStationsOrPodcastsLoader />}
                        >
                            {() => (
                                <div className="u-animation--fadeIn">
                                    <div className="row">
                                        <div className="col col-sml-12 col-lrg-7 col-xxlrg-12 col-huge-7">
                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("title")}
                                                classNameExtend="c-input--outline"
                                            />

                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("streamUrl")}
                                                classNameExtend="c-input--outline"
                                            />
                                            <MrbFieldSelect
                                                t={t}
                                                store={locationSelectStore}
                                                classNameExtend="c-dropdown--dark"
                                            />
                                            <MrbFieldSelect
                                                t={t}
                                                store={languageSelectStore}
                                                classNameExtend="c-dropdown--dark"
                                            />
                                            <MrbFieldSelect
                                                t={t}
                                                store={genreSelectStore}
                                                classNameExtend="c-dropdown--dark"
                                            />

                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("keywords")}
                                                classNameExtend="c-input--outline"
                                            />
                                            <MrbFieldInput
                                                t={t}
                                                field={form.$("descriptionLink")}
                                                classNameExtend="c-input--outline"
                                            />
                                            <MrbFieldTextArea
                                                t={t}
                                                rows={4}
                                                field={form.$("description")}
                                                classNameExtend="c-input--outline"
                                            />

                                            <FieldRichTextEditor field={form.$("alternativeDescription")} t={t} />

                                            {form.$("isPremium").value ? (
                                                <div className="col col-sml-12">
                                                    <FieldRichTextEditor field={form.$("premiumInfo")} t={t} />
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="col col-sml-12 col-lrg-5 col-xxlrg-12 col-huge-5">
                                            <div className="c-toggle__card-list">
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isActive")}
                                                />
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isPremium")}
                                                />
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isTurnedOnByDefault")}
                                                />

                                                <div className="u-separator--primary u-mar--none"></div>
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isVisibleInApp")}
                                                />

                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isTurnedOnByDefaultInApp")}
                                                />

                                                <div className="u-separator--primary u-mar--none"></div>

                                                {overrideRadioUsersSettingsIsVisible ? (
                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("overrideRadioUsersSettings")}
                                                    />
                                                ) : null}
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isVisible")}
                                                />

                                                {overrideAppUsersSettingsIsVisible ? (
                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("overrideAppUsersSettings")}
                                                    />
                                                ) : null}
                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isFeatured")}
                                                />
                                                <div className="u-separator--primary u-mar--none"></div>

                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isVisibleInEmbeddedApp")}
                                                />

                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("isTurnedOnByDefaultInEmbeddedApp")}
                                                />

                                                <div className="u-separator--primary u-mar--none"></div>

                                                <MrbFieldToggle
                                                    classNameExtend="c-toggle__item--inline"
                                                    t={t}
                                                    field={form.$("excludedFromRevenue")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </MrbContentLoader>
                    </div>
                </div>

                <div className="col col-sml-12 col-xxlrg-4 u-mar--top--sml u-mar--bottom--sml">
                    <div className="c-card--primary">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <CoverImageUploadLoader />}>
                            {() => (
                                <StationCoverImageUpload
                                    classNameExtendUpload="c-upload--tny"
                                    isFullWidthFooterButtons
                                    stationCoverImageUploadViewStore={coverImageUploadViewStore}
                                />
                            )}
                        </MrbContentLoader>
                    </div>
                    <MrbFileUploadValidation store={fileUploadStore} />
                </div>

                <div className="col col-sml-12 col-xxlrg-8 u-mar--top--sml">
                    <div>
                        <h1 className="u-type--base u-mar--bottom--sml">Mobile App Promo Widget</h1>

                        <div className="c-card--primary">
                            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <StationPromoEditLoader />}>
                                {() => <StationPromoEdit stationPromoEditViewStore={stationPromoEditViewStore} t={t} />}
                            </MrbContentLoader>
                        </div>
                    </div>
                </div>
            </div>
        </MrbEditPage>
    );
}

StationEditTemplate.propTypes = {
    stationEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(StationEditTemplate);
