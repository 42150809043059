import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunAlbumService, NakiNigunAlbumFileStreamService, MediaVaultStreamService } from "common/services";
import moment from "moment";

class NakiNigunAlbumTrackEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(NakiNigunAlbumService);
        this.mediaVaultStreamService = new MediaVaultStreamService(
            this.rootStore.apiClient,
            this.rootStore.application.baasic
        );
        this.nakiNigunAlbumFileStreamService = this.rootStore.createApplicationService(NakiNigunAlbumFileStreamService);
    }

    async uploadAlbumTrack(albumId, formData) {
        await this.service.uploadAlbumTrack(albumId, formData);
    }

    getMediaVaultFileUrl(id) {
        return this.mediaVaultStreamService.mediaVaultModule.streams.routeDefinition.get(id);
    }

    async uploadImageFile(file) {
        const now = moment();
        const [name, extension] = file.name.split(".");
        const path = `${name}_${now.year()}-${now.month()}-${now.date()}-${now.hour()}-${now.minute()}-${now.second()}.${extension}`;
        const response = await this.mediaVaultStreamService.create(
            {
                path: path,
                fileName: file.name,
            },
            file
        );
        return response.data;
    }

    getNakiNigunAlbumCoverImageUrl(coverImageId) {
        return this.nakiNigunAlbumFileStreamService.routeService.get(coverImageId);
    }

    async getNakiNigunAlbum(albumid) {
        const response = await this.service.get(albumid);
        return response.data;
    }
}

export default NakiNigunAlbumTrackEditRouteStore;
