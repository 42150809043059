import React from "react";
import { PropTypes } from "prop-types";
import { map } from "lodash";
import { observer } from "mobx-react";

function ShuffleEditItemsTemplate({ shuffleEditViewStore, t, itemRender }) {
    const { items } = shuffleEditViewStore;
    if (!items || items.length === 0) {
        return null;
    }

    return (
        <div className="c-card--primary c-card--med">
            <div className="u-animation--fadeIn">
                {map(items, (item) => (
                    <React.Fragment key={item.id}>
                        {itemRender({ item, t, store: shuffleEditViewStore })}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

ShuffleEditItemsTemplate.propTypes = {
    shuffleEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    itemRender: PropTypes.func,
};

export default observer(ShuffleEditItemsTemplate);
