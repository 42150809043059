import React from "react";
import { PropTypes } from "prop-types";
import { MrbListPage, MrbPageContentHeader } from "mrb/components/layout";
import { MrbTable } from "mrb/components/table";
import { MrbQuerySearchInput } from "mrb/components/input";
import { TableNoDataOverlay } from "common/components";
import { defaultTemplate } from "common/hoc";

function StationListTemplate({ stationListViewStore, t }) {
    const { gridStore, queryUtility } = stationListViewStore;
    return (
        <MrbListPage store={stationListViewStore} t={t}>
            <MrbPageContentHeader classNameOverride="c-container--base u-mar--bottom--sml">
                <MrbQuerySearchInput queryUtility={queryUtility} />
            </MrbPageContentHeader>
            <MrbTable
                t={t}
                tableStore={gridStore}
                className="ag-theme-alpine"
                emptyStateRender={TableNoDataOverlay}
            />
        </MrbListPage>
    );
}

StationListTemplate.propTypes = {
    stationListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(StationListTemplate);
