import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunAlbumFileStreamService, NakiNigunAlbumService, NakiNigunArtistService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class AlbumTrackContentExplorerRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.nakiNigunAlbumService = this.rootStore.createApplicationService(NakiNigunAlbumService);
        this.nakiNigunArtistService = this.rootStore.createApplicationService(NakiNigunArtistService);
        this.nakiNigunAblumFileStreamService = this.rootStore.createApplicationService(NakiNigunAlbumFileStreamService);
    }

    async findAudioTrackAlbumSongs(filter) {
        const response = await this.nakiNigunAlbumService.findAudioTrackAlbumSongs(filter);
        return response.data;
    }

    async findAlbums(filter) {
        const response = await this.nakiNigunAlbumService.findAlbums(filter);
        return response.data;
    }

    async findArtists(filter) {
        const response = await this.nakiNigunArtistService.findArtists(filter);
        return response.data;
    }

    createAlbumTrackAudioFileUrl(albumTrackId) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.nakiNigunAblumFileStreamService.routeService.getAlbumTrackAudioFile(albumTrackId)
        );
    }
}

export default AlbumTrackContentExplorerRouteStore;
