import React from "react";
import { observer } from "mobx-react";
import { UserDeviceListTemplate } from "themes/administration/user/pages";
import { UserDeviceListViewStore } from "administration/user/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new UserDeviceListViewStore(rootStore, componentProps),
    "userDeviceListViewStore"
)
@observer
class UserDeviceList extends React.Component {
    render() {
        return <UserDeviceListTemplate {...this.props} />;
    }
}

export default UserDeviceList;
