import React from "react";
import { PropTypes } from "prop-types";
import { MrbFileUpload, MrbImage } from "mrb/components/media";
import { MrbButton } from "mrb/components/button";
import { MrbContentLoader } from "mrb/components/loader";
import coverPlaceholder from "themes/assets/images/placeholder-template.svg";
import { observer } from "mobx-react";
import { UploadedImageLoader, ImageUploadNote } from "common/components";
import { mergeCss } from "mrb/common/utils";

function PodcastCoverImageUploadTemplate({
    coverImageUploadViewStore,
    classNameExtendUpload,
    showInfo,
    isFullWidthFooterButtons,
}) {
    const { fileUploadStore, imageUrl, loaderStore } = coverImageUploadViewStore;
    return (
        <React.Fragment>
            <MrbContentLoader
                loaderStore={loaderStore}
                loaderRender={() => <UploadedImageLoader size={classNameExtendUpload} />}
            >
                {() => (
                    <MrbFileUpload store={fileUploadStore}>
                        <div className="u-animation--fadeIn">
                            <div className={`c-upload__upload ${classNameExtendUpload ? classNameExtendUpload : ""}`}>
                                {imageUrl && <MrbImage src={imageUrl} fallbackSrc={coverPlaceholder} />}
                                {!imageUrl && <ImageUploadNote showInfo={showInfo} />}
                            </div>
                        </div>
                    </MrbFileUpload>
                )}
            </MrbContentLoader>

            <UploadFooter store={coverImageUploadViewStore} isFullWidthFooterButtons={isFullWidthFooterButtons} />
        </React.Fragment>
    );
}

const UploadFooter = observer(function UploadFooter({ store, isFullWidthFooterButtons }) {
    const { imageUrl, uploadImage, remove, loaderStore } = store;

    return (
        <React.Fragment>
            {imageUrl ? (
                <div className="c-upload__footer">
                    <MrbButton
                        label="Replace"
                        classNameExtend={isFullWidthFooterButtons ? "c-btn--full" : ""}
                        disabled={loaderStore.loading}
                        onClick={uploadImage}
                        aria-busy={loaderStore.loading}
                    />
                    <MrbButton
                        classNameExtend={mergeCss(
                            "c-btn--ghost u-mar--left--tny",
                            isFullWidthFooterButtons ? "c-btn--full" : ""
                        )}
                        label="Remove"
                        onClick={remove}
                        disabled={loaderStore.loading}
                    />
                </div>
            ) : (
                <MrbButton
                    label="Upload image"
                    onClick={uploadImage}
                    disabled={loaderStore.loading}
                    aria-busy={loaderStore.loading}
                    className="c-btn c-btn--primary c-btn--base c-btn--full"
                />
            )}
        </React.Fragment>
    );
});

PodcastCoverImageUploadTemplate.propTypes = {
    coverImageUploadViewStore: PropTypes.object.isRequired,
};

export default observer(PodcastCoverImageUploadTemplate);
