import React from "react";
import { PropTypes } from "prop-types";
import { MrbCollapsiblePanel } from "mrb/components/collapsible-panel";
import { MrbButton } from "mrb/components/button";
import { observer } from "mobx-react";
import { MrbToggle } from "mrb/components/checkbox";
import { PodcastGroupPreviewSubPodcastAnalyticsStats } from "administration/podcast/components";
import { Genres, HTMLRender, Image, ProducerLabel } from "common/components";
import { isNil } from "lodash";

function PodcastGroupPreviewSubPodcastTemplate({ podcastGroupPreviewSubPodcastViewStore, t }) {
    const {
        podcast,
        isCollapsed,
        toggleCollapse,
        toggleIsActive,
        togglePremiumStatus,
        toggleOnByDefaultStatus,
        toggleOnByDefaultInAppStatus,
        toggleOnByDefaultInNakiTouchStatus,
        toggleRecentlyUpdatedVisibilityStatus,
        toggleAppVisibilityStatus,
        toggleNakiTouchVisibilityStatus,
        toggleExcludedFromRevenueStatus,
        onClickEdit,
        setPanelRef,
        coverImageUrl,
        isPremiumDisabled,
        isAppVisibilityDisabled,
        isNakiTouchVisibilityDisabled,
        subPodcastAnalyticsStatsWidgetViewStore,
        onClickPodcastEpisodeList,
        toggleHlsEnabledStatus,
    } = podcastGroupPreviewSubPodcastViewStore;
    const { displayMissingProducers } = podcastGroupPreviewSubPodcastViewStore.podcastGroupPreviewViewStore;

    return (
        <MrbCollapsiblePanel
            title={podcast.title}
            innerRef={setPanelRef}
            t={t}
            isCollapsed={isCollapsed}
            onChangeCollapse={toggleCollapse}
            headerClassNameExtend="u-cursor--pointer"
            headerRender={(props) => (
                <SubPodcastPanelHeader
                    {...props}
                    podcast={podcast}
                    displayMissingProducers={displayMissingProducers}
                    isPremiumDisabled={isPremiumDisabled}
                />
            )}
        >
            {() => (
                <React.Fragment>
                    <div className="u-padd--none">
                        <div className="row">
                            <div className="col col-sml-12 col-lrg-7">
                                <div className="row">
                                    <div className="col col-sml-12 u-mar--bottom--sml">
                                        <div className="c-collapse-panel__body__link">
                                            <p className="c-collapse-panel__body__label">
                                                {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.FEED_URL")}
                                            </p>
                                            <a href={podcast.podcastFeed.feedUrl} target="_blank" rel="noreferrer">
                                                {podcast.podcastFeed.feedUrl}
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col col-sml-6 col-xhuge-4 u-mar--bottom--sml">
                                        <p className="c-collapse-panel__body__label">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.LOCATION")}
                                        </p>
                                        <p className="u-type--base">{podcast.location && podcast.location.name}</p>
                                    </div>

                                    <div className="col col-sml-6 col-xhuge-4 u-mar--bottom--sml">
                                        <p className="c-collapse-panel__body__label">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.LANGUAGE")}
                                        </p>
                                        <p className="u-type--base">
                                            {(podcast.language && podcast.language.name) || "-"}
                                        </p>
                                    </div>

                                    <div className="col col-sml-6 col-xhuge-4 u-mar--bottom--sml">
                                        <p className="c-collapse-panel__body__label">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.GENRES")}
                                        </p>
                                        {podcast.genres && podcast.genres.length > 0 ? (
                                            <Genres genres={podcast.genres} />
                                        ) : (
                                            "-"
                                        )}
                                    </div>

                                    <div className="col col-sml-12 u-mar--bottom--sml">
                                        <p className="c-collapse-panel__body__label">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.DESCRIPTION")}
                                        </p>
                                        <p className="c-collapse-panel__body__description">
                                            {podcast.description || "-"}
                                        </p>
                                    </div>

                                    <div className="col col-sml-12 u-mar--bottom--sml">
                                        <p className="c-collapse-panel__body__label">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.PORTAL_DESCRIPTION")}
                                        </p>
                                        <HTMLRender className="c-collapse-panel__body__description">
                                            {podcast.alternativeDescription || "-"}
                                        </HTMLRender>
                                    </div>

                                    <div className="col col-sml-6 col-xhuge-4 u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.EPISODE_SORT_TYPE")}
                                        </p>
                                        <p className="u-type--base">
                                            {podcast.podcastFeed && podcast.podcastFeed.episodeSortType
                                                ? podcast.podcastFeed.episodeSortType.name
                                                : "-"}
                                        </p>
                                    </div>

                                    <div className="col col-sml-6 col-xhuge-4 u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.EPISODE_LIMIT")}
                                        </p>
                                        <p className="u-type--base">
                                            {podcast.podcastFeed && podcast.podcastFeed.episodeLimit
                                                ? podcast.podcastFeed.episodeLimit
                                                : "-"}
                                        </p>
                                    </div>

                                    <div className="col col-sml-6 col-xhuge-4 u-mar--bottom--sml">
                                        <p className="u-type--sml u-type--color--opaque">
                                            {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.PRODUCER")}
                                        </p>

                                        <p className="u-type--base">
                                            {podcast.producer && (
                                                <ProducerLabel
                                                    name={podcast.producer.name}
                                                    isUser={!isNil(podcast.producer.user)}
                                                    user={podcast.producer.user}
                                                />
                                            )}
                                        </p>
                                    </div>

                                    <div className="col col-sml-12 u-mar--bottom--sml">
                                        <div>
                                            <div className="c-collapse-panel__body__link u-mar--bottom--sml">
                                                <p className="c-collapse-panel__body__label">
                                                    {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.DESCRIPTION_LINK")}
                                                </p>
                                                {podcast.descriptionLink ? (
                                                    <a href={podcast.descriptionLink} target="_blank" rel="noreferrer">
                                                        {podcast.descriptionLink}
                                                    </a>
                                                ) : (
                                                    <p className="u-type--base u-type--color--negative">-</p>
                                                )}
                                            </div>
                                            <p className="c-collapse-panel__body__label">
                                                {t("ADMINISTRATION.PODCAST.PREVIEW.FIELDS.COVER_IMAGE")}
                                            </p>
                                            <Image className="c-upload__uploaded-img" src={coverImageUrl} />
                                        </div>
                                        <div className="row u-mar--top--sml">
                                            <PodcastGroupPreviewSubPodcastAnalyticsStats
                                                store={subPodcastAnalyticsStatsWidgetViewStore}
                                                t={t}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col col-sml-12 col-lrg-5">
                                <div className="c-toggle__card-list">
                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.STATUS")}
                                        <MrbToggle
                                            id={`podcast-active-status-${podcast.id}`}
                                            value={podcast.isActive}
                                            onChange={(event) => toggleIsActive(event.target.checked)}
                                        />
                                    </div>

                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.PREMIUM")}
                                        <MrbToggle
                                            id={`podcast-premium-status-${podcast.id}`}
                                            value={podcast.isPremium}
                                            disabled={isPremiumDisabled}
                                            onChange={(event) => togglePremiumStatus(event.target.checked)}
                                        />
                                    </div>

                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.MAKE_DEFAULT")}
                                        <MrbToggle
                                            id={`podcast-on-by-default-status-${podcast.id}`}
                                            value={podcast.isTurnedOnByDefault}
                                            onChange={(event) => toggleOnByDefaultStatus(event.target.checked)}
                                        />
                                    </div>

                                    <div className="u-separator--primary u-mar--none"></div>

                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.VISIBLE_IN_APP")}
                                        <MrbToggle
                                            labelClassNameExtend="u-mar--top--tny"
                                            id={`podcast-app-visibility-status-${podcast.id}`}
                                            value={podcast.isVisibleInApp}
                                            disabled={isAppVisibilityDisabled}
                                            onChange={(event) => toggleAppVisibilityStatus(event.target.checked)}
                                        />
                                    </div>

                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.MAKE_DEFAULT_IN_APP")}
                                        <MrbToggle
                                            id={`podcast-on-by-default-in-app-status-${podcast.id}`}
                                            value={podcast.isTurnedOnByDefaultInApp}
                                            onChange={(event) => toggleOnByDefaultInAppStatus(event.target.checked)}
                                        />
                                    </div>

                                    <div className="u-separator--primary u-mar--none"></div>

                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.VISIBLE_IN_EMBEDDED_APP")}
                                        <MrbToggle
                                            labelClassNameExtend="u-mar--top--tny"
                                            id={`podcast-embedded-app-visibility-status-${podcast.id}`}
                                            value={podcast.isVisibleInEmbeddedApp}
                                            disabled={isNakiTouchVisibilityDisabled}
                                            onChange={(event) => toggleNakiTouchVisibilityStatus(event.target.checked)}
                                        />
                                    </div>

                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.MAKE_DEFAULT_IN_EMBEDDED_APP")}
                                        <MrbToggle
                                            id={`podcast-on-by-default-in-embedded-app-status-${podcast.id}`}
                                            value={podcast.isTurnedOnByDefaultInEmbeddedApp}
                                            onChange={(event) =>
                                                toggleOnByDefaultInNakiTouchStatus(event.target.checked)
                                            }
                                        />
                                    </div>

                                    <div className="u-separator--primary u-mar--none"></div>

                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.RECENTLY_UPDATED_VISIBILITY")}
                                        <MrbToggle
                                            id={`podcast-recently-updated-visibility-status-${podcast.id}`}
                                            value={podcast.podcastFeed.recentlyUpdatedVisibility}
                                            onChange={(event) =>
                                                toggleRecentlyUpdatedVisibilityStatus(event.target.checked)
                                            }
                                        />
                                    </div>
                                    <div className="c-toggle__item--inline">
                                        {t("ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.HLS_ENABLED")}
                                        <MrbToggle
                                            id={`podcast-hls-enabled-status-${podcast.id}`}
                                            value={podcast.podcastFeed.hlsEnabled}
                                            onChange={(event) => toggleHlsEnabledStatus(event.target.checked)}
                                        />
                                    </div>

                                    <div className="u-separator--primary u-mar--none"></div>

                                    <div className="c-sidebar__item">
                                        <div className="u-type--base">{t("Excluded from revenue")}</div>
                                        <MrbToggle
                                            id="podcast-excluded-from-revenue-status"
                                            value={podcast.excludedFromRevenue}
                                            onChange={(event) => toggleExcludedFromRevenueStatus(event.target.checked)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-separator--primary"></div>
                    <MrbButton
                        onClick={onClickEdit}
                        label="ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.SUB_PODCAST_EDIT"
                        t={t}
                    />{" "}
                    <MrbButton
                        onClick={onClickPodcastEpisodeList}
                        className="c-btn c-btn--base c-btn--ghost"
                        label="ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.EPISODES"
                        t={t}
                    />
                </React.Fragment>
            )}
        </MrbCollapsiblePanel>
    );
}

PodcastGroupPreviewSubPodcastTemplate.propTypes = {
    podcast: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const SubPodcastPanelHeader = observer(function SubPodcastPanelHeader({
    title,
    t,
    isCollapsed,
    podcast,
    displayMissingProducers,
    isPremiumDisabled,
}) {
    return (
        <React.Fragment>
            <div className="u-display--flex u-display--flex--ai--center">
                {t(title)}{" "}
                {podcast.isPremium && (
                    <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                )}
            </div>
            <div className="u-display--flex u-display--flex--ai--center">
                {((isPremiumDisabled && podcast.producerId === null) ||
                    (displayMissingProducers && podcast.producerId === null)) && (
                    <p className="u-type--sml u-type--color--warning--light u-mar--right--lrg">
                        {t("ADMINISTRATION.PODCAST.PREVIEW.VALIDATION.MISSING_PRODUCER_LABEL")}
                    </p>
                )}{" "}
                <i
                    className={`u-icon u-icon--med u-icon--arrow-down ${
                        isCollapsed ? "u-rotate--180" : ""
                    } u-cursor--pointer`}
                ></i>
            </div>
        </React.Fragment>
    );
});

export default observer(PodcastGroupPreviewSubPodcastTemplate);
