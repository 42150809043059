import React from "react";
import { action, computed, observable, makeObservable, runInAction } from "mobx";
import { applicationErrorCodes } from "common/constants";
import { MrbBaseViewStore } from "mrb/core";
import { localizationService } from "common/localization";

//import { SubPodcastAnalyticsStatsWidgetViewStore } from "administration/podcast/stores";

class NakiNigunArtistPreviewAlbumViewStore extends MrbBaseViewStore {
    @observable isCollapsed = false;
    @observable album = null;
    panelRef = React.createRef();

    @computed get coverImageUrl() {
        if (this.album && this.album.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(
                this.album.coverImageId,
                150,
                150
            );
        }

        return null;
    }

    @computed get isPremiumDisabled() {
        return this.nakiNigunArtistPreviewViewStore.item.isPremium;
    }

    @computed get isActiveStatusDisabled() {
        return !this.nakiNigunArtistPreviewViewStore.item.isActive;
    }

    constructor(rootStore, { nakiNigunArtistPreviewViewStore, album }) {
        super(rootStore);
        makeObservable(this);
        this.nakiNigunArtistPreviewViewStore = nakiNigunArtistPreviewViewStore;
        this.routeStore = nakiNigunArtistPreviewViewStore.routeStore;
        this.album = album;

        // this.subPodcastAnalyticsStatsWidgetViewStore = new SubPodcastAnalyticsStatsWidgetViewStore(rootStore, {
        //     routeStore: this.routeStore,
        //     podcastId: this.podcast.id,
        // });

        this.reaction(
            () => this.isCollapsed,
            (isCollapsed) => {
                if (isCollapsed) {
                    //this.subPodcastAnalyticsStatsWidgetViewStore.initialize();
                    if (this.panelRef) {
                        // We need to execute scroll function asynchronously in order to take effect.
                        setTimeout(() => {
                            this.panelRef.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        });
                    }
                }
            }
        );

        this.reaction(
            () => this.nakiNigunArtistPreviewViewStore.item.isPremium,
            (isArtistPremium) => {
                if (isArtistPremium && !this.nakiNigunArtistPreviewViewStore.isPremiumDisabled) {
                    runInAction(() => {
                        this.album.isPremium = true;
                    });
                }
            }
        );

        this.reaction(
            () => this.nakiNigunArtistPreviewViewStore.item.isActive,
            (isArtistActive) => {
                runInAction(() => {
                    this.album.isActive = isArtistActive;
                });
            }
        );
    }

    @action.bound
    onInit() {
        const albumId = this.rootStore.routerStore.routerState.queryParams.albumId;
        if (albumId && this.album.id === albumId) {
            this.isCollapsed = true;
        }
        return Promise.resolve();
    }

    @action.bound
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    @action.bound
    async toggleIsActive(value) {
        const oldValue = this.album.isActive;
        this.album.isActive = value;
        try {
            await this.routeStore.updateAlbumActiveStatus(this.album.id, this.album.isActive);
        } catch (err) {
            if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
                this.rootStore.notificationStore.error(
                    "Album updated, but an unexpected error occurred during RSS sync.",
                    err
                );
            } else {
                runInAction(() => {
                    this.album.isActive = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change status", err);
                });
            }
        }
    }

    @action.bound
    async togglePremiumStatus(value) {
        const oldValue = this.album.isPremium;
        this.album.isPremium = value;
        try {
            await this.routeStore.updateAlbumPremiumStatus(this.album.id, this.album.isPremium);
        } catch (err) {
            if (this.album.isPremium && this.album.producerId === null) {
                runInAction(() => {
                    this.album.isPremium = oldValue;
                    this.rootStore.notificationStore.warning(
                        localizationService.t(
                            "ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.VALIDATION.ALBUM_MISSING_PRODUCER"
                        )
                    );
                });
            } else {
                runInAction(() => {
                    this.album.isPremium = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change premium", err);
                });
            }
        }
    }

    @action.bound
    async toggleIsFeatured(value) {
        const oldValue = this.album.isFeatured;
        this.album.isFeatured = value;
        try {
            await this.routeStore.updateAlbumFeaturedStatus(this.album.id, this.album.isFeatured);
        } catch (err) {
            runInAction(() => {
                this.album.isFeatured = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change featured status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleOnByDefaultStatus(value) {
        const oldValue = this.album.isTurnedOnByDefault;
        this.album.isTurnedOnByDefault = value;
        try {
            await this.routeStore.updateAlbumOnByDefaultStatus(this.album.id, this.album.isTurnedOnByDefault);
        } catch (err) {
            runInAction(() => {
                this.album.isTurnedOnByDefault = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change default status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleOnByDefaultInAppStatus(value) {
        const oldValue = this.album.isTurnedOnByDefaultInApp;
        this.album.isTurnedOnByDefaultInApp = value;
        try {
            await this.routeStore.updateAlbumOnByDefaultInAppStatus(this.album.id, this.album.isTurnedOnByDefaultInApp);
        } catch (err) {
            runInAction(() => {
                this.album.isTurnedOnByDefaultInApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change default status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleOnByDefaultInNakiTouchStatus(value) {
        const oldValue = this.album.isTurnedOnByDefaultInEmbeddedApp;
        this.album.isTurnedOnByDefaultInEmbeddedApp = value;
        try {
            await this.routeStore.updateAlbumOnByDefaultInNakiTouchStatus(
                this.album.id,
                this.album.isTurnedOnByDefaultInEmbeddedApp
            );
        } catch (err) {
            runInAction(() => {
                this.album.isTurnedOnByDefaultInEmbeddedApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change default status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleHlsEnabledStatus(value) {
        const oldValue = this.album.albumFeed.hlsEnabled;
        this.album.albumFeed.hlsEnabled = value;
        try {
            await this.routeStore.updateAlbumHlsEnabledStatus(this.album.id, this.album.albumFeed.hlsEnabled);
        } catch (err) {
            runInAction(() => {
                this.album.albumFeed.hlsEnabled = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change HLS enabled status.",
                    err
                );
            });
        }
    }

    onClickAlbumTrackList = () => {
        this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.album.tracks", {
            id: this.album.id,
        });
    };

    onClickEdit = () => {
        this.rootStore.routerStore.goTo(
            "master.administration.naki-nigun.artist.edit",
            { id: this.album.artistId },
            { albumId: this.album.id }
        );
    };

    setPanelRef = (ref) => {
        this.panelRef = ref;
    };

    onDestroy() {
        //this.subPodcastAnalyticsStatsWidgetViewStore.destroy();
        super.onDestroy();
    }
}

export default NakiNigunArtistPreviewAlbumViewStore;
