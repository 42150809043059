import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { Image } from "common/components";
import { MrbButton } from "mrb/components/button";
import { convertSecondsToHMS } from "common/utils";
import { isEmpty, isNil } from "lodash";
import { mergeCss } from "mrb/common/utils";
import { useRootStore } from "mrb/core";

function PodcastEpisodeTemplate({
    item,
    isSyncing,
    generateAudioFiles,
    openEditPodcastEpisodeTitleModal,
    wrapperClassName,
    isActive,
    isHlsEnabled,
    t,
    downloadPodcastEpisode,
    deletePodcastEpisode,
}) {
    const rootStore = useRootStore();
    const artworkUrl = item.artworkFileEntryId
        ? rootStore.coverImageFactory.createPodcastCoverImageUrl(item.artworkFileEntryId, 50, 50)
        : null;

    return (
        <div className={mergeCss("c-card c-card--primary", wrapperClassName)}>
            <div className="row">
                <div className="col col-sml-12">
                    <div className="u-display--flex u-display--flex--ai--center u-gap--base">
                        <Image
                            className="c-home__preview__icon"
                            src={isEmpty(item.artworkUrl) ? artworkUrl : item.artworkUrl}
                            width="50"
                            height="50"
                        />
                        <div className="u-width--100">
                            <div className="c-home__preview__title u-mar--bottom--small u-width--100">
                                <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between u-width--100 u-gap--med">
                                    <div className="u-display--flex u-display--flex--ai--center u-gap--sml">
                                        <p className="u-type--ellipsis">{item.title}</p>

                                        {!isNil(item.syncTask) ? (
                                            <i className="u-icon u-icon--med u-icon u-icon--sync"></i>
                                        ) : null}
                                    </div>

                                    <div className="u-display--flex u-display--flex--ai--center u-gap--sml">
                                        <MrbButton
                                            classNameOverride="c-btn c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                            onlyIcon
                                            title="Edit Episode"
                                            icon="u-icon u-icon--med u-icon--edit"
                                            onClick={() => {
                                                openEditPodcastEpisodeTitleModal(item);
                                            }}
                                        />

                                        <MrbButton
                                            onClick={() => downloadPodcastEpisode(item)}
                                            title={"Download Episode"}
                                            onlyIcon
                                            icon="u-icon u-icon--base u-icon--expand--down"
                                            classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                        ></MrbButton>
                                        {item.isManualUpload && (
                                            <MrbButton
                                                onClick={() => deletePodcastEpisode(item)}
                                                title={"Delete Episode"}
                                                onlyIcon
                                                icon="u-icon u-icon--med u-icon--trash"
                                                classNameOverride="c-btn c-btn--icon"
                                            ></MrbButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <p className="u-type--sml u-type--color--opaque">
                                {dateFormatter.format(item.publishDate, {
                                    format: applicationDefaults.formats.date,
                                })}{" "}
                                &#x2022; {convertSecondsToHMS(item.duration)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="u-mar--top--sml">
                <p className="u-type--sml u-type--color--opaque">Source URL</p>
                <a
                    href={item.streamUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="u-display--ib u-type--base u-type--ellipsis"
                >
                    {item.streamUrl}
                </a>
            </div>

            <div className="row u-mar--top--tny">
                {!isEmpty(item.hlsStreamUrl) ? (
                    <div className="col col-sml-3">
                        <p className="u-type--sml u-type--color--opaque">HLS availability</p>
                        <p className="u-type--sml u-type--color--success">Available</p>
                    </div>
                ) : (
                    <div className="col col-sml-3">
                        <p className="u-type--sml u-type--color--opaque">HLS availability</p>
                        <p className="u-type--sml u-type--color--warning">Not available</p>
                    </div>
                )}

                {!isEmpty(item.audioStreamUrl) ? (
                    <div className="col col-sml-3">
                        <p className="u-type--sml u-type--color--opaque">MP3 availability</p>
                        <p className="u-type--sml u-type--color--success">Available</p>
                    </div>
                ) : (
                    <div className="col col-sml-3">
                        <p className="u-type--sml u-type--color--opaque">MP3 availability</p>
                        <p className="u-type--sml u-type--color--warning">Not available</p>
                    </div>
                )}

                {item.audioFileBitrate > 0 && item.audioFileSize > 0 && (
                    <>
                        <div className="col col-sml-3">
                            <p className="u-type--sml u-type--color--opaque">MP3 file bitrate</p>
                            <p className="u-type--break u-type--sml"> {`${item.audioFileBitrate} Kbit/s`}</p>
                        </div>
                        <div className="col col-sml-3">
                            <p className="u-type--sml u-type--color--opaque">MP3 file size</p>
                            <p className="u-type--break u-type--sml">
                                {`${(item.audioFileSize / Math.round(1024 * 1024)).toFixed(2)} MB`}
                            </p>
                        </div>
                    </>
                )}
            </div>
            <div className="row u-mar--top--tny">
                {!isEmpty(item.audioStreamUrl) && isHlsEnabled ? (
                    <div className="col col-sml-5">
                        <p className="u-type--sml u-type--color--opaque">Streaming from local server</p>
                        <p className="u-type--sml u-type--color--success">Yes</p>
                    </div>
                ) : (
                    <div className="col col-sml-5">
                        <p className="u-type--sml u-type--color--opaque">Streaming from local server</p>
                        <p className="u-type--sml u-type--color--warning">No</p>
                    </div>
                )}
            </div>
            {isActive && isHlsEnabled && (
                <MrbButton
                    label="Generate Audio File"
                    classNameOverride="c-btn c-btn--sml c-btn--primary"
                    disabled={isSyncing}
                    onClick={() => generateAudioFiles(item)}
                />
            )}
        </div>
    );
}

PodcastEpisodeTemplate.propTypes = {
    item: PropTypes.object.isRequired,
    generateAudioFiles: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(PodcastEpisodeTemplate);
