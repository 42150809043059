import React from "react";
import { observer } from "mobx-react";
import { RegisteredUserDevicesActivityTemplate } from "themes/administration/analytics/pages";
import { RegisteredUserDevicesActivityViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new RegisteredUserDevicesActivityViewStore(rootStore, componentProps),
    "registeredUserDevicesActivityViewStore"
)
@observer
class RegisteredUserDevicesActivity extends React.Component {
    render() {
        return <RegisteredUserDevicesActivityTemplate {...this.props} />;
    }
}

export default RegisteredUserDevicesActivity;
