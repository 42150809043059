import React from "react";
import { observer } from "mobx-react";
import { PremiumUsersActivityTemplate } from "themes/administration/analytics/pages";
import { PremiumUsersActivityViewStore } from "administration/analytics/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new PremiumUsersActivityViewStore(rootStore, componentProps),
    "premiumUsersActivityViewStore"
)
@observer
class PremiumUsersActivity extends React.Component {
    render() {
        return <PremiumUsersActivityTemplate {...this.props} />;
    }
}

export default PremiumUsersActivity;
