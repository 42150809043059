import React from "react";
import { PropTypes } from "prop-types";
import { MrbFieldInput, MrbFieldToggle } from "mrb/components";
import { MrbContentLoader } from "mrb/components/loader";
import {
    AddPodcastGroupItem,
    PodcastGroupEditSubPodcastList,
    PodcastGroupEditPageLayout,
    PodcastCoverImageUpload,
    AddPodcastsToTheGroupLoader,
} from "administration/podcast/components";
import { MrbFileUploadValidation } from "mrb/components/media";
import { AddStationsOrPodcastsLoader, CoverImageUploadLoader, FieldRichTextEditor } from "common/components";
import { defaultTemplate } from "common/hoc";
function PodcastGroupEditTemplate({ podcastGroupEditViewStore, t }) {
    const { form, loaderStore, coverImageUploadViewStore } = podcastGroupEditViewStore;
    const { fileUploadStore } = coverImageUploadViewStore;

    return (
        <PodcastGroupEditPageLayout store={podcastGroupEditViewStore} t={t}>
            <div className="row">
                <div className="col col-sml-12 col-xxlrg-8 u-mar--top--sml u-mar--bottom--sml">
                    <div className="c-card--primary u-height--100">
                        <MrbContentLoader
                            loaderStore={loaderStore}
                            loaderRender={() => <AddStationsOrPodcastsLoader podcast podcastGroup />}
                        >
                            {() => (
                                <form onSubmit={form.onSubmit}>
                                    <div className="u-animation--fadeIn">
                                        <div className="row">
                                            <div className="col col-sml-12 col-lrg-7 col-xxlrg-12 col-huge-7">
                                                <div>
                                                    <MrbFieldInput
                                                        t={t}
                                                        field={form.$("title")}
                                                        classNameExtend="c-input--outline"
                                                    />
                                                </div>
                                                <div>
                                                    <MrbFieldInput
                                                        t={t}
                                                        field={form.$("descriptionLink")}
                                                        classNameExtend="c-input--outline"
                                                    />
                                                </div>
                                                <div>
                                                    <FieldRichTextEditor
                                                        field={form.$("alternativeDescription")}
                                                        t={t}
                                                    />
                                                </div>
                                                {form.$("isPremium").value ? (
                                                    <div>
                                                        <FieldRichTextEditor field={form.$("premiumInfo")} t={t} />
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col col-sml-12 col-lrg-5 col-xxlrg-12 col-huge-5">
                                                <div className="c-toggle__card-list">
                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("isActive")}
                                                    />
                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("isPremium")}
                                                    />

                                                    <div className="u-separator--primary u-mar--none"></div>

                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("isVisibleInApp")}
                                                    />

                                                    <div className="u-separator--primary u-mar--none"></div>

                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("isVisible")}
                                                    />
                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("isFeatured")}
                                                    />

                                                    <div className="u-separator--primary u-mar--none"></div>

                                                    <MrbFieldToggle
                                                        classNameExtend="c-toggle__item--inline"
                                                        t={t}
                                                        field={form.$("isVisibleInEmbeddedApp")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </MrbContentLoader>
                    </div>
                </div>

                <div className="col col-sml-12 col-xxlrg-4 u-mar--top--sml u-mar--bottom--sml">
                    <div className="c-card--primary">
                        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <CoverImageUploadLoader />}>
                            {() => (
                                <PodcastCoverImageUpload
                                    classNameExtendUpload="c-upload--tny"
                                    coverImageUploadViewStore={coverImageUploadViewStore}
                                    showInfo
                                    isFullWidthFooterButtons
                                />
                            )}
                        </MrbContentLoader>
                        <MrbFileUploadValidation store={fileUploadStore} />
                    </div>
                </div>
            </div>

            <div className="u-mar--bottom--med">
                <h1 className="u-type--base u-mar--bottom--sml">
                    {t("ADMINISTRATION.PODCAST.EDIT.ADD_PODCAST_TO_GROUP.TITLE")}
                </h1>
            </div>

            <div className="c-card--primary u-mar--bottom--xlrg">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <AddPodcastsToTheGroupLoader />}>
                    {() => <AddPodcastGroupItem podcastGroupEditViewStore={podcastGroupEditViewStore} />}
                </MrbContentLoader>
            </div>

            <div>
                <PodcastGroupEditSubPodcastList podcastGroupEditViewStore={podcastGroupEditViewStore} t={t} />
            </div>
        </PodcastGroupEditPageLayout>
    );
}

PodcastGroupEditTemplate.propTypes = {
    podcastGroupEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(PodcastGroupEditTemplate);
