import React from "react";
import { StationPromoEditTemplate } from "themes/administration/station/components";

class StationPromoEdit extends React.Component {
    render() {
        return <StationPromoEditTemplate {...this.props} />;
    }
}

export default StationPromoEdit;
