import { action, observable, makeObservable, toJS } from "mobx";
import { MrbBasePreviewViewStore } from "mrb/core";
import { cloneDeep, map } from "lodash";
import { localizationService } from "common/localization";

class AdminPlaylistListViewStore extends MrbBasePreviewViewStore {
    @observable playlists = [];
    @observable submitting = false;

    virtualizedListRef = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            layoutActions: {
                save: {
                    label: "Save",
                    onClick: () => this.save(),
                },
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;
    }

    async onInit() {
        await this.getAllAdminPlaylists();
    }

    async getAllAdminPlaylists() {
        const data = await this.routeStore.getAllAdminGeneratedPlaylists();
        this.setPlaylists(data);
    }

    @action.bound
    setSubmitting(isSubmitting) {
        this.submitting = isSubmitting;
    }

    @action.bound
    setPlaylists(items) {
        this.playlists = items;
    }

    @action.bound
    async togglePlaylistIsActive(playlist) {
        try {
            playlist.isToggleDisabled = true;
            const data = cloneDeep(playlist);
            data.isActive = !playlist.isActive;
            await this.routeStore.updateAdminGeneratedPlaylists([data]);
            playlist.isActive = !playlist.isActive;
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.PLAYLIST.ERROR.UPDATE_PLAYLIST_STATUS")
            );
        }
        playlist.isToggleDisabled = false;
    }

    @action.bound
    async save() {
        try {
            this.setSubmitting(true);
            const data = map(this.playlists, (item, index) => {
                item.sortOrder = index + 1;
                return item;
            });
            await this.routeStore.updateAdminGeneratedPlaylists(data);
            this.routeStore.setIsRouteExitBlocked(false);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.PLAYLIST.ERROR.UPDATE_PLAYLIST")
            );
        } finally {
            this.setSubmitting(false);
        }
    }

    @action.bound
    reorderPlaylists(result) {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        this.moveAdminPlaylistItem(source.index, destination.index);
    }

    @action.bound
    moveToTop(index) {
        this.movePlaylistItem(index, 0);
        this.scrollToItem(0);
    }

    @action.bound
    moveAdminPlaylistItem(sourceIndex, destinationIndex) {
        const newPlaylist = Array.from(toJS(this.playlists));
        const item = newPlaylist.splice(sourceIndex, 1)[0];
        newPlaylist.splice(destinationIndex, 0, item);

        this.playlists = newPlaylist;
        this.routeStore.setIsRouteExitBlocked(true);
    }

    @action.bound
    goToPlaylistPreview(playlistId) {
        this.rootStore.routerStore.goTo("master.administration.playlist.preview", {
            id: playlistId,
        });
    }

    setVirtualizedListRef = (ref) => {
        this.virtualizedListRef = ref;
    };
}

export default AdminPlaylistListViewStore;
