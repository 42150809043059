import React from "react";
import { PodcastGroupEditPageHeaderTemplate } from "themes/administration/podcast/components";

class PodcastGroupEditPageHeader extends React.Component {
    render() {
        return <PodcastGroupEditPageHeaderTemplate {...this.props} />;
    }
}

export default PodcastGroupEditPageHeader;
