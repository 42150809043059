import React from "react";
import { NakiNigunArtistEditAlbumListTemplate } from "themes/administration/naki-nigun/components";

class NakiNigunArtistEditAlbumList extends React.Component {
    render() {
        return <NakiNigunArtistEditAlbumListTemplate {...this.props} />;
    }
}

export default NakiNigunArtistEditAlbumList;
