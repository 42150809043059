import React from "react";
import ContentLoader from "react-content-loader";

const ShuffleListLoaderTemplate = ({ row = 3 }) => {
    const items = [];

    for (let i = 0; i < row; i++) {
        items.push(<ShuffleLoaderTemplate key={i} />);
    }

    return <React.Fragment>{items}</React.Fragment>;
};

const ShuffleLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;

    return (
        <div className="c-card--primary u-mar--bottom--sml">
            <ContentLoader
                speed={2}
                width={screenwidth > 768 ? "768" : screenwidth}
                height="100%"
                viewBox={`0 0 ${screenwidth > 768 ? "768" : screenwidth} 80`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                <React.Fragment>
                    <rect x="0" y={0} rx="3" ry="3" width="250" height="14" />
                    <rect x="0" y={25} rx="3" ry="3" width="100%" height="2" />
                    <rect x="0" y={40} rx="3" ry="3" width="115" height="14" />
                    <rect x="0" y={65} rx="3" ry="3" width="87" height="14" />
                </React.Fragment>
            </ContentLoader>
        </div>
    );
};

export default ShuffleListLoaderTemplate;
