import React from "react";
import { observer } from "mobx-react";
import { ShuffleListTemplate } from "themes/administration/shuffle/pages";
import { ShuffleListViewStore } from "administration/shuffle/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new ShuffleListViewStore(rootStore, componentProps),
    "shuffleListViewStore"
)
@observer
class ShuffleList extends React.Component {
    render() {
        return <ShuffleListTemplate {...this.props} />;
    }
}

export default ShuffleList;
