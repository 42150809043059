import { action, computed, observable, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core/stores";
import { map, findIndex } from "lodash";

class DeviceMenuLatestItemsViewStore extends MrbBaseViewStore {
    @observable.ref items = [];

    @computed get latestItems() {
        return map(this.items, (item) => {
            const index = findIndex(
                this.deviceMenuEditViewStore.deviceMenu,
                (menuItem) =>
                    (item.stationId && menuItem.stationId === item.stationId) ||
                    (item.podcastId && menuItem.podcastId === item.podcastId) ||
                    (item.shuffleId && menuItem.shuffleId === item.shuffleId)
            );

            return {
                ...item,
                index: index,
                deviceMenuType:
                    index !== -1
                        ? this.deviceMenuEditViewStore.deviceMenu[index]
                              .deviceMenuType
                        : null,
            };
        });
    }

    constructor(rootStore, deviceMenuEditViewStore) {
        super(rootStore);
        makeObservable(this);
        this.deviceMenuEditViewStore = deviceMenuEditViewStore;
        this.routeStore = this.deviceMenuEditViewStore.routeStore;
    }

    onInit() {
        return this.loadLatestItems();
    }

    async loadLatestItems() {
        const items = await this.getItems();
        this.setItems(items);
    }

    @action.bound
    setItems(items) {
        this.items = items;
    }

    getItems() {
        return this.routeStore.getDeviceMenuLatestItems();
    }

    onClickItem = (item) => {
        this.deviceMenuEditViewStore.this.deviceMenuViewStore.scrollToItem(
            item.index
        );
    };

    onClickMoveToTop = (item) => {
        this.deviceMenuEditViewStore.moveToTop(item.index);
    };
}

export default DeviceMenuLatestItemsViewStore;
