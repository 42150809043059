import React from "react";
import { ShuffleEditNakiNigunAlbumItemTemplate } from "themes/administration/shuffle/components";

class ShuffleEditNakiNigunAlbumItem extends React.Component {
    render() {
        return <ShuffleEditNakiNigunAlbumItemTemplate {...this.props} />;
    }
}

export default ShuffleEditNakiNigunAlbumItem;
