import { useState, useEffect } from "react";
import { PlaylistTags } from "administration/content-explorer/components";
import { PlaylistService } from "common/services";
import { PlaylistSelectStore } from "common/components";
import { useRootStore } from "mrb/core/contexts";
import { localizationService } from "common/localization";
import { noop } from "lodash";

async function findAdminGeneratedPlaylists(rootStore, filter) {
    try {
        filter.orderBy = "title";
        filter.orderDirection = "asc";
        filter.pageSize = 50;
        if (filter.searchQuery) {
            filter.searchQuery = filter.searchQuery.trim();
            if (filter.searchQuery === "") {
                filter.searchQuery = null;
            }
        }

        const playlistService = rootStore.createApplicationService(PlaylistService);
        const response = await playlistService.findAdminGeneratedPlaylists(filter);
        return response.data;
    } catch (err) {
        rootStore.notificationStore.error(
            localizationService.t("ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.ERROR.PLAYLIST_LOAD_ERROR")
        );
        return [];
    }
}

async function createAdminGeneratedPlaylist(rootStore, playlist) {
    const playlistService = rootStore.createApplicationService(PlaylistService);
    const response = await playlistService.createAdminGeneratedPlaylist(playlist);
    return response.data;
}

async function addItemToAdminGeneratedPlaylist(rootStore, playlistId, resource) {
    const playlistService = rootStore.createApplicationService(PlaylistService);
    const response = await playlistService.addItemToAdminGeneratedPlaylist(playlistId, resource);
    return response.data;
}

async function deleteAlbumTrackFromAdminGeneratedPlaylist(rootStore, playlistId, albumTrackId) {
    const playlistService = rootStore.createApplicationService(PlaylistService);
    const response = await playlistService.deleteAlbumTrackFromAdminGeneratedPlaylist(playlistId, albumTrackId);
    return response.data;
}

function AlbumTrackPlaylistCellTemplate({ data, addPlaylist = noop, removePlaylist = noop }) {
    const rootStore = useRootStore();
    const [store] = useState(
        new PlaylistSelectStore({
            isSearchable: true,
            textKey: "title",
            isMulti: true,
            initFetch: false,
            actions: {
                find: (searchQuery) => {
                    return findAdminGeneratedPlaylists(rootStore, { searchQuery });
                },

                createOption: (title) => {
                    return createAdminGeneratedPlaylist(rootStore, { title });
                },

                onSelected: async (playlist) => {
                    try {
                        await addItemToAdminGeneratedPlaylist(rootStore, playlist.id, { albumTrackId: data.id });
                        addPlaylist(data.id, playlist);
                    } catch (error) {
                        rootStore.notificationStore.error(
                            localizationService.t(
                                "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.PLAYLIST.ERROR.ADD_ITEM"
                            )
                        );
                    }
                },

                onRemoved: async (playlist) => {
                    try {
                        await deleteAlbumTrackFromAdminGeneratedPlaylist(rootStore, playlist.id, data.id);
                        removePlaylist(data.id, playlist);
                    } catch (error) {
                        rootStore.notificationStore.error(
                            localizationService.t(
                                "ADMINISTRATION.CONTENT_EXPLORER.ALBUM_TRACK_LIST.PLAYLIST.ERROR.REMOVE_ITEM"
                            )
                        );
                    }
                },
            },
        })
    );
    useEffect(() => {
        store.setSelectedItem(data.playlists);
    });

    return (
        <div>
            <PlaylistTags store={store} />
        </div>
    );
}

export default AlbumTrackPlaylistCellTemplate;
