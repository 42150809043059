import React from "react";
import { observer } from "mobx-react";
import { PodcastListTemplate } from "themes/administration/podcast/pages";
import { PodcastListViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastListViewStore(rootStore, componentProps),
    "podcastListViewStore"
)
@observer
class PodcastList extends React.Component {
    render() {
        return <PodcastListTemplate {...this.props} />;
    }
}

export default PodcastList;
