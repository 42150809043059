import React from "react";
import { PropTypes } from "prop-types";
import { NakiNigunAlbumEdit } from "administration/naki-nigun/components";
import { DragDropList } from "common/components";
import { observer } from "mobx-react";

function NakiNigunArtistEditAlbumListTemplate({ nakiNigunArtistEditViewStore, t }) {
    const { albums, reorderAlbums } = nakiNigunArtistEditViewStore;

    return (
        <React.Fragment>
            {albums && albums.length > 0 && (
                <DragDropList
                    droppableId="albums"
                    items={albums}
                    onDragEnd={reorderAlbums}
                    itemRender={(item) => (
                        <NakiNigunAlbumEdit
                            albumId={item.id}
                            nakiNigunArtistEditViewStore={nakiNigunArtistEditViewStore}
                            t={t}
                        />
                    )}
                ></DragDropList>
            )}
        </React.Fragment>
    );
}

NakiNigunArtistEditAlbumListTemplate.propTypes = {
    nakiNigunArtistEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(NakiNigunArtistEditAlbumListTemplate);
