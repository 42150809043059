import { MrbBaseListViewStore } from "mrb/core";
import { MrbTableViewStore } from "mrb/components";
import { mrbDefaultFormats } from "mrb/common/formatting";

class NakiNigunArtistListViewStore extends MrbBaseListViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            layoutActions: {
                create: {
                    onClick: () => this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.create"),
                    label: "ADMINISTRATION.NAKI_NIGUN.LIST.BUTTONS.ADD",
                },
            },
            queryConfig: {
                orderBy: "name",
                orderDirection: "asc",
            },
        });
        this.routeStore = routeStore;

        this.setGridStore(
            new MrbTableViewStore(this.rootStore, this.queryUtility, {
                onRowClick: (node) =>
                    this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.preview", {
                        id: node.data.id,
                    }),
                columns: [
                    {
                        key: "name",
                        title: "ADMINISTRATION.NAKI_NIGUN.LIST.COLUMNS.ARTIST",
                        sortable: true,
                        /*format: {
                            type: mrbDefaultFormats.bool,
                            trueLabel: "Active",
                            falseLabel: "Inactive",
                        },*/
                    },
                    {
                        key: "isActive",
                        title: "ADMINISTRATION.PODCAST.LIST.COLUMNS.STATUS",
                        format: {
                            type: mrbDefaultFormats.bool,
                            trueLabel: "Active",
                            falseLabel: "Inactive",
                        },
                    },
                    {
                        key: "isPremium",
                        title: "ADMINISTRATION.PODCAST.LIST.COLUMNS.PREMIUM",
                        format: {
                            type: mrbDefaultFormats.bool,
                            trueLabel: "Premium",
                            falseLabel: "Free",
                        },
                    },
                    {
                        key: "isVisible",
                        title: "ADMINISTRATION.PODCAST.LIST.COLUMNS.VISIBLE_ON_PORTAL",
                        format: {
                            type: mrbDefaultFormats.bool,
                            trueLabel: "Visible",
                            falseLabel: "Hidden",
                        },
                    },
                ],
                actions: {
                    edit: {
                        onClick: (item) =>
                            this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.edit", {
                                id: item.id,
                            }),
                        label: "ADMINISTRATION.NAKI_NIGUN.LIST.ACTIONS.EDIT",
                        buttonProps: {
                            className: "c-btn c-btn--sml c-btn--ghost u-mar--right--tny",
                        },
                    },
                    delete: {
                        onClick: (item) =>
                            this.rootStore.confirmDialogStore.showConfirm({
                                message: "Do you want to delete artist?",
                                onConfirm: async () => {
                                    try {
                                        await this.routeStore.deleteNakiNigunArtist(item);
                                        this.queryUtility.fetch();
                                    } catch (err) {
                                        this.rootStore.notificationStore.error(
                                            "An unexpected error occurred. Unable to delete artist.",
                                            err
                                        );
                                    }
                                },
                                yesLabel: "Delete",
                                noLabel: "Cancel",
                                description: "You won't be able to revert this action.",
                            }),
                        label: "ADMINISTRATION.NAKI_NIGUN.LIST.ACTIONS.DELETE",
                        buttonProps: {
                            className: "c-btn c-btn--sml c-btn--ghost",
                        },
                    },
                },
            })
        );
    }

    find(params) {
        return this.routeStore.findNakiNigunArtists(params);
    }

    onFetchError() {
        this.rootStore.notificationStore.error(
            "An unexpected error occurred while trying to load data. Please contact the support team."
        );
    }
}

export default NakiNigunArtistListViewStore;
