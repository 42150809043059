import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage } from "mrb/components/layout";
import { MrbContentLoader } from "mrb/components/loader";
import { currencyFormatter, dateFormatter } from "mrb/common/formatting";
import { applicationDefaults } from "common/constants";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";
import { map, isNil } from "lodash";
import { OrderInfoLoader, OrderTableLoader } from "administration/order/components";

function OrderPreviewTemplate({ orderPreviewViewStore, t }) {
    const { item, loaderStore } = orderPreviewViewStore;
    return (
        <MrbPreviewPage store={orderPreviewViewStore} t={t}>
            <div className="c-container--med">
                <div className="c-card--primary c-card--med u-mar--bottom--med">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <OrderInfoLoader />}>
                        {() => (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col col-sml-12 col-lrg-4">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.ORDER.PREVIEW.FIELDS.ORDER_NUMBER")}
                                            </p>
                                            {item.orderNumber}
                                        </div>
                                    </div>

                                    <div className="col col-sml-6 col-lrg-4">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.ORDER.PREVIEW.FIELDS.DATE_OF_ORDER")}
                                            </p>
                                            {dateFormatter.format(item.dateCreated, {
                                                format: applicationDefaults.formats.dateTimeSeconds,
                                            })}
                                        </div>
                                    </div>

                                    <div className="col col-sml-4 col-lrg-4">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.ORDER.PREVIEW.FIELDS.ORDER_STATUS")}
                                            </p>
                                            {item.orderStatus.name}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-sml-6 col-lrg-4">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.ORDER.PREVIEW.FIELDS.CUSTOMER")}
                                            </p>
                                            <BillingAddress billingAddress={item.billingAddress} />
                                        </div>
                                    </div>

                                    <div className="col col-sml-6 col-lrg-4">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.ORDER.PREVIEW.FIELDS.PAYMENT_PROVIDER")} (
                                                {t(
                                                    "ADMINISTRATION.ORDER.PREVIEW.FIELDS.PAYMENT_PROVIDER_PAYMENT_METHOD"
                                                )}
                                                )
                                            </p>
                                            <span className=" u-mar--right--tny">
                                                {item.paymentMethod} ({item.paymentMethodTitle})
                                            </span>
                                            {item.paymentMethod === "Stripe" && (
                                                <StripePaymentLink paymentId={item.paymentProviderPaymentId} t={t} />
                                            )}
                                        </div>
                                    </div>

                                    <div className="col col-sml-12 col-lrg-4">
                                        <div className="u-type--base u-mar--bottom--sml">
                                            <p className="u-type--sml u-type--color--opaque">
                                                {t("ADMINISTRATION.ORDER.PREVIEW.FIELDS.SHIPPING_ADDRESS")}
                                            </p>
                                            <ShippingAddress shippingAddress={item.shippingAddress} />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </MrbContentLoader>
                </div>
            </div>

            <h1 className="u-type--base u-mar--bottom--sml">{t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.TITLE")}</h1>

            <div className="c-container--med">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <OrderTableLoader />}>
                    {() => <LineItems order={item} t={t} />}
                </MrbContentLoader>
            </div>
        </MrbPreviewPage>
    );
}

OrderPreviewTemplate.propTypes = {
    orderPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

function BillingAddress({ billingAddress }) {
    const address = [];
    if (billingAddress.email) {
        address.push(<div className="u-mar--bottom--tny">{billingAddress.email}</div>);
    }
    if (billingAddress.firstName && billingAddress.lastName) {
        address.push(<div>{`${billingAddress.firstName} ${billingAddress.lastName}`}</div>);
    }
    if (billingAddress.address1) {
        address.push(<div>{billingAddress.address1}</div>);
    }
    if (billingAddress.address2) {
        address.push(<div>{billingAddress.address2}</div>);
    }

    const placeInfo = [];
    if (billingAddress.city) {
        placeInfo.push(`${billingAddress.city},`);
    }
    if (billingAddress.state) {
        placeInfo.push(billingAddress.state);
    }
    if (billingAddress.postCode) {
        placeInfo.push(billingAddress.postCode);
    }
    if (billingAddress.country) {
        placeInfo.push(billingAddress.country);
    }

    return [...address, <div>{placeInfo.join(" ")}</div>];
}

function ShippingAddress({ shippingAddress }) {
    const address = [];
    if (shippingAddress.firstName && shippingAddress.lastName) {
        address.push(<div>{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</div>);
    }
    if (shippingAddress.address1) {
        address.push(<div>{shippingAddress.address1}</div>);
    }
    if (shippingAddress.address2) {
        address.push(<div>{shippingAddress.address2}</div>);
    }

    const placeInfo = [];
    if (shippingAddress.city) {
        placeInfo.push(`${shippingAddress.city},`);
    }
    if (shippingAddress.state) {
        placeInfo.push(shippingAddress.state);
    }
    if (shippingAddress.postCode) {
        placeInfo.push(shippingAddress.postCode);
    }
    if (shippingAddress.country) {
        placeInfo.push(shippingAddress.country);
    }

    return [...address, <div>{placeInfo.join(" ")}</div>];
}

const LineItems = observer(function LineItems({ order, t }) {
    return (
        <div className="c-card--table">
            <table className="c-table c-table--primary">
                <thead className="c-table--primary__thead">
                    <tr className="c-table--primary__tr">
                        <th className="c-table--primary__th u-type--base" colSpan="2">
                            {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.COLUMNS.PRODUCT")}
                        </th>
                        <th className="c-table--primary__th u-type--base">
                            {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.COLUMNS.QUANTITY")}
                        </th>
                        <th className="c-table--primary__th u-type--base">
                            {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.COLUMNS.UNIT_PRICE")}
                        </th>
                        <th className="c-table--primary__th u-type--base">
                            {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.COLUMNS.AMOUNT")}
                        </th>
                    </tr>
                </thead>
                <tbody className="c-table--primary__tbody">
                    {map(order.items, (item) => (
                        <tr className="c-table--primary__tr">
                            <td className="c-table--primary__td u-type--sml" colSpan="2">
                                {item.product ? item.product.name : null}
                            </td>
                            <td className="c-table--primary__td u-type--sml">{item.quantity}</td>
                            <td className="c-table--primary__td u-type--sml">
                                {currencyFormatter.format(item.price, {
                                    currency: "USD",
                                })}
                            </td>
                            <td className="c-table--primary__td u-type--sml">
                                {currencyFormatter.format(item.total, {
                                    currency: "USD",
                                })}
                            </td>
                        </tr>
                    ))}
                    <tr className="c-table--primary__tr">
                        <td className="c-table--primary__td u-type--sml" colSpan={4}>
                            <p className=" u-type--right u-type--wgt--bold">
                                {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.SUMMARY.SUBTOTAL")}
                            </p>
                        </td>
                        <td className="c-table--primary__td u-type--sml u-type--wgt--bold">
                            {currencyFormatter.format(order.subtotalAmount, {
                                currency: "USD",
                            })}
                        </td>
                    </tr>
                    <tr className="c-table--primary__tr">
                        <td className="c-table--primary__td u-type--sml" colSpan={4}>
                            <TotalTaxLabel taxLines={order.taxLines} t={t} />
                        </td>
                        <td className="c-table--primary__td u-type--sml u-type--wgt--bold">
                            {currencyFormatter.format(order.taxAmount, {
                                currency: "USD",
                            })}
                        </td>
                    </tr>
                    <tr className="c-table--primary__tr">
                        <td className="c-table--primary__td u-type--sml" colSpan={4}>
                            <ShippingMethodLabel shippingMethod={order.shippingMethod} t={t} />
                        </td>
                        <td className="c-table--primary__td u-type--sml u-type--wgt--bold">
                            {currencyFormatter.format(order.shippingAmount, {
                                currency: "USD",
                            })}
                        </td>
                    </tr>
                    <tr className="c-table--primary__tr">
                        <td className="c-table--primary__td u-type--sml" colSpan={4}>
                            <p className=" u-type--right u-type--wgt--bold">
                                {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.SUMMARY.TOTAL")}
                            </p>
                        </td>
                        <td className="c-table--primary__td u-type--sml u-type--wgt--bold">
                            {currencyFormatter.format(order.totalAmount, {
                                currency: "USD",
                            })}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
});

function TotalTaxLabel({ taxLines, t }) {
    if (isNil(taxLines) || taxLines.length === 0) {
        return (
            <p className=" u-type--right u-type--color--opaque">
                {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.SUMMARY.TOTAL_TAX")}
            </p>
        );
    }

    const taxLine = taxLines[0];

    return (
        <p className=" u-type--right u-type--color--opaque">
            {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.SUMMARY.TOTAL_TAX")} - {taxLine.label} ({taxLine.ratePercent}%)
        </p>
    );
}

function ShippingMethodLabel({ shippingMethod, t }) {
    if (isNil(shippingMethod)) {
        return (
            <p className=" u-type--right u-type--color--opaque">
                {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.SUMMARY.SHIPPING")}
            </p>
        );
    }

    return (
        <p className=" u-type--right u-type--color--opaque">
            {t("ADMINISTRATION.ORDER.PREVIEW.ITEMS.SUMMARY.SHIPPING")}{" "}
            <span className="u-type--color--opaque">({shippingMethod.methodTitle})</span>
        </p>
    );
}

function StripePaymentLink({ paymentId, t }) {
    let url = `https://dashboard.stripe.com/payments/${paymentId}`;

    return (
        <a
            className="u-type--base u-type--link u-type--link--secondary"
            href={url}
            target="_blank"
            rel="noreferrer"
            title={t("ADMINISTRATION.ORDER.PREVIEW.FIELDS.STRIPE_PAYMENT_INFO")}
        >
            <i className="u-icon u-icon--base u-icon--link--primary"></i>
        </a>
    );
}

export default defaultTemplate(OrderPreviewTemplate);
