import { MrbBaseRouteStore } from "mrb/core";
import { PodcastService, PodcastFileStreamService, PodcastAnalyticsService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class BasePodcastPreviewRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PodcastService);
        this.podcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
        this.podcastAnalyticsService = this.rootStore.createApplicationService(PodcastAnalyticsService);
    }

    async updateActiveStatus(id, isActive) {
        const response = await this.service.updateActiveStatus(id, isActive);
        return response.data;
    }

    async updateExcludedFromRevenueStatus(id, excludedFromRevenue) {
        const response = await this.service.updateExcludedFromRevenueStatus(id, excludedFromRevenue);
        return response.data;
    }

    async getPodcastAnalyticsStats(id, options) {
        const response = await this.podcastAnalyticsService.getPodcastStats(id, options);
        return response.data;
    }

    async updatePremiumStatus(id, isPremium) {
        const response = await this.service.updatePremiumStatus(id, isPremium);
        return response.data;
    }

    async updateOnByDefaultStatus(id, isOnByDefault) {
        const response = await this.service.updateOnByDefaultStatus(id, isOnByDefault);
        return response.data;
    }

    async updateOnByDefaultInAppStatus(id, isOnByDefault) {
        const response = await this.service.updateOnByDefaultInAppStatus(id, isOnByDefault);
        return response.data;
    }

    async updateOnByDefaultInNakiTouchStatus(id, isOnByDefault) {
        const response = await this.service.updateOnByDefaultInNakiTouchStatus(id, isOnByDefault);
        return response.data;
    }

    async updateVisibilityStatus(id, isVisible) {
        const response = await this.service.updateVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateAppVisibilityStatus(id, isVisible) {
        const response = await this.service.updateAppVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateNakiTouchVisibilityStatus(id, isVisible) {
        const response = await this.service.updateNakiTouchVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateRecentlyUpdatedVisibilityStatus(id, isVisible) {
        const response = await this.service.updateRecentlyUpdatedVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateFeaturedStatus(id, isFeatured) {
        const response = await this.service.updateFeaturedStatus(id, isFeatured);
        return response.data;
    }

    async deletePodcast(resource) {
        const response = await this.service.delete(resource);
        return response.data;
    }

    createPodcastCoverImageUrl(coverImageId, width = null, height = null) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.podcastFileStreamService.routeService.get(coverImageId),
            {
                width: width,
                height: height,
            }
        );
    }

    async updateHlsEnabledStatus(id, isHlsEnabled) {
        const response = await this.service.updateHlsEnabledStatus(id, isHlsEnabled);
        return response.data;
    }
}

export default BasePodcastPreviewRouteStore;
