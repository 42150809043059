import React from "react";
import { DashboardTemplate } from "themes/administration/dashboard/pages";
import { DashboardViewStore } from "administration/dashboard/stores";
import { setCurrentView } from "mrb/core";
import { observer } from "mobx-react";

@setCurrentView((rootStore, componentProps) => new DashboardViewStore(rootStore, componentProps), "dashboardViewStore")
@observer
class Dashboard extends React.Component {
    render() {
        return <DashboardTemplate {...this.props} />;
    }
}

export default Dashboard;
