import React from "react";
import { NakiNigunArtistPreviewAlbumListTemplate } from "themes/administration/naki-nigun/components";

class NakiNigunArtistPreviewAlbumList extends React.Component {
    render() {
        return <NakiNigunArtistPreviewAlbumListTemplate {...this.props} />;
    }
}

export default NakiNigunArtistPreviewAlbumList;
