import React from "react";
import { FeaturedSectionLoaderTemplate } from "themes/administration/naki-mobile/components";

class FeaturedSectionLoader extends React.Component {
    render() {
        return <FeaturedSectionLoaderTemplate {...this.props} />;
    }
}

export default FeaturedSectionLoader;
