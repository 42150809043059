import { MrbBaseRouteStore } from "mrb/core";
import { ShuffleService } from "common/services";

class ShuffleEditRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(ShuffleService);
    }
}

export default ShuffleEditRouteStore;
