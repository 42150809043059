import React from "react";
import { PropTypes } from "prop-types";
import { GenreContentListItem, GenreListLoader } from "administration/genre/components";
import { MrbInfiniteScrollGrid } from "mrb/components/infinite-scroll-grid";
import { defaultTemplate } from "common/hoc";
import { MrbPreviewPage, MrbPageHeader } from "mrb/components/layout";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { observer } from "mobx-react";

function GenreContentListTemplate({ genreContentListViewStore, t }) {
    const { gridStore, genre } = genreContentListViewStore;

    return (
        <MrbPreviewPage store={genreContentListViewStore} t={t}>
            <Header genre={genre} />
            <div className="c-container--base">
                <div className="c-card--primary">
                    <MrbInfiniteScrollGrid
                        emptyStateRenderer={() => (
                            <div className="u-display--flex u-display--flex--column u-display--flex--ai--center">
                                <i className="u-icon--empty u-icon--empty--sml u-icon--music-list u-mar--bottom--sml"></i>
                                <h3>
                                    {t("ADMINISTRATION.GENRE.CONTENT_LIST.GENRE_CONTENT_LIST_EMPTY_STATE_DESCRIPTION")}
                                </h3>
                                <p className="u-mar--bottom--sml">
                                    {t(
                                        "ADMINISTRATION.GENRE.CONTENT_LIST.GENRE_CONTENT_LIST_EMPTY_STATE_SUB_DESCRIPTION"
                                    )}
                                </p>
                            </div>
                        )}
                        loaderRenderer={() => <GenreListLoader />}
                        store={gridStore}
                        cellRenderer={(props) => <GenreContentListItem {...props} />}
                    />
                </div>
            </div>
        </MrbPreviewPage>
    );
}

const Header = observer(function Header({ genre }) {
    return (
        <MrbPageHeader>
            <div className="c-page__header--top">
                {genre ? (
                    <MrbRouterLink routeName="master.administration.naki-mobile.sections">
                        <span className="display--ib u-mar--right--tny align--v--middle">
                            <i className="u-icon u-icon--med u-icon--back" />
                        </span>
                        {genre.name}
                    </MrbRouterLink>
                ) : (
                    <MrbRouterLink routeName="master.administration.naki-mobile.sections">
                        <span className="display--ib u-mar--right--tny align--v--middle">
                            <i className="u-icon u-icon--med u-icon--back" />
                        </span>
                    </MrbRouterLink>
                )}
            </div>
        </MrbPageHeader>
    );
});

GenreContentListTemplate.propTypes = {
    genreContentListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(GenreContentListTemplate);
