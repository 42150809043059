import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunArtistService, NakiNigunAlbumService } from "common/services";

class NakiNigunArtistPreviewRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(NakiNigunArtistService);
        this.albumService = rootStore.createApplicationService(NakiNigunAlbumService);
    }

    async getNakiNigunArtist(id) {
        const response = await this.service.get(id, {
            embed: "albums,albums.albumFeed,albums.albumFeed.contentSortType,albums.genres,albums.producer",
        });
        return response.data;
    }

    async updateActiveStatus(id, isActive) {
        const response = await this.service.updateActiveStatus(id, isActive);
        return response.data;
    }

    async updateAlbumActiveStatus(id, isActive) {
        const response = await this.albumService.updateActiveStatus(id, isActive);
        return response.data;
    }

    async updatePremiumStatus(id, isPremium) {
        const response = await this.service.updatePremiumStatus(id, isPremium);
        return response.data;
    }

    async updateAlbumPremiumStatus(id, isPremium) {
        const response = await this.albumService.updatePremiumStatus(id, isPremium);
        return response.data;
    }

    async updateAlbumFeaturedStatus(id, isPremium) {
        const response = await this.albumService.updateFeaturedStatus(id, isPremium);
        return response.data;
    }

    async updateAlbumOnByDefaultStatus(id, isOnByDefault) {
        const response = await this.albumService.updateOnByDefaultStatus(id, isOnByDefault);
        return response.data;
    }

    async updateAlbumOnByDefaultInAppStatus(id, isOnByDefault) {
        const response = await this.albumService.updateOnByDefaultInAppStatus(id, isOnByDefault);
        return response.data;
    }

    async updateAlbumOnByDefaultInNakiTouchStatus(id, isOnByDefault) {
        const response = await this.albumService.updateOnByDefaultInNakiTouchStatus(id, isOnByDefault);
        return response.data;
    }

    async updateVisibilityStatus(id, isVisible) {
        const response = await this.service.updateVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateMobileAppVisibilityStatus(id, isVisible) {
        const response = await this.service.updateMobileAppVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateNakiTouchVisibilityStatus(id, isVisible) {
        const response = await this.service.updateNakiTouchVisibilityStatus(id, isVisible);
        return response.data;
    }

    async updateFeaturedStatus(id, isFeatured) {
        const response = await this.service.updateFeaturedStatus(id, isFeatured);
        return response.data;
    }

    async deleteArtist(resource) {
        const response = await this.service.delete(resource);
        return response.data;
    }

    async updateAlbumHlsEnabledStatus(id, isHlsEnabled) {
        const response = await this.albumService.updateHlsEnabledStatus(id, isHlsEnabled);
        return response.data;
    }
}

export default NakiNigunArtistPreviewRouteStore;
