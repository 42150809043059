import { makeObservable, computed } from "mobx";
import { BasePodcastPreviewViewStore } from "administration/podcast/stores";
import { podcastContentType } from "common/constants";

class PodcastPreviewViewStore extends BasePodcastPreviewViewStore {
    @computed get isTorahAnytimePodcast() {
        return this.item && this.item.podcastContentType === podcastContentType.torahAnytime;
    }

    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            routeStore: routeStore,
            layoutActions: {
                episodes: {
                    onClick: () =>
                        this.rootStore.routerStore.goTo("master.administration.podcast.episodes", {
                            id: this.id,
                        }),
                    label: "ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.EPISODES",
                    className: "c-btn c-btn--base c-btn--ghost",
                },
                edit: {
                    onClick: () =>
                        this.rootStore.routerStore.goTo("master.administration.podcast.edit", { id: this.item.id }),
                    label: "ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.EDIT",
                    className: "c-btn c-btn--base c-btn--primary",
                },
            },
        });
        makeObservable(this);
    }

    async get(id) {
        return this.routeStore.getPodcast(id);
    }

    onClickPodcastEpisodeList = () => {
        this.rootStore.routerStore.goTo("master.administration.podcast.episodes", {
            id: this.id,
        });
    };
}

export default PodcastPreviewViewStore;
