import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunAlbumService, RssSyncService, NakiNigunAlbumFileStreamService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class NakiNigunAlbumTrackListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
        this.nakiNigunAlbumService = this.rootStore.createApplicationService(NakiNigunAlbumService);
        this.rssSyncService = this.rootStore.createApplicationService(RssSyncService);
        this.nakiNigunAblumFileStreamService = this.rootStore.createApplicationService(NakiNigunAlbumFileStreamService);
    }

    async getAlbum(id) {
        const response = await this.nakiNigunAlbumService.get(id, { embed: "artist,albumFeed" });
        return response.data;
    }

    async deleteAlbumTrack(id) {
        const response = await this.nakiNigunAlbumService.deleteAlbumTrack(id);
        return response.data;
    }

    async getSongs(albumId, params) {
        const response = await this.nakiNigunAlbumService.getAllSongs(albumId, params);
        return response.data;
    }

    async hlsEncodeAlbums(options) {
        await this.rssSyncService.hlsEncodeAlbums(options);
    }

    async hlsEncodeAlbumTracks(options) {
        await this.rssSyncService.hlsEncodeAlbumTracks(options);
    }

    async syncNakiNigunAlbums(data) {
        await this.rssSyncService.syncNakiNigunAlbums(data);
    }

    async editAlbumTrackTitle(id, resource) {
        await this.nakiNigunAlbumService.editAlbumTrackTitle(id, resource);
    }

    createAlbumTrackAudioFileUrl(albumTrackId) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.nakiNigunAblumFileStreamService.routeService.getAlbumTrackAudioFile(albumTrackId)
        );
    }

    async syncNakiNigunAlbumsBackground(data) {
        await this.rssSyncService.syncNakiNigunAlbumsBackground(data);
    }
}

export default NakiNigunAlbumTrackListRouteStore;
