import React from "react";
import { NakiMobileHomeSectionListTemplate } from "themes/administration/naki-mobile/pages";
import { NakiMobileHomeSectionListViewStore } from "administration/naki-mobile/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiMobileHomeSectionListViewStore(rootStore, componentProps),
    "nakiMobileHomeSectionListViewStore"
)
class NakiMobileHomeSectionList extends React.Component {
    render() {
        return <NakiMobileHomeSectionListTemplate {...this.props} />;
    }
}

export default NakiMobileHomeSectionList;
