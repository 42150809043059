import { moduleProviderFactory } from "mrb/core";
import { GenreContentList } from "administration/genre/pages";
import { GenreContentListRouteStore } from "administration/genre/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "Genre",
        routes: [
            {
                name: "master.administration.genre",
                pattern: "/genre",
                children: [
                    {
                        name: "master.administration.genre.content",
                        pattern: "/content/:id",
                        store: GenreContentListRouteStore,
                        component: GenreContentList,
                    },
                ],
            },
        ],
    });
})();
