import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const OrderInfoLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);
    const screenWidth = window.innerWidth;
    const loaderHeight = screenWidth < 768 ? 335 : 195;

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div ref={ref}>
            <ContentLoader
                speed={2}
                width="100%"
                height={loaderHeight}
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {screenWidth < 768 ? <MobileInfo /> : <DesktopInfo />}
            </ContentLoader>
        </div>
    );
};

export default OrderInfoLoaderTemplate;

const DesktopInfo = () => {
    return (
        <React.Fragment>
            <rect x="0" y="0" rx="6" ry="6" width="4%" height="8" />
            <rect x="0" y="24" rx="6" ry="6" width="4%" height="12" />

            <rect x="33%" y="0" rx="6" ry="6" width="6%" height="8" />
            <rect x="33%" y="24" rx="6" ry="6" width="24%" height="12" />

            <rect x="69%" y="0" rx="6" ry="6" width="8%" height="8" />
            <rect x="69%" y="24" rx="6" ry="6" width="20%" height="12" />

            <rect x="0" y="80" rx="6" ry="6" width="10%" height="8" />
            <rect x="0" y="104" rx="6" ry="6" width="20%" height="12" />
            <rect x="0" y="138" rx="6" ry="6" width="14%" height="12" />
            <rect x="0" y="160" rx="6" ry="6" width="13%" height="12" />
            <rect x="0" y="182" rx="6" ry="6" width="9%" height="12" />

            <rect x="33%" y="80" rx="6" ry="6" width="10%" height="8" />
            <rect x="33%" y="104" rx="6" ry="6" width="24%" height="12" />

            <rect x="69%" y="80" rx="6" ry="6" width="14%" height="8" />
            <rect x="69%" y="104" rx="6" ry="6" width="28%" height="12" />
            <rect x="69%" y="138" rx="6" ry="6" width="14%" height="12" />
            <rect x="69%" y="160" rx="6" ry="6" width="13%" height="12" />
        </React.Fragment>
    );
};

const MobileInfo = () => {
    return (
        <React.Fragment>
            <rect x="0" y="0" rx="6" ry="6" width="16%" height="8" />
            <rect x="0" y="24" rx="6" ry="6" width="10%" height="12" />

            <rect x="0" y="60" rx="6" ry="6" width="20%" height="8" />
            <rect x="0" y="84" rx="6" ry="6" width="34%" height="12" />

            <rect x="50%" y="60" rx="6" ry="6" width="25%" height="8" />
            <rect x="50%" y="84" rx="6" ry="6" width="32%" height="12" />

            <rect x="0" y="120" rx="6" ry="6" width="14%" height="8" />
            <rect x="0" y="144" rx="6" ry="6" width="28%" height="12" />
            <rect x="0" y="168" rx="6" ry="6" width="14%" height="12" />
            <rect x="0" y="192" rx="6" ry="6" width="13%" height="12" />
            <rect x="0" y="218" rx="6" ry="6" width="9%" height="12" />

            <rect x="50%" y="120" rx="6" ry="6" width="14%" height="8" />
            <rect x="50%" y="144" rx="6" ry="6" width="28%" height="12" />

            <rect x="0" y="250" rx="6" ry="6" width="14%" height="8" />
            <rect x="0" y="274" rx="6" ry="6" width="9%" height="12" />
            <rect x="0" y="298" rx="6" ry="6" width="14%" height="12" />
            <rect x="0" y="322" rx="6" ry="6" width="13%" height="12" />
            <rect x="0" y="348" rx="6" ry="6" width="9%" height="12" />
        </React.Fragment>
    );
};
