import { MrbBaseRouteStore } from "mrb/core";
import { PlaylistService } from "common/services";

class AdminPlaylistPreviewRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
        this.playlistService = this.rootStore.createApplicationService(PlaylistService);
    }

    async getAdminPlaylist(playlistId) {
        const response = await this.playlistService.getAdminPlaylist(playlistId);
        return response.data;
    }

    async deleteAlbumTrackFromAdminPlaylist(playlistId, albumTrackId) {
        const response = await this.playlistService.deleteAlbumTrackFromAdminPlaylist(playlistId, albumTrackId);
        return response.data;
    }
}

export default AdminPlaylistPreviewRouteStore;
