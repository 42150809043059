import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { MrbPreviewPage, MrbPageContentHeader, MrbPageHeader } from "mrb/components/layout";
import { defaultTemplate } from "common/hoc";
import { Image, StatusTag, Popover } from "common/components";
import {
    NakiNigunAlbumTrack,
    NakiNigunArtistAlbumLoader,
    NakiNigunAlbumTrackEditModal,
} from "administration/naki-nigun/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { MrbContentLoader, MrbHeaderLoader } from "mrb/components/loader";
import { FixedSizeList } from "react-window";
import { MrbButton } from "mrb/components/button";
import { GenerateAudioFilesModal } from "administration/content-sync/components";
import { isEmpty } from "lodash";
import { MrbRadioButtons } from "mrb/components/radio";
import { EmptyState } from "common/components/empty-state";

function NakiNigunAlbumTrackListTemplate({ nakiNigunAlbumTrackListViewStore, t }) {
    const {
        openGeneratePopupForAlbum,
        item,
        coverImageUrl,
        isSyncing,
        openGeneratePopupForSong,
        generateAudioFilesModal,
        loaderStore,
        songs,
        filteredSongs,
        filter,
        syncNewSongs,
        editAlbumTrackModal,
        openEditAlbumTrack,
        downloadAlbumTrack,
        goToUploadAlbumTrack,
        deleteAlbumTrack,
    } = nakiNigunAlbumTrackListViewStore;

    return (
        <MrbPreviewPage store={nakiNigunAlbumTrackListViewStore} contentClassNameExtend="u-padd--none" t={t}>
            <MrbPageHeader>
                <div className="c-page__header--top">
                    {item ? (
                        <React.Fragment>
                            <MrbRouterLink
                                routeName="master.administration.naki-nigun.artist.preview"
                                params={{ id: item.artistId }}
                            >
                                <span className="display--ib u-mar--right--tny align--v--middle">
                                    <i className="u-icon u-icon--med u-icon--back" />
                                </span>
                                {item.artist.name}
                            </MrbRouterLink>
                            <div className="c-header__actions c-header__actions--end">
                                {isSyncing ? (
                                    <div className="c-validation--error">
                                        <p className="c-validation__label">
                                            {t("ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.SYNC_MESSAGE")}
                                        </p>
                                    </div>
                                ) : null}
                                {item.isActive && (
                                    <Popover
                                        contentClassName="c-popover__content--right"
                                        trigger={
                                            <MrbButton
                                                label={t("ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.BUTTONS.ACTIONS")}
                                            />
                                        }
                                        contentList={
                                            item.albumFeed.hlsEnabled
                                                ? [
                                                      {
                                                          title: t(
                                                              "ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.BUTTONS.GENERATE"
                                                          ),
                                                          action: openGeneratePopupForAlbum,
                                                          isDisabled: isSyncing,
                                                      },
                                                      {
                                                          title: t(
                                                              "ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.BUTTONS.SYNC_NEW_SONGS"
                                                          ),
                                                          action: syncNewSongs,
                                                          isDisabled: isSyncing,
                                                      },
                                                  ]
                                                : [
                                                      {
                                                          title: t(
                                                              "ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.BUTTONS.SYNC_NEW_SONGS"
                                                          ),
                                                          action: syncNewSongs,
                                                          isDisabled: isSyncing,
                                                      },
                                                  ]
                                        }
                                    />
                                )}
                                <MrbButton
                                    label="ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.BUTTONS.ADD_ALBUM_TRACK"
                                    t={t}
                                    onClick={goToUploadAlbumTrack}
                                />
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            </MrbPageHeader>
            <MrbPageContentHeader classNameOverride="c-content__header--primary">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="c-content__header--primary__img">
                                <Image src={coverImageUrl} />
                            </div>
                            <div className="c-content__header--primary__info">
                                <div className="u-mar--bottom--sml">
                                    <p className="u-type--sml u-type--color--opaque">
                                        {t("ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.TITLE")}
                                    </p>
                                    <div className="u-display--flex u-display--flex--ai--center u-gap--base">
                                        <p className="c-content__header--primary__title">{item.title}</p>

                                        <StatusTag isActive={item.isActive} />
                                    </div>
                                </div>
                                <div className="u-mar--bottom--sml">
                                    <p className="u-type--sml u-type--color--opaque">
                                        {t("ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.RELEASED")}
                                    </p>
                                    <p>{item.year}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <div className="c-container c-container--base">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <NakiNigunArtistAlbumLoader />}>
                    {() => (
                        <React.Fragment>
                            {!isEmpty(songs) && (
                                <div className="u-animation--fadeIn">
                                    <SongFilter filter={filter} t={t} />
                                </div>
                            )}
                            {!isEmpty(songs) && isEmpty(filteredSongs) ? (
                                <div className="c-card c-card--primary c-card--med">
                                    <EmptyState />
                                </div>
                            ) : (
                                <FixedSizeList
                                    height={!isEmpty(songs) ? 500 : 660}
                                    width={"100%"}
                                    itemCount={filteredSongs.length}
                                    itemSize={item.isActive && item.albumFeed.hlsEnabled ? 290 : 255}
                                    style={{ overflowX: "hidden" }}
                                    itemData={{
                                        items: filteredSongs,
                                    }}
                                >
                                    {({ index, style }) => (
                                        <div style={style}>
                                            <NakiNigunAlbumTrack
                                                item={filteredSongs[index]}
                                                wrapperClassName="u-mar--right--sml"
                                                t={t}
                                                isSyncing={isSyncing}
                                                generateAudioFiles={openGeneratePopupForSong}
                                                isActive={item.isActive}
                                                isHlsEnabled={item.albumFeed.hlsEnabled}
                                                openEditAlbumTrack={openEditAlbumTrack}
                                                downloadAlbumTrack={downloadAlbumTrack}
                                                deleteAlbumTrack={deleteAlbumTrack}
                                            />
                                        </div>
                                    )}
                                </FixedSizeList>
                            )}
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
            <GenerateAudioFilesModal modalParams={generateAudioFilesModal} t={t} />
            <NakiNigunAlbumTrackEditModal
                nakiNigunAlbumTrackListViewStore={nakiNigunAlbumTrackListViewStore}
                modalParams={editAlbumTrackModal}
                t={t}
            />
        </MrbPreviewPage>
    );
}

const SongFilter = observer(function SongFilter({ filter, t }) {
    const { setSearch, setHlsAvailable, setMp3Available } = filter;

    const onChange = (event) => {
        setSearch(event.target.value);
    };

    const onInlineReset = () => {
        setSearch("");
    };

    const isSearchEmpty = isEmpty(filter.search);

    return (
        <div className="c-card c-card--primary u-mar--bottom--med">
            <div className="row">
                <div className="col col-sml-12 col-lrg-6">
                    <div className="u-position--rel u-mar--bottom--sml">
                        <input
                            className={`c-input c-input--search c-input--search--sml c-input--full c-input--base ${
                                !isSearchEmpty ? "clear" : ""
                            }`}
                            type="text"
                            placeholder={t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.SEARCH")}
                            value={filter.search || ""}
                            onChange={onChange}
                        />
                        {!isSearchEmpty && (
                            <span className="c-btn--clear" onClick={onInlineReset}>
                                <i className="u-icon u-icon--base u-icon--close--primary"></i>
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="u-display--flex u-gap--med">
                <div className="u-display--flex u-display--flex--column u-display--flex--1">
                    <label className="c-form__field__label">
                        <div className="u-display--flex u-display--flex--jc--space-between">
                            <div>{t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.HLS_LABEL")}</div>
                        </div>
                    </label>
                    <MrbRadioButtons
                        id={`hlsAvailability`}
                        name={`hlsAvailability`}
                        options={[
                            { value: null, label: t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.OPTION_ALL") },
                            { value: true, label: t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.OPTION_AVAILABLE") },
                            { value: false, label: t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.OPTION_NOT_AVAILABLE") },
                        ]}
                        t={t}
                        value={filter.hlsAvailable}
                        classNameExtend={"c-segmented--full"}
                        onChange={(value) => setHlsAvailable(value)}
                    />
                </div>
                <div className="u-display--flex u-display--flex--column u-display--flex--1">
                    <label className="c-form__field__label">
                        <div className="u-display--flex u-display--flex--jc--space-between">
                            <div>{t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.MP3_LABEL")}</div>
                        </div>
                    </label>
                    <MrbRadioButtons
                        id={`mp3Availability`}
                        name={`mp3Availability`}
                        options={[
                            { value: null, label: t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.OPTION_ALL") },
                            { value: true, label: t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.OPTION_AVAILABLE") },
                            { value: false, label: t("ADMINISTRATION.NAKI_NIGUN.SONGS.FILTER.OPTION_NOT_AVAILABLE") },
                        ]}
                        t={t}
                        value={filter.mp3Available}
                        classNameExtend={"c-segmented--full"}
                        onChange={(value) => setMp3Available(value)}
                    />
                </div>
            </div>
        </div>
    );
});

NakiNigunAlbumTrackListTemplate.propTypes = {
    nakiNigunAlbumTrackListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(NakiNigunAlbumTrackListTemplate);
