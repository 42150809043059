import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbTabLayout, MrbTabContent } from "mrb/components/tabs";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import {
    UserStationExpiration,
    UserPodcastExpiration,
    UserSettingsLoader,
} from "administration/user/components";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";
import { MrbContentLoader } from "baasic-react-bootstrap/src";

function UserContentExpirationTemplate({ userContentExpirationViewStore, t }) {
    const {
        tabsStore,
        userStationExpirationViewStore,
        userPodcastExpirationViewStore,
        loaderStore,
    } = userContentExpirationViewStore;
    return (
        <MrbPage store={userContentExpirationViewStore} t={t}>
            <MrbPageHeader>
                <Header
                    userContentExpirationViewStore={
                        userContentExpirationViewStore
                    }
                />
            </MrbPageHeader>

            <div className="c-container--med">
                <MrbContentLoader
                    loaderStore={loaderStore}
                    loaderRender={() => (
                        <UserSettingsLoader
                            backgroundColor={"#33363B"}
                            foregroundColor={"#393C42"}
                        />
                    )}
                >
                    {() => (
                        <React.Fragment>
                            <MrbTabLayout store={tabsStore} t={t}>
                                <MrbTabContent label="Stations">
                                    <UserStationExpiration
                                        userStationExpirationViewStore={
                                            userStationExpirationViewStore
                                        }
                                        t={t}
                                    />
                                </MrbTabContent>
                                <MrbTabContent label="Podcasts">
                                    <UserPodcastExpiration
                                        userPodcastExpirationViewStore={
                                            userPodcastExpirationViewStore
                                        }
                                        t={t}
                                    />
                                </MrbTabContent>
                            </MrbTabLayout>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </MrbPage>
    );
}

UserContentExpirationTemplate.propTypes = {
    userContentExpirationViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const Header = observer(function Header({ userContentExpirationViewStore }) {
    const { userId, user } = userContentExpirationViewStore;
    if (!user) {
        return null;
    }
    const userDisplayName = user && `${user.firstName} ${user.lastName}`;
    return (
        <div className="c-page__header--top">
            <MrbRouterLink
                routeName="master.administration.user.edit"
                params={{ id: userId }}
            >
                <span className="display--ib u-mar--right--tny align--v--middle">
                    <i className="u-icon u-icon--med u-icon--back" />
                </span>
                {userDisplayName}
            </MrbRouterLink>
        </div>
    );
});

Header.propTypes = {
    userContentExpirationViewStore: PropTypes.object.isRequired,
};

export default defaultTemplate(UserContentExpirationTemplate);
