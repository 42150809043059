import { MrbBaseViewStore } from "mrb/core";
import { computed, makeObservable } from "mobx";

class StationPromoPreviewViewStore extends MrbBaseViewStore {
    @computed get coverImageUrl() {
        if (this.item && this.item.coverImageId) {
            return this.routeStore.createStationCoverImageUrl(this.item.coverImageId, 200, 200);
        }

        return null;
    }

    constructor(rootStore, { routeStore, item }) {
        super(rootStore);
        makeObservable(this);
        this.routeStore = routeStore;
        this.item = item;
    }
}

export default StationPromoPreviewViewStore;
