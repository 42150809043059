import React, { useState, useCallback } from "react";
import ContentLoader from "react-content-loader";

const StationsPreviewDescriptionLoaderTemplate = (props) => {
    const screenWidth = window.innerWidth;
    const loaderHeight = screenWidth > 766 ? 220 : 330;
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div className="c-card--primary" ref={ref}>
            <ContentLoader
                speed={2}
                width={loaderWidth}
                height="100%"
                viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                backgroundColor="#262729"
                foregroundColor="#2b2b2b"
                {...props}
            >
                {screenWidth > 766 ? (
                    <React.Fragment>
                        <rect x="0" y={0} rx="6" ry="6" width="220" height="220" />
                        <rect x="250" y={0} rx="6" ry="6" width="168" height="10" />
                        <rect x="250" y={22} rx="6" ry="6" width="30%" height="14" />
                        <rect x="250" y={42} rx="6" ry="6" width="25%" height="14" />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <rect x="37%" y={0} rx="6" ry="6" width="220" height="220" />

                        <rect x="0" y={250 + 12} rx="6" ry="6" width="168" height="10" />
                        <rect x="0" y={250 + 36} rx="6" ry="6" width="268" height="14" />
                        <rect x="0" y={250 + 60} rx="6" ry="6" width={"100%"} height="14" />
                    </React.Fragment>
                )}
            </ContentLoader>
        </div>
    );
};

export default StationsPreviewDescriptionLoaderTemplate;
