import React from 'react'
import ContentLoader from "react-content-loader";

const NakiNigunAddAlbumLoaderTemplate = (props) => {
    const screenwidth = window.innerWidth;
    const loaderWidth = screenwidth > 990 ? screenwidth - 260 : screenwidth;

    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox={`0 0 ${loaderWidth} ${screenwidth > 767 ? 476 : 830}`}
            backgroundColor="#262729"
            foregroundColor="#2b2b2b"
            {...props}
        >
            <AddAlbumsLoader screenwidth={screenwidth} />
        </ContentLoader>
    )
}

export default NakiNigunAddAlbumLoaderTemplate;

const AddAlbumsLoader = ({ screenwidth }) => {
    return (
        screenwidth > 767 ?
            <React.Fragment>
                <rect x="0" y="17" rx="6" ry="6" width="10%" height="10" />
                <rect x="0" y="37" rx="6" ry="6" width="75%" height="40" />

                <rect x="0" y="97" rx="6" ry="6" width="10%" height="10" />
                <rect x="0" y="117" rx="6" ry="6" width="75%" height="40" />

                <rect x="0" y="177" rx="6" ry="6" width="10%" height="10" />
                <rect x="0" y="197" rx="6" ry="6" width="45%" height="40" />

                <rect x="48%" y="177" rx="6" ry="6" width="10%" height="10" />
                <rect x="48%" y="197" rx="6" ry="6" width="27%" height="40" />

                <rect x="80%" y="17" rx="6" ry="6" width="17%" height="150" />
                <rect x="80%" y="197" rx="6" ry="6" width="17%" height="40" />

                <rect x="0" y="270" rx="6" ry="6" width="10%" height="10" />
                <rect x="0" y="290" rx="6" ry="6" width="100%" height="40" />

                <rect x="0" y="357" rx="6" ry="6" width="4%" height="10" />
                <rect x="0" y="377" rx="6" ry="6" width="6%" height="20" />

                <rect x="8%" y="357" rx="6" ry="6" width="4%" height="10" />
                <rect x="8%" y="377" rx="6" ry="6" width="6%" height="20" />

                <rect x="16%" y="357" rx="6" ry="6" width="4%" height="10" />
                <rect x="16%" y="377" rx="6" ry="6" width="15%" height="20" />

                <rect x="32%" y="357" rx="6" ry="6" width="4%" height="10" />
                <rect x="32%" y="377" rx="6" ry="6" width="6%" height="20" />

                <rect x="40%" y="357" rx="6" ry="6" width="4%" height="10" />
                <rect x="40%" y="377" rx="6" ry="6" width="20%" height="20" />

                <rect x="0" y="420" rx="6" ry="6" width="100%" height="1" />
                <rect x="0" y="440" rx="6" ry="6" width="100" height="35" />
                <rect x="120" y="440" rx="6" ry="6" width="100" height="35" />

            </React.Fragment>
            :
            <React.Fragment>
                <rect x="0" y="17" rx="6" ry="6" width="10%" height="10" />
                <rect x="0" y="37" rx="6" ry="6" width="100%" height="40" />

                <rect x="0" y="97" rx="6" ry="6" width="10%" height="10" />
                <rect x="0" y="117" rx="6" ry="6" width="100%" height="40" />

                <rect x="0" y="177" rx="6" ry="6" width="10%" height="10" />
                <rect x="0" y="197" rx="6" ry="6" width="48%" height="40" />

                <rect x="52%" y="177" rx="6" ry="6" width="10%" height="10" />
                <rect x="52%" y="197" rx="6" ry="6" width="48%" height="40" />



                <rect x="37%" y="290" rx="6" ry="6" width="200" height="250" />
                <rect x="37%" y="560" rx="6" ry="6" width="200" height="40" />


                <rect x="0" y="630" rx="6" ry="6" width="60" height="10" />
                <rect x="0" y="650" rx="12" ry="6" width="40" height="24" />

                <rect x="90" y="630" rx="6" ry="6" width="60" height="10" />
                <rect x="90" y="650" rx="12" ry="6" width="40" height="24" />

                <rect x="180" y="630" rx="6" ry="6" width="140" height="10" />
                <rect x="180" y="650" rx="12" ry="6" width="40" height="24" />

                <rect x="350" y="630" rx="6" ry="6" width="60" height="10" />
                <rect x="350" y="650" rx="12" ry="6" width="40" height="24" />

                <rect x="0%" y="700" rx="6" ry="6" width="4%" height="10" />
                <rect x="0%" y="720" rx="6" ry="6" width="200" height="34" />


                <rect x="0" y="770" rx="6" ry="6" width="100%" height="1" />
                <rect x="0" y="790" rx="6" ry="6" width="100" height="35" />
                <rect x="120" y="790" rx="6" ry="6" width="100" height="35" />
            </React.Fragment>
    );
};