import { moduleProviderFactory } from "mrb/core";
import {
    StationEdit,
    StationList,
    StationPreview,
} from "administration/station/pages";
import {
    StationEditRouteStore,
    StationListRouteStore,
    StationPreviewRouteStore,
} from "administration/station/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "Station",
        routes: [
            {
                name: "master.administration.station",
                pattern: "/station",
                children: [
                    {
                        name: "master.administration.station.create",
                        pattern: "/create",
                        component: StationEdit,
                        store: StationEditRouteStore,
                    },
                    {
                        name: "master.administration.station.edit",
                        pattern: "/:id/edit",
                        component: StationEdit,
                        store: StationEditRouteStore,
                    },
                    {
                        name: "master.administration.station.list",
                        pattern: "",
                        component: StationList,
                        store: StationListRouteStore,
                        data: {
                            title: "ADMINISTRATION.STATION.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.administration.station.preview",
                        pattern: "/:id",
                        component: StationPreview,
                        store: StationPreviewRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.STATION",
                order: 2,
                icon: "stations",
                route: "master.administration.station.list",
            },
        ],
    });
})();
