import React from "react";
import { observer } from "mobx-react";
import { PodcastEditTemplate } from "themes/administration/podcast/pages";
import { PodcastEditViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastEditViewStore(rootStore, componentProps),
    "podcastEditViewStore"
)
@observer
class PodcastEdit extends React.Component {
    render() {
        return <PodcastEditTemplate {...this.props} />;
    }
}

export default PodcastEdit;
