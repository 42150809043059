import { BaseShuffleEditRouteStore } from "administration/shuffle/stores";
import { NakiNigunAlbumService } from "common/services";

class NakiNigunShuffleEditRouteStore extends BaseShuffleEditRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.nakiNigunAlbumService = this.rootStore.createApplicationService(NakiNigunAlbumService);
    }

    async getNakiNigunAlbumShuffle(id) {
        const response = await this.service.get(id, {
            embed: "shuffleItems,shuffleItems.nakiNigunAlbum,shuffleItems.nakiNigunAlbum.artist",
        });
        return response.data;
    }

    async createNakiNigunAlbumShuffle(resource) {
        const response = await this.service.createNakiNigun(resource);
        return response.data;
    }

    async updateNakiNigunAlbumShuffle(resource) {
        const response = await this.service.update(resource);
        return response.data;
    }

    async findShufflableNakiNigunAlbum(params) {
        const response = await this.nakiNigunAlbumService.findShufflableNakiNigunAlbum(params);
        return response.data;
    }
}

export default NakiNigunShuffleEditRouteStore;
