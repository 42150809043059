import React from "react";
import { NakiNigunAlbumTrackEditModalTemplate } from "themes/administration/naki-nigun/components";

class NakiNigunAlbumTrackEditModal extends React.Component {
    render() {
        return <NakiNigunAlbumTrackEditModalTemplate {...this.props} />;
    }
}

export default NakiNigunAlbumTrackEditModal;
