import React from "react";
import { NakiNigunArtistEditPageLayoutTemplate } from "themes/administration/naki-nigun/components";

class NakiNigunArtistEditPageLayout extends React.Component {
    render() {
        return <NakiNigunArtistEditPageLayoutTemplate {...this.props} />;
    }
}

export default NakiNigunArtistEditPageLayout;
