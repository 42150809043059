import React from "react";
import { AdminPlaylistItemTemplate } from "themes/administration/playlist/components";

class AdminPlaylistItem extends React.Component {
    render() {
        return <AdminPlaylistItemTemplate {...this.props} />;
    }
}

export default AdminPlaylistItem;
