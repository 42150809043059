import { BasePodcastPreviewViewStore } from "administration/podcast/stores";

class PodcastGroupPreviewViewStore extends BasePodcastPreviewViewStore {
    constructor(rootStore, { routeStore }) {
        super(rootStore, {
            routeStore: routeStore,
            layoutActions: {
                edit: {
                    onClick: () =>
                        this.rootStore.routerStore.goTo("master.administration.podcast.edit-group", {
                            id: this.item.id,
                        }),
                    label: "ADMINISTRATION.PODCAST.PREVIEW.BUTTONS.EDIT",
                    className: "c-btn c-btn--base c-btn--primary",
                },
            },
        });
    }

    async get(id) {
        return this.routeStore.getPodcastGroup(id);
    }
}

export default PodcastGroupPreviewViewStore;
