import React from "react";
import { PodcastEpisodeEditTitleTemplate } from "themes/administration/podcast/components";
import { PodcastEpisodeEditTitleViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new PodcastEpisodeEditTitleViewStore(rootStore, componentProps),
    "podcastEpisodeEditTitleViewStore"
)
class PodcastEpisodeEditTitle extends React.Component {
    render() {
        return <PodcastEpisodeEditTitleTemplate {...this.props} />;
    }
}

export default PodcastEpisodeEditTitle;
