import React from "react";
import { AdminProducerAccountPreviewTemplate } from "themes/administration/producer/pages";
import { AdminProducerAccountPreviewViewStore } from "administration/producer/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new AdminProducerAccountPreviewViewStore(rootStore, componentProps),
    "adminProducerAccountPreviewViewStore"
)
class AdminProducerAccountPreview extends React.Component {
    render() {
        return <AdminProducerAccountPreviewTemplate {...this.props} />;
    }
}

export default AdminProducerAccountPreview;
