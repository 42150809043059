import { moduleProviderFactory } from "mrb/core";
import {
    PodcastEpisodeContentExplorer,
    AlbumTrackContentExplorer,
    ContentExplorer,
} from "administration/content-explorer/pages";
import {
    PodcastEpisodeContentExplorerRouteStore,
    ContentExplorerRouteStore,
    AlbumTrackContentExplorerRouteStore,
} from "administration/content-explorer/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "content-explorer",
        routes: [
            {
                name: "master.administration.content-explorer",
                pattern: "/content-explorer",
                children: [
                    {
                        name: "master.administration.content-explorer.list",
                        pattern: "",
                        component: ContentExplorer,
                        store: ContentExplorerRouteStore,
                        children: [
                            {
                                name: "master.administration.content-explorer.list.podcast-episodes",
                                pattern: "podcast-episodes",
                                component: PodcastEpisodeContentExplorer,
                                store: PodcastEpisodeContentExplorerRouteStore,
                            },
                            {
                                name: "master.administration.content-explorer.list.album-tracks",
                                pattern: "album-tracks",
                                component: AlbumTrackContentExplorer,
                                store: AlbumTrackContentExplorerRouteStore,
                            },
                        ],
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.CONTENT_EXPLORER",
                order: 6,
                icon: "content-explorer",
                route: "master.administration.content-explorer.list.podcast-episodes",
            },
        ],
    });
})();
