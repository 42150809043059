import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { MrbTabLayout, MrbTabContent } from "mrb/components/tabs";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { defaultTemplate } from "common/hoc";
import {
    StationDevice,
    PodcastDevice,
    UserDeviceSettingsTabHeaders,
    UserDeviceSettingsEmptyState,
    UserSettingsLoader,
} from "administration/user/components";
import { observer } from "mobx-react";
import { MrbContentLoader } from "baasic-react-bootstrap/src";

function UserDeviceSettingsTemplate({ userDeviceSettingsViewStore, t }) {
    const {
        userId,
        anyDevice,
        tabsStore,
        deviceSelectStore,
        stationDeviceViewStore,
        podcastDeviceViewStore,
        loaderStore,
    } = userDeviceSettingsViewStore;

    return (
        <MrbPage store={userDeviceSettingsViewStore} t={t}>
            <MrbPageHeader>
                <Header
                    userDeviceSettingsViewStore={userDeviceSettingsViewStore}
                />
            </MrbPageHeader>

            <div className="c-container--med">
                <MrbContentLoader
                    loaderStore={loaderStore}
                    loaderRender={() => (
                        <UserSettingsLoader
                            dropdown
                            backgroundColor={"#33363B"}
                            foregroundColor={"#393C42"}
                        />
                    )}
                >
                    {() => (
                        <React.Fragment>
                            {anyDevice ? (
                                <MrbTabLayout
                                    store={tabsStore}
                                    t={t}
                                    headersRenderer={(props) => (
                                        <UserDeviceSettingsTabHeaders
                                            deviceSelectStore={
                                                deviceSelectStore
                                            }
                                            {...props}
                                        />
                                    )}
                                >
                                    <MrbTabContent label="Stations">
                                        <StationDevice
                                            stationDeviceViewStore={
                                                stationDeviceViewStore
                                            }
                                            t={t}
                                        />
                                    </MrbTabContent>
                                    <MrbTabContent label="Podcasts">
                                        <PodcastDevice
                                            podcastDeviceViewStore={
                                                podcastDeviceViewStore
                                            }
                                            t={t}
                                        />
                                    </MrbTabContent>
                                </MrbTabLayout>
                            ) : (
                                <UserDeviceSettingsEmptyState
                                    userId={userId}
                                    t={t}
                                />
                            )}
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </div>
        </MrbPage>
    );
}

UserDeviceSettingsTemplate.propTypes = {
    userDeviceSettingsViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const Header = observer(function Header({ userDeviceSettingsViewStore }) {
    const { userId, user } = userDeviceSettingsViewStore;
    let userDisplayName;
    if (user) {
        userDisplayName = user && `${user.firstName} ${user.lastName}`;
    }
    return (
        <div className="c-page__header--top">
            <MrbRouterLink
                routeName="master.administration.user.edit"
                params={{ id: userId }}
            >
                <span className="display--ib u-mar--right--tny align--v--middle">
                    <i className="u-icon u-icon--med u-icon--back" />
                </span>
                {userDisplayName}
            </MrbRouterLink>
        </div>
    );
});

Header.propTypes = {
    userDeviceSettingsViewStore: PropTypes.object.isRequired,
};

export default defaultTemplate(UserDeviceSettingsTemplate);
