import { computed, makeObservable, observable } from "mobx";
import { MrbBaseEditViewStore } from "mrb/core";
import { PodcastEpisodeEditForm } from "administration/podcast/forms";
import { MrbFileUploadViewStore } from "mrb/components/media";
import { PodcastCoverImageUploadViewStore } from "administration/podcast/stores";
import { head, isNil } from "lodash";
import { httpStatusCodes } from "mrb/core/constants";
import { localizationService } from "common/localization";

class PodcastEpisodeEditViewStore extends MrbBaseEditViewStore {
    @observable.ref navigateBack = null;

    @computed get file() {
        return head(this.fileUploadStore.files);
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            name: "PodcastEpisodeEdit",
            FormClass: PodcastEpisodeEditForm,
            autoFocusField: "title",
            successCreateNotification: null,
            successUpdateNotification: null,
            redirectOnUpdateSuccess: () =>
                this.rootStore.routerStore.goTo("master.administration.podcast.episodes", { id: id }),
        });
        this.routeStore = routeStore;
        this.podcastId = id;
        makeObservable(this);

        this.fileUploadStore = new MrbFileUploadViewStore(this.rootStore, {
            acceptedFiles: "audio/mpeg",
            maxSize: 500000000, // 500 MB in bytes
            showValidation: false,
            errorsMap: {
                "file-invalid-type": () => {
                    return "File type must be .mp3";
                },
                "file-too-large": () => {
                    return "File is larger than 500MB";
                },
            },
        });

        this.reaction(
            () => this.file,
            (file) => {
                if (!isNil(file)) {
                    const fileName = file.name ? file.name.split(".")[0] : null;
                    if (fileName) {
                        this.form.$("title").value = fileName;
                    }
                }
            }
        );

        this.coverImageUploadViewStore = new PodcastCoverImageUploadViewStore(this.rootStore, {
            routeStore: this.routeStore,
        });
    }

    async get(id) {
        const podcast = await this.routeStore.getPodcast(id);
        this.navigateBack = this._createNavigateBackAction(podcast);
    }

    async update(data) {
        if (!this.file || !data || !data.title) {
            return;
        }

        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("title", data.title);
        if (!isNil(this.coverImageUploadViewStore.uploadedFileId)) {
            formData.append("coverImageId", this.coverImageUploadViewStore.uploadedFileId);
        }

        try {
            await this.routeStore.uploadEpisode(this.podcastId, formData);
        } catch (error) {
            if (error.statusCode === httpStatusCodes.Conflict)
                this.rootStore.notificationStore.error(
                    localizationService.t("ADMINISTRATION.PODCAST.EPISODES.UPLOAD.ERROR.ALREADY_EXIST"),
                    error
                );
            else {
                this.rootStore.notificationStore.error(
                    localizationService.t("ADMINISTRATION.PODCAST.EPISODES.UPLOAD.ERROR.GENERIC"),
                    error
                );
            }
        }
    }

    attachAudioFile = (event) => {
        this.fileUploadStore.raiseBrowse(event);
    };

    removeAudioFile = () => {
        this.fileUploadStore.clear();
    };

    _createNavigateBackAction(podcast) {
        if (podcast) {
            return {
                title: podcast.title,
                routeName: "master.administration.podcast.episodes",
                routeParams: { id: podcast.id },
            };
        }
    }
}

export default PodcastEpisodeEditViewStore;
