import { moduleProviderFactory } from "mrb/core";
import { OrderList, OrderPreview } from "administration/order/pages";
import { OrderListRouteStore, OrderPreviewRouteStore } from "administration/order/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "Order",
        routes: [
            {
                name: "master.administration.order",
                pattern: "/order",
                children: [
                    {
                        name: "master.administration.order.list",
                        pattern: "",
                        component: OrderList,
                        store: OrderListRouteStore,
                        data: {
                            title: "ADMINISTRATION.ORDER.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.administration.order.preview",
                        pattern: ":id",
                        component: OrderPreview,
                        store: OrderPreviewRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.ORDER",
                order: 12,
                icon: "orders",
                route: "master.administration.order.list",
            },
        ],
    });
})();
