import React from "react";
import { PropTypes } from "prop-types";
import { DragDropList } from "common/components";
import { observer } from "mobx-react";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { NakiMobileHomeSection } from "administration/naki-mobile/components";
import { MrbButton } from "mrb/components/button";
import { MrbContentLoader } from "mrb/components/loader";
import { defaultTemplate } from "common/hoc";
import { SectionListLoader, AddGenreHomeSectionModal } from "administration/naki-mobile/components";
import { Popover } from "common/components";

function NakiMobileHomeSectionListTemplate({ nakiMobileHomeSectionListViewStore, t }) {
    return (
        <MrbPage store={nakiMobileHomeSectionListViewStore} t={t}>
            <MrbPageHeader>
                <div>Home Screen Sections</div>
                <Actions store={nakiMobileHomeSectionListViewStore} t={t} />
            </MrbPageHeader>

            <PanelHeader nakiMobileHomeSectionListViewStore={nakiMobileHomeSectionListViewStore} />

            <SectionList nakiMobileHomeSectionListViewStore={nakiMobileHomeSectionListViewStore} t={t} />
        </MrbPage>
    );
}

const SectionList = observer(function SectionList({ nakiMobileHomeSectionListViewStore, t }) {
    const { sections, reorderSections, loaderStore } = nakiMobileHomeSectionListViewStore;
    return (
        <div className="c-card--med c-card--primary u-mar--top--sml">
            <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <SectionListLoader />}>
                {() => (
                    <React.Fragment>
                        {sections.length > 0 && (
                            <div>
                                <DragDropList
                                    droppableId="naki-mobile-home-sections"
                                    items={sections}
                                    onDragEnd={reorderSections}
                                    itemRender={(item) => (
                                        <NakiMobileHomeSection
                                            id={item.id}
                                            nakiMobileHomeSectionListViewStore={nakiMobileHomeSectionListViewStore}
                                            t={t}
                                        />
                                    )}
                                ></DragDropList>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </MrbContentLoader>
        </div>
    );
});

const Actions = observer(function Actions({ store, t }) {
    const { onClickSave, isSubmitting } = store;
    return (
        <React.Fragment>
            <MrbButton
                t={t}
                className="c-btn c-btn--base c-btn--primary"
                onClick={onClickSave}
                aria-label="Save"
                aria-busy={isSubmitting}
                label="Save"
                disabled={isSubmitting}
            />
        </React.Fragment>
    );
});

const PanelHeader = observer(function PanelHeader({ nakiMobileHomeSectionListViewStore }) {
    const {
        addSection,
        openAddGenreHomeSectionModal,
        sections,
        isSubmitting,
        sectionsLimit,
        sectionsLimitReached,
        addGenreHomeSectionModalParams,
    } = nakiMobileHomeSectionListViewStore;
    const label = `Add Section (${sections.length}/${sectionsLimit})`;
    return (
        <div className="c-card--med c-card--primary">
            <Popover
                isDisabled={isSubmitting || sectionsLimitReached}
                trigger={
                    <MrbButton
                        className="c-btn c-btn--med c-btn--ghost u-mar--right--sml"
                        label={label}
                        disabled={isSubmitting || sectionsLimitReached}
                    />
                }
                contentList={[
                    {
                        title: "Add custom section",
                        action: addSection,
                        isDisabled: isSubmitting || sectionsLimitReached,
                    },
                    {
                        title: "Add genre section",
                        action: openAddGenreHomeSectionModal,
                        isDisabled: isSubmitting || sectionsLimitReached,
                    },
                ]}
            />
            <AddGenreHomeSectionModal
                nakiMobileHomeSectionListViewStore={nakiMobileHomeSectionListViewStore}
                modalParams={addGenreHomeSectionModalParams}
            />
            {sectionsLimitReached && (
                <div className="c-device-menu__note c-device-menu__note--secondary">
                    Maximum number of home screen sections reached.
                </div>
            )}
        </div>
    );
});

NakiMobileHomeSectionListTemplate.propTypes = {
    nakiMobileHomeSectionListViewStore: PropTypes.object.isRequired,
};

export default defaultTemplate(NakiMobileHomeSectionListTemplate);
