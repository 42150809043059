import { MrbBaseViewStore } from "mrb/core";
import { StationPromoImageUploadViewStore } from "administration/station/stores";

class StationPromoEditViewStore extends MrbBaseViewStore {
    form = null;

    constructor(rootStore, { stationEditViewStore, form }) {
        super(rootStore);

        this.form = form;
        this.stationEditViewStore = stationEditViewStore;
        this.routeStore = stationEditViewStore.routeStore;

        this.promoImageUploadViewStore = new StationPromoImageUploadViewStore(this.rootStore, {
            routeStore: this.routeStore,
            width: 200,
            height: 200,
        });
    }
}

export default StationPromoEditViewStore;
