import { LocalizedForm } from "common/localization";

export default class PodcastEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "title",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.TITLE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.TITLE_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "description",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.DESCRIPTION_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.DESCRIPTION_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "alternativeDescription",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PORTAL_DESCRIPTION_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PORTAL_DESCRIPTION_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "premiumInfo",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PREMIUM_INFO_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PREMIUM_INFO_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "descriptionLink",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.DESCRIPTION_LINK_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.DESCRIPTION_LINK_PLACEHOLDER",
                    rules: "string|url",
                },
                {
                    name: "isActive",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_ACTIVE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_ACTIVE_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isFeatured",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_FEATURED_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_FEATURED_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isPremium",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_PREMIUM_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_PREMIUM_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isVisible",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isTurnedOnByDefault",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isTurnedOnByDefaultInApp",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_IN_APP_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_IN_APP_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "isTurnedOnByDefaultInEmbeddedApp",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_IN_EMBEDDED_APP_LABEL",
                    placeholder:
                        "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_TURNED_ON_BY_DEFAULT_IN_EMBEDDED_APP_PLACEHOLDER",
                    type: "checkbox",
                },
                {
                    name: "overrideRadioUsersSettings",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_OVERRIDE_USERS_SETTINGS",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_OVERRIDE_USERS_SETTINGS",
                    type: "checkbox",
                },
                {
                    name: "overrideAppUsersSettings",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_OVERRIDE_USERS_SETTINGS",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_OVERRIDE_USERS_SETTINGS",
                    type: "checkbox",
                },
                {
                    name: "isVisibleInApp",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_IN_APP",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_IN_APP",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "isVisibleInEmbeddedApp",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_IN_EMBEDDED_APP",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_VISIBLE_IN_EMBEDDED_APP",
                    type: "checkbox",
                    value: true,
                },
                {
                    name: "excludedFromRevenue",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.EXCLUDED_FROM_REVENUE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.EXCLUDED_FROM_REVENUE_PLACEHOLDER",
                    type: "checkbox",
                    value: false,
                },
                {
                    name: "genreIds",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.GENRE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.GENRE_PLACEHOLDER",
                },
                {
                    name: "locationId",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.LOCATION_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.LOCATION_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "languageId",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.LANGUAGE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.LANGUAGE_PLACEHOLDER",
                    rules: "required|string",
                },
                {
                    name: "producerId",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PRODUCER_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PRODUCER_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "keywords",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.KEYWORDS_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.KEYWORDS_PLACEHOLDER",
                    rules: "string",
                },
                {
                    name: "podcastContentType",
                    label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PODCAST_CONTENT_TYPE_LABEL",
                    placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.PODCAST_CONTENT_TYPE_PLACEHOLDER",
                    rules: "required|numeric",
                },
                {
                    name: "podcastFeed",
                    fields: [
                        {
                            name: "feedUrl",
                            label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.FEED_URL_LABEL",
                            placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.FEED_URL_PLACEHOLDER",
                            rules: "required|string",
                        },
                        {
                            name: "episodeSortTypeId",
                            label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.EPISODE_SORT_TYPE_LABEL",
                            placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.EPISODE_SORT_TYPE_PLACEHOLDER",
                            rules: "required|string",
                        },
                        {
                            name: "episodeLimit",
                            type: "numeric",
                            label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.EPISODE_LIMIT_LABEL",
                            placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.EPISODE_LIMIT_PLACEHOLDER",
                            rules: "numeric|not_in:0",
                        },
                        {
                            name: "recentlyUpdatedVisibility",
                            type: "checkbox",
                            label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.RECENTLY_UPDATED_VISIBILITY_LABEL",
                            placeholder: "ADMINISTRATION.PODCAST.EDIT.FIELDS.RECENTLY_UPDATED_VISIBILITY_PLACEHOLDER",
                        },
                        {
                            name: "hlsEnabled",
                            label: "ADMINISTRATION.PODCAST.EDIT.FIELDS.IS_HLS_ENABLED_LABEL",
                            placeholder: "",
                            type: "checkbox",
                        },
                    ],
                },
            ],
        };
    }
}
