import React from "react";
import { ShuffleEditItemsTemplate } from "themes/administration/shuffle/components";

class ShuffleEditItems extends React.Component {
    render() {
        return <ShuffleEditItemsTemplate {...this.props} />;
    }
}

export default ShuffleEditItems;
