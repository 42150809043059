import { MrbBaseInfiniteScrollListViewStore } from "mrb/core";
import { MrbInfiniteScrollGridViewStore } from "mrb/components/infinite-scroll-grid";
import { action, observable, makeObservable } from "mobx";
import { httpStatusCodes } from "mrb/core/constants";

class GenreContentListViewStore extends MrbBaseInfiniteScrollListViewStore {
    @observable genre = null;

    constructor(rootStore, { routeStore }) {
        super(rootStore, {});
        makeObservable(this);

        this.routeStore = routeStore;
        this.genreId = rootStore.routerStore.routerState.params.id;

        this.setGridStore(
            new MrbInfiniteScrollGridViewStore(this.rootStore, this.queryUtility, {
                generateKey: (item) => item.id,
                mapper: (item) => {
                    if (item.podcast) {
                        return {
                            id: item.podcast.id,
                            type: "Podcast",
                            title: item.podcast.title,
                            isPremium: item.podcast.isPremium,
                            isActive: item.podcast.isActive,
                            coverImageUrl: this.createPodcastCoverImageUrl(item.podcast),
                            routeParams: {
                                route: "master.administration.podcast.preview",
                                params: { id: item.podcast.id },
                            },
                        };
                    } else if (item.album) {
                        return {
                            id: item.album.id,
                            type: "Album",
                            title: item.album.title,
                            isPremium: item.album.isPremium,
                            isActive: item.album.isActive,
                            coverImageUrl: this.createAlbumCoverImageUrl(item.album),
                            routeParams: {
                                route: "master.administration.naki-nigun.artist.preview",
                                params: { id: item.album.artistId },
                                queryParams: { albumId: item.album.id },
                            },
                        };
                    } else if (item.station) {
                        return {
                            id: item.station.id,
                            type: "Station",
                            title: item.station.title,
                            isPremium: item.station.isPremium,
                            isActive: item.station.isActive,
                            coverImageUrl: this.createStationCoverImageUrl(item.station),
                            routeParams: {
                                route: "master.administration.station.preview",
                                params: { id: item.station.id },
                            },
                        };
                    }
                },
            })
        );

        this.createPodcastCoverImageUrl = this.createPodcastCoverImageUrl.bind(this);
        this.createAlbumCoverImageUrl = this.createAlbumCoverImageUrl.bind(this);
        this.createStationCoverImageUrl = this.createStationCoverImageUrl.bind(this);
    }

    async onInit() {
        return Promise.all([this.queryUtility.initialize(), this.getGenre(this.genreId)]);
    }

    async getGenre(genreId) {
        if (genreId) {
            try {
                const genre = await this.routeStore.getGenre(genreId);
                this.setGenre(genre);
                return true;
            } catch (error) {
                if (error.statusCode === httpStatusCodes.NotFound) {
                    await this.rootStore.routerStore.goToNotFound();
                    return false;
                } else {
                    throw error;
                }
            }
        }
    }

    async find(params) {
        if (this.genreId) {
            try {
                const filter = {
                    pageNumber: params.pageNumber,
                    pageSize: 30,
                };
                const genreContent = await this.routeStore.getGenreContent(this.genreId, filter);
                return genreContent;
            } catch (error) {
                if (error.statusCode === httpStatusCodes.NotFound) {
                    await this.rootStore.routerStore.goToNotFound();
                    return false;
                } else {
                    throw error;
                }
            }
        }
    }

    createPodcastCoverImageUrl(podcast) {
        if (podcast.coverImageId) {
            return this.rootStore.coverImageFactory.createPodcastCoverImageUrl(podcast.coverImageId, 150, 150);
        }
        return null;
    }

    createAlbumCoverImageUrl(album) {
        if (album.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(album.coverImageId, 150, 150);
        }
        return null;
    }

    createStationCoverImageUrl(station) {
        if (station.coverImageId) {
            return this.rootStore.coverImageFactory.createStationCoverImageUrl(station.coverImageId, 150, 150);
        }
        return null;
    }

    @action.bound
    setGenre(genre) {
        this.genre = genre;
    }
}

export default GenreContentListViewStore;
