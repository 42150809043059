import React from "react";
import { PropTypes } from "prop-types";
import { AdminPlaylist } from "administration/playlist/components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";
import { observer } from "mobx-react";
import { MrbButton, MrbPage, MrbPageHeader } from "baasic-react-bootstrap/src";
import { defaultTemplate } from "common/hoc";

function AdminPlaylistListTemplate({ adminPlaylistListViewStore, t }) {
    const { playlists, reorderPlaylists, setVirtualizedListRef } = adminPlaylistListViewStore;

    return (
        <MrbPage>
            <MrbPageHeader>
                {t("ADMINISTRATION.PLAYLIST.TITLE")}
                <div className="c-header__actions c-header__actions--end">
                    <Actions store={adminPlaylistListViewStore} t={t} />
                </div>
            </MrbPageHeader>
            <div className="col col-sml-12 col-lrg-8 col-xxlrg-6 c-card--primary">
                <DragDropContext onDragEnd={reorderPlaylists}>
                    <Droppable
                        droppableId="playlist"
                        mode="virtual"
                        renderClone={(provided, snapshot, rubric) => (
                            <AdminPlaylist
                                provided={provided}
                                isDragging={snapshot.isDragging}
                                item={playlists[rubric.source.index]}
                                store={adminPlaylistListViewStore}
                                index={rubric.source.index}
                                t={t}
                            />
                        )}
                    >
                        {(provided) => (
                            <FixedSizeList
                                height={714}
                                width={"100%"}
                                itemCount={playlists.length}
                                itemSize={69}
                                itemData={{
                                    items: playlists,
                                    store: adminPlaylistListViewStore,
                                }}
                                outerRef={provided.innerRef}
                                ref={setVirtualizedListRef}
                                t={t}
                            >
                                {Row}
                            </FixedSizeList>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </MrbPage>
    );
}

AdminPlaylistListTemplate.propTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const Row = React.memo(({ data, index, style, t }) => {
    const { items, store } = data;
    const item = items[index];

    return (
        <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={store.submitting}>
            {(provided) => (
                <AdminPlaylist provided={provided} style={style} item={item} index={index} store={store} t={t} />
            )}
        </Draggable>
    );
});

const Actions = observer(function Actions({ store, t }) {
    const { layoutActions, submitting } = store;
    return (
        <React.Fragment>
            <MrbButton
                t={t}
                className="c-btn c-btn--base c-btn--primary"
                onClick={layoutActions.save.onClick}
                aria-label={layoutActions.save.label}
                aria-busy={submitting}
                label={layoutActions.save.label}
                disabled={submitting}
            />
        </React.Fragment>
    );
});

export default defaultTemplate(AdminPlaylistListTemplate);
