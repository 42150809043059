import React from "react";
import { PropTypes } from "prop-types";
import { MrbGrid } from "mrb/components/grid";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import { UserDeviceCell } from "administration/user/components";
import { MrbLineContentLoader } from "mrb/components/loader";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";

function UserDeviceListTemplate({ userDeviceListViewStore, t }) {
    const { gridStore } = userDeviceListViewStore;
    return (
        <MrbPage store={userDeviceListViewStore} t={t}>
            <MrbPageHeader>
                <Header userDeviceListViewStore={userDeviceListViewStore} />
            </MrbPageHeader>
            <div className="c-container--base">
                <MrbGrid
                    emptyStateRender={() => <NoDataEmptyState t={t} />}
                    loaderRender={() => (
                        //TODO: loader
                        <MrbLineContentLoader
                            lineheight={65}
                            setY={85}
                            rows={10}
                            backgroundColor={"#33363B"}
                            foregroundColor={"#393C42"}
                        />
                    )}
                    classNameOverride="test"
                    store={gridStore}
                    t={t}
                    cellRender={(props) => (
                        <UserDeviceCell
                            userDeviceId={props.item.id}
                            userDeviceListViewStore={userDeviceListViewStore}
                            {...props}
                        />
                    )}
                />
            </div>
        </MrbPage>
    );
}

UserDeviceListTemplate.propTypes = {
    userDeviceListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

const Header = observer(function Header({ userDeviceListViewStore }) {
    const { userId, user } = userDeviceListViewStore;
    let userDisplayName;
    if (user) {
        userDisplayName = user && `${user.firstName} ${user.lastName}`;
    }
    return (
        <div className="c-page__header--top">
            <MrbRouterLink routeName="master.administration.user.edit" params={{ id: userId }}>
                <span className="display--ib u-mar--right--tny align--v--middle">
                    <i className="u-icon u-icon--med u-icon--back" />
                </span>
                {userDisplayName}
            </MrbRouterLink>
        </div>
    );
});

Header.propTypes = {
    userDeviceSettingsViewStore: PropTypes.object.isRequired,
};

function NoDataEmptyState({ t }) {
    return (
        <div className="c-card c-card--med c-card--primary">
            <div className="c-empty-state--no-results c-empty-state--no-results--lrg">
                <div className="u-mar--bottom--sml">
                    <i className="u-icon u-icon--xhuge u-icon--playlist-add"></i>
                </div>
                <div className="u-mar--bottom--med">
                    <h3>{t("APPLICATION.DEVICE_LIST.NO_DEVICES_REGISTERED")}</h3>
                </div>
            </div>
        </div>
    );
}

export default defaultTemplate(UserDeviceListTemplate);
