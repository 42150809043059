import React from "react";
import { PropTypes } from "prop-types";
import { MrbTable } from "mrb/components/table";
import { MrbQuerySearchInput } from "mrb/components/input";
import { FloatingCard, TableNoDataOverlay } from "common/components";
import { defaultTemplate } from "common/hoc";
import { MrbSelect } from "mrb/components/select";
import { MrbRadioButtons } from "mrb/components/radio";
import { observer } from "mobx-react";
import { MrbButton } from "baasic-react-bootstrap/src";

function AlbumTrackContentExplorerTemplate({ albumTrackContentExplorerViewStore, t }) {
    const { gridStore, queryUtility, albumSelectStore, artistSelectStore, downloadSelectedItems, selectedItemIds } =
        albumTrackContentExplorerViewStore;
    return (
        <div>
            <div className="u-mar--bottom--med">
                <div className="u-display--flex u-display--flex--ai--flex-end u-display--flex--wrap u-gap--base u-mar--bottom--sml c-container--lrg">
                    <div className="u-display--flex u-display--flex--column">
                        <MrbSelect
                            t={t}
                            closeMenuOnSelect={false}
                            store={albumSelectStore}
                            classNameExtend="c-dropdown--dark c-dropdown--sml-custom"
                        />
                    </div>
                    <div className="u-display--flex u-display--flex--column">
                        <MrbSelect
                            t={t}
                            closeMenuOnSelect={false}
                            store={artistSelectStore}
                            classNameExtend="c-dropdown--dark c-dropdown--sml-custom"
                        />
                    </div>
                    <AlbumTrackFilter store={albumTrackContentExplorerViewStore} t={t} />

                    <MrbQuerySearchInput queryUtility={queryUtility} />

                    {selectedItemIds.length > 0 && (
                        <FloatingCard>
                            <div className="u-display--flex u-display--flex--ai--center u-display--flex--jc--space-between">
                                <div className="u-display--flex u-display--flex--ai--center u-gap--sml">
                                    <p className="u-type--base">
                                        {selectedItemIds.length} file{selectedItemIds.length > 1 && "s"} selected
                                    </p>
                                    <MrbButton
                                        onlyIcon
                                        icon="u-icon u-icon--base u-icon--close"
                                        onClick={() => gridStore.gridApi.deselectAll()}
                                        classNameOverride="c-btn c-btn--icon"
                                    />
                                </div>
                                <MrbButton
                                    icon="u-icon u-icon--med u-icon--download u-mar--right--tny"
                                    classNameOverride="c-btn c-btn--base c-btn--outline"
                                    label="Download"
                                    disabled={selectedItemIds.length === 0}
                                    onClick={downloadSelectedItems}
                                />
                            </div>
                        </FloatingCard>
                    )}
                </div>
            </div>

            <div className="c-content__main">
                <MrbTable
                    t={t}
                    tableStore={gridStore}
                    className="ag-theme-alpine"
                    emptyStateRender={TableNoDataOverlay}
                />
            </div>
        </div>
    );
}

const AlbumTrackFilter = observer(function AlbumTrackFilter({ store, t }) {
    const { changeAudioTrackAvailabilityFilter, queryUtility, audioFileAvailabilityOptions } = store;
    return (
        <div className="u-display--flex u-gap--med">
            <div className="u-display--flex u-display--flex--column u-display--flex--1">
                <label className="c-form__field__label">
                    <div className="u-display--flex u-display--flex--jc--space-between">
                        <div>{t("ADMINISTRATION.CONTENT_EXPLORER.PODCAST_EPISODE_LIST.FILTER.MP3_LABEL")}</div>
                    </div>
                </label>
                <MrbRadioButtons
                    options={audioFileAvailabilityOptions}
                    t={t}
                    value={queryUtility.filter.audioTrackAvailable}
                    classNameExtend={"c-segmented--full"}
                    onChange={changeAudioTrackAvailabilityFilter}
                />
            </div>
        </div>
    );
});

AlbumTrackContentExplorerTemplate.propTypes = {
    albumTrackContentExplorerViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(AlbumTrackContentExplorerTemplate);
