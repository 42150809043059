import { MrbBaseRouteStore } from "mrb/core";
import { PlaylistService } from "common/services";

class AdminPlaylistListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(PlaylistService);
    }

    async getAllAdminGeneratedPlaylists() {
        const response = await this.service.getAllAdminGeneratedPlaylists();
        return response.data;
    }

    async updateAdminGeneratedPlaylists(playlists) {
        const response = await this.service.updateAdminGeneratedPlaylists(playlists);
        return response.data;
    }
}

export default AdminPlaylistListRouteStore;
