import React from "react";
import { NakiNigunArtistListTemplate } from "themes/administration/naki-nigun/pages";
import { NakiNigunArtistListViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunArtistListViewStore(rootStore, componentProps),
    "nakiNigunArtistListViewStore"
)
class NakiNigunArtistList extends React.Component {
    render() {
        return <NakiNigunArtistListTemplate {...this.props} />;
    }
}

export default NakiNigunArtistList;
