import { action } from "mobx";
import { BaseShuffleEditViewStore } from "administration/shuffle/stores";
import { MrbSelectStore } from "mrb/components/select";
import { UUID } from "common/utils";
import { head, findIndex, map } from "lodash";

class PodcastShuffleEditViewStore extends BaseShuffleEditViewStore {
    constructor(rootStore, componentProps) {
        super(rootStore, componentProps);

        this.shuffleItemSelectStore = new MrbSelectStore({
            isSearchable: true,
            textKey: "title",
            valueKey: "id",
            initFetch: false,
            actions: {
                find: (searchTerm, pageNumber, pageSize) => {
                    return this.findPodcasts(searchTerm, pageNumber, pageSize);
                },
            },
        });
    }

    findPodcasts(searchTerm, pageNumber, pageSize) {
        const selectedIds = map(this.items, (item) => item.podcast.id);

        const params = {
            search: searchTerm,
            pageNumber: pageNumber,
            pageSize: pageSize,
            orderBy: "title",
            orderDirection: "asc",
            fields: "id,title,isPremium",
            excludeIds: selectedIds.length > 0 ? selectedIds : null,
        };
        return this.routeStore.findShufflablePodcast(params);
    }

    create(data) {
        return this.routeStore.createPodcastShuffle(data);
    }

    get(id) {
        return this.routeStore.getPodcastShuffle(id);
    }

    update(data) {
        return this.routeStore.updatePodcastShuffle(data);
    }

    @action.bound
    addPodcastShuffleItem() {
        const podcast = this.shuffleItemSelectStore.selectedItem;
        const obj = {
            id: UUID.create(),
            podcastId: podcast.id,
            podcast: podcast,
            shuffleWeightId: head(this.shuffleWeights).id,
        };
        if (this.itemExists(obj.podcastId)) {
            return;
        }
        this.addShuffleItem(obj);
        this.shuffleItemSelectStore.clearOptions();
        this.shuffleItemSelectStore.filter();
    }

    itemExists(itemId) {
        return (
            findIndex(this.items, (item) => item.podcastId === itemId) !== -1
        );
    }
}

export default PodcastShuffleEditViewStore;
