import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage, MrbPageContentHeader } from "mrb/components/layout";
import { MrbContentLoader } from "mrb/components/loader";
import { map } from "lodash";
import { defaultTemplate } from "common/hoc";
import { ShuffleListLoader } from "administration/shuffle/components";
import { MrbHeaderWithoutImageLoader } from "mrb/components/loader";
import { mergeCss } from "mrb/common/utils";
function ShufflePreviewTemplate({ shufflePreviewViewStore, t }) {
    const { item, loaderStore, goTo } = shufflePreviewViewStore;
    return (
        <MrbPreviewPage store={shufflePreviewViewStore} contentClassNameExtend="u-padd--none" t={t}>
            <MrbPageContentHeader classNameOverride="c-content__header--primary c-content__header--no-image">
                <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <MrbHeaderWithoutImageLoader />}>
                    {() => (
                        <React.Fragment>
                            <div className="u-animation--fadeIn">
                                <div className="u-mar--bottom--sml">
                                    <p className="u-type--sml u-type--color--opaque">
                                        {t("ADMINISTRATION.SHUFFLE.PREVIEW.FIELDS.TITLE")}
                                    </p>
                                    <p className="c-content__header--primary__title">{item.name}</p>
                                </div>

                                <div className="c-content__header--primary__descriprtion">
                                    <p className="u-type--sml u-type--color--opaque">
                                        {t("ADMINISTRATION.SHUFFLE.PREVIEW.FIELDS.TYPE")}
                                    </p>
                                    {item.shuffleType && item.shuffleType.name}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </MrbContentLoader>
            </MrbPageContentHeader>

            <div className="c-container--content">
                <div className="c-container--base">
                    <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <ShuffleListLoader />}>
                        {() => (
                            <div className="c-card--primary c-card--med">
                                <div className="u-animation--fadeIn">
                                    {item?.shuffleItems.length > 0 ? (
                                        <React.Fragment>
                                            {map(item.shuffleItems, (shuffleItem, index) => (
                                                <div className="c-shuffle__item" key={index}>
                                                    <div className="u-animation--fadeIn">
                                                        <div className="u-display--flex u-display--flex--jc--space-between">
                                                            <div
                                                                className="u-type--link--secondary"
                                                                onClick={() => goTo(shuffleItem)}
                                                            >
                                                                {shuffleItem.name && shuffleItem.name}
                                                                {shuffleItem.isPremium && (
                                                                    <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny"></i>
                                                                )}
                                                            </div>
                                                            <p
                                                                className={mergeCss(
                                                                    "c-shuffle__status",
                                                                    `c-shuffle__status--${
                                                                        shuffleItem.isActive ? "active" : "inactive"
                                                                    }`
                                                                )}
                                                            >
                                                                <i
                                                                    className={`u-icon u-icon--med u-icon--circle--${
                                                                        shuffleItem.isActive ? "active" : "inactive"
                                                                    } u-mar--right--micro`}
                                                                ></i>
                                                                {shuffleItem.isActive ? "Active" : "Inactive"}
                                                            </p>
                                                        </div>
                                                        <div className="u-separator--primary u-separator--primary--sml"></div>
                                                        <div>
                                                            <p className="u-type--sml u-type--color--opaque">
                                                                Shuffle Weight
                                                            </p>
                                                            {shuffleItem.shuffleWeight.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <ShuffleEmptyState />
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        )}
                    </MrbContentLoader>
                </div>
            </div>
        </MrbPreviewPage>
    );
}

ShufflePreviewTemplate.propTypes = {
    shufflePreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function ShuffleEmptyState() {
    return (
        <div className="c-empty-state--shuffle">
            <i className="u-icon u-icon--empty--lrg u-icon--empty--shuffle"></i>
            <p className="u-type--lrg u-type--wgt--bold">No shuffled content</p>
            <p>Please, create new shuffle podcast, station or Naki Nigun list.</p>
        </div>
    );
}

export default defaultTemplate(ShufflePreviewTemplate);
