import { action, runInAction, computed, makeObservable, observable } from "mobx";
import { applicationErrorCodes } from "common/constants";
import { MrbBasePreviewViewStore } from "mrb/core";
import { some } from "lodash";
import { localizationService } from "common/localization";

class BasePodcastPreviewViewStore extends MrbBasePreviewViewStore {
    @observable displayMissingProducers = false;

    @computed get coverImageUrl() {
        if (this.item && this.item.coverImageId) {
            return this.routeStore.createPodcastCoverImageUrl(this.item.coverImageId, 200, 200);
        }

        return null;
    }

    @computed get isPremiumDisabled() {
        if (this.item === null) {
            return false;
        }
        if (this.item.podcastType === 2) {
            return some(this.item.subPodcasts, (subPodcast) => subPodcast.producerId === null) && this.item.isPremium;
        } else {
            return this.item.producerId === null;
        }
    }

    constructor(rootStore, { routeStore, layoutActions }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: id,
            layoutActions,
            navigateBack: "master.administration.podcast.list",
        });
        makeObservable(this);
        this.routeStore = routeStore;
    }

    @action.bound
    async toggleIsActive(value) {
        const oldValue = this.item.isActive;
        this.item.isActive = value;
        try {
            await this.routeStore.updateActiveStatus(this.item.id, this.item.isActive);
        } catch (err) {
            if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
                this.rootStore.notificationStore.error(
                    "Podcast updated, but an unexpected error occurred during RSS sync.",
                    err
                );
            } else {
                runInAction(() => {
                    this.item.isActive = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change status", err);
                });
            }
        }
    }

    @action.bound
    async toggleExcludedFromRevenueStatus(value) {
        const oldValue = this.item.excludedFromRevenue;
        this.item.excludedFromRevenue = value;
        try {
            await this.routeStore.updateExcludedFromRevenueStatus(this.item.id, this.item.excludedFromRevenue);
        } catch (err) {
            if (err.data?.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
                this.rootStore.notificationStore.error(
                    "Podcast updated, but an unexpected error occurred during RSS sync.",
                    err
                );
            } else {
                runInAction(() => {
                    this.item.excludedFromRevenue = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change status", err);
                });
            }
        }
    }

    @action.bound
    async togglePremiumStatus(value) {
        const oldValue = this.item.isPremium;
        this.item.isPremium = value;
        try {
            await this.routeStore.updatePremiumStatus(this.item.id, this.item.isPremium);
            this.setDisplayMissingProducers(false);
        } catch (err) {
            if (this.isPremiumDisabled) {
                this.setDisplayMissingProducers(true);
                runInAction(() => {
                    this.item.isPremium = oldValue;
                    if (this.item.podcastType === 2) {
                        this.rootStore.notificationStore.warning(
                            localizationService.t(
                                "ADMINISTRATION.PODCAST.PREVIEW.VALIDATION.SUB_PODCAST_MISSING_PRODUCER"
                            )
                        );
                    } else {
                        this.rootStore.notificationStore.warning(
                            localizationService.t("ADMINISTRATION.PODCAST.PREVIEW.VALIDATION.PODCAST_MISSING_PRODUCER")
                        );
                    }
                });
            } else {
                runInAction(() => {
                    this.item.isPremium = oldValue;
                    this.rootStore.notificationStore.error("Unexpected error occurred. Unable to change premium", err);
                });
            }
        }
    }

    @action.bound
    async toggleOnByDefaultStatus(value) {
        const oldValue = this.item.isTurnedOnByDefault;
        this.item.isTurnedOnByDefault = value;
        try {
            await this.routeStore.updateOnByDefaultStatus(this.item.id, this.item.isTurnedOnByDefault);
        } catch (err) {
            runInAction(() => {
                this.item.isTurnedOnByDefault = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change default status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleOnByDefaultInAppStatus(value) {
        const oldValue = this.item.isTurnedOnByDefaultInApp;
        this.item.isTurnedOnByDefaultInApp = value;
        try {
            await this.routeStore.updateOnByDefaultInAppStatus(this.item.id, this.item.isTurnedOnByDefaultInApp);
        } catch (err) {
            runInAction(() => {
                this.item.isTurnedOnByDefaultInApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change default status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleOnByDefaultInNakiTouchStatus(value) {
        const oldValue = this.item.isTurnedOnByDefaultInEmbeddedApp;
        this.item.isTurnedOnByDefaultInEmbeddedApp = value;
        try {
            await this.routeStore.updateOnByDefaultInNakiTouchStatus(
                this.item.id,
                this.item.isTurnedOnByDefaultInEmbeddedApp
            );
        } catch (err) {
            runInAction(() => {
                this.item.isTurnedOnByDefaultInEmbeddedApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change default status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleVisibilityStatus(value) {
        const oldValue = this.item.isVisible;
        this.item.isVisible = value;
        try {
            await this.routeStore.updateVisibilityStatus(this.item.id, this.item.isVisible);
        } catch (err) {
            runInAction(() => {
                this.item.isVisible = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change visibility status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleAppVisibilityStatus(value) {
        const oldValue = this.item.isVisibleInApp;
        this.item.isVisibleInApp = value;
        try {
            await this.routeStore.updateAppVisibilityStatus(this.item.id, this.item.isVisibleInApp);
        } catch (err) {
            runInAction(() => {
                this.item.isVisibleInApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change app visibility status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleNakiTouchVisibilityStatus(value) {
        const oldValue = this.item.isVisibleInEmbeddedApp;
        this.item.isVisibleInEmbeddedApp = value;
        try {
            await this.routeStore.updateNakiTouchVisibilityStatus(this.item.id, this.item.isVisibleInEmbeddedApp);
        } catch (err) {
            runInAction(() => {
                this.item.isVisibleInEmbeddedApp = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change Naki Bolt visibility status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleRecentlyUpdatedVisibilityStatus(value) {
        const oldValue = this.item.podcastFeed.recentlyUpdatedVisibility;
        this.item.podcastFeed.recentlyUpdatedVisibility = value;
        try {
            await this.routeStore.updateRecentlyUpdatedVisibilityStatus(
                this.item.id,
                this.item.podcastFeed.recentlyUpdatedVisibility
            );
        } catch (err) {
            runInAction(() => {
                this.item.podcastFeed.recentlyUpdatedVisibility = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change visibility status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleFeaturedStatus(value) {
        const oldValue = this.item.isFeatured;
        this.item.isFeatured = value;
        try {
            await this.routeStore.updateFeaturedStatus(this.item.id, this.item.isFeatured);
        } catch (err) {
            runInAction(() => {
                this.item.isFeatured = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change featured status",
                    err
                );
            });
        }
    }

    @action.bound
    async toggleHlsEnabledStatus(value) {
        const oldValue = this.item.podcastFeed.hlsEnabled;
        this.item.podcastFeed.hlsEnabled = value;
        try {
            await this.routeStore.updateHlsEnabledStatus(this.item.id, this.item.podcastFeed.hlsEnabled);
        } catch (err) {
            runInAction(() => {
                this.item.podcastFeed.hlsEnabled = oldValue;
                this.rootStore.notificationStore.error(
                    "Unexpected error occurred. Unable to change HLS enabled status.",
                    err
                );
            });
        }
    }

    @action.bound
    async deletePodcast() {
        this.rootStore.confirmDialogStore.showConfirm({
            message: "Do you want to delete podcast?",
            onConfirm: async () => {
                try {
                    await this.routeStore.deletePodcast(this.item);
                    this.rootStore.routerStore.goTo("master.administration.podcast.list");
                } catch (err) {
                    this.rootStore.notificationStore.error(
                        "An unexpected error occurred. Unable to delete station.",
                        err
                    );
                }
            },
            yesLabel: "Delete",
            noLabel: "Cancel",
            description: "You won't be able to revert this action.",
        });
    }

    @action.bound
    setDisplayMissingProducers(value) {
        this.displayMissingProducers = value;
    }
}

export default BasePodcastPreviewViewStore;
