import React from "react";
import { observer } from "mobx-react";
import { PodcastGroupEditTemplate } from "themes/administration/podcast/pages";
import { PodcastGroupEditViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastGroupEditViewStore(rootStore, componentProps),
    "podcastGroupEditViewStore"
)
@observer
class PodcastGroupEdit extends React.Component {
    render() {
        return <PodcastGroupEditTemplate {...this.props} />;
    }
}

export default PodcastGroupEdit;
