import { MrbBaseRouteStore } from "mrb/core";
import { ShuffleService, ShuffleWeightCacheService } from "common/services";

class ShufflePreviewRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = this.rootStore.createApplicationService(ShuffleService);

        this.shuffleWeightService = new ShuffleWeightCacheService(this.rootStore);
    }

    async getShuffle(id) {
        const response = await this.service.get(id, {
            embed: "shuffleType,shuffleItems,shuffleItems.station,shuffleItems.podcast,shuffleItems.nakiNigunAlbum,shuffleItems.nakiNigunAlbum.artist",
        });
        return response.data;
    }

    getShuffleWeights() {
        return this.shuffleWeightService.getItems();
    }
}

export default ShufflePreviewRouteStore;
