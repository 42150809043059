import { moduleProviderFactory } from "mrb/core";
import {
    NakiMobileHomeSectionList,
    NakiMobileFeaturedSection,
    NakiMobileEmbeddedFeaturedSection,
    FeaturedSection,
} from "administration/naki-mobile/pages";
import {
    NakiMobileHomeSectionListRouteStore,
    NakiMobileFeaturedSectionRouteStore,
    NakiMobileEmbeddedFeaturedSectionRouteStore,
    FeaturedSectionRouteStore,
} from "administration/naki-mobile/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "NakiMobile",
        routes: [
            {
                name: "master.administration.naki-mobile",
                pattern: "/naki-mobile",
                children: [
                    {
                        name: "master.administration.naki-mobile.sections",
                        pattern: "sections",
                        store: NakiMobileHomeSectionListRouteStore,
                        component: NakiMobileHomeSectionList,
                        data: {
                            title: "Home Screen Sections",
                        },
                    },
                    {
                        name: "master.administration.naki-mobile.featured-section",
                        pattern: "featured-section",
                        store: FeaturedSectionRouteStore,
                        component: FeaturedSection,
                        children: [
                            {
                                name: "master.administration.naki-mobile.featured-section.mobile",
                                pattern: "",
                                component: NakiMobileFeaturedSection,
                                store: NakiMobileFeaturedSectionRouteStore,
                            },
                            {
                                name: "master.administration.naki-mobile.featured-section.mobile-embedded",
                                pattern: "",
                                component: NakiMobileEmbeddedFeaturedSection,
                                store: NakiMobileEmbeddedFeaturedSectionRouteStore,
                            },
                        ],
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.NAKI_MOBILE",
                order: 9,
                icon: "mobile",
                subMenu: [
                    {
                        title: "ADMINISTRATION.MENU.NAKI_MOBILE_HOME_SCREEN",
                        order: 1,
                        route: "master.administration.naki-mobile.sections",
                    },
                    {
                        title: "ADMINISTRATION.MENU.NAKI_MOBILE_FEATURED_SECTION",
                        order: 2,
                        route: "master.administration.naki-mobile.featured-section.mobile",
                    },
                ],
            },
        ],
    });
})();
