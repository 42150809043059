import { BasePodcastPreviewRouteStore } from "administration/podcast/stores";

class PodcastGroupPreviewRouteStore extends BasePodcastPreviewRouteStore {
    constructor(rootStore) {
        super(rootStore);
    }

    async getPodcastGroup(id) {
        const response = await this.service.get(id, {
            embed: "subPodcasts,subPodcasts.language,subPodcasts.location,subPodcasts.genres,subPodcasts.producer,subPodcasts.podcastFeed,subPodcasts.podcastFeed.episodeSortType,subPodcasts.podcastFeed.shuffleWeight",
        });
        return response.data;
    }
}

export default PodcastGroupPreviewRouteStore;
