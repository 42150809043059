import React, { useState, useEffect, useMemo } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { MrbPageHeader } from "mrb/components/layout";
import { MrbButton } from "mrb/components/button";
import { EditFeaturedSectionContentModal, FeaturedSectionLoader } from "administration/naki-mobile/components";
import { MrbContentLoader } from "mrb/components/loader";
import { FeaturedSectionItemViewStore } from "administration/naki-mobile/stores";
import { UploadedImageLoader } from "common/components";
import { Image } from "common/components";
import { MrbFileUpload } from "mrb/components/media";
import {
    canExpandLeft,
    canExpandRight,
    canExpandTop,
    canExpandBottom,
    isGridItemDefined,
    canCollapseTop,
    canCollapseLeft,
    canCollapseRight,
    canCollapseBottom,
} from "administration/naki-mobile/utils";
import { defaultTemplate } from "common/hoc";
import { map, isNil } from "lodash";

function NakiMobileFeaturedSectionTemplate({ nakiMobileFeaturedSectionViewStore, t }) {
    const { editItemModalParams, loaderStore } = nakiMobileFeaturedSectionViewStore;

    return (
        <React.Fragment>
            <MrbPageHeader>
                <div>Featured Section</div>
                <Actions store={nakiMobileFeaturedSectionViewStore} t={t} />
            </MrbPageHeader>

            {/* <PanelHeader nakiMobileHomeSectionListViewStore={nakiMobileHomeSectionListViewStore} /> */}
            <div className="c-container--med">
                <Grid viewStore={nakiMobileFeaturedSectionViewStore} />
            </div>

            <EditFeaturedSectionContentModal modalParams={editItemModalParams} loaderStore={loaderStore} />
        </React.Fragment>
    );
}

const Actions = observer(function Actions({ store, t }) {
    const { updateFeaturedGrid, isSubmitting } = store;
    return (
        <React.Fragment>
            <MrbButton
                t={t}
                className="c-btn c-btn--base c-btn--primary"
                onClick={updateFeaturedGrid}
                aria-label="Save"
                aria-busy={isSubmitting}
                label="Save"
                disabled={isSubmitting}
            />
        </React.Fragment>
    );
});

const Grid = observer(function Grid({ viewStore }) {
    const {
        items,
        rowsLimit,
        columnsLimit,
        editItem,
        expandToLeft,
        expandToRight,
        expandToTop,
        expandToBottom,
        collapseLeft,
        collapseRight,
        collapseTop,
        collapseBottom,
        clearSelection,
        loaderStore,
        rootStore,
        routeStore,
    } = viewStore;

    const grid = map(items, (item) => (
        <GridItem
            key={item.id}
            item={item}
            editItem={editItem}
            clearSelection={clearSelection}
            expandToLeft={expandToLeft}
            expandToRight={expandToRight}
            expandToTop={expandToTop}
            expandToBottom={expandToBottom}
            collapseLeft={collapseLeft}
            collapseRight={collapseRight}
            collapseTop={collapseTop}
            collapseBottom={collapseBottom}
            rowsLimit={rowsLimit}
            columnsLimit={columnsLimit}
            rootStore={rootStore}
            routeStore={routeStore}
        />
    ));

    return (
        <MrbContentLoader loaderStore={loaderStore} loaderRender={() => <FeaturedSectionLoader />}>
            {() => <div className="c-grid c-grid--3 c-grid__gap--6">{grid}</div>}
        </MrbContentLoader>
    );
});

const GridItem = observer(function GridItem({
    item,
    editItem,
    clearSelection,
    expandToLeft,
    expandToRight,
    expandToTop,
    expandToBottom,
    collapseLeft,
    collapseRight,
    collapseTop,
    collapseBottom,
    rowsLimit,
    columnsLimit,
    routeStore,
    rootStore,
}) {
    const [isHovering, setIsHovering] = useState(false);

    const expandDownButton = canExpandBottom(item.gridRow, item.gridRowSpan, rowsLimit);
    const expandTopButton = canExpandTop(item.gridRow);
    const expandLeftButton = canExpandLeft(item.gridColumn);
    const expandRightButton = canExpandRight(item.gridColumn, item.gridColumnSpan, columnsLimit);

    const collapseTopButton = canCollapseTop(item.gridRowSpan);
    const collapseLeftButton = canCollapseLeft(item.gridColumnSpan);
    const collapseRightButton = canCollapseRight(item.gridColumnSpan);
    const collapseBottomButton = canCollapseBottom(item.gridRowSpan);

    const gridArea = createGridAreaDefinition(item.gridRow, item.gridColumn, item.gridRowSpan, item.gridColumnSpan);

    return (
        <div
            className="c-card--med c-card--primary"
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{
                gridArea: gridArea,
            }}
        >
            {isGridItemDefined(item) ? (
                <DefinedItem
                    item={item}
                    edit={editItem}
                    gridArea={gridArea}
                    clearSelection={clearSelection}
                    rootStore={rootStore}
                    routeStore={routeStore}
                />
            ) : (
                <DefaultItem item={item} edit={editItem} />
            )}
            {/* Use for debug */}
            {/* <div className="u-type--base">
                <span>Row: {item.gridRow}</span>
                <span>Col: {item.gridColumn}</span>
                <span>RowSpan: {item.gridRowSpan}</span>
                <span>ColSpan: {item.gridColumnSpan}</span>
            </div> */}

            {isHovering && (
                <React.Fragment>
                    <div className="c-featured__btn-group c-featured__btn-group--left">
                        {expandLeftButton ? (
                            <MrbButton
                                classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                onlyIcon
                                icon="u-icon u-icon--base u-icon--expand--left"
                                onClick={() => expandToLeft(item)}
                            />
                        ) : null}
                        {collapseLeftButton ? (
                            <MrbButton
                                classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                onlyIcon
                                icon="u-icon u-icon--base u-icon--expand--right"
                                onClick={() => collapseLeft(item)}
                            />
                        ) : null}
                    </div>

                    <div className="c-featured__btn-group c-featured__btn-group--top">
                        {collapseTopButton ? (
                            <MrbButton
                                classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                onlyIcon
                                icon="u-icon u-icon--base u-icon--expand--down"
                                onClick={() => collapseTop(item)}
                            />
                        ) : null}
                        {expandTopButton ? (
                            <MrbButton
                                classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                onlyIcon
                                icon="u-icon u-icon--base u-icon--expand--top"
                                onClick={() => expandToTop(item)}
                            />
                        ) : null}
                    </div>

                    <div className="c-featured__btn-group c-featured__btn-group--right">
                        {collapseRightButton ? (
                            <MrbButton
                                classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                onlyIcon
                                icon="u-icon u-icon--base u-icon--expand--left"
                                onClick={() => collapseRight(item)}
                            />
                        ) : null}
                        {expandRightButton ? (
                            <MrbButton
                                classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                onlyIcon
                                icon="u-icon u-icon--base u-icon--expand--right"
                                onClick={() => expandToRight(item)}
                            />
                        ) : null}
                    </div>

                    <div className="c-featured__btn-group c-featured__btn-group--bottom">
                        {expandDownButton ? (
                            <MrbButton
                                classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                onlyIcon
                                icon="u-icon u-icon--base u-icon--expand--down"
                                onClick={() => expandToBottom(item)}
                            />
                        ) : null}
                        {collapseBottomButton ? (
                            <MrbButton
                                classNameOverride="c-btn c-btn--primary c-btn--rounded c-btn--rounded--sml c-btn--icon"
                                onlyIcon
                                icon="u-icon u-icon--base u-icon--expand--top"
                                onClick={() => collapseBottom(item)}
                            />
                        ) : null}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
});

function DefaultItem({ item, edit }) {
    return (
        <div className="c-card--lrg c-card--secondary c-featured__card__img">
            <div>
                <i className="u-icon u-icon--xxxlrg u-icon--note-list u-mar--bottom--sml"></i>
            </div>

            <MrbButton label="Add new item" onClick={() => edit(item)} />
        </div>
    );
}

const DefinedItem = observer(function DefinedItem({ item, edit, gridArea, clearSelection, rootStore, routeStore }) {
    const store = useMemo(
        () => new FeaturedSectionItemViewStore(rootStore, { item, routeStore }),
        [item, rootStore, routeStore]
    );

    useEffect(() => {
        store.initialize();
    }, [store]);

    const gridStart = gridArea.split(" / ")[0];
    const gridEnd = gridArea.split(" / ")[2];
    let imgSize = "";

    if (gridEnd - gridStart === 1) {
        imgSize = "sml";
    } else if (gridEnd - gridStart === 2) {
        imgSize = "med";
    } else {
        imgSize = "lrg";
    }
    const width = item.gridColumnSpan * 200;
    const height = item.gridRowSpan * 200;

    let coverImageUrl = null;
    if (item.fileEntryId) {
        coverImageUrl = rootStore.coverImageFactory.createFeaturedSectionCoverImageUrl(item.fileEntryId, width, height);
    } else if (item.station && item.station.coverImageId) {
        coverImageUrl = rootStore.coverImageFactory.createStationCoverImageUrl(
            item.station.coverImageId,
            width,
            height
        );
    } else if (item.podcast && item.podcast.coverImageId) {
        coverImageUrl = rootStore.coverImageFactory.createPodcastCoverImageUrl(
            item.podcast.coverImageId,
            width,
            height
        );
    } else if (item.artist && item.artist.coverImageId) {
        coverImageUrl = rootStore.coverImageFactory.createNakiNigunArtistCoverImageUrl(
            item.artist.coverImageId,
            width,
            height
        );
    } else if (item.album && item.album.coverImageId) {
        coverImageUrl = rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(
            item.album.coverImageId,
            width,
            height
        );
    }

    return (
        <div className="c-featured__card">
            <div className="c-featured__card__img">
                <div>
                    <MrbButton
                        label="Replace image"
                        onClick={store.uploadImage}
                        classNameOverride="c-btn c-btn--sml c-btn--primary"
                        icon="u-cursor--pointer u-icon u-icon--base u-icon--sync u-mar--right--tny"
                    />
                    {(item.fileEntryId || store.coverImageUploadViewStore.file) && (
                        <MrbButton
                            aria-label="Revert to Default"
                            label="Clear"
                            onClick={() => store.revertToDefaultArtwork(item)}
                            classNameOverride="c-btn c-btn--sml c-btn--outline u-mar--left--tny"
                            icon="u-cursor--pointer u-icon u-icon--base u-icon--close u-mar--right--tny"
                        />
                    )}
                </div>
                <MrbContentLoader
                    loaderStore={store.coverImageUploadViewStore.loaderStore}
                    loaderRender={() => <UploadedImageLoader size="c-upload__upload--full" />}
                >
                    {() => (
                        <MrbFileUpload store={store.coverImageUploadViewStore.fileUploadStore}>
                            <Image
                                src={store.coverImageUploadViewStore.imageUrl ?? coverImageUrl}
                                className={`c-featured__img--${imgSize}`}
                            />
                        </MrbFileUpload>
                    )}
                </MrbContentLoader>
            </div>

            <ContentLabel item={item} />

            <div className="u-display--flex c-grid__gap--2">
                <MrbButton label="Change" onClick={() => edit(item)} />
                <MrbButton
                    classNameOverride="c-btn c-btn--base c-btn--ghost"
                    label="Clear"
                    onClick={() => {
                        store.revertToDefaultArtwork();
                        clearSelection(item);
                    }}
                />
            </div>
        </div>
    );
});

function ContentLabel({ item }) {
    let icon = null;
    let title = null;
    let isPremium = false;

    if (!isNil(item.stationId)) {
        icon = "stations";
        title = item.station.title;
        isPremium = item.station.isPremium;
    } else if (!isNil(item.podcastId)) {
        icon = "podcasts";
        title = item.podcast.title;
        isPremium = item.podcast.isPremium;
    } else if (!isNil(item.nakiNigunArtistId)) {
        icon = "nigun";
        title = item.artist.name;
        isPremium = item.artist.isPremium;
    } else if (!isNil(item.nakiNigunAlbumId)) {
        icon = "nigun";
        title = item.album.title;
        isPremium = item.album.isPremium;
    }

    return (
        <p className="u-type--base">
            <i className={`u-icon u-icon--med u-icon--${icon} u-mar--right--tny`} />
            {title}
            {isPremium && <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny" />}
        </p>
    );
}

function createGridAreaDefinition(row, column, rowSpan = 1, columnSpan = 1) {
    return `${row + 1} / ${column + 1} / ${row + 1 + rowSpan} / ${column + 1 + columnSpan}`;
}

NakiMobileFeaturedSectionTemplate.propTypes = {
    nakiMobileFeaturedSectionViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(NakiMobileFeaturedSectionTemplate);
