import { BasePodcastEditRouteStore } from "administration/podcast/stores";
import { TorahAnytimeService } from "common/services";

class PodcastEditRouteStore extends BasePodcastEditRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.torahAnytimeService = this.rootStore.createApplicationService(TorahAnytimeService);
    }

    async createPodcast(resource) {
        const response = await this.service.create(resource);
        return response.data;
    }

    async getPodcast(id) {
        const response = await this.service.get(id, {
            embed: "podcastFeed,genres,language,location,podcastVolumes,producer",
        });
        return response.data;
    }

    async updatePodcast(resource) {
        const response = await this.service.update(resource);
        return response.data;
    }

    async getTorahAnytimeSetting() {
        const response = await this.torahAnytimeService.getSetting();
        return response.data;
    }
}

export default PodcastEditRouteStore;
