import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const PodcastEpisodeListLoaderTemplate = ({ listNumber = 4 }) => {
    const [loaderWidth, setLoaderWidth] = useState(0);

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    return (
        <div ref={ref}>
            {Array.from({ length: listNumber }, () => {
                return (
                    <div className="c-card c-card--primary u-mar--bottom--sml" key={Math.random()}>
                        <ContentLoader
                            speed={2}
                            width={loaderWidth}
                            height="163"
                            viewBox={`0 0 ${loaderWidth} 163`}
                            backgroundColor="#262729"
                            foregroundColor="#2b2b2b"
                        >
                            <rect x="0" y={0} rx="6" ry="6" width="50" height="50" />

                            <rect x="62" y={4} rx="6" ry="6" width="60" height="10" />
                            <rect x="62" y={38} rx="6" ry="6" width="170" height="8" />

                            <rect x="0" y={70} rx="6" ry="6" width="70" height="8" />

                            <rect x="0" y={85} rx="6" ry="6" width="100%" height="12" />
                            <rect x="0" y={105} rx="6" ry="6" width="100" height="12" />

                            <rect x="0" y={125} rx="6" ry="6" width="30" height="8" />

                            <rect x="0" y={140} rx="6" ry="6" width="130" height="24" />
                        </ContentLoader>
                    </div>
                );
            })}
        </div>
    );
};

export default PodcastEpisodeListLoaderTemplate;
