import React from "react";
import { PodcastGroupEditPageLayoutTemplate } from "themes/administration/podcast/components";

class PodcastGroupEditPageLayout extends React.Component {
    render() {
        return <PodcastGroupEditPageLayoutTemplate {...this.props} />;
    }
}

export default PodcastGroupEditPageLayout;
