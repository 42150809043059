import React from "react";
import { observer } from "mobx-react";
import { NakiNigunAddAlbumTemplate } from "themes/administration/naki-nigun/components";
import { NakiNigunAddAlbumViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunAddAlbumViewStore(rootStore, componentProps),
    "nakiNigunAddAlbumViewStore"
)
@observer
class NakiNigunAddAlbum extends React.Component {
    render() {
        return <NakiNigunAddAlbumTemplate {...this.props} />;
    }
}

export default NakiNigunAddAlbum;
