import { moduleProviderFactory } from "mrb/core";
import { AdministrationRouteStore } from "administration/common";
import { AdministrationLayout } from "administration/common";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "administration",
        routes: [
            {
                name: "master.administration",
                pattern: "/administration",
                component: AdministrationLayout,
                store: AdministrationRouteStore,
                beforeEnter: (fromState, toState, routerStore) => {
                    const { userStore } = routerStore.rootStore;
                    if (!userStore.isAdministrator) {
                        return Promise.reject(routerStore.notFoundState);
                    }
                },
            },
        ],
    });
})();
