import React from "react";
import { observer } from "mobx-react";
import { AddPodcastVolumeItemTemplate } from "themes/administration/podcast/components";
import { AddPodcastVolumeItemViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new AddPodcastVolumeItemViewStore(rootStore, componentProps),
    "addPodcastVolumeItemViewStore"
)
@observer
class AddPodcastVolumeItem extends React.Component {
    render() {
        return <AddPodcastVolumeItemTemplate {...this.props} />;
    }
}

export default AddPodcastVolumeItem;
