import React from "react";
import { NakiNigunArtistPreviewTemplate } from "themes/administration/naki-nigun/pages";
import { NakiNigunArtistPreviewViewStore } from "administration/naki-nigun/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunArtistPreviewViewStore(rootStore, componentProps),
    "nakiNigunArtistPreviewViewStore"
)
class NakiNigunArtistPreview extends React.Component {
    render() {
        return <NakiNigunArtistPreviewTemplate {...this.props} />;
    }
}

export default NakiNigunArtistPreview;
