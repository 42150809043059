import React from "react";
import { StationEditTemplate } from "themes/administration/station/pages";
import { StationEditViewStore } from "administration/station/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new StationEditViewStore(rootStore, componentProps),
    "stationEditViewStore"
)
class StationEdit extends React.Component {
    render() {
        return <StationEditTemplate {...this.props} />;
    }
}

export default StationEdit;
