import React from "react";
import { NakiMobileHomeSectionTemplate } from "themes/administration/naki-mobile/components";
import { NakiMobileHomeSectionViewStore } from "administration/naki-mobile/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiMobileHomeSectionViewStore(rootStore, componentProps),
    "nakiMobileHomeSectionViewStore"
)
class NakiMobileHomeSection extends React.Component {
    render() {
        return <NakiMobileHomeSectionTemplate {...this.props} />;
    }
}

export default NakiMobileHomeSection;
