import { MrbBaseRouteStore } from "mrb/core";
import { NakiNigunArtistService } from "common/services";

class NakiNigunArtistListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);

        this.service = rootStore.createApplicationService(NakiNigunArtistService);
    }

    async findNakiNigunArtists(params) {
        const response = await this.service.find(params);
        return response.data;
    }

    async deleteNakiNigunArtist(resource) {
        const response = await this.service.delete(resource);
        return response.data;
    }
}

export default NakiNigunArtistListRouteStore;
