import React from "react";
import { PodcastPreviewPodcastVolumeListTemplate } from "themes/administration/podcast/components";

class PodcastPreviewPodcastVolumeList extends React.Component {
    render() {
        return <PodcastPreviewPodcastVolumeListTemplate {...this.props} />;
    }
}

export default PodcastPreviewPodcastVolumeList;
