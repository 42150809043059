import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditFormLayout } from "mrb/components/layout";
import { MrbFieldInput } from "mrb/components/input";
import { PodcastCoverImageUpload } from "administration/podcast/components";
import { defaultTemplate } from "common/hoc";

function AddPodcastVolumeItemTemplate({ addPodcastVolumeItemViewStore, t }) {
    const {
        form,
        resetForm,
        coverImageUploadViewStore,
    } = addPodcastVolumeItemViewStore;
    return (
        <MrbEditFormLayout
            form={form}
            t={t}
            onClickCancel={resetForm}
            submitLabel="ADMINISTRATION.PODCAST.EDIT.ADD_PODCASTVOLUME.BUTTONS.ADD"
            cancelLabel="ADMINISTRATION.PODCAST.EDIT.ADD_PODCASTVOLUME.BUTTONS.RESET"
        >
            <div className="u-animation--fadeIn">
                <div className="c-upload__wrapper c-upload__wrapper--sml">
                    <div className="c-upload c-upload--sml c-upload--sml--left">
                        <PodcastCoverImageUpload
                            classNameExtendUpload="c-upload__upload--sml"
                            coverImageUploadViewStore={coverImageUploadViewStore}
                        />
                    </div>

                    <MrbFieldInput t={t} field={form.$("title")} classNameExtend="c-input--outline" />
                </div>

                <div className="u-separator--primary"></div>
            </div>
        </MrbEditFormLayout>
    );
}

AddPodcastVolumeItemTemplate.propTypes = {
    addPodcastVolumeItemViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(AddPodcastVolumeItemTemplate);
