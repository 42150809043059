import React from "react";
import { PropTypes } from "prop-types";
import { MrbListPage, MrbPageContentHeader } from "mrb/components/layout";
import { MrbTable } from "mrb/components/table";
import { MrbQuerySearchInput } from "mrb/components/input";
import { TableNoDataOverlay } from "common/components";
import { defaultTemplate } from "common/hoc";
import { AdminProducerListSummaryPreview } from "administration/producer/components";

function AdminProducerListTemplate({ adminProducerListViewStore, t }) {
    const { gridStore, queryUtility } = adminProducerListViewStore;
    return (
        <React.Fragment>
            <MrbListPage store={adminProducerListViewStore} t={t}>
                <MrbPageContentHeader classNameOverride="u-mar--bottom--sml">
                    <MrbQuerySearchInput queryUtility={queryUtility} />
                </MrbPageContentHeader>
                <div className="c-container--sidebar u-padd--none">
                    <MrbTable
                        t={t}
                        tableStore={gridStore}
                        className="ag-theme-alpine u-mar--bottom--sml"
                        emptyStateRender={TableNoDataOverlay}
                    />

                    <div className="row">
                        <div className="col col-sml-12 col-lrg-6 col-xxlrg-12">
                            <AdminProducerListSummaryPreview adminProducerListViewStore={adminProducerListViewStore} />
                        </div>
                    </div>
                </div>
            </MrbListPage>
        </React.Fragment>
    );
}

AdminProducerListTemplate.propTypes = {
    adminProducerListViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(AdminProducerListTemplate);
