import React from "react";
import { PropTypes } from "prop-types";
import { MrbEditFormLayout } from "mrb/components/layout";
import { MrbFieldInput, MrbFieldNumericInput } from "mrb/components/input";
import { MrbFieldSelect } from "mrb/components/select";
import { MrbFieldToggle } from "mrb/components/checkbox";
import { MrbFieldRadioButtons } from "mrb/components/radio";
import { CoverImageUpload, ProducerFieldSelect } from "common/components";
import { defaultTemplate } from "common/hoc";

function NakiNigunAddAlbumTemplate({ nakiNigunAddAlbumViewStore, t }) {
    const {
        form,
        episodeSortTypeOptions,
        resetForm,
        coverImageUploadViewStore,
        genreSelectStore,
        producerSelectStore,
        isPremiumDisabled,
        isActiveStatusDisabled,
    } = nakiNigunAddAlbumViewStore;
    return (
        <MrbEditFormLayout
            form={form}
            t={t}
            onClickCancel={resetForm}
            submitLabel="ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.ADD_ALBUMS.BUTTONS.ADD"
            cancelLabel="ADMINISTRATION.NAKI_NIGUN.ARTIST.EDIT.ADD_ALBUMS.BUTTONS.RESET"
        >
            <div className="u-animation--fadeIn">
                <div className="row">
                    <div className="col col-sml-12 col-xxlrg-8 u-mar--bottom--sml">
                        <div className="row">
                            <div className="col col-sml-12">
                                <MrbFieldInput t={t} field={form.$("title")} classNameExtend="c-input--outline" />
                            </div>

                            <div className="col col-sml-12">
                                <MrbFieldInput
                                    t={t}
                                    field={form.$("albumFeed.feedUrl")}
                                    classNameExtend="c-input--outline"
                                />
                            </div>

                            <div className="col col-sml-12 col-huge-2">
                                <MrbFieldNumericInput
                                    classNameOverride={"c-input c-input--med c-input--outline"}
                                    precision={0}
                                    t={t}
                                    field={form.$("year")}
                                />
                            </div>
                            <div className="col col-sml-12 col-huge-10">
                                <MrbFieldSelect t={t} store={genreSelectStore} classNameExtend="c-dropdown--dark" />
                            </div>
                            <div className="col col-sml-12">
                                <ProducerFieldSelect
                                    t={t}
                                    store={producerSelectStore}
                                    classNameExtend="c-dropdown--dark"
                                />
                            </div>

                            <div className="col col-sml-12">
                                <MrbFieldInput t={t} field={form.$("keywords")} classNameExtend="c-input--outline" />

                                <div className="u-mar--right--lrg">
                                    <MrbFieldRadioButtons
                                        field={form.$("albumFeed.contentSortTypeId")}
                                        t={t}
                                        options={episodeSortTypeOptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col col-sml-12 col-xxlrg-4">
                        <div className="c-toggle__card-list">
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isActive")}
                                disabled={isActiveStatusDisabled}
                            />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isPremium")}
                                disabled={isPremiumDisabled}
                            />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isTurnedOnByDefault")}
                            />

                            <div className="u-separator--primary u-mar--none"></div>

                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isTurnedOnByDefaultInApp")}
                            />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isTurnedOnByDefaultInEmbeddedApp")}
                            />
                            <div className="u-separator--primary u-mar--none"></div>

                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("isFeatured")}
                            />
                            <MrbFieldToggle
                                classNameExtend="c-toggle__item--inline"
                                t={t}
                                field={form.$("albumFeed.hlsEnabled")}
                                disabled={isActiveStatusDisabled}
                            />
                        </div>

                        <div className="u-separator--primary"></div>

                        <CoverImageUpload
                            classNameExtendUpload="c-upload--tny"
                            coverImageUploadViewStore={coverImageUploadViewStore}
                            isFullWidthFooterButtons
                        />
                    </div>
                </div>

                <div className="u-separator--primary"></div>
            </div>
        </MrbEditFormLayout>
    );
}

NakiNigunAddAlbumTemplate.propTypes = {
    nakiNigunAddAlbumViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(NakiNigunAddAlbumTemplate);
