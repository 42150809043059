import React from "react";
import { PodcastGroupPreviewSubPodcastTemplate } from "themes/administration/podcast/components";
import { PodcastGroupPreviewSubPodcastViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastGroupPreviewSubPodcastViewStore(rootStore, componentProps),
    "podcastGroupPreviewSubPodcastViewStore"
)
class PodcastGroupPreviewSubPodcast extends React.Component {
    render() {
        return <PodcastGroupPreviewSubPodcastTemplate {...this.props} />;
    }
}

export default PodcastGroupPreviewSubPodcast;
