import React from "react";
import { MrbPage } from "mrb/components/layout";
import PropTypes from "prop-types";
import { defaultTemplate } from "common/hoc";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { mergeCss } from "mrb/common/utils";

function FeaturedSectionTemplate({ featuredSectionViewStore, renderChildRoute, t, ...props }) {
    const { selectedTab, setSelectedTab } = featuredSectionViewStore;

    return (
        <React.Fragment>
            <MrbPage>
                <div className="c-tabs">
                    <React.Fragment>
                        <MrbRouterLink
                            onClick={() => setSelectedTab(1)}
                            className={mergeCss(
                                "c-tabs__item",
                                selectedTab.title === "mobile" && "c-tabs__item--active"
                            )}
                            routeName="master.administration.naki-mobile.featured-section.mobile"
                        >
                            {t("ADMINISTRATION.NAKI_MOBILE.FEATURED_SECTION.MOBILE.TITLE")}
                        </MrbRouterLink>
                        <MrbRouterLink
                            onClick={() => setSelectedTab(2)}
                            className={mergeCss(
                                "c-tabs__item",
                                selectedTab.title === "mobile_embedded" && "c-tabs__item--active"
                            )}
                            routeName="master.administration.naki-mobile.featured-section.mobile-embedded"
                        >
                            {t("ADMINISTRATION.NAKI_MOBILE.FEATURED_SECTION.MOBILE_EMBEDDED.TITLE")}
                        </MrbRouterLink>
                    </React.Fragment>
                </div>

                {renderChildRoute(props)}
            </MrbPage>
        </React.Fragment>
    );
}

FeaturedSectionTemplate.propTypes = {
    featuredSectionViewStore: PropTypes.object.isRequired,
    renderChildRoute: PropTypes.func,
    t: PropTypes.func,
};

export default defaultTemplate(FeaturedSectionTemplate);
