import React from "react";
import { AlbumTrackPlaylistCellTemplate } from "themes/administration/content-explorer";

class AlbumTrackPlaylistCell extends React.Component {
    render() {
        return <AlbumTrackPlaylistCellTemplate {...this.props} />;
    }
}

export default AlbumTrackPlaylistCell;
