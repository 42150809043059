import { LocalizedForm } from "common/localization";

export default class ShuffleEditForm extends LocalizedForm {
    constructor(hooks) {
        super(hooks);
    }

    setup() {
        return {
            fields: [
                {
                    name: "name",
                    label: "ADMINISTRATION.SHUFFLE.EDIT.FIELDS.NAME_LABEL",
                    placeholder:
                        "ADMINISTRATION.SHUFFLE.EDIT.FIELDS.NAME_PLACEHOLDER",
                    rules: "string|required",
                },
            ],
        };
    }
}
