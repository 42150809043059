import React from "react";
import { PropTypes } from "prop-types";
import { PortalDetailedActivity } from "administration/analytics/components";
import { defaultTemplate } from "common/hoc";

function PremiumUsersActivityTemplate({ premiumUsersActivityViewStore, t }) {
    return (
        <PortalDetailedActivity
            store={premiumUsersActivityViewStore}
            activityLabel="Premium users over period"
            totalLabel="Total # of premium users"
            t={t}
        />
    );
}

PremiumUsersActivityTemplate.propTypes = {
    premiumUsersActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(PremiumUsersActivityTemplate);
