import React from "react";
import { PropTypes } from "prop-types";
import { PodcastEditPodcastVolume } from "administration/podcast/components";
import { DragDropList } from "common/components";
import { observer } from "mobx-react";

function PodcastEditPodcastVolumeListTemplate({ podcastEditViewStore, t }) {
    const { podcastVolumes, reorderPodcastVolumes } = podcastEditViewStore;

    return (
        <React.Fragment>
            {podcastVolumes && podcastVolumes.length > 0 && (
                <DragDropList
                    droppableId="sub-podcasts"
                    items={podcastVolumes}
                    onDragEnd={reorderPodcastVolumes}
                    itemRender={(item) => (
                        <PodcastEditPodcastVolume
                            podcastId={item.id}
                            podcastEditViewStore={podcastEditViewStore}
                            t={t}
                        />
                    )}
                ></DragDropList>
            )}
        </React.Fragment>
    );
}

PodcastEditPodcastVolumeListTemplate.propTypes = {
    podcastEditViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default observer(PodcastEditPodcastVolumeListTemplate);
