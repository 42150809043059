import React from "react";
import { PropTypes } from "prop-types";
import { MrbPage, MrbPageHeader } from "mrb/components/layout";
import {
    AnalyticsTimeRange,
    DeviceActivitySummary,
    PortalUserDevice,
    TopTrendingPodcasts,
    TopTrendingStations,
    PortalNewDevices,
} from "administration/analytics/components";
import { defaultTemplate } from "common/hoc";

function AnalyticsDashboardTemplate({ analyticsDashboardViewStore, t }) {
    const {
        analyticsTimeRangeViewStore,
        deviceActivitySummaryViewStore,
        topTrendingPodcastsViewStore,
        topTrendingStationsViewStore,
        portalViewStore,
    } = analyticsDashboardViewStore;

    return (
        <MrbPage>
            <MrbPageHeader>
                <div>Analytics</div>
                <AnalyticsTimeRange analyticsTimeRangeViewStore={analyticsTimeRangeViewStore} t={t} />
            </MrbPageHeader>

            <div className="c-container--sidebar u-padd--none">
                <div>
                    <div className="row">
                        <PortalUserDevice portalViewStore={portalViewStore} t={t} />
                        <PortalNewDevices portalViewStore={portalViewStore} t={t} />
                        <div className="col col-sml-12">
                            <div>
                                <DeviceActivitySummary
                                    deviceActivitySummaryViewStore={deviceActivitySummaryViewStore}
                                    t={t}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="row">
                        <div className="col col-sml-12 col-lrg-6 col-xxlrg-12">
                            <TopTrendingPodcasts topTrendingPodcastsViewStore={topTrendingPodcastsViewStore} t={t} />
                        </div>
                        <div className="col col-sml-12 col-lrg-6 col-xxlrg-12">
                            <TopTrendingStations topTrendingStationsViewStore={topTrendingStationsViewStore} t={t} />
                        </div>
                    </div>
                </div>
            </div>
        </MrbPage>
    );
}

AnalyticsDashboardTemplate.propTypes = {
    analyticsDashboardViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(AnalyticsDashboardTemplate);
