import { moduleProviderFactory } from "mrb/core";
import {
    StationShuffleEdit,
    PodcastShuffleEdit,
    NakiNigunShuffleEdit,
    ShuffleList,
    ShufflePreview,
} from "administration/shuffle/pages";
import {
    StationShuffleEditRouteStore,
    PodcastShuffleEditRouteStore,
    NakiNigunShuffleEditRouteStore,
    ShuffleListRouteStore,
    ShufflePreviewRouteStore,
} from "administration/shuffle/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "Shuffle",
        routes: [
            {
                name: "master.administration.shuffle",
                pattern: "/shuffle",
                children: [
                    {
                        name: "master.administration.shuffle.create-station",
                        pattern: "/create-station",
                        component: StationShuffleEdit,
                        store: StationShuffleEditRouteStore,
                    },
                    {
                        name: "master.administration.shuffle.edit-station",
                        pattern: "/:id/edit-station",
                        component: StationShuffleEdit,
                        store: StationShuffleEditRouteStore,
                    },
                    {
                        name: "master.administration.shuffle.create-podcast",
                        pattern: "/create-podcast",
                        component: PodcastShuffleEdit,
                        store: PodcastShuffleEditRouteStore,
                    },
                    {
                        name: "master.administration.shuffle.edit-podcast",
                        pattern: "/:id/edit-podcast",
                        component: PodcastShuffleEdit,
                        store: PodcastShuffleEditRouteStore,
                    },
                    {
                        name: "master.administration.shuffle.create-naki-nigun",
                        pattern: "/create-naki-nigun",
                        component: NakiNigunShuffleEdit,
                        store: NakiNigunShuffleEditRouteStore,
                    },
                    {
                        name: "master.administration.shuffle.edit-naki-nigun",
                        pattern: "/:id/edit-naki-nigun",
                        component: NakiNigunShuffleEdit,
                        store: NakiNigunShuffleEditRouteStore,
                    },
                    {
                        name: "master.administration.shuffle.list",
                        pattern: "",
                        component: ShuffleList,
                        store: ShuffleListRouteStore,
                        data: {
                            title: "Shuffles",
                        },
                    },
                    {
                        name: "master.administration.shuffle.preview",
                        pattern: "/:id",
                        component: ShufflePreview,
                        store: ShufflePreviewRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.SHUFFLE",
                order: 5,
                icon: "shuffles",
                route: "master.administration.shuffle.list",
            },
        ],
    });
})();
