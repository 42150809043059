import React from "react";
import { SectionListLoaderTemplate } from "themes/administration/naki-mobile/components";

class SectionListLoader extends React.Component {
    render() {
        return <SectionListLoaderTemplate {...this.props} />;
    }
}

export default SectionListLoader;
