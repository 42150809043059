import React from "react";
import { observer } from "mobx-react";
import { NakiNigunShuffleEditTemplate } from "themes/administration/shuffle/pages";
import { NakiNigunShuffleEditViewStore } from "administration/shuffle/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new NakiNigunShuffleEditViewStore(rootStore, componentProps),
    "nakiNigunShuffleEditViewStore"
)
@observer
class NakiNigunShuffleEdit extends React.Component {
    render() {
        return <NakiNigunShuffleEditTemplate {...this.props} />;
    }
}

export default NakiNigunShuffleEdit;
