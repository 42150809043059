import React from "react";
import { PropTypes } from "prop-types";
import { PortalDetailedActivity } from "administration/analytics/components";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import { mergeCss } from "mrb/common/utils";
import { defaultTemplate } from "common/hoc";
import { isNil } from "lodash";

function PodcastActivityTemplate({ podcastActivityViewStore, t }) {
    return (
        <PortalDetailedActivity
            store={podcastActivityViewStore}
            activityLabel="Podcast playbacks over period"
            totalLabel="Total # of playbacks"
            nameFieldRenderer={(props) => <PodcastNameField {...props} />}
            t={t}
        />
    );
}

PodcastActivityTemplate.propTypes = {
    podcastActivityViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function PodcastNameField({ summaryData }) {
    let route, id, subPodcastId;
    if (!isNil(summaryData.podcast.parentPodcastId)) {
        route = "master.administration.podcast.preview-group";
        id = summaryData.podcast.parentPodcastId;
        subPodcastId = summaryData.podcast.id;
    } else {
        route = "master.administration.podcast.preview";
        id = summaryData.podcast.id;
    }
    return (
        <React.Fragment>
            <p className="u-type--sml u-type--color--opaque">Podcast</p>
            <p className="u-display--flex u-display--flex--ai--center">
                <MrbRouterLink
                    routeName={route}
                    params={{ id: id }}
                    queryParams={{ subPodcastId: subPodcastId }}
                    className="c-analytics__list__title c-analytics__list__title--lrg">
                    {summaryData.podcast.title}
                </MrbRouterLink>
                <i
                    className={mergeCss(
                        "u-icon u-icon--base u-mar--left--tny",
                        summaryData.podcast && summaryData.podcast.isPremium ? "u-icon--premium" : null
                    )}
                />
            </p>
        </React.Fragment >
    );
}

export default defaultTemplate(PodcastActivityTemplate);
