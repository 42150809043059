import { makeObservable, observable, runInAction, action, computed, toJS } from "mobx";
import { MrbFieldSelectStore } from "mrb/components/select";
import { PodcastEditForm } from "administration/podcast/forms";
import { BasePodcastEditViewStore } from "administration/podcast/stores";
import { map, omit, filter, findIndex, merge, some } from "lodash";
import { PODCAST_FORM_STATUS } from "./PodcastGroupEditViewStore";
import { applicationErrorCodes, podcastContentType } from "common/constants";
import { UUID } from "common/utils";
import { isEmpty, isNil, find } from "lodash";

class PodcastEditViewStore extends BasePodcastEditViewStore {
    @observable overrideRadioUsersSettingsIsVisible = false;
    @observable overrideAppUsersSettingsIsVisible = false;
    @observable premiumInfoIsVisible = false;
    @observable podcastVolumes = [];
    @observable podcastVolumesChanged = false;
    @observable torahAnytimeSettings;

    skipPodcastContentTypeReaction = true;

    podcastContentTypes = [
        { name: "Default", id: podcastContentType.default },
        { name: "TorahAnytime", id: podcastContentType.torahAnytime },
    ];

    @computed get isFormChanged() {
        return (
            this.form.changed ||
            some(
                this.podcastVolumes,
                (podcastVolume) =>
                    podcastVolume.FORM_STATUS === PODCAST_FORM_STATUS.CHANGED ||
                    podcastVolume.FORM_STATUS === PODCAST_FORM_STATUS.UPDATED
            ) ||
            this.podcastVolumesChanged ||
            this.coverImageUploadViewStore.hasChanged
        );
    }

    @computed get isTorahAnytimeTypeSelected() {
        return this.form.$("podcastContentType").value === podcastContentType.torahAnytime;
    }

    constructor(rootStore, { routeStore }) {
        const id = rootStore.routerStore.routerState.params.id;
        super(rootStore, routeStore, {
            id: id,
            name: "podcast",
            FormClass: PodcastEditForm,
            autoFocusField: "title",
            navigateBack: "master.administration.podcast.list",
            redirectOnCreateSuccess: ({ id }) =>
                this.rootStore.routerStore.goTo("master.administration.podcast.preview", { id: id }),
            redirectOnUpdateSuccess: () =>
                this.rootStore.routerStore.goTo("master.administration.podcast.preview", { id: this.id }),
        });
        makeObservable(this);

        this.genreSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                isMulti: true,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findGenres(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createGenre({ name });
                    },
                },
            },
            this.form.$("genreIds")
        );

        this.locationSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findLocations(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createLocation({ name });
                    },
                },
            },
            this.form.$("locationId")
        );

        this.languageSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findLanguages(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createLanguage({ name });
                    },
                },
            },
            this.form.$("languageId")
        );

        this.producerSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: true,
                virtualized: false,
                actions: {
                    find: (searchTerm) => {
                        return this.routeStore.findProducers(searchTerm);
                    },
                    createOption: (name) => {
                        return this.routeStore.createProducer({ name });
                    },
                },
            },
            this.form.$("producerId")
        );

        this.podcastContentTypeSelectStore = new MrbFieldSelectStore(
            {
                isSearchable: false,
                virtualized: false,
                isClearable: false,
                /* eslint-disable */
                isOptionDisabled: ({ value }) =>
                    value == podcastContentType.torahAnytime && isNil(this.torahAnytimeSettings),
                /* eslint-enable */
                actions: {
                    find: () => {
                        return this.podcastContentTypes;
                    },
                },
            },
            this.form.$("podcastContentType")
        );

        if (this.isEdit) {
            this.reaction(
                () => this.form.$("isTurnedOnByDefault").value,
                (isTurnedOnByDefault) => {
                    if (isTurnedOnByDefault !== this.item.isTurnedOnByDefault) {
                        runInAction(() => {
                            this.overrideRadioUsersSettingsIsVisible = true;
                        });
                    } else {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = false;
                            this.form.$("overrideRadioUsersSettings").value = false;
                        });
                    }
                }
            );
            this.reaction(
                () => this.form.$("isTurnedOnByDefaultInApp").value,
                (isTurnedOnByDefault) => {
                    if (isTurnedOnByDefault !== this.item.isTurnedOnByDefaultInApp) {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = true;
                        });
                    } else {
                        runInAction(() => {
                            this.overrideAppUsersSettingsIsVisible = false;
                            this.form.$("overrideAppUsersSettings").value = false;
                        });
                    }
                }
            );
        }

        this.reaction(
            () => this.form.$("isPremium").value,
            (isPremium) => {
                if (!isPremium) {
                    this.form.$("premiumInfo").set(null);
                }
                if (isPremium) {
                    this.form.$("producerId").addIsRequiredRule();
                } else {
                    this.form.$("producerId").removeIsRequiredRule();
                }
            }
        );

        this.reaction(
            () => this.form.$("podcastContentType").value,
            (type) => {
                // Skip reaction on the first run
                /* eslint-disable */
                if (!this.skipPodcastContentTypeReaction) {
                    if (type == podcastContentType.default) {
                        this.form.$("podcastFeed.feedUrl").enable();
                        this.form.$("podcastFeed.feedUrl").addIsRequiredRule();
                        this.form.$("podcastFeed.feedUrl").value = null;

                        this.form.$("podcastFeed.episodeSortTypeId").addIsRequiredRule();
                    } else if (type == podcastContentType.torahAnytime) {
                        this.form.$("podcastFeed.feedUrl").removeIsRequiredRule();
                        this.form.$("podcastFeed.feedUrl").value = this.torahAnytimeSettings.apiUrl;

                        this.form.$("podcastFeed.episodeLimit").value = null;

                        this.form.$("podcastFeed.episodeSortTypeId").removeIsRequiredRule();
                        this.form.$("isPremium").value = false;
                        this.form.$("podcastFeed.hlsEnabled").value = false;
                    }
                }
                /* eslint-enable */
                this.skipPodcastContentTypeReaction = false;
            }
        );

        this.registerRouteExitBlocking();
    }

    async loadLookups() {
        await super.loadLookups();
        await this.fetchTorahAnytimeSettings();
    }

    resetForm() {
        this.form.set({
            isActive: true,
            isVisible: true,
            podcastFeed: {
                episodeSortTypeId: this.episodeSortTypes[0].id,
                recentlyUpdatedVisibility: true,
            },
            podcastContentType: podcastContentType.default,
        });
        this.podcastContentTypeSelectStore.setSelectedItem(
            find(this.podcastContentTypes, (type) => type.id === podcastContentType.default)
        );
    }

    get(id) {
        return this.routeStore.getPodcast(id);
    }

    create(data) {
        this._onAfterSave(data);
        data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        return this.routeStore.createPodcast(data);
    }

    _onAfterSave(data) {
        data.podcastVolumes = map(this.podcastVolumes, (podcastVolume, index) => {
            return {
                ...omit(podcastVolume, "FORM_STATUS"),
                sortOrder: index + 1,
            };
        });
        if (!data.isPremium && !isEmpty(data.premiumInfo)) {
            data.premiumInfo = null;
        }
    }

    update(data) {
        this._onAfterSave(data);
        if (this.coverImageUploadViewStore.hasChanged) {
            data.coverImageId = this.coverImageUploadViewStore.uploadedFileId;
        } else {
            data.coverImageId = this.item.coverImageId;
        }
        return this.routeStore.updatePodcast(data);
    }

    setItem(item) {
        this.podcastVolumes = item.podcastVolumes;
        item.podcastVolumes = null;
        super.setItem(item);
        this.genreSelectStore.setSelectedItem(item.genres);
        this.locationSelectStore.setSelectedItem(item.location);
        this.languageSelectStore.setSelectedItem(item.language);
        this.producerSelectStore.setSelectedItem(item.producer);
        this.podcastContentTypeSelectStore.setSelectedItem(
            find(this.podcastContentTypes, (type) => type.id === item.podcastContentType)
        );
        runInAction(() => {
            this.premiumInfoIsVisible = item.isPremium;
        });
        this.form.$changed = false;
        this.form.$("languageId").$changed = false;
        this.form.$("locationId").$changed = false;
        this.form.$("genreIds").$changed = false;
        this.form.$("producerId").$changed = false;
        this.form.$("podcastContentType").$changed = false;
    }

    @action.bound
    addPodcastVolume(item) {
        item.id = UUID.create();
        item.FORM_STATUS = PODCAST_FORM_STATUS.UPDATED;
        this.podcastVolumes.push(item);
        this.podcastVolumesChanged = true;
    }

    @action.bound
    deletePodcastVolume(podcastVolumeId) {
        this.podcastVolumes = filter(this.podcastVolumes, (podcastVolume) => podcastVolume.id !== podcastVolumeId);
        this.podcastVolumesChanged = true;
    }

    @action.bound
    updatePodcastVolume(id, item) {
        const index = findIndex(this.podcastVolumes, (podcastVolume) => podcastVolume.id === id);
        item.FORM_STATUS = PODCAST_FORM_STATUS.UPDATED;
        this.podcastVolumes[index] = merge(this.podcastVolumes[index], item);
        this.podcastVolumesChanged = true;
    }

    updateForm(values) {
        this.form.set({
            ...values,
            genreIds: values.genres ? map(values.genres, (genre) => genre.id) : null,
        });
    }

    @action.bound
    markPodcastVolumeAsChanged(podcastVolumeId) {
        const index = findIndex(this.podcastVolumes, (podcastVolume) => podcastVolume.id === podcastVolumeId);
        if (index > -1) {
            this.podcastVolumes[index].FORM_STATUS = PODCAST_FORM_STATUS.CHANGED;
        }
        this.podcastVolumesChanged = true;
    }

    @action.bound
    markPodcastVolumeAsUpdated(podcastVolumeId) {
        const index = findIndex(this.podcastVolumes, (podcastVolume) => podcastVolume.id === podcastVolumeId);
        if (index > -1) {
            this.podcastVolumes[index].FORM_STATUS = PODCAST_FORM_STATUS.UPDATED;
        }
        this.podcastVolumesChanged = true;
    }

    async fetchTorahAnytimeSettings() {
        try {
            const data = await this.routeStore.getTorahAnytimeSetting();
            this.setTorahAnytimeSettings(data);
            if (!data) {
                this.rootStore.notificationStore.warning("TorahAnytime disabled. TorahAnytime settings are missing.");
            }
        } catch (err) {
            this.rootStore.notificationStore.error("Unable to load TorahAnytime settings.", err);
        }
    }

    @action.bound
    setTorahAnytimeSettings(value) {
        this.torahAnytimeSettings = value;
    }

    @action.bound
    reorderPodcastVolumes(result) {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const newPodcastVolumes = Array.from(toJS(this.podcastVolumes));
        const podcastVolume = newPodcastVolumes.splice(source.index, 1)[0];
        podcastVolume.FORM_STATUS = PODCAST_FORM_STATUS.UPDATED;
        newPodcastVolumes.splice(destination.index, 0, podcastVolume);

        this.podcastVolumes = newPodcastVolumes;
        this.subPodcastsChanged = true;
    }

    registerRouteExitBlocking() {
        this.reaction(
            () => this.isFormChanged,
            (isChanged) => {
                this.routeStore.setIsRouteExitBlocked(isChanged);
            }
        );
    }

    onCreateSuccess(result) {
        this.routeStore.setIsRouteExitBlocked(false);
        super.onCreateSuccess(result);
    }

    onUpdateSuccess(result) {
        this.routeStore.setIsRouteExitBlocked(false);
        super.onUpdateSuccess(result);
    }

    onCreateError(err) {
        if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
            this.routeStore.setIsRouteExitBlocked(false);
            const podcast = err.data.response;
            this.rootStore.notificationStore.error(
                "Podcast created, but unexpected error occurred during RSS sync.",
                err
            );
            this.redirectOnCreateSuccess(podcast);
        } else {
            this.rootStore.notificationStore.error("An unexpected error occurred while trying to create podcast.", err);
        }
    }

    onUpdateError(err) {
        if (err.data.errorCode === applicationErrorCodes.rssSync.rssSyncFailed) {
            this.routeStore.setIsRouteExitBlocked(false);
            this.rootStore.notificationStore.error(
                "Podcast updated, but an unexpected error occurred during RSS sync.",
                err
            );
            this.redirectOnUpdateSuccess();
        } else {
            this.rootStore.notificationStore.error("An unexpected error occurred while trying to update podcast.", err);
        }
    }
}

export default PodcastEditViewStore;
