import { moduleProviderFactory } from "mrb/core";
import {
    AdminProducerList,
    ProducerPaymentReportPreview,
    AdminProducerPreview,
    AdminProducerAccounts,
    AdminProducerEdit,
    AdminProducerAccountPreview,
} from "administration/producer/pages";
import {
    AdminProducerListRouteStore,
    ProducerPaymentReportPreviewRouteStore,
    AdminProducerPreviewRouteStore,
    AdminProducerAccountEditRouteStore,
    AdminProducerEditRouteStore,
    AdminProducerAccountPreviewRouteStore,
} from "administration/producer/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "Producer",
        routes: [
            {
                name: "master.administration.producer",
                pattern: "/producer",
                children: [
                    {
                        name: "master.administration.producer.create",
                        pattern: "/create",
                        component: AdminProducerEdit,
                        store: AdminProducerEditRouteStore,
                    },
                    {
                        name: "master.administration.producer.edit",
                        pattern: "/:id/edit",
                        component: AdminProducerEdit,
                        store: AdminProducerEditRouteStore,
                    },
                    {
                        name: "master.administration.producer.list",
                        pattern: "",
                        component: AdminProducerList,
                        store: AdminProducerListRouteStore,
                        data: {
                            title: "ADMINISTRATION.PRODUCER.PAYMENT.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.administration.producer.preview",
                        pattern: "/:id",
                        component: AdminProducerPreview,
                        store: AdminProducerPreviewRouteStore,
                    },
                    {
                        name: "master.administration.producer.preview.payment",
                        pattern: "/:id/payment",
                        component: ProducerPaymentReportPreview,
                        store: ProducerPaymentReportPreviewRouteStore,
                    },
                    {
                        name: "master.administration.producer.accounts",
                        pattern: "/:id/accounts",
                        component: AdminProducerAccounts,
                        store: AdminProducerAccountEditRouteStore,
                    },
                    {
                        name: "master.administration.producer.account",
                        pattern: "/account/:id",
                        component: AdminProducerAccountPreview,
                        store: AdminProducerAccountPreviewRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.PRODUCER",
                order: 10,
                icon: "producers",
                route: "master.administration.producer.list",
            },
        ],
    });
})();
