import { BaseStatsWidgetViewStore } from "administration/analytics/stores";
import { timeRangeType } from "administration/analytics/constants";

class StationAnalyticsStatsWidgetViewStore extends BaseStatsWidgetViewStore {
    constructor(rootStore, { routeStore, stationId }) {
        super(rootStore, {
            routeName: "master.administration.analytics.station-activity",
            routeParams: { id: stationId },
        });
        this.routeStore = routeStore;
        this.stationId = stationId;

        this.setTimeRange(timeRangeType.thisMonth);
    }

    fetch(filter) {
        const params = {
            from: filter.from,
            to: filter.to,
            previousCycleFrom: filter.previousCycleFrom,
            previousCycleTo: filter.previousCycleTo,
            timeZone: filter.timeZone,
        };
        return this.routeStore.getStationAnalyticsStats(this.stationId, params);
    }
}

export default StationAnalyticsStatsWidgetViewStore;
