import React, { useCallback, useState } from "react";
import ContentLoader from "react-content-loader";

const OrderTableLoaderTemplate = (props) => {
    const [loaderWidth, setLoaderWidth] = useState(0);
    const loaderHeight = 108;

    const ref = useCallback((node) => {
        if (node !== null) setLoaderWidth(node.getBoundingClientRect().width);
    }, []);

    const tableList = [];
    let y = 0;

    for (let i = 0; i < 2; i++) {
        tableList.push(
            <React.Fragment>
                <rect x="0" y={y} rx="6" ry="6" width="15%" height="12" />
                <rect x="60%" y={y} rx="6" ry="6" width="6%" height="12" />
                <rect x="72%" y={y} rx="6" ry="6" width="9%" height="12" />
                <rect x="88%" y={y} rx="6" ry="6" width="12%" height="12" />
                <rect x="0" y={y + 22} rx="6" ry="6" width="100%" height="1" />
            </React.Fragment>
        );

        y += 32;
    }

    return (
        <div ref={ref}>
            <div className="c-card c-card--primary">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height={loaderHeight}
                    viewBox={`0 0 ${loaderWidth} ${loaderHeight}`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...props}
                >
                    {tableList}

                    <rect x="72%" y={y} rx="6" ry="6" width="9%" height="12" />
                    <rect x="88%" y={y} rx="6" ry="6" width="12%" height="12" />
                    <rect x="0" y={y + 22} rx="6" ry="6" width="100%" height="1" />

                    <rect x="72%" y={y + 32} rx="6" ry="6" width="9%" height="12" />
                    <rect x="88%" y={y + 32} rx="6" ry="6" width="12%" height="12" />
                </ContentLoader>
            </div>
        </div>
    );
};

export default OrderTableLoaderTemplate;
