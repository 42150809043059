import React from "react";
import { PropTypes } from "prop-types";
import { MrbPreviewPage } from "mrb/components/layout";
import { AnalyticsGraphLoader } from "administration/analytics/components";
import {
    ProducerPreviewSummary,
    ProducerPreviewMostPopularContent,
    AdminProducerAccountsList,
} from "administration/producer/components";
import { ProducerReportsTable } from "common/components/producer";
import { LineChart } from "common/components";
import { TableNoDataOverlay } from "common/components";
import { defaultTemplate } from "common/hoc";

function AdminProducerPreviewTemplate({ adminProducerPreviewViewStore, t }) {
    const { gridStore, chartStore, routeStore } = adminProducerPreviewViewStore;
    return (
        <MrbPreviewPage store={adminProducerPreviewViewStore} t={t}>
            <div className="c-container--sidebar u-padd--none">
                <div>
                    <AdminProducerAccountsList producerId={adminProducerPreviewViewStore._id} routeStore={routeStore} />

                    <div className="u-mar--bottom--sml">
                        <h1 className="u-type--base u-mar--bottom--sml">Revenue in the last 12 months</h1>
                        <div className="c-card c-card--primary">
                            <LineChart store={chartStore} loaderRenderer={() => <AnalyticsGraphLoader />} />
                        </div>
                    </div>

                    <ProducerReportsTable
                        title="Reports"
                        gridStore={gridStore}
                        emptyStateRenderer={TableNoDataOverlay}
                        t={t}
                    />
                </div>

                <div>
                    <div className="row">
                        <div className="col col-sml-12 col-lrg-6 col-xxlrg-12">
                            <ProducerPreviewSummary producerPreviewViewStore={adminProducerPreviewViewStore} />
                        </div>
                        <div className="col col-sml-12 col-lrg-6 col-xxlrg-12">
                            <ProducerPreviewMostPopularContent
                                producerPreviewViewStore={adminProducerPreviewViewStore}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MrbPreviewPage>
    );
}

AdminProducerPreviewTemplate.propTypes = {
    adminProducerPreviewViewStore: PropTypes.object.isRequired,
    t: PropTypes.func,
};

export default defaultTemplate(AdminProducerPreviewTemplate);
