import { moduleProviderFactory } from "mrb/core";
import {
    NakiNigunArtistEdit,
    NakiNigunArtistList,
    NakiNigunArtistPreview,
    NakiNigunAlbumTrackList,
    NakiNigunAlbumTrackEdit,
} from "administration/naki-nigun/pages";
import {
    NakiNigunArtistEditRouteStore,
    NakiNigunArtistListRouteStore,
    NakiNigunArtistPreviewRouteStore,
    NakiNigunAlbumTrackListRouteStore,
    NakiNigunAlbumTrackEditRouteStore,
} from "administration/naki-nigun/stores";

(function () {
    moduleProviderFactory.module("administration").register({
        name: "NakiNigunArtist",
        routes: [
            {
                name: "master.administration.naki-nigun.artist",
                pattern: "/naki-nigun/artist",
                children: [
                    {
                        name: "master.administration.naki-nigun.artist.create",
                        pattern: "/create",
                        component: NakiNigunArtistEdit,
                        store: NakiNigunArtistEditRouteStore,
                    },
                    {
                        name: "master.administration.naki-nigun.artist.edit",
                        pattern: "/:id/edit",
                        component: NakiNigunArtistEdit,
                        store: NakiNigunArtistEditRouteStore,
                    },
                    {
                        name: "master.administration.naki-nigun.artist.list",
                        pattern: "",
                        component: NakiNigunArtistList,
                        store: NakiNigunArtistListRouteStore,
                        data: {
                            title: "ADMINISTRATION.NAKI_NIGUN.LIST.TITLE",
                        },
                    },
                    {
                        name: "master.administration.naki-nigun.artist.preview",
                        pattern: "/:id",
                        component: NakiNigunArtistPreview,
                        store: NakiNigunArtistPreviewRouteStore,
                    },
                    {
                        name: "master.administration.naki-nigun.artist.album.tracks",
                        pattern: "/album/:id/tracks",
                        component: NakiNigunAlbumTrackList,
                        store: NakiNigunAlbumTrackListRouteStore,
                    },
                    {
                        name: "master.administration.naki-nigun.artist.album.tracks.create",
                        pattern: "/album/:id/tracks/create",
                        component: NakiNigunAlbumTrackEdit,
                        store: NakiNigunAlbumTrackEditRouteStore,
                    },
                ],
            },
        ],
        menu: [
            {
                title: "ADMINISTRATION.MENU.NAKI_NIGUN",
                order: 4,
                icon: "nigun",
                route: "master.administration.naki-nigun.artist.list",
            },
        ],
    });
})();
