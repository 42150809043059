import React from "react";
import { observer } from "mobx-react";
import { PodcastPreviewTemplate } from "themes/administration/podcast/pages";
import { PodcastPreviewViewStore } from "administration/podcast/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new PodcastPreviewViewStore(rootStore, componentProps),
    "podcastPreviewViewStore"
)
@observer
class PodcastPreview extends React.Component {
    render() {
        return <PodcastPreviewTemplate {...this.props} />;
    }
}

export default PodcastPreview;
