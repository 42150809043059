import React from "react";
import { OrderPreviewTemplate } from "themes/administration/order/pages";
import { OrderPreviewViewStore } from "administration/order/stores";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) => new OrderPreviewViewStore(rootStore, componentProps),
    "orderPreviewViewStore"
)
class OrderPreview extends React.Component {
    render() {
        return <OrderPreviewTemplate {...this.props} />;
    }
}

export default OrderPreview;
