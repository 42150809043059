import React from "react";
import ContentLoader from "react-content-loader";

const FeaturedSectionLoaderTemplate = (props) => {
    const { gridNum = 9 } = props;
    const gridList = [];

    for (let i = 0; i < gridNum; i++) {
        gridList.push(
            <div className="c-card--lrg c-card--primary" key={Math.random() * i}>
                <ContentLoader
                    speed={2}
                    width="100%"
                    height="100%"
                    viewBox={`0 0 293 164`}
                    backgroundColor="#262729"
                    foregroundColor="#2b2b2b"
                    {...props}
                >
                    <rect x="0" y="0" rx="6" ry="6" width="100%" height="100%" />
                </ContentLoader>
            </div>
        );
    }

    return <div className="c-grid c-grid--3 c-grid__gap--6 u-height--660">{gridList}</div>;
};

export default FeaturedSectionLoaderTemplate;
